import React, { Component } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import Product from '../../../components/admin/order/product'
import Attachment from '../../../components/admin/order/attachment'
import adminStore from '../../../store/admin_store'
import { setProducts, setStorageTypes, setProductItems, setAttachmentItems, setOptionalColumns } from '../../../actions/admin/order/form'

const store = adminStore()

export default class Form extends Component {
  componentWillMount() {
    if (this.props.products) store.dispatch(setProducts(this.props.products))
    if (this.props.storage_types) store.dispatch(setStorageTypes(this.props.storage_types))
    if (this.props.product_items) store.dispatch(setProductItems(this.props.product_items))
    if (this.props.attachment_items) store.dispatch(setAttachmentItems(this.props.attachment_items))
    store.dispatch(setOptionalColumns(this.props.optional_columns))
  }

  componentWillUnmount() {
    store.dispatch(setProductItems([]))
    store.dispatch(setAttachmentItems([]))
  }

  render() {
    return (
      <Provider store={store}>
        <div>
          <Product role={this.props.role} shop_id={this.props.shop_id} />
          <Attachment />
        </div>
      </Provider>
    )
  }
}
