import React, { Component } from 'react'
import AsyncSelect from 'react-select/async'
import _ from "lodash";

class AddProductItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      items: [],
      storage_types: []
    }
    this.addItem = this.addItem.bind(this)
    this.updateItem = this.updateItem.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.fetchDefaultStorageTypes = this.fetchDefaultStorageTypes.bind(this)
  }
  componentDidMount() {
    this.fetchDefaultStorageTypes()
      .then(data => {
        this.setState({ storage_types: data.storage_types })
      })
  }
  async fetchDefaultStorageTypes() {
    return await fetch(`/operator/receipts/products_by_shop?shop_id=${this.props.receipt?.shop_id}`,
    {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token,
      },
      credentials: 'same-origin'
    }).then(res => res.json())
  }
  addItem() {
    let items = this.state.items
    items.push({ key: Math.random() })
    this.setState({ items })
  }
  removeItem(key) {
    let items = this.state.items
    this.setState({ items: items.filter(i => i.key !== key) })
  }
  updateItem(index, product_id, product_label) {
    let items = this.state.items
    items[index] = {
      key: items[index].key,
      product_id: product_id,
      product_label: product_label
    }
    this.setState({ items })
  }
  render() {
    const { items } = this.state
    return(
      <>
        <table className="table">
          <tbody>
            {
              items.map((item, index) => (
                <Item key={index} index={index}
                  {...this.props} item={item}
                  updateItem={this.updateItem}
                  removeItem={this.removeItem}
                  storage_types={this.state.storage_types}
                />
              ))
            }
          </tbody>
        </table>
        <div className='panel-footer text-right' style={{display: 'flex', alignItems: 'center'}}>
          <a className="btn btn-success" onClick={this.addItem}>增加品項</a>
          {
            this.state.items.length > 0
              ? <div className='panel-footer text-right'>
                  <button className="btn btn-primary" type="submit">更新</button>
                </div>
              : null
          }
        </div>
        <hr className='hr'></hr>
      </>
    )
  }
}

class Item extends Component {
  constructor(props) {
    super(props)
    this.loadOptions = this.loadOptions.bind(this)
    this.debouncedLoadOptions = _.debounce(this.loadOptions, 500);
    this.state = {
      products: []
    }
    this.toOption = this.toOption.bind(this)
    this.fetchDefaultProducts = this.fetchDefaultProducts.bind(this)
    this.fetchProducts = this.fetchProducts.bind(this)
    this.handleProductChange = this.handleProductChange.bind(this)
    this.handleItemDelete = this.handleItemDelete.bind(this)
  }
  componentDidMount() {
    this.fetchDefaultProducts()
  }
  toOption(product) {
    return { label: [product.name, product.uid, product.barcode].filter(Boolean).join("/"), value: product.id }
  }
  fetchDefaultProducts() {
    this.fetchProducts()
      .then(data => {
        this.setState({products: data.products})
      })
  }
  async fetchProducts(inputValue = "") {
    return await fetch(`/operator/products.json?shop_id=${this.props.receipt?.shop_id}&search=${inputValue}&product_only=true&limit=50`,
    {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token,
      },
      credentials: 'same-origin'
    }).then(res => res.json())
  }
  loadOptions(inputValue, callback){
    if(inputValue.length <2){
      callback([])
      return
    }
    this.fetchProducts(inputValue)
    .then(data => {
      let products = this.state.products
      let options = data.products.map(product=>this.toOption(product))
      for(let shop_product of data.products){
        let found = false
        for(let product of products){
          if(shop_product.uid == product.uid){
            found = true
            break
          }
        }
        if(!found){
          products.push(shop_product)
        }
      }
      this.setState({ products })
      callback(options)
    })      
  };
  handleProductChange = (product_id, product_label) => {
    this.props.updateItem(this.props.index, product_id, product_label)
  }
  handleItemDelete() {
    this.props.removeItem(this.props.item.key)
  }
  render() {
    let { item, storage_types } = this.props;
    const storage_type_select_options = storage_types.map(function (storage_type, index) {
      let name = `${storage_type.code} - ${storage_type.name}`
      let id = storage_type.id
      return <option key={index} value={id}>{name}</option>
    })

    return(
      <tr>
        <td className="text-center" width="24%">
          <div style={{display: 'flex', alignItems: 'flex-start'}}>
            <div style={{paddingTop: '4px'}}>
              <a className="btn btn-danger btn-xs" onClick={this.handleItemDelete}>X</a>
              <input type="hidden" name="receipt_id" value={this.props.receipt?.id}/>
            </div>
            <div style={{flexGrow: 1, paddingLeft: '8px'}}>
              <AsyncSelect
                name="receipt_items[][product_id]"
                className='react-select-container'
                classNamePrefix="react-select"  
                value={item.product_id ? { label: item.product_label, value: item.product_id } : null}
                searchable={true}
                clearable={false}
                placeholder="請選擇商品"
                noOptionsMessage={() => '找不到商品，請輸入兩碼以上關鍵字'}
                onChange={(e)=>this.handleProductChange(e.value,e.label)}
                loadOptions={(inputValue,callback) => this.debouncedLoadOptions(inputValue,callback)}
                defaultOptions={[...this.state.products.map(product=>this.toOption(product)), {label: "輸入兩碼以上關鍵字查看更多..." ,value: undefined, isDisabled: true}]}
              />
            </div>
          </div>
        </td>
        <td className="text-center" width="8%">
          <select name="receipt_items[][product_storage_type_id]"
            className="custom-select form-control" 
            defaultValue={item.product_storage_type_id} placeholder="倉別"
          >
            {storage_type_select_options}
          </select>
        </td>
        <td className="text-center" width="8%">
          <input name="receipt_items[][pcs_per_box]"
            type="number" min={1} className="form-control" required='required' 
            defaultValue={item.pcs_per_box} placeholder="箱入數"
          />
        </td>
        <td className="text-center" width="8%">
          {
            this.state.products.find(p => p.id == item.product_id)?.valid_period
              ? <input name="receipt_items[][expiration_date]"
                  className="form-control datepicker-input"
                  defaultValue={item.expiration_date} type="date" placeholder="效期"
                />
              : null
          }
        </td>
        <td className="text-center" width="8%">
          <input name="receipt_items[][batch]"
            className="form-control ignore" type="text" 
            defaultValue={item.batch} placeholder="批號"
          />
        </td>
        <td className="text-center" width="8%">
          <input name="receipt_items[][scheduled_pcs]"
            className="form-control ignore" type="number" min={0} 
            defaultValue={item.scheduled_pcs} placeholder="預計入庫"
          />
        </td>
        <td className="text-center" width="8%">
          <input name="receipt_items[][verified_pcs]"
            className="form-control ignore" type="number" min={0}
            defaultValue={item.verified_pcs} placeholder="驗收數量"
          />
        </td>
        <td className="text-center" width="8%"></td>
        <td className="text-center" width="8%"></td>
        <td className="text-center" width="8%"></td>
      </tr>
    )
  }
}

export default AddProductItem;
