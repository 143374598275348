import {
  BUNDLE_SET_PRODUCTS, BUNDLE_SET_PRODUCT_ITEMS, BUNDLE_ADD_PRODUCT_ITEM, BUNDLE_DELETE_PRODUCT_ITEM,
  BUNDLE_SET_PRODUCT_STORAGE_TYPES
} from '../../../actions/action_types'

const initialState = { product_storage_types: [], products: [], product_items: [] }

export default function AdminBundleForm(state = initialState, action) {
  switch (action.type) {
  case BUNDLE_SET_PRODUCTS:
    return {
      ...state,
      products: action.products
    }
  case BUNDLE_SET_PRODUCT_ITEMS:
    return {
      ...state,
      product_items: action.product_items
    }
  case BUNDLE_ADD_PRODUCT_ITEM:
    return {
      ...state,
      product_items: [
        ...state.product_items,
        { id: action.item.id },
      ]
    }
  case BUNDLE_DELETE_PRODUCT_ITEM:
    return {
      ...state,
      product_items: state.product_items.filter(item => item.id !== action.id),
    }
  case BUNDLE_SET_PRODUCT_STORAGE_TYPES:
    return {
      ...state,
      product_storage_types: action.product_storage_types
    }
  default:
    return state
  }
}
