import React, { Component } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import Product from '../../../components/common/receipt/product'
import adminStore from '../../../store/operator_store'
import { setProducts, setStorageTypes, setProductItems } from '../../../actions/common/receipt/form'

const store = adminStore()

export default class Form extends Component {
  componentWillMount() {
    if (this.props.products) store.dispatch(setProducts(this.props.products))
    if (this.props.storage_types) store.dispatch(setStorageTypes(this.props.storage_types))
    if (this.props.product_items) store.dispatch(setProductItems(this.props.product_items))
  }

  componentWillUnmount() {
    store.dispatch(setProductItems([]))
  }

  render() {
    return (
      <Provider store={store}>
        <div>
          <Product />
        </div>
      </Provider>
    )
  }
}
