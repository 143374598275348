import React, { Component } from 'react'
import AsyncSelect from 'react-select/async'
import _ from "lodash";
import DataTable from 'react-data-table-component';


export default class TransferForm extends Component {

  constructor(props) {
    super(props)
    this.onShopSelect = this.onShopSelect.bind(this)
    this.syncProductStorageType = this.syncProductStorageType.bind(this)
    this.state = {
      shop_products: [],
      storage_types: [],
      selected_products: [],
      filtered_product_storages: [],
      candidates: []
    }
    this.debouncedLoadOptions = _.debounce(this.loadOptions, 500);
    this.handleProductChange = this.handleProductChange.bind(this)
    this.searchProductStorages = this.searchProductStorages.bind(this)
    this.onCandidateProductStorageTypeChange = this.onCandidateProductStorageTypeChange.bind(this)
    this.onBatchChangeProductStorageType = this.onBatchChangeProductStorageType.bind(this)
    this.onCandidateQuantityChange = this.onCandidateQuantityChange.bind(this)
    this.removeCandidate = this.removeCandidate.bind(this)
    this.columns = [
      {
        name: '商品',
        selector: ({product})=>product,
        sortable: true,
      },
      {
        name: '倉別',
        selector: ({product_storage_type_code})=>product_storage_type_code,
        sortable: true,
      }, {
        name: '效期',
        selector: ({expiration_date})=>expiration_date,
        sortable: true,
      }, {
        name: '批號',
        selector: ({batch})=>batch,
        sortable: true,
      }, {
        name: '可用庫存(',
        selector: ({available_pcs})=>available_pcs,
        sortable: true,
      }, {
        name: '',
        cell: (row) => <a className='btn btn-primary' onClick={(e) => {
          this.handleAddProductStorage(row.id)
        }}><ion-icon class="ion-md-add"></ion-icon></a>,
        sortable: false,
      }
    ];
  }

  fetchDefaultProducts() {
    this.fetchProducts()
      .then(data => {
        this.setState({ shop_products: data.products })
      })
  }
  async fetchProducts(inputValue = "") {
    return await fetch(`/operator/products.json?shop_id=${this.state.shop_id}&search=${inputValue}&limit=50`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json())
  }
  handleProductChange(selected_products) {
    this.setState({ selected_products });
  };

  removeCandidate(id){
    this.setState({candidates: this.state.candidates.filter(c=>c.id != id)})
  }

  onCandidateProductStorageTypeChange(id,value){
    let candidates = this.state.candidates
    for(let candidate of candidates){
      if (candidate.id == id) {
        candidate.product_storage_type_id = value;
        break
      }
    }
    this.setState({candidates:candidates})
  }
  onCandidateQuantityChange(id,value){
    for(let candidate of this.state.candidates){
      if (candidate.id == id) candidate.transfer_quantity = value;break
    }
    // this.setState({candidates:candidates})
    // DO NOT Trigger updateState => or input in Datatable will be unfocused
  }
  onCandidateBatchChange(id,value){
    for(let candidate of this.state.candidates){
      if (candidate.id == id) candidate.batch = value;break
    }
  }

  onBatchChangeProductStorageType(e){
    this.setState({candidates:this.state.candidates.map(c=>{
      c.product_storage_type_id = e.target.value
      return c
    })})
  }

  handleAddProductStorage(id) {
    let exist = this.state.candidates.filter(c => c.id == id)
    if (exist.length > 0) {
      return
    }
    let target = this.state.filtered_product_storages.filter(s => s.id == id)[0]
    let candidates = this.state.candidates
    candidates.push(target)
    this.setState({ candidates: candidates})
  }

  searchProductStorages() {
    let product_storage_type_id = document.getElementsByName("product-storage-type-filter")[0].value
    let products = this.state.selected_products.map(product_option => product_option.value)
    let batch = this.refs.batch_filter.value
    let expiration_date = this.refs.expiration_date_filter.value
    let expiration_date_condition = this.refs.expiration_date_condition.value
    let existing = this.state.candidates.map(c => c.id)
    fetch(`/operator/product_storages.json?product_storage_type_id=${product_storage_type_id}&products=${products}&batch=${batch}&expiration_date=${expiration_date}&expiration_date_condition=${expiration_date_condition}&not=${existing}`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        this.setState({ filtered_product_storages: data.data.filter(p=>p.available_pcs > 0) })
      })
  }
  toOption(product) {
    return { label: [product.barcode, product.uid, product.name].filter(Boolean).join("/"), value: product.id }
  }

  loadOptions(inputValue, callback) {
    if (!this.state.shop_id || inputValue.length < 2) {
      callback([])
      return
    }
    this.fetchProducts(inputValue)
      .then(data => {
        let shop_products = this.state.shop_products
        let options = data.products.map(product => this.toOption(product))
        for (let option of options) {
          let found = false
          for (let shop_product of shop_products) {
            if (shop_product.value == option.value) {
              found = true
              break
            }
          }
          if (!found) {
            shop_products.push(option)
          }
        }
        this.setState({ shop_products: shop_products })
        callback(options)
      })
  };


  syncProductStorageType(shop_id) {
    fetch(`/operator/product_storage_types.json?shop_id=${shop_id}`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        this.setState({ storage_types: data.storage_types })

      })
  }
  onShopSelect(e) {
    let shop_id = e.target.value
    this.setState({ shop_id: shop_id }, () => {
      this.fetchDefaultProducts()
      this.handleProductChange([])
    })
    this.syncProductStorageType(shop_id)
  }


  render() {
    return (
      <div className='panel' id='product_form'>
        <div className="form-horizontal">
          <div className='panel-heading'>
            <div className="form-group">
              <label className="col-md-2 control-label required">
                調撥單號
              </label>
              <div className="col-md-8">
                <input required="required" className="form-control" type="text" name="transfer[title]" id="transfer_token" />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">
                備註
              </label>
              <div className="col-md-8">
                <input className="form-control" type="text" name="transfer[note]" id="transfer_token" />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label required">
                客戶
              </label>
              <div className="col-md-8">
                <select name="transfer[shop_id]" className='form-control' defaultValue="" onChange={this.onShopSelect}>
                  <option key="no-shop" value="" disabled="disabled" >請選擇客戶</option>
                  {
                    this.props.shops.map(shop => {
                      return <option key={shop.id} value={shop.id}>{shop.name}</option>
                    })
                  }
                </select>
              </div>
            </div>
          </div>
          <div className='panel-body'>
            <div className="form-group">
              <label className="col-md-2 control-label required">
                商品選取
              </label>
              <div className="col-md-8">
                <AsyncSelect name="products"
                  className='react-select-container'
                  classNamePrefix="react-select"
                  searchable={true}
                  clearable={false}
                  isMulti={true}
                  value={this.state.selected_products}
                  onChange={this.handleProductChange}
                  required={true}
                  placeholder={!this.state.shop_id ? '請先選擇客戶' : "請選擇商品"}
                  noOptionsMessage={() => !this.state.shop_id ? '請先選擇客戶' : '找不到商品，請輸入兩碼以上關鍵字'}
                  loadOptions={(inputValue, callback) => this.debouncedLoadOptions(inputValue, callback)}
                  defaultOptions={
                    !this.state.shop_id
                      ? []
                      : [...this.state.shop_products.map(product=>this.toOption(product)), {label: "輸入兩碼以上關鍵字查看更多..." ,value: undefined, isDisabled: true}]
                  }
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">
                效期
              </label>
              <div className="col-md-4">
                <input className="form-control" type="date" ref="expiration_date_filter" />
              </div>
              <div className="col-md-4">
                <select className="form-control" ref="expiration_date_condition">
                  <option value="before">以前</option>
                  <option value="equal">等於</option>
                  <option value="after">以後</option>
                  <option value=''>忽略</option>
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">
                倉別
              </label>
              <div className="col-md-4">
                <select className="form-control" name='product-storage-type-filter'>
                  <option value=''>全部</option>
                  {
                    this.state.storage_types.map(type => <option key={type[0]} value={type[0]}>{`${type[2]} - ${type[1]}`}</option>)
                  }
                </select>
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">
                批號
              </label>
              <div className="col-md-4">
                <input className="form-control" type="text" ref="batch_filter" />
              </div>
              <div className="col-md-4">
                <a className="btn btn-primary btn-block" onClick={this.searchProductStorages}>篩選</a>
              </div>
            </div>
            <hr />
            <div className='row'>
              <div className='col-md-12'>
                <DataTable
                  title="篩選結果"
                  columns={this.columns}
                  pagination={true}
                  data={this.state.filtered_product_storages.filter((p)=> !this.state.candidates.map(c=>c.id).includes(p.id))}
                />
              </div>
            </div>
            {
              this.state.candidates.length > 0 ?
              <div className='row'>
              <div className='col-md-12'>
                <DataTable
                  title="待轉清單"
                  columns={
                    [
                      {
                        name: '原始庫存',
                        sortable: true,
                        cell: (row) => {
                          return <div>
                            {[
                              row.product,
                              row.product_storage_type_code,
                              row.expiration_date,
                              row.batch
                            ].filter(e=>e).map((p,index)=><div key={index}>{p}</div>)}
                            <input type='hidden' name="items[][source_product_storage_id]" value={row.id}></input>
                          </div>
                        }
                      },
                      {
                        name: '可用PCS',
                        selector: ({available_pcs})=>available_pcs,
                        sortable: true,
                      },
                      {
                        name: <div>
                          <select className='form-control' onChange={this.onBatchChangeProductStorageType}>
                            <option value="">目標倉別</option>
                            {
                              this.state.storage_types.map(type => <option key={type[0]} value={type[0]}>{`${type[2]} - ${type[1]}`}</option>)
                            }
                          </select>
                        </div>,
                        cell: (row) => {
                          return <select required='required' className='form-control' name="items[][product_storage_type_id]" value={row.product_storage_type_id} onChange={
                            (e)=>this.onCandidateProductStorageTypeChange(row.id,e.target.value)
                          }>
                            {
                              this.state.storage_types.map(type => <option key={type[0]} value={type[0]}>{`${type[2]} - ${type[1]}`}</option>)
                            }
                          </select>
                        }
                      },
                      {
                        name: '目標批號',
                        cell: (row)=><input name="items[][batch]" className='form-control' type='text' defaultValue={row.batch} onChange={(e)=>this.onCandidateBatchChange(row.id,e.target.value)}></input>
                      },
                      {
                        name: '待選數量',
                        cell: (row)=><input required='required' name="items[][quantity]" className='form-control' type='number' min={0} max={row.available_pcs} defaultValue={row.transfer_quantity || row.available_pcs} onChange={(e)=>this.onCandidateQuantityChange(row.id,e.target.value)}></input>
                      },
                      {
                        name,
                        cell: (row)=><a className='btn btn-danger btn-outline' onClick={(e)=>this.removeCandidate(row.id)}>
                          <ion-icon class="ion-md-remove"></ion-icon>
                        </a>

                      }
                    ]
                  }
                  pagination={false}
                  data={
                    this.state.candidates.map(c=>c)
                  }
                />
              </div>
              <div className='row'>
                <div className='col-md-12'>
                <hr></hr>
                </div>
              </div>
              <div className='row'>
                <div className='col-md-12'>
                  <button type='submit' className='btn btn-primary'>確認送出</button>
                </div>
              </div>
            </div> : ''
            }
          </div>
        </div>

      </div>
    )
  }
}
