import React, { Component } from 'react'
import Select from 'react-select'

export default class ShopProductStorageTypeTransferRuleIndex extends Component {
  constructor(props) {
    super(props)
    console.log(props)
    this.state = {
      rules: this.props.rules.map(rule=>{
        rule.key = rule.id;
        return rule
      })
    }
    console.log(this.state)
    this.handleItemAdd = this.handleItemAdd.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.updateThreshold = this.updateThreshold.bind(this)
  }
  componentDidMount() {
  }

  handleItemAdd() {
    let rules = this.state.rules
    rules.push({ key: Math.random() })
    this.setState({ rules: rules })
  }
  removeItem(key) {
    let rules = this.state.rules.filter(e => e.key != key)
    this.setState({ rules: rules })
  }

  updateThreshold(key,value){
    let rules = this.state.rules
    let rule = rules.find(rule=>rule.key==key)
    rule.original_expiration_date_threshold = value
    this.setState({ rules: rules })

  }

  render() {
    const { rules } = this.state
    const {product_storage_types} = this.props
    const product_storage_type_options = product_storage_types.map(product_storage_type=>{
      return { value: product_storage_type.id, label: `${product_storage_type.code} - ${product_storage_type.name}` }
    })
    return (
      <div>
        {
          rules.map((rule,index) => {
            console.log(rule)
            return <div key={rule.key}>
              <div className="form-group">
                <label className="col-md-1 control-label">
                原始效期</label>
                <div className="col-md-2">
                  <div className="input-group">
                    <input className="form-control" type="number" onChange={(e)=>this.updateThreshold(rule.key,e.target.value)} value={rule.original_expiration_date_threshold} name="rules[][original_expiration_date_threshold]"/>
                    <div className="input-group-addon">
                      {
                        rules[index+1] && rules[index+1].original_expiration_date_threshold ? `天 ~ ${rules[index+1].original_expiration_date_threshold - 1}天` : "天以上"
                      }  
                    </div>
                  </div>
                </div>
                <label className="col-md-1 control-label">低於比例</label>
                <div className="col-md-2">
                  <div className="input-group">
                    <input max="100" min="0" className="form-control" type="number" defaultValue={rule.ratio} name="rules[][ratio]" />
                    <div className="input-group-addon">%</div>
                  </div>
                </div>
                <label className="col-md-1 control-label">低於天數</label>
                <div className="col-md-1">
                  <input className="form-control" type="number" defaultValue={rule.days_before_expiration} name="rules[][days_before_expiration]"  />
                </div>
                <label className="col-md-1 control-label">轉換至</label>
                <div className="col-md-2">
                  <Select
                    name="rules[][target_product_storage_type_id]"
                    options={product_storage_type_options}
                    defaultValue={rule.target_product_storage_type_id ? product_storage_type_options.find(e=>e.value==rule.target_product_storage_type_id) : null}                      
                    searchable={true}
                    clearable={false}
                    required={true}
                    placeholder="請選擇倉別"
                  />

                </div>
                <label className="col-md-1 control-label">
                  <a href='#!' onClick={()=>this.removeItem(rule.key)} className='btn btn-link text-danger'>
                  <i className='fa fa-trash'>
                  </i>
                    </a>
                    </label>

              </div>
            </div>
          })
        }

        <div className='form-group'>
          <div className='col-md-1'/>
          <div className='col-md-6'>
            <a className="btn btn-success btn-block" onClick={this.handleItemAdd}>+ 新增規則</a>
          </div>
        </div>
      </div>
    )
  }
}
