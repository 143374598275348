import React, { Component } from 'react'

export default class ReturnOrderCollect extends Component {
  constructor(props) {
    super(props)
    this.onShopSelect = this.onShopSelect.bind(this)
    this.state = {
      shop_id: null,
      return_orders: [],
    }

    this.onCheckAll = this.onCheckAll.bind(this)
    this.onOrderSelected = this.onOrderSelected.bind(this)
  }

  componentDidMount() {
    if(this.props.shop_id){
      this.onShopSelect({target: {value: this.props.shop_id}})
    }
  }

  onShopSelect(e) {
    let shop_id = e.target.value
    this.setState({
      shop_id: shop_id,
      return_orders: this.props.todo_returns.filter(r => r.shop.id == shop_id)
    })

    fetch(`/operator/product_storage_types.json?shop_id=${shop_id}`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        this.setState({ storage_types: data.storage_types })
      })
  }

  onOrderSelected(id, checked) {
    let return_orders = this.state.return_orders
    for (let return_order of return_orders) {
      if (return_order.id == id) {
        return_order.checked = checked
        break
      }
    }
    this.setState({ return_orders: return_orders })
  }


  onCheckAll(e) {
    let return_orders = this.state.return_orders
    for (let return_order of return_orders) {
      return_order.checked = e.target.checked
    }
    this.setState({ return_orders: return_orders })
  }

  render() {
    let shop_options = this.props.todo_returns.map(r => {
      return { id: r.shop.id, name: r.shop.name }
    })
    shop_options = [...new Set(shop_options.map(o => JSON.stringify(o)))].map(s => JSON.parse(s))
    return (
      <form action={`/operator/return_orders/create_receipt`} method="POST" className='form-horizontal'>
        <input type='hidden' value={this.props.authenticity_token} name='authenticity_token' />
        <input type='hidden' value={this.state.shop_id || ""} name='shop_id' />
        <input type='hidden' value={this.state.return_orders.filter(o => o.checked).map(o => o.id).join(',')} name="order_ids" />
        <div>
          <div className="form-horizontal">
            <div className="form-group">
              <label className="col-md-2 control-label">
                客戶
              </label>
              <div className="col-md-10">
                <select name="return_order[shop_id]" className='form-control' defaultValue="" onChange={this.onShopSelect}>
                  <option key="no-shop" value="" disabled="disabled" >請選擇客戶</option>
                  {shop_options.map(shop => <option key={shop.id} value={shop.id}>{shop.name}</option>)}
                </select>
              </div>
            </div>
          </div>
          {
            this.state.return_orders.length > 0 ?
              <>
                <table className='table return-order-table'>
                  <thead>
                    <tr>
                      <th>
                        <label className="custom-control custom-checkbox" htmlFor="checkall" onChange={this.onCheckAll}>
                          <input className="custom-control-input" id="checkall" name="checkall" type="checkbox" />
                          <span className="custom-control-indicator"></span>退貨編號
                        </label>
                      </th>
                      <th>內容</th>
                      <th>驗收日期</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      this.state.return_orders.map(order => {
                        return <tr key={order.id}>
                          <td>
                            <label className="custom-control custom-checkbox" htmlFor={`return-${order.id}`}>
                              <input className="custom-control-input return-order-checkbox"
                                checked={order.checked || false}
                                id={`return-${order.id}`} type="checkbox"
                                onChange={(e) => this.onOrderSelected(order.id, e.target.checked)}
                              />
                              <span className="custom-control-indicator">
                              </span>
                              {order.token}
                            </label>
                          </td>
                          <td>{order.return_order_products.map(p => p.product.name).join(" / ")}</td>
                          <td>{order.verified_date}</td>
                        </tr>
                      })
                    }
                  </tbody>
                </table>
              </>
              : ''
          }
        </div>
        {this.state.return_orders.filter(r => r.checked).length > 0 ?
          <div>
            <hr className='hr'></hr>
            <div className="col-md-6">
              <div className='form-group'>
                <label className="custom-checkbox custom-control" style={{ paddingRight: '10px' }}>
                  <input type="checkbox" className='custom-control-input' name="verified" value="1" defaultChecked='checked' />
                  <span className='custom-control-indicator'></span>
                  自動全驗收
                </label>
              </div>
              <div className='form-group'>
                <label className="custom-checkbox custom-control" style={{ paddingRight: '10px' }}>
                  <input type="radio" className='custom-control-input' name="is_pcs" value="1" defaultChecked='checked' />
                  <span className='custom-control-indicator'></span>
                  PCS入
                </label>
                <label className="custom-checkbox custom-control" style={{ paddingRight: '10px' }}>
                  <input type="radio" className='custom-control-input' name="is_pcs" value='' />
                  <span className='custom-control-indicator'></span>
                  箱入
                </label>

              </div>
            </div>
            <div className="col-md-6">
                <button type='submit' className='btn btn-primary'
                  onClick={(e) => { if (!confirm('確定要轉入倉單?')) { e.preventDefault() } }}
                >轉入倉單</button>
              </div>

          </div>
          : ''}
      </form>
    )
  }
}
