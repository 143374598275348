import React, { useState } from "react";
import Select from "react-select";
import { useRulesContext } from "./Context";
import { styles } from "../styles";


function Rule({ rule, updateRule, removeRule, onPinRule, options,rulePinned }) {
  const [pinned, setPinned] = useState(rulePinned)
  const Component = rule.component;

  return <div className="form-group">
    <div className="col-md-2">
      <Select
        styles={styles.reactSelect}
        options={options}
        defaultValue={{
          value: rule.inputName,
          label: rule.label,
        }}
        onChange={(select) => {
          updateRule(rule, select)
        }}
      />
    </div>
    <div className="col-md-9">
      <Component {...rule} />
    </div>
    <div className="col-md-1">
      <div className="d-flex justify-content-end btn-group">
        <a
          className='btn-link btn '
          href="#"
          onClick={() => {
            onPinRule(rule.inputName, !pinned);
            setPinned(!pinned)
          }
          }
        >
          <i className={`fa fa-map-pin ${pinned ? 'text-primary' : 'text-muted'}`} aria-hidden="true" />
        </a>
        <a
          className='btn-link btn'
          href="#"
          onClick={() => removeRule(rule)}
        >
          <i className="fa fa-trash-o text-danger" aria-hidden="true" />
        </a>

      </div>
    </div>
  </div>
}

function RulesForm({ options,onPinRule,supportRules,pinnedRules }) {
  const { rulesInputNames, updateRule, removeRule } = useRulesContext();

  return (
    <>
      {rulesInputNames.map((ruleInputName) => {
        const rule = supportRules.find(rule=>rule.inputName === ruleInputName)
        return (
          <Rule key={rule.inputName}
            options={options}
            rule={rule}
            updateRule={updateRule}
            removeRule={removeRule}
            onPinRule={onPinRule}
            rulePinned={pinnedRules.includes(rule.inputName)}
          />
        );
      })}
    </>
  );
}

export default RulesForm;
