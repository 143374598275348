import React, { Component } from 'react'
import { Dropzone } from '../../components/common/dropzone'
import Select from 'react-select'

export default class TicketForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      images: []
    }
    this.onImageSelect = this.onImageSelect.bind(this)
  }
  componentDidMount() {
    $("#ticket-related_orders-select").select2({tags:true,tokenSeparators: [',', ' '],multiple: true})
  }
  onImageSelect(files) {
    let self = this;
    let images = this.state.images
    let file = files[0]

    this.toDataURL(URL.createObjectURL(file),(data) => {
      images.push({
        key: Date.now(),
        url: data,
        name: file.name.split(".")[0],
        type: file.type.replace("image/", "")
      })
      self.setState({ images: images })
    })
  }
  onImageDelete(key) {
    let images = this.state.images
    images = images.filter(image => image.key !== key)
    this.setState({ images: images })
  }
  toDataURL(url, callback){
    var xhr = new XMLHttpRequest();
    xhr.onload = function(){
      var reader = new FileReader();
      reader.onloadend = function(){
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  render() {
    let { images } = this.state;

    return (
      <div className="form-horizontal">
        {
          this.props.shop && <div className='form-group'>
            <label className="col-md-2 control-label">
              <label style={{color: 'red'}}>* </label>
              選擇客戶
            </label>
            <div className="col-md-9">

              <Select className='react-select-container' name="ticket[shop_id]"
              searchable={true}
              clearable={false}
              required={true}
              placeholder="請選擇客戶"
              options={
                this.props.shop.map(shop => { return { label: shop.name, value: shop.id } })
              }
              ></Select>
            </div>

          </div>
        }
        <div className="form-group">
          <label className="col-md-2 control-label">
            <label style={{color: 'red'}}>* </label>
            標題
          </label>
          <div className="col-md-9">
            <input required="required" className="form-control" type="text" name="ticket[title]" />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">
            關聯訂單(請輸入訂單編號)
          </label>
          <div className="col-md-9">
            <select id="ticket-related_orders-select" className="form-control" multiple={true} name="ticket[order_names][]">
            </select>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">
            <label style={{color: 'red'}}>* </label>
            內文
          </label>
          <div className="col-md-9">
            <textarea required="required" className="form-control ticket-message-textarea" type="text" name="ticket[description]" />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">
            圖片
          </label>
          <div className="col-md-9">
            <div style={{display: 'flex', alignItems: 'center', flexWarp: 'wrap'}}>
              {
                images.map(image => (
                  <div key={image.key} className="ticket-message-image">
                    <img
                      src={image.url}
                      height="100%"
                      width="100%"
                      style={{objectFit: 'cover'}}
                    />
                    <button
                      type="button" className="delete-ticket-message-image"
                      onClick={() => this.onImageDelete(image.key)}
                    >X</button>
                    <input type="hidden" name="ticket[photos][][url]" value={image.url}/>
                    <input type="hidden" name="ticket[photos][][name]" value={image.name}/>
                    <input type="hidden" name="ticket[photos][][type]" value={image.type}/>
                  </div>
                ))
              }
              {
                images.length < 5 &&
                  <div className="ticket-message-dropzone">
                    <Dropzone
                      className="ticket-message" accept={{'': ['.jpg', '.jpeg', '.gif', '.png', '.webp']}} fileMaxNum={1} fileMaxSizeMB={2}
                      fileType="圖檔" onFileDrop={(files) => this.onImageSelect(files)}
                    />
                  </div>
              }
            </div>
          </div>
        </div>
      </div>
    )
  }
}
