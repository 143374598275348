import React, { useState } from "react";

function NumberRange({inputName,min,max}) {

  return (
    <div className="number-range input-group">
      <input
        value={min}
        name={`${inputName}[min]`}
        type="number"
        className="form-control"
      />
      <div className="input-group-addon">到</div>
      <input
        value={max}
        name={`${inputName}[max]`}
        type="number"
        className="form-control"
      />
    </div>
  );
}

export default NumberRange;
