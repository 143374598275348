import React from 'react'

import ProductSelect from '../../../components/admin/product_select'


export default function Form(props) {
  const {selectedProducts,name} = props
  return (
    <div>
      <ProductSelect
        selectedProducts={selectedProducts}
        name={name}
        multiple={true}

        />
    </div>
  )
}