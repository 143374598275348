import {
  ORDER_SET_PRODUCTS, ORDER_SET_STORAGE_TYPES, ORDER_SET_PRODUCT_ITEMS, ORDER_SET_ATTACHMENT_ITEMS,
  ORDER_SET_OPTIONAL_COLUMNS,
  ORDER_ADD_PRODUCT_ITEM, ORDER_UPDATE_PRODUCT_ITEM, ORDER_DELETE_PRODUCT_ITEM,
  ORDER_PRODUCT_ITEM_CHANGE,
  ORDER_ADD_ATTACHMENT_ITEM, ORDER_UPDATE_ATTACHMENT_ITEM, ORDER_DELETE_ATTACHMENT_ITEM,
} from '../../../actions/action_types'

// product
export const setProducts = (products) => {
  return {
    type: ORDER_SET_PRODUCTS,
    products: products
  }
}

export const setStorageTypes = (storage_types) => {
  return {
    type: ORDER_SET_STORAGE_TYPES,
    storage_types: storage_types
  }
}

export const setProductItems = (product_items) => {
  return {
    type: ORDER_SET_PRODUCT_ITEMS,
    product_items: product_items
  }
}

export const setAttachmentItems = (attachment_items) => {
  return {
    type: ORDER_SET_ATTACHMENT_ITEMS,
    attachment_items: attachment_items
  }
}

export const setOptionalColumns = (columns)=>{
  return {
    type: ORDER_SET_OPTIONAL_COLUMNS,
    optional_columns: columns
  }
}

export const onProductItemAdd = (item) => {
  return {
    type: ORDER_ADD_PRODUCT_ITEM,
    item: item
  }
}

export const onProductItemUpdate = (item) => {
  return {
    type: ORDER_UPDATE_PRODUCT_ITEM,
    item: item
  }
}

export const onProductItemDelete = (item) => {
  return {
    type: ORDER_DELETE_PRODUCT_ITEM,
    item: item
  }
}

export const onProductItemChange = (item) => {
  return {
    type: ORDER_PRODUCT_ITEM_CHANGE,
    item: item
  }
}

export const onAttachmentItemAdd = (item) => {
  return {
    type: ORDER_ADD_ATTACHMENT_ITEM,
    item: item
  }
}

export const onAttachmentItemUpdate = (item) => {
  return {
    type: ORDER_UPDATE_ATTACHMENT_ITEM,
    item: item
  }
}

export const onAttachmentItemDelete = (item) => {
  return {
    type: ORDER_DELETE_ATTACHMENT_ITEM,
    item: item
  }
}
