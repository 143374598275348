import React, { Component } from 'react'
import { Dropzone } from '../dropzone'

export default class SenderBox extends Component {
  constructor(props) {
    super(props)
    this.state = {
      images: []
    }
    this.onImageSelect = this.onImageSelect.bind(this)
  }
  onImageSelect(files) {
    let self = this;
    let images = this.state.images
    let file = files[0]

    this.toDataURL(URL.createObjectURL(file),(data) => {
      images.push({
        key: Date.now(),
        url: data,
        name: file.name.split(".")[0],
        type: file.type.replace("image/", "")
      })
      self.setState({ images: images })
    })
  }
  onImageDelete(key) {
    let images = this.state.images
    images = images.filter(image => image.key !== key)
    this.setState({ images: images })
  }
  toDataURL(url, callback){
    var xhr = new XMLHttpRequest();
    xhr.onload = function(){
      var reader = new FileReader();
      reader.onloadend = function(){
        callback(reader.result);
      }
      reader.readAsDataURL(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  }

  render() {
    let { images } = this.state;

    return (
      <div id="sender_box" className="form-horizontal sender-box-background">
        <div className="form-group">
          <div className="col-md-12">
            <textarea required="required" className="form-control ticket-message-textarea" type="text" name="ticket_message[message]" />
          </div>
        </div>
        <div style={{display: 'flex', alignItems: 'center', flexWarp: 'wrap'}}>
          {
            images.map(image => (
              <div key={image.key} className="ticket-message-image">
                <img
                  src={image.url}
                  height="100%"
                  width="100%"
                  style={{objectFit: 'cover'}}
                />
                <button
                  type="button" className="delete-ticket-message-image"
                  onClick={() => this.onImageDelete(image.key)}
                >X</button>
                <input type="hidden" name="ticket_message[photos][][url]" value={image.url}/>
                <input type="hidden" name="ticket_message[photos][][name]" value={image.name}/>
                <input type="hidden" name="ticket_message[photos][][type]" value={image.type}/>
              </div>
            ))
          }
          {
            images.length < 5 &&
              <div className="ticket-message-dropzone">
                <Dropzone
                  className="ticket-message" accept={{'': ['.jpg', '.jpeg', '.gif', '.png', '.webp']}} fileMaxNum={1} fileMaxSizeMB={2}
                  fileType="圖檔" onFileDrop={(files) => this.onImageSelect(files)}
                />
              </div>
          }
          <button className="btn btn-primary sender" type="submit">送出</button>
        </div>
      </div>
    )
  }
}
