import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import Select from 'react-select'
import * as AdminOrderActions from '../../../actions/admin/order/form'

class AttachmentItem extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    $('.custom-file').pxFile()
  }

  handleItemDelete = (event) => {
    this.props.onAttachmentItemDelete({index: this.props.index})
  }

  handleFileChange = (event) => {
  }

  handleItemUpdate = (key, value) => {
    this.props.onProductItemUpdate({
      index: this.props.index,
      [key]: value,
    })
  }

  handleDestroyChange = (event) => {
  }

  render() {
    const { products, validates, item } = this.props
    const { id, file, _destroy } = item

    let file_html = ''
    if (file) {
      file_html = (
        <a className="" href={file.url} target="_blank">下載</a>
      )
    } else {
      file_html = (
        <label id="grid-input-6-file" className="custom-file px-file">
          <input name="order[attachments_attributes][][file]" className="custom-file-input" required="required" type="file" onChange={this.handleFileChange}/>
          <span className="custom-file-control form-control">請選擇檔案</span>
          <div className="px-file-buttons">
            <button type="button" className="btn btn-xs px-file-clear">清除</button>
            <button type="button" className="btn btn-primary btn-xs px-file-browse">瀏覽</button>
          </div>
        </label>
      )
    }

    let tr_class = ''
    let destroy_html = ''
    if (_destroy) {
      destroy_html = (
        <input name="order[attachments_attributes][][_destroy]" type="hidden" value={_destroy} onChange={this.handleDestroyChange} />
      )
      tr_class = 'hide'
    }

    return (
      <tr className={tr_class}>
        <td className="text-center">
          <input name="order[attachments_attributes][][id]" type="hidden" value={id} onChange={this.handleIdChange} />
          {destroy_html}
          <a className="btn btn-danger btn-xs" onClick={this.handleItemDelete}>刪除</a>
        </td>
        <td className="text-center">
          <div className="form-group m-a-0">
            {file_html}
          </div>
        </td>
      </tr>
    )
  }
}

AttachmentItem.propTypes = {
  attachment_items: PropTypes.array.isRequired,
}

// Redux connect
const mapStateToProps = (state, ownProps) => {
  return {
    attachment_items: state.AdminOrderForm.attachment_items,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(AdminOrderActions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(AttachmentItem)
