import React, { Component } from 'react'
import Modal from 'react-responsive-modal';

export default class Messages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      current_photo_url: ""
    }
    this.openPhotoModal = this.openPhotoModal.bind(this)
    this.closePhotoModal = this.closePhotoModal.bind(this)
  }
  openPhotoModal(photo) {
    this.setState({
      open: true,
      current_photo_url: (photo || "")
    })
  }
  closePhotoModal() {
    this.setState({
      open: false,
      current_photo_url: ""
    })
  }
  normalizedDateTime(datetime_string) {
    try {
      const datetime = datetime_string.split('T')
      return `${datetime[0]} ${datetime[1].split(".")[0]}`
    } catch (e) {
      return ''
    }
  }
  render() {
    let { messages, sender, shop_title } = this.props;
    return (
      <div className="message-histories">
        {
          messages.map((message) => {
            let _sender = ""
            let _title = ""
            if (!!message.user) {
              _sender = "user"
              _title = <><span className='label label-info label-outline'>{shop_title}</span>{message.user.email}</>
            }
            else if (!!message.operator) {
              _sender = "operator"
              _title = <>{message.operator.name}</>
            }
            const _message = message.message.split("\n")
            const _message_length = _message.length
            return (
              <div className={`item-wrapper ${(sender == _sender) ? "customer" : ""}`} key={message.id}>
                <div className="item-content">
                  <p className="title">{_title}</p>
                  <div className="msgbox">
                    <p className="text-content">{
                      _message.map((item, i) =>
                        (i==_message_length-1) ? <React.Fragment key={i}>{item}</React.Fragment> : <React.Fragment key={i}>{item}<br/></React.Fragment>)
                    }</p>
                    <div className="thumbnail-lists">
                      {
                        message.ticket_message_photos.map(ticket_message_photo => (
                          <div className="thumbnail" key={ticket_message_photo.id}>
                            <img
                              src={ticket_message_photo.public_photo_url}
                              onClick={() => this.openPhotoModal(ticket_message_photo.public_photo_url)}
                            />
                          </div>
                        ))
                      }
                    </div>
                    <p className="datetime">{this.normalizedDateTime(message.created_at)}</p>
                  </div>
                </div>
              </div>
            )
          })
        }
        {
          this.state.current_photo_url.length > 0 &&
            <Modal open={this.state.open} onClose={this.closePhotoModal}
              classNames={{
                overlay: 'galleryModlaOverlay',
                modal: 'galleryModla',
              }}
            >
              <img className="w-100" src={this.state.current_photo_url} />
            </Modal>
        }
      </div>
    )
  }
}
