import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootOperatorReducer from '../reducers/operator'

const createStoreWithMiddleware = applyMiddleware(
  thunk
)(createStore)

export default function operatorStore(initialState) {
  return createStoreWithMiddleware(rootOperatorReducer, initialState)
}
