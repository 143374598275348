import React, { Component } from 'react'

const Version = {
  LEGACY: "legacy",
  API: "api"
}

export default class Tcat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      version: Version["API"]
    }
    this.updateVersion = this.updateVersion.bind(this)
  }
  componentDidMount() {
    if (this.props.shipping_account.ftp_username && !this.props.shipping_account.cust_id && !this.props.shipping_account.check_word)
      this.updateVersion(Version["LEGACY"])
  }
  updateVersion(version) {
    this.setState({version: version})
  }
  render() {
    let { shipping_account_title, shipping_account_type, shipping_account } = this.props
    return (
      <table className="table table-striped">
        <tbody>
          <tr>
            <td>{shipping_account_title}</td>
            <td></td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">啟用</td>
            <td>
              <div className="form-group">
                <div className="col-md-3">
                  <label htmlFor={`${shipping_account_type}-${shipping_account.id}-enable-switcher`} className="switcher switcher-success">
                    <input type="checkbox" id={`${shipping_account_type}-${shipping_account.id}-enable-switcher`} name={`shipping_accounts[${shipping_account.id}][enabled]`} defaultChecked={shipping_account.enabled}/>
                    <div className="switcher-indicator">
                      <div className="switcher-yes">啟用</div>
                      <div className="switcher-no">停用</div>
                    </div>
                  </label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">版本</td>
            <td>
              <div className="form-group">
                <div className="col-md-3">
                  <label htmlFor={`${shipping_account_type}-${shipping_account.id}-version-switcher`} className="switcher switcher-success">
                    <input type="checkbox" id={`${shipping_account_type}-${shipping_account.id}-version-switcher`} checked={this.state.version === Version["API"]} onChange={(e) => this.updateVersion(e.target.checked ? Version["API"] : Version["LEGACY"])}/>
                    <div className="switcher-indicator">
                      <div className="switcher-yes">API版</div>
                      <div className="switcher-no">舊版</div>
                    </div>
                  </label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">契客代號</td>
            <td>
              <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][customer_code]`} defaultValue={shipping_account.customer_code}/>
            </td>
          </tr>
          {
            this.state.version == Version["API"] &&
              (<>
                <tr>
                  <td className="col-md-4 text-center">契客授權碼</td>
                  <td>
                    <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][check_word]`} defaultValue={shipping_account.check_word}/>
                  </td>
                </tr>
                <tr>
                  <td className="col-md-4 text-center">顯示固定品名</td>
                  <td>
                    <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][default_product_name]`} defaultValue={shipping_account.default_product_name}/>
                  </td>
                </tr>
              </>)
          }
          <tr>
            <td className="col-md-4 text-center">FTP IP (上傳用)</td>
            <td>
              <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][ftp_address]`} defaultValue={shipping_account.ftp_address}  placeholder="請輸入ip或是網址(不包含ftp://)，例如tcatrt.tcat.com.tw"/>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">FTP 帳號</td>
            <td>
              <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][ftp_username]`} defaultValue={shipping_account.ftp_username}/>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">FTP 密碼</td>
            <td>
              <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][ftp_password]`} defaultValue={shipping_account.ftp_password} type='password'/>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center"></td>
            <td className="col-md-6 text-center">
              <button className="btn btn-primary">更新</button>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}