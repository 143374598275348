import React, { Component } from 'react'
import Messages from './messages'
import SenderBox from './sender_box'

export default class TicketMessageForm extends Component {
  render() {
    return (
      <>
        <Messages {...this.props} />
        {this.props.ticket.status !== "closed" && <SenderBox />}
      </>
    )
  }
}
