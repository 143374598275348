import React, { Component } from 'react'

export default class OrderMerge extends Component {
  constructor(props) {
    super(props)
    this.onShopSelect = this.onShopSelect.bind(this)
    this.findCandidate = this.findCandidate.bind(this)
    this.loadOrders = this.loadOrders.bind(this)
    this.onConfirmMerge = this.onConfirmMerge.bind(this)
    this.onRemoveCandidate = this.onRemoveCandidate.bind(this)
    this.state = {
      candidates: {}
    }
  }

  componentDidMount() {
  }

  onShopSelect(e) {
    let shop_id = e.target.value
    this.setState({shop_id: shop_id})
    this.loadOrders(shop_id)
  }
  loadOrders(shop_id){
    fetch(`/operator/orders/merge.json?shop_id=${shop_id}`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        this.setState({ orders: data,candidates: this.findCandidate(data) })
      })

  }
  findCandidate(orders){
    let candidates = {}
    for(let order of orders){
      let key = `${order.customer_name}-${order.address}-${order.shipping_type}-${order.is_cod}-${order.delivery_date}`
      if(order.customer_name.includes('*') || !order.address || order.address.includes("*")){
        continue
      }
      if(candidates[key]){
        candidates[key].push(order)
      }else{
        candidates[key] = [order]
      }
    }
    for(let key of Object.keys(candidates)){
      if(candidates[key].length < 2){
        delete candidates[key]
      }
    }
    return candidates
  }

  onConfirmMerge(candidates){
    fetch(`/operator/orders/confirm_merge.json`,
      {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          candidates: candidates.map(order=>order.id)
        }),
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        if(data.success){
          notyMessage("併單成功")
        }else{
          notyError(`併單失敗`)
        }
        this.loadOrders(this.state.shop_id)

      })
  }

  onRemoveCandidate(key,id){
    let candidates = this.state.candidates
    candidates[key] = candidates[key].filter(e=>e.id != id)
    this.setState({candidates:candidates})
  }

  render() {
    return (
      <div>
        <div className="form-group">
          <select className='form-control' defaultValue="" onChange={this.onShopSelect}>
            <option key="no-shop" value="" disabled="disabled" >請選擇客戶</option>
            {
              this.props.shops.map(shop => {
                return <option key={shop.id} value={shop.id}>{shop.name}</option>
              })
            }
          </select>
        </div>
        <div className='panel-group'>
        {
          Object.keys(this.state.candidates).map(key=>{
            let candidates = this.state.candidates[key]
            return candidates.length > 1 ? <div className="panel" key={`candidate-${key}`}>
              <div className="panel-heading">
                <a className="accordion-toggle" data-toggle="collapse" href={`#candidate-${key}`}>
                  {candidates[0].customer_name}
                  {candidates[0].delivery_date ? `  到貨日： ${candidates[0].delivery_date}` : ''}
                </a>
              </div>
              <div id={`candidate-${key}`} className="panel-collapse collapse in">
              <div className="panel-body">
                <table className='table table table-hover'>
                  <tbody>
                  {
                    candidates.map(candidate=>{
                      return <tr key={candidate.id}>
                        <td>{candidate.name}</td>
                        <td>{candidate.customer_name}</td>
                        <td>{candidate.phone}</td>
                        <td>{candidate.address}</td>
                        <td>{candidate.is_cod ? `代收貨款 ${candidate.total_price}`:''}</td>
                        <td>{candidate.shipping_name}</td>
                        <td><a className='text-danger' href="#" onClick={()=>this.onRemoveCandidate(key,candidate.id)}><i className='px-nav-icon ion-md-close'></i></a></td>
                        </tr>
                    })
                  }
                  <tr>
                    <td>
                      <a className='btn btn-primary btn-outline' onClick={()=>this.onConfirmMerge(candidates)}>
                        確認併單
                      </a>
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                  </tr>
                  </tbody>
                </table>
              </div>
              </div>
            </div>: ''
          })
        }
        </div>
      </div>
    )
  }
}
