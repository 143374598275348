import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as FinishedGoodsOrderActions from '../../../actions/admin/processing_order/form'
import _ from "lodash";
import AsyncSelect from 'react-select/async'
import BaseSelect from "react-select";
import FixRequiredSelect from "../../common/fix_react_select";

const Select = props => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options || options}
  />
);

class Target extends Component {
  constructor(props) {
    super(props)
    this.remove = this.remove.bind(this)
    this.handleItemAdd = this.handleItemAdd.bind(this)
    this.onProductSelect = this.onProductSelect.bind(this)
    this.onQuantityChange = this.onQuantityChange.bind(this)
    this.onStorageChange = this.onStorageChange.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.debouncedLoadOptions = _.debounce(this.loadOptions, 500);
  }

  loadOptions(inputValue, callback){
    if(inputValue.length <2){
      callback([])
      return
    }
    fetch(`/admin/products.json?search=${inputValue}&product_only=true&limit=50`,
    {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token,
      },
      credentials: 'same-origin'
    }).then(res => res.json())
    .then(data => {
      let shop_products = this.props.shop_products
      let options = data.products.map(product=>{
        return { label: [product.barcode,product.uid, product.name].filter(Boolean).join("/"), value: product.id }
      })
      for(let option of options){
        let found = false
        for(let shop_product of shop_products){
          if(shop_product.value == option.value){
            found = true
            break
          }
        }
        if(!found){
          shop_products.push(option)
        }
      }
      this.props.onSyncProducts(shop_products)
      callback(options)
    })
  };

  onProductSelect(id, label,key) {
    let items = this.props.target_items
    fetch(`/admin/products/${id}/product_storages.json`, {
      method: 'GET',
      credentials: 'same-origin'
    }).then(res => res.json())
      .then(res => {
        for (let item of items) {
          if (item.key == key) {
            item.product_id = id
            item.product_label = label
            item.storages = res.data
            item.product_storage_type_id = null
            item.product_storage_type_label = null

            break
          }
        }
        this.props.onTargetItemsSet(items)

      })
  }


  onStorageChange(product_storage_type_id,product_storage_type_label,key){
    let items = this.props.target_items
    for (let item of items) {
      if (item.key == key) {
        item.product_storage_type_id = product_storage_type_id
        item.product_storage_type_label = product_storage_type_label

      }
    }
    this.props.onTargetItemsSet(items)

  }
  onQuantityChange(quantity,key){
    let items = this.props.target_items
    for (let item of items) {
      if (item.key == key) {
        item.quantity = quantity
      }
    }
    this.props.onTargetItemsSet(items)
  }
  onBatchChange(batch, key){
    let items = this.props.target_items
    for (let item of items) {
      if (item.key == key) {
        item.batch = batch
      }
    }
    this.props.onTargetItemsSet(items)
  }
  onExpirationDateChange(date, key){
    let items = this.props.target_items
    for (let item of items) {
      if (item.key == key) {
        item.expiration_date = date
      }
    }
    this.props.onTargetItemsSet(items)
  }
  handleItemAdd() {
    let items = this.props.target_items
    items.push({ key: Math.random() })
    this.props.onTargetItemsSet(items)

  }
  remove(key) {
    let items = this.props.target_items.filter(e => e.key != key)
    this.props.onTargetItemsSet(items)
  }

  render() {
    const { target_items, shop } = this.props
    return (
      <div className="col-md-12">
        <div className="panel">
          <div className="panel-heading">
            <span className="panel-title">加工後品項</span>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th className="col-md-3">商品 <span style={{color: 'red'}}>*</span></th>
                <th className="col-md-2">倉別 <span style={{color: 'red'}}>*</span></th>
                <th className="col-md-2">效期</th>
                <th className="col-md-2">批號</th>
                <th className="col-md-2">數量 <span style={{color: 'red'}}>*</span></th>
                <th className="col-md-1"></th>
              </tr>
            </thead>
            <tbody>
              {
                target_items.map(item => {
                  return (
                    <tr key={item.key}>
                      <td>
                        <div>
                          <AsyncSelect name="receipt[items_attributes][][product_id]"
                            className='react-select-container'
                            classNamePrefix="react-select"
                            value={item.product_id ? {value: item.product_id,label:item.product_label} : null}
                            searchable={true}
                            clearable={false}
                            required={true}
                            onChange={e => this.onProductSelect(e.value,e.label, item.key)}
                            loadOptions={(inputValue,callback) => this.debouncedLoadOptions(inputValue,callback)}
                            placeholder="請選擇商品"
                            noOptionsMessage={() => '找不到商品，請輸入兩碼以上關鍵字'}
                            defaultOptions={
                              [...this.props.shop_products, {label: "輸入兩碼以上關鍵字查看更多..." ,value: undefined, isDisabled: true}]
                            }

                          />

                        </div>
                      </td>
                      <td>
                        <Select
                          name="receipt[items_attributes][][product_storage_type_id]"
                          className='react-select-container'
                          classNamePrefix="react-select"
                          value={item.product_storage_type_id ?
                            this.props.storage_types.filter(storage_type=>storage_type[1] == item.product_storage_type_id).map(type=>{
                            return {label:type[0],value:type[1]}})[0] : null}
                          searchable={true}
                          clearable={false}
                          required={true}
                          placeholder="請選擇倉別"
                          onChange={e => this.onStorageChange(e.value,e.label,item.key)}
                          options={this.props.storage_types.map((type)=>{
                            return {label:type[0],value:type[1]}
                          })
                          }
                        />
                      </td>

                      <td>
                        <div>
                          <input className='form-control'
                            name="receipt[items_attributes][][expiration_date]"
                            value={item.expiration_date} type="date"
                            onChange={e => this.onExpirationDateChange(e.target.value, item.key)}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <input className='form-control'
                            name='receipt[items_attributes][][batch]'
                            value={item.batch || ''} type='text'
                            onChange={e => this.onBatchChange(e.target.value, item.key)}
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <input className='form-control'
                            type='number' name='receipt[items_attributes][][quantity]'
                            min='1' required="required" value={item.quantity || ""}
                            onChange={e => this.onQuantityChange(e.target.value, item.key)}
                          />
                        </div>
                      </td>
                      <td>
                        <a className="btn btn-danger"
                          onClick={() => this.remove(item.key)}>X</a>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <a className="btn btn-success btn-block"
            onClick={this.handleItemAdd}>+ 新增品項</a>
        </div>
      </div>
    )
  }
}

Target.propTypes = {
  shop_products: PropTypes.array.isRequired,
}

// Redux connect
const mapStateToProps = (state) => {
  return {
    storage_types: state.AdminFinishedGoodsOrderForm.storage_types,
    target_items: state.AdminFinishedGoodsOrderForm.target_items,
    shop_products: state.AdminFinishedGoodsOrderForm.shop_products,
    expiration_dates: state.AdminFinishedGoodsOrderForm.expiration_dates,
    shop: state.AdminFinishedGoodsOrderForm.shop,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(FinishedGoodsOrderActions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Target)
