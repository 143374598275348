import React, { useEffect, useState, useRef } from 'react';
import { useRecordContext } from '../Context';
import FilterComponent from '../FilterComponent';
import { useRulesContext } from './Context';
import RulesForm from './RulesForm';




function Content({
  supportRules,
  searchable = false,
  tableId,
  lastModified,
}) {
  const { getRecords, submitForm, setSubmitForm, clearSelected ,submitStatus } = useRecordContext();
  const { rulesInputNames, addRule } = useRulesContext();
  const formRef = useRef(null);
  const PINNED_RULES_KEY = `pinnedRule-${tableId}`

  useEffect(() => {
    if (lastModified) {
      onSubmit()
    }
  }, [lastModified])

  useEffect(() => {
    const pinnedData = window.localStorage.getItem(PINNED_RULES_KEY)
    let useDefaultPinned = pinnedData === null
    if(useDefaultPinned){
      let pinnedRules = supportRules.filter(r => r.defaultPinned).map(r=>r.inputName)
      window.localStorage.setItem(PINNED_RULES_KEY, JSON.stringify(pinnedRules))
      for(let ruleInputName of pinnedRules){
        addRule(ruleInputName)
      }
    }else{
      let pinnedRules = JSON.parse(pinnedData)
      for(let rule of supportRules){
        if(pinnedRules.includes(rule.inputName)){
          addRule(rule.inputName)
        }
      }
    }
  }, [])


  const onPinRule = (inputName, pinned) => {
    let pinnedRules = JSON.parse(window.localStorage.getItem(PINNED_RULES_KEY)) || []
    if (pinned) {
      pinnedRules.push(inputName)
      notyMessage('已固定篩選欄位')
    } else {
      pinnedRules = pinnedRules.filter((rule) => rule !== inputName)
      notyMessage('已取消固定篩選欄位')
    }
    pinnedRules = [...new Set(pinnedRules)]
    window.localStorage.setItem(PINNED_RULES_KEY, JSON.stringify(pinnedRules))
  }

  const pinnedRules = () => {
    let pinnedRules = JSON.parse(window.localStorage.getItem(PINNED_RULES_KEY)) || []
    return pinnedRules
  }


  const checkPrecondition = (rule) => {
    if (rule.precondition) {
      const form = formRef.current;
      if (!form) {
        return false
      }
      const formData = new FormData(form);
      for (const [key, value] of formData.entries()) {
        if (key === rule.precondition) {
          return value ? true : false
        }
      }
      return false
    } else {
      return true
    }
  }

  const options = supportRules.filter(({ inputName }) => !rulesInputNames.some((r) => r === inputName))
    .filter((rule) => checkPrecondition(rule))


  const onSubmit = (e) => {
    if (e) {
      e.preventDefault();
    }
    const form = formRef.current;
    const formData = new FormData(form);
    let formDataObj = {};
    formData.forEach((value, key) => {
      if (value === '') {
        return
      }
      if (typeof (value) == 'string') {
        value = value.trim();
      }
      if (value.includes("\n")) {
        value = value.split("\n").filter((v) => v !== '').map((v) => v.trim())
      }
      let nestedKey = key.match(/\[.+\]$/g)
      if(nestedKey){
        key = key.replace(/\[.+\]$/g,'')
        nestedKey = nestedKey[0].replace(/\[|\]/g,'')
        value = {
          [nestedKey]: value
        }
        if(formDataObj[key]){
          formDataObj[key] = {
            ...formDataObj[key],
            ...value
          }
        }else{
          formDataObj[key] = value
        }
      }else{
        if (!Reflect.has(formDataObj, key)) {
          formDataObj[key] = value;
          return;
        }
        if (!Array.isArray(formDataObj[key])) {
          formDataObj[key] = [formDataObj[key]];
        }
        formDataObj[key].push(value);
      }
    });

    clearSelected();

    setSubmitForm(formDataObj);
  };




  return (
    <div>
      <form
        ref={formRef}
        onSubmit={onSubmit} className='form-horizontal'>
        {searchable && <FilterComponent />}
        <RulesForm options={options} supportRules={supportRules} onPinRule={onPinRule} pinnedRules={pinnedRules()} />

        <div className='row'>
          <div className='col-md-2'>
            {options.length > 0 && (
              <button
                onClick={() => addRule(options[0].inputName)}
                className="btn btn-info btn-outline pull-right "
                type="button"
              >
                新增篩選條件
              </button>
            )}

          </div>
          <div className='col-md-10 d-flex justify-content-end'>

            <button
              className={`btn btn-primary ${submitStatus ? 'disabled' : ''}`}
              type="submit"
              onClick={() => {
                clearSelected()
              }}
            >
              即時篩選
            </button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default Content;
