import React, { Component } from 'react'
import TransferItem from './item'

const LOWEEST_FIRST = 'lowest_first'
const HIGHEST_FIRST = 'highest_first'
const MAX_FIRST = 'max_first'
const MIN_FIRST = 'min_first'

export default class TransferSetShelves extends Component {  
  constructor(props) {
    super(props)
    this.arrangeItemShelves = this.arrangeItemShelves.bind(this)
    this.onShelfClick = this.onShelfClick.bind(this)
    this.setPriority = this.setPriority.bind(this)
    this.state = {
      items: this.props.transfer.transfer_items
    }
  }
  arrangeItemShelves(){
    let items = this.state.items
    for(let item of items){
      let quantity_remain = item.quantity
      let shelves = item.source_product_storage.shelf_storages
      shelves = shelves.sort((a,b)=>{
        return (b.piority ? b.piority : 0) -(a.piority ? a.piority : 0)
      })
  
      for( let shelf_storage of shelves ){
        let quantity_to_transfer = Math.min(shelf_storage.pcs,quantity_remain)
        shelf_storage.quantity_to_transfer = quantity_to_transfer
        quantity_remain -= quantity_to_transfer
        if(quantity_remain <0 ){
          throw "QUANTITY REMAIN LESS THAN ZERO"
        }
      }
    }
    return items
  }

  onShelfClick(item_id,shelf_storage_id){
    let items = this.state.items
    for(let item of items){
      if(item.id == item_id){
        for(let shelf_storage of item.source_product_storage.shelf_storages){
          if(shelf_storage.id == shelf_storage_id){
            if(shelf_storage.quantity_to_transfer > 0){
              shelf_storage.piority = - Date.now()
            }else{
              shelf_storage.piority = Date.now()
            }            // PICKED : 1, UNPICKED: -1 , DEFAULT: null, pioirity => 1 > null > -1
            this.setState({items:items})
            return
          }
        }
      }
    }
  }

  setPriority(e){
    let rule = e.target.value
    let items = this.state.items
    for(let item of items){
      for(let shelf_storage of item.source_product_storage.shelf_storages){
        console.log(shelf_storage)
        switch(rule){
          case LOWEEST_FIRST:
            shelf_storage.piority = - shelf_storage.shelf.layer
            break
          case HIGHEST_FIRST:
            shelf_storage.piority = shelf_storage.shelf.layer
            break
          case MAX_FIRST:
            shelf_storage.piority = shelf_storage.pcs
          break
          case MIN_FIRST:
            shelf_storage.piority = - shelf_storage.pcs
            break
        }
      }
    }
    this.setState({items})
  }

  checkValid(item){
    let sum = item.source_product_storage.shelf_storages.reduce((sum, x) => sum + x.quantity_to_transfer,0);
    return sum == item.quantity
  }


  render(){
    let items = this.arrangeItemShelves()
    return <div>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-group pull-right'>
            <select defaultValue='none' className='form-control' onChange={this.setPriority}>
              <option value="none" disabled='disabled'>自動指派</option>
              <option value={LOWEEST_FIRST}>低層優先</option>
              <option value={HIGHEST_FIRST}>高層優先</option>
              <option value={MAX_FIRST}>量多優先</option>
              <option value={MIN_FIRST}>量少優先</option>
            </select>
          </div>
        </div>
      </div>
      <div>
        {
          items.map(item=><TransferItem key={item.id} valid={this.checkValid(item)} {...item} onShelfClick={this.onShelfClick}/>)
        }
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <button type='submit' className='btn btn-primary'>確認</button>
        </div>
      </div>
    </div>
  }
}
