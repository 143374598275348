import React, { useEffect, useState, useRef } from 'react'
import BarcodeInput from './barcode_input'
import { playErrorSound } from '../../../components/common/sounds/sounds'



export default function VerifyByScan(props) {

  const {receipt,operator_error_sound} = props
  const [currentItemIndex, setCurrentItemIndex] = useState(null)
  const [items, setItems] = useState(receipt.items)
  let currentProductRef = useRef(null)

  useEffect(() => {
    if (currentProductRef) {
      window.scrollTo(0, currentProductRef.offsetTop)
    }
  })

  useEffect(() => {
    for(let item of items){
      if(!item.verified_pcs){
        item.verified_pcs = 0
      }
    }
    setItems([...items])
  }, [])

  function verifyItem(item){
    fetch(`/operator/receipts/${receipt.id}/verifies/${item.id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
      },
      body: JSON.stringify({verified_pcs: item.verified_pcs})
    })
  }

  function partial(){
    if(confirm("確定分批驗收?") == false){
      return
    }
    fetch(`/operator/receipts/${receipt.id}/partial_verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
      },
      body: JSON.stringify({
        items: items.map(item => {
          return {
            id: item.id,
            verified_pcs: item.verified_pcs
          }
        })
      })
    }).then(res => {
      if(res.ok){
        window.location.href = `/operator/receipts/${receipt.id}`
      }else{
        notyErrorBlock("驗貨失敗!")
      }
    })
  }

  function done(){
    if(confirm("確定完成驗收?") == false){
      return
    }
    fetch(`/operator/receipts/${receipt.id}/done_verify`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
      },
      body: JSON.stringify({
        items: items.map(item => {
          return {
            id: item.id,
            verified_pcs: item.verified_pcs
          }
        })
      })
    }).then(res => {
      if(res.ok){
        window.location.href = `/operator/receipts/${receipt.id}`
      }else{
        notyErrorBlock("驗貨失敗!")
      }
    })
  }

  function onBarcodeEnter(barcode) {
    let itemIndex = items.findIndex(item => (item.product.barcode === barcode || item.product.uid === barcode) && item.verified_pcs < item.scheduled_pcs)
    if(itemIndex == -1){
      // use last item with same barcode (超量驗收)
      itemIndex = items.findLastIndex(item =>( item.product.barcode === barcode || item.product.uid === barcode ))
    }
    if (itemIndex != -1) {
      let item = items[itemIndex]
      item.verified_pcs += 1
      verifyItem(item)
      setCurrentItemIndex(itemIndex)
      setItems([...items])
    }else{
      notyErrorBlock("商品錯誤!")
      playErrorSound(operator_error_sound)
    }
  }

  return <div>
    <div className="page-header">
      <div className="pull-left"><h1>{`進倉刷驗 ${receipt.title}`}</h1></div>
      <div className="pull-right">
        <BarcodeInput onBarcodeEnter={onBarcodeEnter} />
      </div>
    </div>
    <table className="table">
      <thead>
        <tr>
          <th className='col-md-1'></th>
          <th className='col-md-2'>品號</th>
          <th className='col-md-3'>品名</th>
          <th className='col-md-2'>條碼</th>
          <th className='col-md-2'>數量</th>
          <th className='col-md-2 text-center' colSpan={2}>已驗</th>
        </tr>
      </thead>
      <tbody>
        {
          items.map((item, index) => {
            return <tr key={index} style={index == currentItemIndex ? {backgroundColor: 'yellow'} : {}} ref={(ref)=> {
              if(index == currentItemIndex){
                currentProductRef = ref
              }
              return currentProductRef
            }}>
              <td>
                { item.verified_pcs == item.scheduled_pcs ? <i className="fa fa-check text-success" aria-hidden="true"></i> : null}
                {
                  item.verified_pcs > item.scheduled_pcs ? <i className="fa fa-exclamation-triangle text-warning" aria-hidden="true"></i> : null
                }
              </td>
              <td>
                {item.product.uid}
              </td>
              <td>
                {item.product.name}
              </td>
              <td>
                {item.product.barcode}
              </td>
              <td>
                {item.scheduled_pcs}
              </td>
              <td className='col-md-1'>
                <a className='btn btn-xs btn-danger' onClick={()=>{
                  item.verified_pcs -= 1
                  verifyItem(item)
                  setItems([...items])
                }}>
                  <i className="fa fa-minus" aria-hidden="true"></i>
                </a>
              </td>
              <td className='col-md-1'>
                {item.verified_pcs}

              </td>
            </tr>
          })
        }
        </tbody>
    </table>
    <div className="row">
      <div className="col-md-3">
      </div>
      <div className="col-md-3">
        <a className='btn btn-warning btn-block' onClick={partial} >
          分批驗收
        </a>
      </div>
      <div className="col-md-3">
        <a className='btn btn-primary btn-block' onClick={done} >
          完成驗收
        </a>
      </div>
      <div className="col-md-3">
      </div>

    </div>
  </div>

}