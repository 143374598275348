// Bundle
// product
export const BUNDLE_SET_PRODUCTS            = 'BUNDLE_SET_PRODUCTS'
export const BUNDLE_SET_PRODUCT_ITEMS       = 'BUNDLE_SET_PRODUCT_ITEMS'
export const BUNDLE_ADD_PRODUCT_ITEM        = 'BUNDLE_ADD_PRODUCT_ITEM'
export const BUNDLE_DELETE_PRODUCT_ITEM     = 'BUNDLE_DELETE_PRODUCT_ITEM'

export const BUNDLE_SET_PRODUCT_STORAGE_TYPES = 'BUNDLE_SET_PRODUCT_STORAGE_TYPES'

// Receipt
// product
export const RECEIPT_SET_PRODUCTS                   = 'RECEIPT_SET_PRODUCTS'
export const RECEIPT_SET_STORAGE_TYPES              = 'RECEIPT_SET_STORAGE_TYPES'
export const RECEIPT_SET_PRODUCT_ITEMS              = 'RECEIPT_SET_PRODUCT_ITEMS'
export const RECEIPT_ADD_PRODUCT_ITEM               = 'RECEIPT_ADD_PRODUCT_ITEM'
export const RECEIPT_UPDATE_PRODUCT_ITEM            = 'RECEIPT_UPDATE_PRODUCT_ITEM'
export const RECEIPT_DELETE_PRODUCT_ITEM            = 'RECEIPT_DELETE_PRODUCT_ITEM'
export const RECEIPT_PRODUCT_ITEM_CHANGE            = 'RECEIPT_PRODUCT_ITEM_CHANGE'
export const RECEIPT_PRODUCT_EXPIRATION_DATE_CHANGE = 'RECEIPT_PRODUCT_EXPIRATION_DATE_CHANGE'

// Order
// product
export const ORDER_SET_PRODUCTS           = 'ORDER_SET_PRODUCTS'
export const ORDER_SET_STORAGE_TYPES      = 'ORDER_SET_STORAGE_TYPES'
export const ORDER_SET_PRODUCT_ITEMS      = 'ORDER_SET_PRODUCT_ITEMS'
export const ORDER_SET_ATTACHMENT_ITEMS   = 'ORDER_SET_ATTACHMENT_ITEMS'
export const ORDER_SET_OPTIONAL_COLUMNS   = 'ORDER_SET_OPTIONAL_COLUMNS'
export const ORDER_ADD_PRODUCT_ITEM       = 'ORDER_ADD_PRODUCT_ITEM'
export const ORDER_UPDATE_PRODUCT_ITEM    = 'ORDER_UPDATE_PRODUCT_ITEM'
export const ORDER_DELETE_PRODUCT_ITEM    = 'ORDER_DELETE_PRODUCT_ITEM'
export const ORDER_PRODUCT_ITEM_CHANGE    = 'ORDER_PRODUCT_ITEM_CHANGE'
export const ORDER_ADD_ATTACHMENT_ITEM    = 'ORDER_ADD_ATTACHMENT_ITEM'
export const ORDER_UPDATE_ATTACHMENT_ITEM = 'ORDER_UPDATE_ATTACHMENT_ITEM'
export const ORDER_DELETE_ATTACHMENT_ITEM = 'ORDER_DELETE_ATTACHMENT_ITEM'

// Order Import
export const ORDER_IMPORT_SET_ATTACHMENT_ITEMS   = 'ORDER_IMPORT_SET_ATTACHMENT_ITEMS'
export const ORDER_IMPORT_ADD_ATTACHMENT_ITEM    = 'ORDER_IMPORT_ADD_ATTACHMENT_ITEM'
export const ORDER_IMPORT_UPDATE_ATTACHMENT_ITEM = 'ORDER_IMPORT_UPDATE_ATTACHMENT_ITEM'
export const ORDER_IMPORT_DELETE_ATTACHMENT_ITEM = 'ORDER_IMPORT_DELETE_ATTACHMENT_ITEM'
export const ORDER_IMPORT_SET_SUBMIT             = 'ORDER_IMPORT_SET_SUBMIT'

// FinishedGoodsOrder
export const FINISHED_GOODS_ORDER_SET_CSRF_TOKEN          = 'FINISHED_GOODS_ORDER_SET_CSRF_TOKEN'
export const FINISHED_GOODS_ORDER_SET_SHOPS               = 'FINISHED_GOODS_ORDER_SET_SHOPS'
export const FINISHED_GOODS_ORDER_SET_PRODUCTS            = 'FINISHED_GOODS_ORDER_SET_PRODUCTS'
export const FINISHED_GOODS_ORDER_SET_STORAGE_TYPES       = 'FINISHED_GOODS_ORDER_SET_STORAGE_TYPES'
export const FINISHED_GOODS_ORDER_SET_PRODUCT_ITEMS       = 'FINISHED_GOODS_ORDER_SET_PRODUCT_ITEMS'
export const FINISHED_GOODS_ORDER_SET_EXPIRATION_DATES    = 'FINISHED_GOODS_ORDER_SET_EXPIRATION_DATES'

export const FINISHED_GOODS_ORDER_CHANGE_SHOP      = 'FINISHED_GOODS_ORDER_CHANGE_SHOP'


export const FINISHED_GOODS_ORDER_ADD_PRODUCT_ITEM        = 'FINISHED_GOODS_ORDER_ADD_PRODUCT_ITEM'
export const FINISHED_GOODS_ORDER_UPDATE_PRODUCT_ITEM     = 'FINISHED_GOODS_ORDER_UPDATE_PRODUCT_ITEM'
export const FINISHED_GOODS_ORDER_DELETE_PRODUCT_ITEM     = 'FINISHED_GOODS_ORDER_DELETE_PRODUCT_ITEM'
export const FINISHED_GOODS_ORDER_PRODUCT_ITEM_CHANGE     = 'FINISHED_GOODS_ORDER_PRODUCT_ITEM_CHANGE'
export const FINISHED_GOODS_ORDER_SET_TARGET_ITEMS        = 'FINISHED_GOODS_ORDER_SET_TARGET_ITEMS'
export const FINISHED_GOODS_ORDER_SYNC_PRODUCTS           = 'FINISHED_GOODS_ORDER_SYNC_PRODUCTS'


export const FINISHED_GOODS_ORDER_ITEM_EXPIRATION_DATE_CHANGE     = 'FINISHED_GOODS_ORDER_ITEM_EXPIRATION_DATE_CHANGE'
export const FINISHED_GOODS_ORDER_COPY_ITEM     = 'FINISHED_GOODS_ORDER_COPY_ITEM'

