import React, {useState} from 'react'
import AttachmentItem from './attachment_item'


const Attachment = (props) => {
  console.log(props.attachments)
  const [attachments, setAttachments] = useState(props.attachments)

  const handleAttachmentItemAdd = () => {
    setAttachments(prevAttachments => {
      return [...prevAttachments, {index: +new Date()}]
    })
  }

  const changeAttachmentItem = (index, key, value) => {
    setAttachments(prevAttachments => {
      return prevAttachments.map(attachment => {
        if (attachment.index === index) {
          return {
            ...attachment,
            [key]: value
          }
        } else {
          return attachment
        }
      })
    })
  }

  const deleteAttachmentItem = (index) => {
    const attachment = attachments.find(attachment => attachment.id === index)
    if (attachment) {
      setAttachments(prevAttachments => {
        return prevAttachments.map(attachment => {
          if (attachment.id === index) {
            return {
              ...attachment,
              _destroy: 1
            }
          } else {
            return attachment
          }
        })
      })
    } else {
      setAttachments(prevAttachments => {
        return prevAttachments.filter(attachment => attachment.index !== index)
      })
    }
  }
  console.log('props.edited', props.edited)
  return (
    <div className="panel">
      <div className="panel-heading">
        <span className="panel-title">附件</span>
        <div className="panel-heading-controls">
          {
            props.edited && <a className="btn btn-success btn-xs" onClick={handleAttachmentItemAdd}>新增</a>
          }
        </div>
      </div>
      <table className="table">
        <thead>
          <tr>
            <th className="col-md-1"></th>
            <th className="col-md-11 text-center">附件</th>
          </tr>
        </thead>
        <tbody>
          {
            attachments.map((attachment, index) => {
              return <AttachmentItem
                key={index}
                edited={props.edited}
                attachment={attachment}
                deleteAttachmentItem={deleteAttachmentItem}
              />
            })
          }
        </tbody>
      </table>
    </div>
  )
}

Attachment.defaulProps = {
  attachments: [],
  edited: true
}

export default Attachment;