import React, { useState, useEffect } from 'react';

import {GiftByPrice} from './gift_by_price'

const MODULE_TEXTS = {
  'OrderPostGiftByPrice':'滿額加贈'
}

const MODULE_VIEWS = {
  'OrderPostGiftByPrice': GiftByPrice

}



export default function Show(props) {
  const { processor ,shop_channels} = props
  const [module, setModule] = useState(props.module || '')
  const View = MODULE_VIEWS[module]

  function formatDateTimeLocal(datetime) {
    if (!datetime) {
      return ''
    }
    return datetime.slice(0, 16)
  }

  return (
    <div className="form-horizontal">
      <div className="form-group"><label className="col-md-2 control-label">名稱 <span style={{color: 'red'}}>*</span></label>
        <div className="col-md-4">
          <input className="form-control" required="required" defaultValue={processor.name} type="text" name="order_post_processor[name]" />
        </div>
      </div>
      <div className="form-group"><label className="col-md-2 control-label">適用通路 <span style={{color: 'red'}}>*</span></label>
        <div className="col-md-4">
          <select className="form-control" required="required" defaultValue={processor.shop_channel_id} name="order_post_processor[shop_channel_id]">
            <option value="">請選擇</option>
            {
              shop_channels.map((channel) => {
                return <option key={channel.id} value={channel.id}>{channel.code}</option>
              })
            }
          </select>
        </div>
      </div>
      <div className="form-group"><label className="col-md-2 control-label">適用起迄時間(依訂單成立日) <span style={{color: 'red'}}>*</span></label>
        <div className="col-md-4">
          <div className='input-group date'>
            <input className="form-control" required="required" defaultValue={formatDateTimeLocal(processor.start_datetime)} type="datetime-local" name="order_post_processor[start_datetime]" />
            <span className="input-group-addon"> ~ </span>
            <input className="form-control" required="required" defaultValue={formatDateTimeLocal(processor.end_datetime)} type="datetime-local" name="order_post_processor[end_datetime]" />
          </div>
        </div>
      </div>
      <div className="form-group"><label className="col-md-2 control-label">類別 <span style={{color: 'red'}}>*</span></label>
        <div className="col-md-4">
          <select className="form-control" value={module}
            onChange={(e) => setModule(e.target.value)}
            required="required" name="order_post_processor[type]" id="order_post_processor_module">
            <option value="">請選擇</option>
            {Object.keys(MODULE_TEXTS).map((key) => {
              return <option key={key} value={key}>{MODULE_TEXTS[key]}</option>
            })}
          </select>
        </div>
      </div>
      {
        View && <View rules={processor.order_post_processor_rules}/>
      }


    </div>
  )
}