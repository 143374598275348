import {
  BUNDLE_SET_PRODUCTS, BUNDLE_SET_PRODUCT_ITEMS, BUNDLE_ADD_PRODUCT_ITEM, BUNDLE_DELETE_PRODUCT_ITEM,
  BUNDLE_SET_PRODUCT_STORAGE_TYPES
} from '../../../actions/action_types'

// product
export const setProducts = (products) => {
  return {
    type: BUNDLE_SET_PRODUCTS,
    products: products
  }
}

export const setProductItems = (product_items) => {
  return {
    type: BUNDLE_SET_PRODUCT_ITEMS,
    product_items: product_items
  }
}

export const onProductItemAdd = (item) => {
  return {
    type: BUNDLE_ADD_PRODUCT_ITEM,
    item: item
  }
}

export const onProductItemDelete = (id) => {
  return {
    type: BUNDLE_DELETE_PRODUCT_ITEM,
    id: id
  }
}

// product_storage_types
export const setProductStorageTypes = (product_storage_types) => {
  return {
    type: BUNDLE_SET_PRODUCT_STORAGE_TYPES,
    product_storage_types: product_storage_types
  }
}
