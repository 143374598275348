import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ProductItem from './product_item'
import * as AdminBundleActions from '../../../actions/admin/bundle/form'

class Product extends Component {
  handleItemAdd = () => {
    this.props.onProductItemAdd({
      id: +new Date(),
    })
  }

  render() {
    const { product_items } = this.props
    let _product_items = product_items.map(function (item, index) {
      return <ProductItem key={item.id} item={item} />
    }.bind(this))

    return (
      <div className="panel">
        <div className="panel-heading">
          <span className="panel-title">商品</span>
          <div className="panel-heading-controls">
            <a className="btn btn-success btn-xs" onClick={this.handleItemAdd}>新增</a>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th className="col-md-1"></th>
              <th className="col-md-3 text-center">商品 <span style={{color: 'red'}}>*</span></th>
              <th className="col-md-3 text-center">倉別 <span style={{color: 'red'}}>*</span></th>
              <th className="col-md-5 text-center">數量 <span style={{color: 'red'}}>*</span></th>
            </tr>
          </thead>
          <tbody>
            {_product_items}
          </tbody>
        </table>
      </div>
    );
  }
}

// Redux connect
const mapStateToProps = (state) => {
  return {
    products: state.AdminBundleForm.products,
    product_items: state.AdminBundleForm.product_items,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(AdminBundleActions, dispatch);
}
export default connect(mapStateToProps, mapDispatchToProps)(Product);
