import React, { Component } from 'react'

import fixWebmDuration from 'webm-duration-fix';
const mimeType = 'video/webm\;codecs=vp9';

const RECORDING = 'recording'
const PAUSED = 'paused'
const STOPPED = 'stopped'

export default class Video extends Component {
  componentDidMount(){
    let session = {
      audio: false,
      video: true
    };

    navigator.getUserMedia(session, this.recorder_ready, function(error) {
      alert('無法偵測到攝影機，請檢查設定');
      console.error(error);
    });
    $(".sticky-video").sticky({topSpacing:30});
  }
  constructor(props){
    super(props)
    this.start_recording = this.start_recording.bind(this)
    this.stop_recording = this.stop_recording.bind(this)
    this.toggle_recording = this.toggle_recording.bind(this)
    this.recorder_ready = this.recorder_ready.bind(this)
    this.resume_recording = this.resume_recording.bind(this)
    this.setCaption = this.setCaption.bind(this)
    this.videoRefresher = this.videoRefresher.bind(this)
    this.state = {
      recorder: null,
      recording_status: STOPPED,
      caption: '',
      caption_countdown: 0
    }
  }

  setCaption(text,countdown){
    if(!countdown)
      countdown = 1000
    this.setState({caption: text, caption_countdown: countdown})
  }

  start_recording() {
    try {
      if(this.state.recorder.getState()=="inactive" || this.state.recorder.getState()=="stopped"){
        this.state.recorder.startRecording();
        this.setState({recording_status: RECORDING})
        return true
      } else {
        this.state.recorder.stopRecording(this.start_recording)
      }
    } catch(e) {
      return false
    }
    return false
  }
  stop_recording() {
    return new Promise((resolve, reject) => {
      if(this.state.recording_status == RECORDING
        || this.state.recording_status == PAUSED) {
        this.state.recorder.stopRecording(() => {
          this.setState({
            recording_status: STOPPED,
            caption: '',
            caption_countdown: 0
          })
          fixWebmDuration(this.state.recorder.getBlob(), { type: mimeType }).then((fixBlob) => {
            resolve(fixBlob)
          })

          // resolve(this.state.recorder.getBlob())
        })
      } else {
        resolve()
      }
    })
  }
  resume_recording(){
    // for qc resume
    if(this.state.recording_status == PAUSED){
      this.state.recorder.resumeRecording()
      this.setState({recording_status:RECORDING})
    }
  }

  toggle_recording(){
    if(this.state.recording_status == PAUSED){
      if(this.props.onResume){
        this.props.onResume()
      }
      this.resume_recording()
    }else if(this.state.recording_status == RECORDING){
      this.state.recorder.pauseRecording()
      this.setState({recording_status:PAUSED})
      if(this.props.onPause){
        this.props.onPause()
      }

    }
  }

  recorder_ready(camera){
    let videoPreview = document.getElementById('video-preview');
    let canvas = document.createElement('canvas');
    let context = canvas.getContext('2d');

    canvas.style = 'position: absolute; top: 0; left: 0; opacity: 0; margin-top: -9999999999; margin-left: -9999999999; top: -9999999999; left: -9999999999; z-index: -1;';
    document.body.appendChild(canvas);

    let video = document.createElement('video');
    video.autoplay = true;
    video.srcObject = camera;

    let canvasStream = canvas.captureStream(15);

    let videoCanvasStream = new MediaStream();
    canvasStream.getVideoTracks().forEach(function(videoTrack) {
        videoCanvasStream.addTrack(videoTrack);
    });

    let recorder = RecordRTC(videoCanvasStream, {
        type: 'video'
    });

    videoPreview.srcObject = canvasStream;
    this.setState({recorder: recorder})

    this.videoRefresher(video,canvas,context,0)

  }

  videoRefresher(video,canvas,context){
    if(!this.state.recorder) return; // ignore/skip on stop-recording
    let rectX = 120;
    let rectY = 720 - 60;
    canvas.width = 960;
    canvas.height = 720;
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    context.save();
    if(this.state.caption && this.state.caption_countdown > 0){
      context.fillStyle = 'black';
      context.globalAlpha = 0.4;
      context.fillRect(0, rectY - 30, 1500, 65)
      context.restore();
      context.font = '45px Georgia';
      context.fillStyle = 'white';
      context.fillText(this.state.caption, rectX, rectY+18);
      this.setState({caption_countdown: this.state.caption_countdown - 150 })
    }
    context.font = '30px Georgia';
    context.fillStyle = 'red';
    context.fillText(new Date().toLocaleString(), 600, 45);


    setTimeout(()=>this.videoRefresher(video,canvas,context), 100);
  }

  render() {
    return (
      <div className='sticky-video'>
      {
        this.state.recording_status == RECORDING ?
          <div style={{position: 'absolute'}}>
          <span className='btn btn-danger'>
            錄影中
          </span>
          </div>

          : ''
      }
      {
        this.state.recording_status == PAUSED ?
          <div style={{position: 'absolute'}}>
            <span className='btn btn-warning'>
              暫停錄影
            </span>
            </div>

          : ''
      }

      <video  style={{width:"100%"}} onClick={this.toggle_recording} id='video-preview' autoPlay='autoPlay' playsInline='playsInline'></video>
      </div>
    )
  }
}
