import React, { useState, useEffect } from 'react'


const LogisticDistributions = ({shipping, statusOptions, shipping_token}) => {
  const [statusStyle, setStatusStyle] = useState('label')
  const [selectedStatus, setSelectedStatus] = useState('')

  const handleChangeShippingStatusOption = (event) => {
    setSelectedStatus(event.target.value)
  }

  const send = (event) => {
    event.preventDefault()
    $('#submit-btn').prop('disabled', true)
    fetch(event.target.action, {method:'post', body: new FormData(event.target)}).then(res=>res.json()).then(data=>{
      if(data.success) {
        notyMessage("更新成功")
        window.location.reload()
      } else {
        notyError(data.message || "更新失敗")
      }
      $('#submit-btn').prop('disabled', false)
    });
  }

  useEffect(() => {
    switch(shipping.status) {
      case 'arrived':
      case 'received':
        setStatusStyle('label label-success')
        break
      case 'processing':
      case 'shipped':
      case 'transit':
        setStatusStyle('label label-info')
        break
      case 'answered':
        setStatusStyle('label label-primary')
        break
      case 'close':
      case 'returned':
      case 'canceled':
        setStatusStyle('label label-danger')
        break
      case 'temp_no_answered':
      case 'no_answered':
      case 'non_delivery_area':
      case 'need_help':
        setStatusStyle('label label-warning')
        break
      case 'cancelled':
        setStatusStyle('label label-secondary')
        break
    }
  }, [shipping.status])

  useEffect(() => {
    setSelectedStatus(shipping.status)
  }, [shipping.status])


  return (
    <>
      <div className="row">
        <div className="panel">
          <div className="panel-heading">
            <span className="panel-title">托運單資訊</span>
          </div>
          <table className="table">
            <tbody>
              <tr>
                <td className="col-md-3 text-right font-weight-bold">廠商</td>
                <td className="col-md-8">{shipping.order.shop.name}</td>
              </tr>
              <tr>
                <td className="col-md-3 text-right font-weight-bold">托運單號</td>
                <td className="col-md-8">{shipping.tracking_number}</td>
              </tr>
              <tr>
                <td className="col-md-3 text-right font-weight-bold">收件人</td>
                <td className="col-md-8">{shipping.order.customer_name}</td>
              </tr>
              <tr>
                <td className="col-md-3 text-right font-weight-bold">電話</td>
                <td className="col-md-8">{shipping.order.phone}</td>
              </tr>
              <tr>
                <td className="col-md-3 text-right font-weight-bold">地址</td>
                <td className="col-md-8">{`${shipping.order.city}${shipping.order.district}${shipping.order.address}`}</td>
              </tr>
              <tr>
                <td className="col-md-3 text-right font-weight-bold">狀態</td>
                <td className="col-md-8">
                  <div className={statusStyle}>
                    {shipping.status_kv.attributes.name}
                  </div>
                </td>
              </tr>
              <tr>
                <td className="col-md-3 text-right font-weight-bold">預約出貨日</td>
                <td className="col-md-8">{shipping.order.scheduled_shipping_date}</td>
              </tr>
              <tr>
                <td className="col-md-3 text-right font-weight-bold">預約到貨日</td>
                <td className="col-md-8">{shipping.order.delivery_date}</td>
              </tr>
              <tr>
                <td className="col-md-3 text-right font-weight-bold">交貨時間</td>
                <td className="col-md-8">{shipping.order.delivery_time_kv.attributes.name}</td>
              </tr>
              <tr>
                <td className="col-md-3 text-right font-weight-bold">備註</td>
                <td className="col-md-8">{shipping.order.note}</td>
              </tr>
            </tbody>
          </table>
          <button
            className="btn btn-primary"
            style={{width: "100%"}}
            data-toggle="modal"
            data-target="#statusModal"
          >
            更新配送狀態
          </button>
        </div>
      </div>
      <div className="modal fade" id="statusModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <button className="close" type="button" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
              <h4 className="modal-title">更新配送狀態</h4>
            </div>
            <div className="modal-body">
              <form action={`/logistic_distributions/${shipping_token}`} method="post" autoComplete="off" onSubmit={send}>
                <div className="panel">
                  <div className="panel-body">
                    <div className="col-md-12">
                      <select name="shipping[status]" id="shipping_status" className="form-control" value={selectedStatus} onChange={handleChangeShippingStatusOption} required={true}>
                        <option value="" disabled>請選擇</option>
                        {
                          statusOptions.map((option) =>
                            <option key={option.value} value={option.value}>{option.label}</option>
                          )
                        }
                      </select>
                    </div>
                  </div>
                  <div className="panel-footer">
                    <button type="submit" className="btn-primary btn" id="submit-btn">更新</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default LogisticDistributions