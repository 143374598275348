import React, { useState, useEffect } from 'react'


import ProductSelect from '../../../components/admin/product_select'

import Select from 'react-select'



Array.prototype.toProductStorageTypeOption = function () {
  return this.map(productStorageType => {
    return { value: productStorageType.id, label: `${productStorageType.code} / ${productStorageType.name}` }
  })
}

const MECHANISM = {
  QUANTITY: 'quantity',
  TURNOVER_DAYS: 'turnover_days',
  STALLED_DAYS: 'stalled_days'
}

const mechanismOptions = [
  { value: MECHANISM.QUANTITY, label: '庫存總量' },
  { value: MECHANISM.TURNOVER_DAYS, label: '週轉天數' },
  { value: MECHANISM.STALLED_DAYS, label: '滯銷天數' },
]

function StockTrackerForm(props) {


  const { stockTracker, productStorageTypes, preloadProducts } = props
  const [product, setProduct] = useState(props.product)
  const [productId, setProductId] = useState(props.product?.id)
  const [productStorageType, setProductStorageType] = useState(
    stockTracker?.product_storage_type_id ?
      productStorageTypes.toProductStorageTypeOption().find(option => option.value === stockTracker.product_storage_type_id)
      : null
  )

  const [expiryDays, setExpiryDays] = useState(stockTracker?.expiry_days || '')
  const [quantity, setQuantity] = useState(stockTracker?.quantity || '')
  const [daysInterval, setDaysInterval] = useState(stockTracker?.days_interval || '')
  const [checkTotalStock, setCheckTotalStock] = useState(stockTracker?.check_total_stock)
  const [mechanism, setMechanism] = useState(stockTracker?.mechanism || MECHANISM.QUANTITY)




  function handleSubmit() {
    fetch('/admin/stock_trackers.json', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      body: JSON.stringify({
        stock_tracker_id: stockTracker?.id,
        product_id: productId,
        product_storage_type_id: productStorageType.value,
        expiry_days: expiryDays,
        quantity: quantity,
        days_interval: daysInterval,
        mechanism: mechanism,
        check_total_stock: checkTotalStock
      })
    }).then(res => res.json()).then(data => {
      if (data.status === 'success') {
        window.location.href = '/admin/stock_trackers'
      }
    })
  }


  return (
    <div className="form-horizontal">
      <div className="panel">
        <div className="panel-body">
          <div className="form-group">
            <label className="col-md-2 control-label required">
              追蹤商品
            </label>
            <div className="col-md-6">
              <ProductSelect
                preloadProducts={
                  [{
                    uid: '',
                    name: '全商品   (批次建立，僅限目前有庫存的商品)',
                    id: 'all'
                  }
                    ,...preloadProducts]}
                name="product"
                onChange={(option) => {
                  setProductId(option.value)
                }}
                selectedProducts={product ? [product] : []}
                multiple={false}
              />
            </div>
            <div className="col-md-4">
              <span className="text-muted">
                可選擇既有設定的商品(同商品/同倉別/同樣機制的設定會更新既有設定)
              </span>
            </div>
          </div>
          <div className="form-group">
            <label className="col-md-2 control-label required">
              倉別
            </label>
            <div className="col-md-6">
              <Select

                options={productStorageTypes.toProductStorageTypeOption()}
                onChange={
                  (value) => {
                    setProductStorageType(value)
                  }
                }
                value={productStorageType}
              />
            </div>
          </div>
          <div className="form-group">
            <label className="col-md-2 control-label">
              機制
            </label>
            <div className="col-md-6">
              <Select
                options={mechanismOptions}
                onChange={
                  (value) => {
                    setMechanism(value.value)
                  }
                }
                value={mechanismOptions.find(option => option.value === mechanism)}
              />
            </div>
          </div>
          {(mechanism === MECHANISM.QUANTITY || mechanism === MECHANISM.TURNOVER_DAYS) && <div className="form-group">
              <label className="col-md-2 control-label">
                效期天數(以上)
              </label>
              <div className="col-md-6">
                <input type="number" className="form-control"
                  onChange={(e) => setExpiryDays(e.target.value)}
                  value={expiryDays}
                />
              </div>
            </div>}
          {mechanism === MECHANISM.TURNOVER_DAYS && <div className="form-group">
            <label className="col-md-2 control-label required">
              週轉天數(小於)
            </label>
            <div className="col-md-6">
              <input type="number" className="form-control"
                value={daysInterval}
                onChange={(e) => setDaysInterval(e.target.value)}
              />
            </div>
          </div>}
          {mechanism === MECHANISM.QUANTITY && (
            <div className="form-group">
              <label className="col-md-2 control-label required">
                庫存量(小於)
              </label>
              <div className="col-md-6">
                <div className="input-group">
                  <input type="number" className="form-control"
                    style={{ zIndex: 0 }}
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                  <div className="input-group-addon">
                    個
                  </div>
                </div>
              </div>
            </div>)}
          {mechanism === MECHANISM.STALLED_DAYS && (
            <div className="form-group">
              <label className="col-md-2 control-label">

              </label>
              <div className="col-md-6">
                <div className="input-group">
                  <input type="number" className="form-control"
                    value={daysInterval}
                    onChange={(e) => setDaysInterval(e.target.value)}
                  />
                  <div className="input-group-addon">
                    天內出貨量低於
                  </div>
                  <input type="number" className="form-control"
                    value={quantity}
                    onChange={(e) => setQuantity(e.target.value)}
                  />
                  <div className="input-group-addon">
                    個
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="form-group">
            <label className="col-md-2 control-label">
              追蹤所有庫存
            </label>
            <div className="col-md-6">
              <label className="custom-control custom-checkbox">
                <input type="checkbox" className="custom-control-input" onChange={(e) => setCheckTotalStock(e.target.checked)} />
                <span className="custom-control-indicator"></span>
                <span className="custom-control-description">追蹤所有庫存</span>
              </label>
            </div>
          </div>
        </div>
        <div className="panel-footer">
          <div className='row'>
            <div className='col-md-offset-2 col-md-6'>
              <button className="btn btn-primary" onClick={handleSubmit}>
                確認
              </button>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}

export default StockTrackerForm