import {
  ORDER_SET_PRODUCTS, ORDER_SET_STORAGE_TYPES, ORDER_SET_PRODUCT_ITEMS, ORDER_SET_ATTACHMENT_ITEMS,
  ORDER_ADD_PRODUCT_ITEM, ORDER_UPDATE_PRODUCT_ITEM, ORDER_DELETE_PRODUCT_ITEM,
  ORDER_PRODUCT_ITEM_CHANGE,
  ORDER_ADD_ATTACHMENT_ITEM, ORDER_UPDATE_ATTACHMENT_ITEM, ORDER_DELETE_ATTACHMENT_ITEM, ORDER_SET_OPTIONAL_COLUMNS,
} from '../../../actions/action_types'

const initialState = { products: [], product_items: [], attachment_items: [] }

export default function AdminOrderForm(state = initialState, action) {
  switch (action.type) {
  case ORDER_SET_PRODUCTS:
    return {
      ...state,
      products: action.products
    }
  case ORDER_SET_STORAGE_TYPES:
    return {
      ...state,
      storage_types: action.storage_types
    }
  case ORDER_SET_PRODUCT_ITEMS:
    return {
      ...state,
      product_items: action.product_items
    }
  case ORDER_SET_ATTACHMENT_ITEMS:
    return {
      ...state,
      attachment_items: action.attachment_items
    }
  case ORDER_SET_OPTIONAL_COLUMNS:
    return {
      ...state,
      optional_columns: action.optional_columns
    }
  case ORDER_ADD_PRODUCT_ITEM:
    const _item = action.item
    return {
      ...state,
      product_items: [
        ...state.product_items,
        _item,
      ]
    }
  case ORDER_UPDATE_PRODUCT_ITEM:
    const _product_items = state.product_items.map((item, index) => {
      if (item.index == action.item.index) {
        item = {
          ...item,
          ...action.item,
        }
      }
      return item
    })

    return {
      ...state,
      product_items: _product_items,
    }
  case ORDER_DELETE_PRODUCT_ITEM:
    return {
      ...state,
      product_items: state.product_items.filter(item => item.index !== action.item.index),
    }
  case ORDER_PRODUCT_ITEM_CHANGE:
    const product = Object.assign({}, state.products.filter(product => product.uid == action.item.uid)[0])
    const product_items = state.product_items.map((item) => {
      if (item.index == action.item.index) {
        item = {
          ...item,
          ...product
        }
      }
      return item
    })

    return {
      ...state,
      product_items: product_items,
    }
  case ORDER_ADD_ATTACHMENT_ITEM:
    const __item = {
      index: action.item.index,
    }
    const attachment_items = Object.assign({}, __item)

    return {
      ...state,
      attachment_items: [
        ...state.attachment_items,
        attachment_items,
      ]
    }
  case ORDER_UPDATE_ATTACHMENT_ITEM:
    const __attachment_items = state.product_items.map((item, index) => {
      if (item.index == action.item.index) {
        item = {
          ...item,
          ...action.item,
        }
      }
      return item
    })

    return {
      ...state
    }
  case ORDER_DELETE_ATTACHMENT_ITEM:
    const _attachment_item = state.attachment_items.filter(item => item.index === action.item.index)[0]
    if (_attachment_item.id) {
      const __attachment_items = state.attachment_items.map((item, index) => {
        if (item.index == action.item.index) {
          item = {
            ...item,
            _destroy: 1
          }
        }
        return item
      })

      return {
        ...state,
        attachment_items: __attachment_items,
      }
    } else {
      return {
        ...state,
        attachment_items: state.attachment_items.filter(item => item.index !== action.item.index),
      }
    }
  default:
    return state
  }
}
