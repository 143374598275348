import { combineReducers } from 'redux'
import ReceiptForm from './common/receipt/form'
import OperatorFinishedGoodsOrderForm from './operator/finished_goods_order/form'

const rootReducer = combineReducers({
  OperatorFinishedGoodsOrderForm,
  ReceiptForm,
})

export default rootReducer
