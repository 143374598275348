import {
  ORDER_IMPORT_SET_ATTACHMENT_ITEMS,
  ORDER_IMPORT_ADD_ATTACHMENT_ITEM, ORDER_IMPORT_DELETE_ATTACHMENT_ITEM,
  ORDER_IMPORT_SET_SUBMIT
} from '../../../actions/action_types'


export const setAttachmentItems = (attachment_items) => {
  return {
    type: ORDER_IMPORT_SET_ATTACHMENT_ITEMS,
    attachment_items: attachment_items
  }
}

export const setSubmit = (is_submit) => {
  return {
    type: ORDER_IMPORT_SET_SUBMIT,
    is_submit: is_submit
  }
}

export const onAttachmentItemAdd = (item) => {
  return {
    type: ORDER_IMPORT_ADD_ATTACHMENT_ITEM,
    item: item
  }
}

export const onAttachmentItemDelete = (item) => {
  return {
    type: ORDER_IMPORT_DELETE_ATTACHMENT_ITEM,
    item: item
  }
}
