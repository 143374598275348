import React, { Component } from 'react'
import Select from 'react-select'

export default class SfCvs extends Component {
  render() {
    let { shipping_account_type, shipping_account } = this.props

    return (
      <>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>公司名稱(順豐識別用)</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][customer_code]`} defaultValue={shipping_account.customer_code}/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>廠商名稱</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][customer_name]`} defaultValue={shipping_account.customer_name}/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>轉貼單打印帳號</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][client_code]`} defaultValue={shipping_account.client_code}/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>API帳號</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][cust_id]`} defaultValue={shipping_account.cust_id}/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>API密碼</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][check_word]`} defaultValue={shipping_account.check_word}/>
          </div>
        </div>
      </>
    )
  }
}