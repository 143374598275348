import React, { Component } from 'react'
import ShopProducts from '../../../components/common/shop_products'

import Select from 'react-select'
import Switch from "react-switch";

export default class ReturnOrderForm extends Component {
  constructor(props) {
    super(props)
    this.onShopSelect = this.onShopSelect.bind(this)
    this.syncProductStorageType = this.syncProductStorageType.bind(this)
    this.getShopChannels = this.getShopChannels.bind(this)
    this.state = {
      channels: [],
      shop_shipping_types: [],
      reverse_logistic: false,
      original_products: (this.props.order?.products || []).map((order_product,index) => {
        return {
          id: order_product.product_id ,
          expiration_date: order_product.expiration_date,
          storage_type: order_product.product_storage_type_id,
          uid: order_product.product.uid,
          name: order_product.product.name,
          batch: order_product.batch,
          index: index,
          quantity: order_product.quantity,
          batches: []
        }
      })
    }
    this.setByOrder = this.setByOrder.bind(this)

  }

  componentDidMount() {
    if(this.props.order.id){
      this.setByOrder(this.props.order)
    }

  }

  setByOrder(order){
    this.setState({ shop_id: order.shop_id })
    this.syncProductStorageType(order.shop_id)
    this.getShopChannels(order.shop_id)
    this.getShopShippingTypes(order.shop_id)
  }

  getShopChannels(shop_id) {
    fetch(`/operator/shop_channels.json?shop_id=${shop_id}`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        console.log(data)
        this.setState({ channels: data })
      })
  }

  getShopShippingTypes(shop_id) {
    fetch(`/operator/shop_shipping_types.json?shop_id=${shop_id}`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        console.log(data)
        this.setState({ shop_shipping_types: data })
      })
  }


  syncProductStorageType(shop_id) {
    fetch(`/operator/product_storage_types.json?shop_id=${shop_id}`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        this.setState({ storage_types: data.storage_types })

      })
  }
  onShopSelect(e) {
    let shop_id = e.value
    this.setState({ shop_id: shop_id })
    this.syncProductStorageType(shop_id)
    this.getShopChannels(shop_id)
    this.getShopShippingTypes(shop_id)
  }


  render() {
    let { order } = this.props
    return (
      <div className='panel' id='product_form'>
        <div className='panel-body'>
          <div className="form-horizontal">
            <div className="form-group">
              <label className="col-md-2 control-label required">
                客戶
              </label>
              <div className="col-md-10">
                <Select name="return_order[shop_id]" onChange={this.onShopSelect}
                  value={this.props.shops.filter(shop => shop.id == this.state.shop_id).map(shop => {
                    return { value: shop.id, label: shop.name }
                  })[0]}
                  options={this.props.shops.map(shop => {
                    return { value: shop.id, label: shop.name }
                  })}
                />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label required">
                退貨單號
              </label>
              <div className="col-md-10">
                <input required="required" className="form-control" type="text" name="return_order[token]" id="return_order_token" defaultValue={order.name} />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">
                原始訂單單號
              </label>
              <div className="col-md-10">
                <input className="form-control" type="text" name="return_order[order_name]" defaultValue={order.name} />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label required">
                退貨人名稱
              </label>
              <div className="col-md-10">
                <input required="required" className="form-control" type="text" name="return_order[sender_name]" defaultValue={order.customer_name} />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">
                退貨人電話
              </label>
              <div className="col-md-10">
                <input className="form-control" type="text" name="return_order[sender_phone]" defaultValue={order.phone} />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">
                退貨人地址
              </label>
              <div className="col-md-10">
                <input className="form-control" type="text" name="return_order[sender_address]" defaultValue={order.full_address} />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">
                退貨物流
              </label>
              <div className="col-md-10">
                {
                  this.state.shop_shipping_types.length > 0 ?
                    <Select
                      name={`return_order[shipping_type]`}
                      options={this.state.shop_shipping_types.map(shipping_type => {
                        return { value: shipping_type.code, label: shipping_type.name }
                      })}
                      searchable={true}
                      clearable={false}
                      placeholder="請選擇物流類型" /> : "若需要指定物流，請先建立物流與客戶的對應"
                }
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">
                備註
              </label>
              <div className="col-md-10">
                <input className="form-control" type="text" name="return_order[note]" />
              </div>
            </div>
            <div className="form-group">
              <label className="col-md-2 control-label">
                完成驗收
              </label>
                <div className='col-md-10'>
                <Switch onChange={
                    (checked) => {
                      this.setState({ verified: checked })
                    }
                  } name="return_order[verified]" checked={this.state.verified} />                
              </div>
            </div>
            {
              this.state.channels.length > 0 ?
                <div className="form-group">
                  <label className="col-md-2 control-label">
                    通路
                  </label>
                  <div className="col-md-10">
                    <Select
                      name={`return_order[shop_channel_id]`}
                      options={this.state.channels.map(channel => {
                        return { value: channel.id, label: channel.code }
                      })}
                      defaultValue={
                        order.shop_channel_id ? {
                          value: order.shop.channels.find(channel => channel.id == order.shop_channel_id)?.id,
                          label: order.shop.channels.find(channel => channel.id == order.shop_channel_id)?.code
                        } : null
                      }
                      searchable={true}
                      clearable={false}
                      placeholder="請選擇通路" />
                  </div>
                </div> : ''
            }
            <div className="form-group">
              <label className="col-md-2 control-label">
                主動派收(逆物流)
              </label>
              <div className="col-md-9">
                <Switch onChange={
                  (checked) => {
                    this.setState({ reverse_logistic: checked })
                  }
                } name="return_order[reverse_logistic]" checked={this.state.reverse_logistic} />
              </div>
            </div>
            {
              this.state.reverse_logistic &&
              <div className="form-group">
                <label className="col-md-2 control-label">
                  指定派收日期
                </label>
                <div className="col-md-3">
                  <input className="form-control" type="date" name="return_order[reverse_logistic_date]" />
                </div>
              </div>
            }
            {
              this.state.shop_id ? <ShopProducts operator_interface={true} original_products={this.state.original_products} shop_id={this.state.shop_id} storage_types={this.state.storage_types} batch_input={true} /> : ''
            }
          </div>
        </div>
        <div className='panel-footer text-right'>
          <input type="submit" name="commit" value="確認新增" className="btn-primary btn submit-button" data-disable-with="確認新增" />
        </div>
      </div>
    )
  }
}
