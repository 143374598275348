import React, { Component } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import Attachment from '../../../components/admin/order_import/attachment'
import adminStore from '../../../store/admin_store'
import { setAttachmentItems, setSubmit } from '../../../actions/admin/order_import/form'

const store = adminStore()

export default class Form extends Component {
  componentWillMount() {
    if (this.props.attachment_items) 
      store.dispatch(setAttachmentItems(this.props.attachment_items))
    if (this.props.is_submit) 
      store.dispatch(setSubmit(this.props.is_submit))
  }

  componentWillUnmount() {
    store.dispatch(setAttachmentItems([]))
  }

  render() {
    return (
      <Provider store={store}>
        <div>
          <Attachment />
        </div>
      </Provider>
    )
  }
}
