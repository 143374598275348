import React, { useState, useEffect } from 'react';

import CreatableSelect from "react-select/creatable";

export function GiftThreshold(props){
  const { threshold, next ,onSetThresholdPrice } = props
  const [gifts, setGifts] = useState(threshold?.order_post_processor_actions?.map(action=>action.action_value) || [])

  return <div className="form-group">
    <div className="col-md-4">
      <div className='input-group'>
      <input className="form-control" required="required" type="number" pattern="\d+" value={threshold.criterion_value}  name="order_post_processor_rules[]price"
      onChange={(e) => onSetThresholdPrice(e.target.value)}
      />
      <span className="input-group-addon">{`
       ~ ${next && next.criterion_value ? next.criterion_value - 1 : '以上'}
      `}</span>
      </div>
    </div>
    <div className="col-md-6">
      <CreatableSelect
        isMulti
        name="order_post_processor_rules[]order_post_processor_actions[]value"
        className='react-select-container'
        classNamePrefix="react-select"
        placeholder="請輸入品號，若贈送多個請使用虛擬組合品號"
        options={[]}
        value={gifts.map(g=>({label: g, value: g}))}
        onChange={(values) => {
          console.log('values', values)
          setGifts(values.map(v=>v.value))
        }}
      />
    </div>
  </div>
}

export function GiftByPrice(props){


  const [thresholds, setThresholds] = useState(()=>props.rules?.map(t=>{
    t.key = Math.random()
    return t
  }) || [])


  return <div>
    <div className="form-group">
      <label className="col-md-2 control-label">門檻 <span style={{color: 'red'}}>*</span></label>
      <div className="col-md-10">
        {
          thresholds.sort((a,b)=>{
            if(a.criterion_value && b.criterion_value )
              return parseInt(a.criterion_value) - parseInt(b.criterion_value)
            else{
              return 0
            }
          }).map((threshold, index) => {
            let next = thresholds[index + 1]
            return <GiftThreshold key={threshold.key}
            onSetThresholdPrice={(price) => {
              threshold.criterion_value = price
              setThresholds([...thresholds])
            }}
            threshold={threshold} next={next}/>
          })
        }
        <a href='#' className="btn btn-outline btn-info btn-block" onClick={() => setThresholds([...thresholds, {
          key: Math.random(),
        }])}>新增</a>

      </div>
    </div>
  </div>
}