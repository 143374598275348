import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as FinishedGoodsOrderActions from '../../../actions/operator/finished_goods_order/form'
import BaseSelect from "react-select";
import FixRequiredSelect from '../../common/fix_react_select'

const Select = props => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options || options}
  />
);


class Shops extends Component {
  constructor(props) {
    super(props)
  }

  handleShopChange = (shop) => {
    this.props.onShopChange(shop.value)
    this.props.onShopSelect(shop.value)
  }

  render() {
    const { shops ,shop} = this.props

    const shop_select_options = shops.map(function (_shop, index) {
      let name = _shop[1]
      let code = _shop[0]
      return { label: name, value: code }
    })
    let selectedShop = shop_select_options.find(function (_shop, index) {
      return _shop.value == shop
    })

    return (
      <div className="col-md-12">
        <div className="form-group select required">
          <label className="form-group-input-1 select required" htmlFor="shop_select_options">客戶</label>
          <Select searchable={true}
            clearable={false}
            required={true}
            options={shop_select_options}
            value={shop ? selectedShop : null}
            onChange={this.handleShopChange}
            name="finished_goods_order[shop_id]"
          />
        </div>
      </div>
    )
  }
}

Shops.propTypes = {
  shops: PropTypes.array.isRequired,
}

// Redux connect
const mapStateToProps = (state) => {
  return {
    shops: state.OperatorFinishedGoodsOrderForm.shops,
    shop: state.OperatorFinishedGoodsOrderForm.shop
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(FinishedGoodsOrderActions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Shops)
