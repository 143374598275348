export const styles = {
  container: {
    width: '100%',
    height: '100%',
  },

  reactSelect: {
    control: (provided, state) => ({
      ...provided,
      minHeight: '30px',
      boxShadow: state.isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0 6px',
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 1000
    })
  }
};

