import React , { useState ,useEffect } from 'react'
import OnShelfRow from './on_shelf_row'


export default function OnShelf(props){
  const [shelves, setShelves] = useState([])
  const [submit, setSubmit] = useState(false)
  const { palletCount,onChangePalletCount } = props

  const { pcs_per_pallet } = props
  let total_pcs = shelves.reduce((value, shelf) => value + parseInt(shelf.pcs), 0)

  useEffect(() => {
    console.log(palletCount)
    for(let i = 0; i < palletCount; i++){
      addShelf()
    }
  }, [])

  useEffect(() => {
    if(palletCount > shelves.length){
      for(let i = shelves.length; i < palletCount; i++){
        addShelf()
      }
    }else{
      setShelves(shelves.slice(0,palletCount))
    }
  },[palletCount])

  useEffect(() => {
    let to_received_pcs = parseInt(props.to_received_pcs)
    to_received_pcs = isNaN(to_received_pcs) ? 0 : to_received_pcs

    let remainQuantity = to_received_pcs
    let newShelves = shelves.map(_shelf=>{
      _shelf.pcs = Math.min(remainQuantity, pcs_per_pallet)
      remainQuantity -= _shelf.pcs
      return _shelf
    }).filter(_shelf => _shelf.pcs > 0)
    while (remainQuantity > 0) {
      let pcs = Math.min(remainQuantity, pcs_per_pallet)
      newShelves.push({
        token: '',
        randomKey: Math.random().toString(36).substring(2),
        pcs: pcs
      })
      remainQuantity -= pcs
    }
    onChangePalletCount(newShelves.length)
    setShelves([...newShelves])
  }, [props.to_received_pcs])

  function remainQuantity(){
    let all_pcs = props.to_received_pcs
    let shelves_pcs = 0
    shelves.map(shelf => {
      shelves_pcs += parseInt(shelf.pcs)
    })
    console.log(shelves)
    return all_pcs - shelves_pcs
  }
  function addShelf(){
    let newShelves = shelves
    newShelves.push({token: '', pcs: Math.min(remainQuantity(),pcs_per_pallet), randomKey: Math.random().toString(36).substring(2)})
    setShelves([...newShelves])
  }

  function onPressAddShelf(){
    if (props.to_received_pcs > total_pcs) {
      onChangePalletCount(shelves.length + 1)
    }
    else {
      window.notyError('上架數量已達驗收數量');
    }
  }

  function onUpdateValidity(key, validity){
    let newShelves = shelves
    let index = newShelves.findIndex(shelf => shelf.randomKey == key)
    newShelves[index].validity = validity
    setShelves([...newShelves])
  }

  function destroyShelf(randomKey){
    let newShelves = shelves.filter(shelf => shelf.randomKey !== randomKey)
    setShelves(newShelves)
    onChangePalletCount(newShelves.length)
  }

  function onUpdateToken(key, token){
    let newShelves = shelves
    let index = newShelves.findIndex(shelf => shelf.randomKey == key)
    newShelves[index].token = token
    setShelves([...newShelves])
  }

  function onUpdatePcs(key, pcs){
    let newShelves = shelves
    let index = newShelves.findIndex(shelf => shelf.randomKey == key)
    newShelves[index].pcs = pcs

    let sum = newShelves.reduce((value, shelf) => value + parseInt(shelf.pcs), 0)
    if(sum > props.to_received_pcs){
      newShelves[index].pcs = newShelves[index].pcs - (sum - props.to_received_pcs)
    }
    setShelves([...newShelves])
  }

  function confirmPartial(){
    if(confirm('確定要部分上架嗎?')){
      handleSubmit()
    }
  }

  function handleSubmit(){
    setSubmit(true)
    fetch(`${window.location.pathname}/confirm`,
      {
        method: 'POST',
        credentials: 'same-origin',
        headers: {
          'X-CSRF-Token': props.authenticity_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          product: props.product,
          receipt_item: props.receipt_item,
          storages: shelves,
          verified_pcs: props.verified_pcs,
          partial_verify: props.partial_verify
        })
      }
    )
    .then(data => {
      console.log(data)
      window.location.href = "/operator/receipts/"+props.receipt.id
    })
  }

  function checkDuplicateToken(token){
    let tokens = shelves.map(shelf => shelf.token)
    if(tokens.filter(t => t == token).length > 1){
      return true
    }
  }

  function checkValid(){
    return !submit && props.to_received_pcs >= 0 &&
      shelves.length >= 0 && shelves.filter(shelf => !shelf.validity).length == 0
  }

  return (
      <div>
        <div className="row">
          <div className="col-md-12">
            <div className="panel">
              <table className="table table-stripted">
                <thead>
                  <tr>
                    <th className="text-center">
                      {'儲位 '}
                      <span style={{color: 'red'}}>*</span>
                    </th>
                    <th className="text-center" colSpan={2}>
                      {'上架數量 '}
                      <span style={{color: 'red'}}>*</span>
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {
                    shelves.map((shelf) => (
                      <OnShelfRow key={shelf.randomKey} shelf={shelf}
                        onUpdatePcs={onUpdatePcs}
                        onUpdateToken={onUpdateToken}
                        onUpdateValidity={onUpdateValidity}
                        destroyShelf={destroyShelf}
                        pcs={shelf.pcs}
                       checkDuplicateToken={checkDuplicateToken}
                      />
                    ))
                  }
                </tbody>
              </table>
              <a
                className="btn btn-success btn-block"
                onClick={onPressAddShelf}
              >+ 新增</a>
            </div>
            <div className="panel">
              <div className="panel-body">
                {
                  (total_pcs == props.to_received_pcs) && checkValid() &&
                  <button className="btn btn-success"
                    onClick={() => handleSubmit()}>
                    確認上架
                  </button>
                }
                {
                  (total_pcs < props.to_received_pcs) && checkValid() &&
                  <button className="btn btn-warning"
                    onClick={() => confirmPartial()}>
                    部分上架
                  </button>
                }
                &nbsp;&nbsp;
                <a className="btn btn-default"
                  href={"/operator/receipts/"+props.receipt.id}>
                  返回
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
}


