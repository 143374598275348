import React, { Component } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import Product from '../../../components/admin/bundle/product'
import adminStore from '../../../store/admin_store'
import { setProducts, setProductItems, setProductStorageTypes } from '../../../actions/admin/bundle/form'

const store = adminStore()

export default class Form extends Component {
  componentWillMount() {
    store.dispatch(setProducts([]))
    store.dispatch(setProductItems(this.props.product_items))
    store.dispatch(setProductStorageTypes(this.props.product_storage_types))
  }
  render() {
    return (
      <Provider store={store}>
        <div>
          <Product />
        </div>
      </Provider>
    )
  }
}
