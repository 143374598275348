import React, { Component } from 'react'

export default class ShopValueAddedServices extends Component {
  constructor(props) {
    super(props)
    this.state = {
      services: this.props.services.map(service => {
        service.key = service.id;
        return service
      })
    }
    this.handleServiceAdd = this.handleServiceAdd.bind(this)
    this.removeService = this.removeService.bind(this)
  }
  handleServiceAdd() {
    let services = this.state.services
    services.push({ key: Math.random() })
    this.setState({ services: services })
  }
  removeService(key) {
    let services = this.state.services.filter(e => e.key != key)
    this.setState({ services: services })
  }

  render() {
    const { services } = this.state
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>服務名稱 <span className="text-danger">*</span></th>
              <th>預設單價 <span className="text-danger">*</span></th>
            </tr>
          </thead>
          <tbody>
            {
              services.map((service, _) => (
                <tr key={service.key}>
                  <td>
                    {
                      !service.id &&
                        <a href='#!' onClick={()=>this.removeService(service.key)} className='btn btn-link text-danger'>
                          <i className='fa fa-trash'/>
                        </a>
                    }
                  </td>
                  <td>
                    <input hidden name="services[][id]" defaultValue={service.id}/>
                    <input className="form-control" type="text" defaultValue={service.title} name="services[][title]" required={true} />
                  </td>
                  <td>
                    <div className="input-group">
                      <input min="0" step="any" className="form-control" type="number" defaultValue={service.default_price} name="services[][default_price]" required={true} />
                      <div className="input-group-addon">元</div>
                    </div>
                  </td>
                </tr>
              ))
            }
            <tr>
              <td></td>
              <td>
                <a href='#!' onClick={this.handleServiceAdd} className='btn btn-info btn-block'>
                  <i className='fa fa-plus'/> 新增服務
                </a>
              </td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </>
    )
  }
}
