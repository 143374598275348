import {
  FINISHED_GOODS_ORDER_SET_CSRF_TOKEN,
  FINISHED_GOODS_ORDER_SET_SHOPS,
  FINISHED_GOODS_ORDER_SET_PRODUCTS,
  FINISHED_GOODS_ORDER_SET_STORAGE_TYPES,
  FINISHED_GOODS_ORDER_SET_PRODUCT_ITEMS,
  FINISHED_GOODS_ORDER_SET_EXPIRATION_DATES,

  FINISHED_GOODS_ORDER_CHANGE_SHOP,
  FINISHED_GOODS_ORDER_SET_TARGET_ITEMS,

  FINISHED_GOODS_ORDER_ADD_PRODUCT_ITEM, FINISHED_GOODS_ORDER_UPDATE_PRODUCT_ITEM,
  FINISHED_GOODS_ORDER_DELETE_PRODUCT_ITEM, FINISHED_GOODS_ORDER_PRODUCT_ITEM_CHANGE,
  FINISHED_GOODS_ORDER_ITEM_EXPIRATION_DATE_CHANGE,FINISHED_GOODS_ORDER_COPY_ITEM,
  FINISHED_GOODS_ORDER_SYNC_PRODUCTS
} from '../../../actions/action_types'

const initialState = { csrf_token: null, shops: [], shop: null, products: [],  storage_types: [], target_item: {}, expiration_dates: {}, product_items: [],expiration_dates:[], target_items:[]}

export default function OperatorFinishedGoodsOrderForm(state = initialState, action) {
  switch (action.type) {
  case FINISHED_GOODS_ORDER_SET_CSRF_TOKEN:
    return {
      ...state,
      csrf_token: action.csrf_token
    }
  case FINISHED_GOODS_ORDER_SET_SHOPS:
    return {
      ...state,
      shops: action.shops
    }
  case FINISHED_GOODS_ORDER_SET_PRODUCTS:
    return {
      ...state,
      products: action.products
    }
  case FINISHED_GOODS_ORDER_SET_STORAGE_TYPES:
    return {
      ...state,
      storage_types: action.storage_types
    }
  case FINISHED_GOODS_ORDER_SET_PRODUCT_ITEMS:
    return {
      ...state,
      product_items: action.product_items
    }
  case FINISHED_GOODS_ORDER_SET_EXPIRATION_DATES:
    let _expiration_dates = {}
    _expiration_dates[action.uid] = action.expiration_dates

    return {
      ...state,
      expiration_dates: {
        ...state.expiration_dates,
        ..._expiration_dates,
      }
    }
  case FINISHED_GOODS_ORDER_CHANGE_SHOP:
    return {
      ...state,
      shop: action.shop
    }
  case FINISHED_GOODS_ORDER_SET_TARGET_ITEMS:
    // new an array to trigger re-rendering
    return {
      ...state,
      target_items: [...action.target_items]
    }
  case FINISHED_GOODS_ORDER_ADD_PRODUCT_ITEM:
    const _item = {
      index: action.item.index,
    }

    return {
      ...state,
      product_items: [
        ...state.product_items,
        _item,
      ]
    }
  case FINISHED_GOODS_ORDER_UPDATE_PRODUCT_ITEM:
    const _product_items = state.product_items.map((item, index) => {
      if (item.index == action.item.index) {
        item = {
          ...item,
          ...action.item,
        }
      }
      return item
    })

    return {
      ...state,
      product_items: _product_items,
    }
  case FINISHED_GOODS_ORDER_DELETE_PRODUCT_ITEM:
    return {
      ...state,
      product_items: state.product_items.filter(item => item.index !== action.item.index),
    }
  case FINISHED_GOODS_ORDER_SYNC_PRODUCTS:
    return {
      ...state,
      shop_products: action.shop_products
    }
  case FINISHED_GOODS_ORDER_ITEM_EXPIRATION_DATE_CHANGE:
    let expiration_dates = state.expiration_dates
    let found = false
    for(let expiration_date of expiration_dates){
      if(expiration_date.key == action.item.key){
        expiration_date.expiration_date = action.item.expiration_date
        found = true
        break
      }
    }
    if(!found){
      expiration_dates.push(action.item)
    }
    //return a new object to redux(or it wont rerender)
    expiration_dates = expiration_dates.filter(e=>e.expiration_date)
    return {
      ...state,
      expiration_dates: expiration_dates
    }
  case FINISHED_GOODS_ORDER_PRODUCT_ITEM_CHANGE:
    const product = Object.assign({}, state.products.filter(product => product.uid == action.item.uid)[0])
    const product_items = state.product_items.map((item, index) => {
      if (item.index == action.item.index) {
        const product_id = product.id
        const bundle_id = product.bundle_id || ''
        delete product.id

        item = {
          ...item,
          ...product,
          product_id: product_id,
          bundle_id: bundle_id,
        }
      }
      return item
    })

    return {
      ...state,
      product_items: product_items,
    }
  case FINISHED_GOODS_ORDER_COPY_ITEM:
    if(action.target_items){
      return {
        ...state,
        target_items: [...action.target_items]
      }
    }
    return state
  default:
    return state
  }
}
