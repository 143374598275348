import React, { useEffect,useState } from 'react'

import { playErrorSound } from '../../../components/common/sounds/sounds'

export default function SerialNumberModal(props){

  const [scannedBarcode, setScannedBarcode] = useState('')
  const [serialNumbers, setSerialNumbers] = useState([])
  const { quantity, confirmSerialNumbers ,currentProduct} = props

  const [warningText, setWarningText] = useState('')

  useEffect(() => {
    checkQuantity()
  }, [serialNumbers])

  function checkQuantity(){
    if(quantity == serialNumbers.length){
      confirmSerialNumbers(serialNumbers)
    }
  }

  return <div className='form-group'>
    <label htmlFor="serial-number-input-1">請掃描或輸入序號</label>
    <input type="text" className="form-control" value={scannedBarcode} autoFocus
      onChange={e => {
        setScannedBarcode(e.target.value)
      }}
      onKeyDown={e => {
        if (e.keyCode == 13 && scannedBarcode) {

          if(currentProduct.barcode == scannedBarcode){
            setScannedBarcode('')
            setWarningText(`${scannedBarcode} 序號與商品條碼相同，已排除`)
            playErrorSound(props.operator_error_sound)
            return
          }
          setWarningText('')
          setSerialNumbers([...serialNumbers, scannedBarcode])
          setScannedBarcode('')
          checkQuantity()
        }
      }
      }
    />
    <hr></hr>
    <div className='row'>
      <div className='col-md-12'>
        <label htmlFor="serial-number-input-1">已掃描序號</label>
          {serialNumbers.map((serialNumber, index) => {
            return <a className="btn btn-sm label-pill btn-outline btn-warning"
            style={{ margin: 3 }} onClick={() => {
              setSerialNumbers(serialNumbers.filter((_, i) => i != index))
            }} key={index}>{serialNumber}</a>
          })}
          {warningText && <div className='alert alert-warning'>{warningText}</div>}
      </div>
    </div>
    <hr></hr>
    <a className='btn btn-primary' onClick={() => {
      confirmSerialNumbers(serialNumbers)
    }}>確認</a>
  </div>

}