import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as AdminOrderActions from '../../../actions/admin/order/form'
// import {reactSelectStyles} from '../../common/react_select_styles'

import _ from "lodash";
import AsyncSelect from 'react-select/async';
import BaseSelect from "react-select";
import CreatableSelect from 'react-select/creatable'
import FixRequiredSelect from "../../common/fix_react_select";

const Select = props => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options || options}
  />
);

class ProductItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedProduct: null,
      batches: this.props.item.batches || [],
      selectedBatch: this.props.item.batch,
      expiration_dates: this.props.item.expiration_dates || [],
      selectedExpirationDate: this.props.item.expiration_deadline,
      shop_products:[]
    }
    this.handleProductChange = this.handleProductChange.bind(this)
    this.updateSelectedProduct = this.updateSelectedProduct.bind(this)
  }

  componentDidMount() {
    $(ReactDOM.findDOMNode(this))
      .find('.datepicker-input')
      .datepicker({
        todayBtn: 'linked',
        todayHighlight: true,
        daysOfWeekHighlighted: '0,6',
        format: 'yyyy-mm-dd',
        language: 'zh-TW',
      })
      .on('change', this.handleExpirationDeadlineChange)

  }
  componentDidUpdate(prevProps) {
    if (this.props.item.batch !== prevProps.item.batch) {
      this.setState({ selectedBatch: this.props.item.batch })
    }
    if (this.props.item.batches !== prevProps.item.batches) {
      this.setState({ batches: this.props.item.batches || [] })
    }
    if (this.props.item.expiration_deadline !== prevProps.item.expiration_deadline) {
      this.setState({ selectedExpirationDate: this.props.item.expiration_deadline })
    }
    if (this.props.item.expiration_dates !== prevProps.item.expiration_dates) {
      this.setState({ expiration_dates: this.props.item.expiration_dates || [] })
    }
    if (this.props.shop_id !== prevProps.shop_id && !!this.props.shop_id) {
      this.props.onFetchProducts('', this.props.shop_id)
    }
  }

  toOption(product){
    return { label: [product.barcode,product.uid, product.name].filter(Boolean).join("/"), value: product.uid }
  }

  isColumnEnabled(code){
    for(let column of this.props.optional_columns ){
      if(code == column.code){
        return true
      }
    }
    return false
  }

  handleItemDelete = (event) => {
    this.props.onProductItemDelete({ index: this.props.index })
  }

  handleProductChange = (options) => {
    if (options && options.value) {
      this.props.onProductItemChange({
        index: this.props.index,
        uid: options.value,
      })

    }
  }

  handleIdChange = (event) => {
    this.handleItemUpdate('id', event.target.value)
  }

  handleProductTypeChange = (event) => {
    this.handleItemUpdate('product_storage_type_id', event.value)
  }

  handleQuantityChange = (event) => {
    this.handleItemUpdate('quantity', event.target.value)
  }

  handleExpirationDeadlineChange = (event) => {
    if (event.value === "" || this.isLegalDate(event.value)) {
      this.handleItemUpdate('expiration_deadline', event.value)
      this.setState({ selectedExpirationDate: event.value })
    }
    else {
      window.notyError("日期格式錯誤或不存在!")
    }
  }
  isLegalDate = (dateStr) => {
    let re = new RegExp("^([0-9]{4})[.-]{1}([0-9]{1,2})[.-]{1}([0-9]{1,2})$");
    if (re.exec(dateStr) != null) {
      let dateObj = dateStr.split('-');
      let theYear = parseInt(dateObj[0]);
      let theMonth = parseInt(dateObj[1]);
      let theDay = parseInt(dateObj[2]);

      let limitInMonth = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
      if (theYear % 4 == 0) limitInMonth[1] = 29; // 閏年
      return theDay <= limitInMonth[theMonth - 1];
    }
  }

  handleBatchChange = (event) => {
    this.handleItemUpdate('batch', event.value)
    this.setState({ selectedBatch: event.value })
  }

  handleItemUpdate = (key, value) => {
    this.props.onProductItemUpdate({
      index: this.props.index,
      [key]: value,
    })
  }

  handleDestroyChange = (event) => {
  }
  updateSelectedProduct = (option) => {
    let selectProduct = this.props.fetchedProducts.find(product => product.uid == option.value)
    if (!!selectProduct) {
      this.setState({ selectProduct }, () => {
        this.setState({ batches: this.state.selectProduct?.batches, selectedBatch: null, expiration_dates: this.state.selectProduct?.expiration_dates, selectedExpirationDate: null })
        this.handleItemUpdate('batches', this.state.selectProduct?.batches)
        this.handleItemUpdate('uid', this.state.selectProduct?.uid)
        this.handleItemUpdate('name', this.state.selectProduct?.name)
        this.handleItemUpdate('barcode', this.state.selectProduct?.barcode)
        this.handleItemUpdate('batch', null)
        this.handleItemUpdate('expiration_dates', this.state.selectProduct?.expiration_dates)
        this.handleItemUpdate('expiration_deadline', null)
      })
    }
  }
  render() {
    const { products, storage_types, item ,optional_columns} = this.props
    const { id, uid, bundle_id, product_storage_type_id, quantity, batch, _destroy ,note ,status,price,subtype,price_percentage, barcode, name, identifier} = item
    console.log('quantity',quantity)

    const product_select_options = products.map((product, index) => {
      let name = `${product.uid} ${product.name}`
      let code = product.uid
      return { value: code, label: name }
    })

    const storage_type_select_options = storage_types.map(function (storage_type, index) {
      let name = `${storage_type.code} - ${storage_type.name}`
      let id = storage_type.id
      return {value: id, label: name}
    })
    let batches = []
    if(this.state.batches && this.state.batches.length > 0){
      batches = this.state.batches.map(b => { return { label: b, value: b} })
      batches.unshift({label: '不指定',value:''},{label: '指定無批號',value:'指定無批號'})
    }
    let expiration_dates = []
    if(this.state.expiration_dates && this.state.expiration_dates.length > 0){
      expiration_dates = this.state.expiration_dates.map(b => { return { label: b, value: b} })
      expiration_dates.unshift({label: '不指定',value:''})
    }

    const loadOptions = (inputValue, callback) => {
      if(inputValue.length <2){
        callback([])
        return
      }
      this.props.onFetchProducts(inputValue, this.props.shop_id).then(products=>{
        console.log('products',products)
        callback(products.map(product=>this.toOption(product)))
      }
      )
    };
    const debouncedLoadOptions = _.debounce(loadOptions, 500)

    let product_select = ''
    let products_uid_html = ''
    if (id) {
      const _product = products.filter(product => product.uid == uid)[0]
      if(_product){
        if (_product.is_bundle) {
          const _buneld = products.filter(product => product.uid == uid)[0]
          product_select = (<strong className={status == 'nostock' ? 'text-warning' : ''}>[{`組合`}] {`${_product.uid} ${_product.name}`}</strong>)
        } else {
          product_select = (<strong className={status == 'nostock' ? 'text-warning' : ''}>{`${_product.uid} ${_product.name}`}</strong>)
        }
      }else{
        product_select = <strong>{`無法修改${item.uid}(已下架或刪除)`}</strong>
      }

      products_uid_html = (
        <input name="order[products][][uid]" type="hidden" value={uid} />
      )
    } else {
      product_select = <AsyncSelect
        className='react-select-container'
        classNamePrefix="react-select"
        placeholder="請選擇商品"
        name="order[products][][uid]"
        required={true}
        cacheOptions
        defaultOptions={[...this.props.fetchedProducts.map(product=>this.toOption(product)), {label: "輸入兩碼以上關鍵字查看更多..." ,value: undefined, isDisabled: true}]}
        value={uid ? { label: [barcode, uid, name].filter(Boolean).join("/"), value: uid } : null}
        loadOptions={(inputValue,callback) => debouncedLoadOptions(inputValue,callback)}
        onChange={this.updateSelectedProduct}
      />
    }

    let tr_class = ''
    let storage_type_class = ''
    let destroy_html = ''
    if (_destroy) {
      destroy_html = (
        <input name="order[products][][_destroy]" type="hidden" value={_destroy} onChange={this.handleDestroyChange} />
      )
      tr_class = 'hide'
    }

    let products_id_html = ''
    if (id) {
      products_id_html = (
        <input name="order[products][][id]" type="hidden" value={id} onChange={this.handleIdChange} />
      )
    }

    return (
      <tr className={tr_class}>
        <td className="text-center">
          {products_id_html}
          {products_uid_html}
          {
            item.is_bundle ?
            <input name="order[products][][bundle_id]" type='hidden' value={bundle_id}></input> :''
          }

          {destroy_html}
          <a className="btn btn-danger btn-xs" onClick={this.handleItemDelete}>{`刪除`}</a>
        </td>
        <td>
          <div className="form-group text-center">
            <input type="hidden" name="order[products][][identifier]" value={identifier || ""}/>
            {product_select}
          </div>
        </td>
        <td>
          {
            item.is_bundle ? '' :
              <div className="form-group">
                <Select name="order[products][][product_storage_type_id]"
                  className='react-select-container'
                  classNamePrefix="react-select"
                  value={storage_type_select_options.find(option => option.value == product_storage_type_id) || storage_type_select_options[0]}
                  searchable={false}
                  clearable={false}
                  options={storage_type_select_options}
                  onChange={this.handleProductTypeChange}
                />
              </div>

          }
        </td>
        <td>
          <div className="form-group required">
            <input name="order[products][][quantity]" className="form-control required" required={'required'} type="number" min={0} step="any" value={quantity || ""} onChange={this.handleQuantityChange} />
          </div>
        </td>
        { this.isColumnEnabled('expiration_deadline') ?
          <td>
            <div className="form-group ">
              {
                this.state.expiration_dates && this.state.expiration_dates.length > 0  ?
                  <CreatableSelect name="order[products][][expiration_deadline]"
                  className='react-select-container'
                  classNamePrefix="react-select"
                  value={(this.state.selectedExpirationDate !== null) ? {label: (this.state.selectedExpirationDate || "不指定"), value: this.state.selectedExpirationDate} : null}
                  searchable={true}
                  clearable={false}
                  options={expiration_dates}
                  onChange={this.handleExpirationDeadlineChange} />
                  : ''
              }
            </div>
          </td> : null
        }
        { this.isColumnEnabled('batch') ?
          <td>
            <div className="form-group ">
              {
                this.state.batches && this.state.batches.length > 0  ?
                  <Select name="order[products][][batch]"
                  className='react-select-container'
                  classNamePrefix="react-select"
                  value={(this.state.selectedBatch !== null) ? {label: (this.state.selectedBatch || "不指定"), value: this.state.selectedBatch} : null}
                  searchable={true}
                  clearable={false}
                  options={batches}
                  onChange={this.handleBatchChange} />
                  : ''
              }
            </div>
          </td> : null
        }
        {
          // batch and expiration need extra infomation
          optional_columns.filter((column=>column.code!='expiration_deadline' && column.code!='batch' && column.code != 'identifier' && column.code != 'product.barcode' )).map(column=>{
            return <td key={column.code}>
              <div className="form-group ">
                <input  name={`order[products][][${column.code}]`} value={this.props.item[column.code] || ''} onChange={(event)=>{this.handleItemUpdate(column.code, event.target.value)}} className="form-control" type={column.type} />
              </div>
            </td>
          })
        }

      </tr>
    )
  }
}

ProductItem.propTypes = {
  products: PropTypes.array.isRequired,
  product_items: PropTypes.array.isRequired,
  storage_types: PropTypes.array.isRequired,
}

// Redux connect
const mapStateToProps = (state, ownProps) => {
  return {
    products: state.AdminOrderForm.products,
    product_items: state.AdminOrderForm.product_items,
    storage_types: state.AdminOrderForm.storage_types,
    optional_columns: state.AdminOrderForm.optional_columns
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(AdminOrderActions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductItem)
