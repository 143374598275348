import React, { Component } from 'react'
import Creatable from 'react-select/creatable';

export default class OrderShopValueAddedServices extends Component {
  constructor(props) {
    super(props)
    this.state = {
      order_services: this.props.order_services.map(service => {
        service.key = service.id;
        return service
      })
    }
    this.handleServiceAdd = this.handleServiceAdd.bind(this)
    this.removeService = this.removeService.bind(this)
  }
  handleServiceAdd() {
    let order_services = this.state.order_services
    order_services.push({ key: Math.random() })
    this.setState({ order_services: order_services })
  }
  removeService(key) {
    let order_services = this.state.order_services.filter(e => e.key != key)
    this.setState({ order_services: order_services })
  }

  render() {
    const { order_services } = this.state
    return (
      <>
        <table className="table">
          <thead>
            <tr>
              <th></th>
              <th>服務名稱</th>
              <th>單價 <span className="text-danger">*</span></th>
              <th>數量 <span className="text-danger">*</span></th>
            </tr>
          </thead>
          <tbody>
            {
              order_services.map((order_service, _) => (
                <OrderShopValueAddedService
                  key={order_service.key}
                  order_service={order_service}
                  shop_services={this.props.shop_services}
                  removeService={this.removeService}
                />
              ))
            }
            <tr>
              <td colSpan="4">
                <a href='#!' onClick={this.handleServiceAdd} className='btn btn-block btn-info'>
                  <i className='fa fa-plus'/> 新增服務
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    )
  }
}
class OrderShopValueAddedService extends Component {
  constructor(props) {
    super(props)
    this.state = {
      order_service: this.props.order_service,
      price: this.props.order_service.price || 0,
      quantity: this.props.order_service.quantity ?? 1,
    }
    this.updateService = this.updateService.bind(this)
  }
  updateService(service_option) {
    let shop_service = this.props.shop_services.find(s => s.title === service_option.value)
    if(shop_service){
      this.setState({
        title: shop_service.title,
        price: shop_service.default_price,
        quantity: 1
      })  
    }
  }
  render() {
    const { order_service, price, quantity } = this.state
    return (
      <tr>
        <td>
          <a href='#!' onClick={()=>this.props.removeService(order_service.key)} className='btn btn-link text-danger'>
            <i className='fa fa-trash'/>
          </a>
        </td>
        <td>
          <Creatable
            name="services[][title]"
            defaultValue={order_service.title ? {value: order_service.title, label: order_service.title} : null}
            options={this.props.shop_services.map(s => ({value: s.title, label: s.title}))}  
            onChange={this.updateService}
          />
        </td>
        {
          <>
            <td>
              <div className="input-group">
                <input
                  min="0" className="form-control" type="number" required={true}
                    value={price} name="services[][price]" step="any"
                    onChange={(event) => this.setState({ price: event.target.value })}
                />
                <div className="input-group-addon">元</div>
              </div>
            </td>
            <td>
              <div className="input-group">
                <input
                  min="1" className="form-control" type="number" required={true}
                  defaultValue={quantity} name="services[][quantity]"
                />
                <div className="input-group-addon">個</div>
              </div>
            </td>
          </>
        }
      </tr>
    )
  }
}
