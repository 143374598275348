import React, { useState, useEffect, useRef } from 'react'
import printJS from 'print-js'

const KEYS = {
  name: '名稱',
  uid: '品號',
  barcode: '條碼',
  spec: '規格',
  note: '備註',
  box_barcode: '箱條碼',
  size: '尺寸',
  color: '顏色',
  category: '類別',
  mid_unit_barcode: '中包條碼',
  default_pcs: '箱入數',
}


export default function Scan() {

  const [barcode, setBarcode] = useState('')
  const [buckets, setBuckets] = useState([])

  const [enableGrouping, setEnableGrouping] = useState(false)

  const [product, setProduct] = useState(null)
  const [photo, setPhoto] = useState(null)
  const [currentGroupIndex, setCurrentGroupIndex] = useState(null)
  const [labels, setLabels] = useState([])
  const barcodeInputRef = useRef(null)

  useEffect(() => {
    document.body.onkeydown = (e) => {
      if (barcodeInputRef.current) {
        barcodeInputRef.current.focus()
      }
    }
  }, [])
  function onBarcodeInput(e) {
    setBarcode(e.target.value)
  }

  function onError(message) {
    notyError(message)
  }

  function onKeyDown(e) {
    if (e.key === 'Enter' || e.key === 13) {
      fetch(`/operator/products/scan.json?barcode=${barcode}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        }
      }).then(res => {
        if (res.status === 404) {
          onError(`找不到商品條碼${barcode}`)
        }
        return res.json()
      }).then(data => {
        console.log('data', data)
        setProduct(data.product)
        setPhoto(data.photo)
        setLabels(data.labels)
        if (enableGrouping) {
          let found = false

          for (let i = 0; i < buckets.length; i++) {
            if (buckets[i].uid === data.product.uid) {
              buckets[i].count += 1
              found = true
              setCurrentGroupIndex(i + 1)
              var msg = new SpeechSynthesisUtterance(`${i + 1}號`);
              window.speechSynthesis.speak(msg)

              break
            }
          }
          if (!found) {
            buckets.push({
              uid: data.product.uid,
              count: 1
            })
            setCurrentGroupIndex(buckets.length)
            var msg = new SpeechSynthesisUtterance(`${buckets.length}號`);
            window.speechSynthesis.speak(msg)

          }

        }
      }).catch(err => {
      })
      setBarcode('')

    }
  }



  return <div>
    <div className="page-header">
      <div className="pull-left col-md-6"><h1>掃描商品</h1>
      </div>
      <div className="col-md-3">
        <label className="switcher switcher-success" htmlFor="switcher-basic">
          <input id="switcher-basic" type="checkbox"
            checked={enableGrouping}
            onChange={() => setEnableGrouping(!enableGrouping)}
          />
          <div className="switcher-indicator">
            <div className="switcher-yes">是</div>
            <div className="switcher-no">否</div>
          </div>
          啟用商品歸類
        </label>
      </div>
      <div className="pull-right col-md-3">

        <input type="text"
          placeholder="請輸入或掃描條碼"
          ref={barcodeInputRef}
          autoFocus={true}
          value={barcode}
          onChange={onBarcodeInput}
          onKeyDown={onKeyDown}
          className="form-control input-lg" />
      </div>
    </div>
    {product && <div className='row'>
      <div className='col-md-6'>
        <h4>{product.name}</h4>
        <img className='img-responsive' src={photo} />
      </div>
      <div className='col-md-6'>
        {labels.length > 0 && <>
          <h4>商品標籤</h4>
          <div className='btn-group'>
          {
            labels.map(label => {
            return <a href={`#!`} key={label.id}
                className='btn btn-primary btn-outline'
                onClick={() => {
                  fetch(`/operator/product_labels/${label.id}/download.json`, {
                    method: 'GET',
                    headers: {
                      'Content-Type': 'application/json',
                      'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
                    }
                  }).then(res => res.json()).then(data => {
                    let fileType = 'pdf'
                    let extension = data.filename.split('.').pop().toLowerCase()
                    switch(extension) {
                      case 'pdf':
                        fileType = 'pdf'
                        break
                      case 'png':
                      case 'jpg':
                      case 'jpeg':
                        fileType = 'image'
                        break
                      default:
                        fileType = 'url'
                    }
                    printJS({
                      printable: data.url,
                      type: fileType
                    })
                  })
                }}
              >
                <i className="fa fa-2x fa-print" style={{marginRight: '10px'}}></i>
                {label.name}
              </a>
          })
        }
        </div>
        </>}
        {
          enableGrouping && <div>
            <h4>商品歸類</h4>
            <h1 className='animated pulse' style={{ lineHeight: "230px", fontSize: "300px", textAlign: 'center', height: '240px', overflow: 'hidden', margin: 0 }}>
              {currentGroupIndex}
            </h1>
          </div>
        }
        <h4>商品資訊</h4>
        <table className='table table-striped'>
          <tbody>
            {
              Object.keys(KEYS).filter(key => !!product[key]).map(key => {
                return <tr key={key}>
                  <td>{KEYS[key]}</td>
                  <td>{product[key]}</td>
                </tr>
              })
            }
          </tbody>
        </table>
      </div>
    </div>
    }
  </div>
}