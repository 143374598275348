import React, { useState,useEffect } from 'react'
import Select from 'react-select'
import VehicleDispatchFormVehicle from './vehicle_dispatch_form_vehicle'
import Creatable from 'react-select/creatable';

import DataTable from 'react-data-table-component';

export default function VehicleDispatchForm(props) {

  const [shopId, setShopId] = useState(props.shop_id)
  const [channels, setChannels] = useState([])
  const [selectedShippingTypes, setSelectedShippingTypes] = useState(null)
  const [selectedChannels, setSelectedChannels] = useState(null)
  const {shops ,shipping_types, vehicles,vehicle_dispatch_form, logistic_companies, picking_list} = props
  const [startDate, setStartDate] = useState((new Date()).toLocaleDateString('en-CA'))
  const [endDate, setEndDate] = useState((new Date()).toLocaleDateString('en-CA'))
  const [dateCondition, setDateCondition ] = useState('created_at')
  const [orders, setOrders] = useState(props.orders)
  const [companyPhone, setCompanyPhone] = useState(vehicle_dispatch_form.logistic_company_phone || '')

  const [includeDispatchedOrders, setIncludeDispatchedOrders] = useState(false)

  const getShopChannels = (shop_id)=>{
    if(!shop_id) return
    fetch(`/operator/shop_channels.json?shop_id=${shop_id}&picking_list_id=${picking_list ? picking_list.id : ''}`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        setChannels(data)
      })
  }

  const getOrders = () => {
    if(!shopId){
      notyError('請選擇客戶')
      return
    }
    let shipping_type_ids = []
    let channel_ids = []
    if(selectedShippingTypes) {
      shipping_type_ids = selectedShippingTypes.map(e => e.value)
    }
    if(selectedChannels) {
      channel_ids = selectedChannels.map(e => e.value)
    }
    if(!startDate || !endDate) {
      notyError('請選擇日期')
      return 
    }
    fetch(`/operator/vehicle_dispatch_forms/related_orders.json?shop_id=${shopId}&shipping_type_ids=${shipping_type_ids}&channel_ids=${channel_ids}&date_condition=${dateCondition}&start_date=${startDate}&end_date=${endDate}&picking_list_id=${picking_list ? picking_list.id : ''}&include_dispatched_orders=${includeDispatchedOrders ? 'true' : ''}`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
        },
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        setOrders(data)
      })
  }


  const onShippingTypesSelect = (event) => {
    setSelectedShippingTypes(event)
  }
  const onChannelsSelect = (event) => {
    setSelectedChannels(event)
  }

  useEffect(() => {
    getShopChannels(shopId,picking_list)
  }, [shopId]);

  useEffect(() => {
    if(picking_list) {
      setShopId(picking_list.shop_id)
    }
    
  }, []);

  const onChangeCompany = (event) => {
    if(event.value) {
      let logistic_company = logistic_companies.find(company => company.name === event.value)
      if(logistic_company) {
        setCompanyPhone(logistic_company.phone)
      }else{
        setCompanyPhone('')
      }
    }
  }

  return (
    <div className="panel">
      <div className="panel-body">
        <div className="row">
          <div className="col-md-4">
            <div className="form-group vehicle_dispatch_form_shop_id" aria-required="true">
              <label className="control-label" htmlFor="vehicle_dispatch_form_shop_id" aria-required="true">
                <abbr title="required"></abbr> 客戶
              </label>
              {
                picking_list ? <input type="hidden" name="vehicle_dispatch_form[shop_id]" value={picking_list.shop_id} /> : ''
              }
              <Select
                name="vehicle_dispatch_form[shop_id]" required={true}
                value={
                  shops.find(shop => shop.id === shopId) ? {
                    value: shopId,
                    label: shops.find(shop => shop.id === shopId).name
                  } : null
                }
                isDisabled={picking_list}
                options={shops.map(shop => ({ value: shop.id, label: shop.name }))}
                onChange={(event) => {
                  setShopId(event.value)}}
              ></Select>
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group vehicle_dispatch_form_dispatching_date">
              <label className="control-label" htmlFor="vehicle_dispatch_form_dispatching_date">派車日期</label>
              <input className="form-control" type="date" name="vehicle_dispatch_form[dispatching_date]" defaultValue={vehicle_dispatch_form.dispatching_date || (new Date()).toLocaleDateString('en-CA') } id="vehicle_dispatch_form_dispatching_date" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group string required vehicle_dispatch_form_logistic_company" aria-required="true">
              <label className="control-label string required" htmlFor="vehicle_dispatch_form_logistic_company" aria-required="true">
                <abbr title="required"></abbr> 貨運公司
              </label>
              <Creatable
                name="vehicle_dispatch_form[logistic_company]" required={true}
                options={logistic_companies.map(company => ({ value: company.name, label: company.name }))}
                onChange={(event) => {
                  onChangeCompany(event)
                }}
                defaultValue={vehicle_dispatch_form.logistic_company ? { value: vehicle_dispatch_form.logistic_company, label: vehicle_dispatch_form.logistic_company } : null}
              ></Creatable>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <div className="form-group vehicle_dispatch_form_loading_pallet">
              <label className="control-label" htmlFor="vehicle_dispatch_form_loading_pallet">裝載板數</label>
              <input className="form-control numeric" min="0" type="number" step="1" name="vehicle_dispatch_form[loading_pallet]" defaultValue={vehicle_dispatch_form.loading_pallet} id="vehicle_dispatch_form_loading_pallet" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group vehicle_dispatch_form_loading_box"><label className="control-label" htmlFor="vehicle_dispatch_form_loading_box">裝載箱數</label>
              <input className="form-control numeric" min="0" type="number" step="1" name="vehicle_dispatch_form[loading_box]" defaultValue={vehicle_dispatch_form.loading_box} id="vehicle_dispatch_form_loading_box" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group vehicle_dispatch_form_logistic_company_phone">
              <label className="control-label" htmlFor="vehicle_dispatch_form_logistic_company_phone">貨運公司電話</label>
              <input className="form-control string" type="tel" name="vehicle_dispatch_form[logistic_company_phone]" value={companyPhone} 
              onChange={(event) => setCompanyPhone(event.target.value)}
              autoComplete="off"
              id="vehicle_dispatch_form_logistic_company_phone" />
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-4">
            <div className="form-group vehicle_dispatch_form_customer_name">
              <label className="control-label" htmlFor="vehicle_dispatch_form_customer_name">收貨人名稱</label>
              <input className="form-control" type="text" name="vehicle_dispatch_form[customer_name]" defaultValue={vehicle_dispatch_form.customer_name} id="vehicle_dispatch_form_customer_name" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group vehicle_dispatch_form_customer_phone">
              <label className="control-label" htmlFor="vehicle_dispatch_form_customer_phone">收貨人電話</label>
              <input className="form-control string" type="tel" name="vehicle_dispatch_form[customer_phone]" defaultValue={vehicle_dispatch_form.customer_phone} id="vehicle_dispatch_form_customer_phone" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group vehicle_dispatch_form_customer_address">
              <label className="control-label" htmlFor="vehicle_dispatch_form_customer_address">收貨人地址</label>
              <input className="form-control" type="text" name="vehicle_dispatch_form[customer_address]" defaultValue={vehicle_dispatch_form.customer_address} id="vehicle_dispatch_form_customer_address" />
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-4">
            <div className="form-group vehicle_dispatch_form_point_fee">
              <label className="control-label" htmlFor="vehicle_dispatch_form_point_fee">對點費用</label>
              <input className="form-control numeric" min="0" type="number" step="any" name="vehicle_dispatch_form[point_fee]" defaultValue={vehicle_dispatch_form.point_fee} id="vehicle_dispatch_form_point_fee" />
            </div>
          </div>
          <div className="col-md-4">
            <div className="form-group vehicle_dispatch_form_other_fee">
              <label className="control-label" htmlFor="vehicle_dispatch_form_other_fee">其他費用</label>
              <input className="form-control numeric" min="0" type="number" step="any" name="vehicle_dispatch_form[other_fee]" defaultValue={vehicle_dispatch_form.other_fee} id="vehicle_dispatch_form_other_fee" />
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-8">
            <div className="form-group">
              <label className="control-label">派車單類型</label>
              <select className="custom-select form-control" name="vehicle_dispatch_form[dispatching_type]" defaultValue={vehicle_dispatch_form.dispatching_type}>
                <option value="forward_logistics">正物流（倉庫端裝載，收貨人端卸載）</option>
                <option value="backward_logistics">逆物流（收貨人端裝載，倉庫端卸載）</option>
              </select>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="form-group">
              <label className="control-label">派車</label>
              <VehicleDispatchFormVehicle
                vehicles={vehicles}
                type={"loading"}
              ></VehicleDispatchFormVehicle>
            </div>
          </div>
        </div>
        <hr />
        <div className="row">
          <div className="col-md-8">
            <div className="form-group vehicle_dispatch_form_note">
              <label className="control-label" htmlFor="vehicle_dispatch_form_note">出貨備註</label>
              <input className="form-control" type="text" name="vehicle_dispatch_form[note]" defaultValue={vehicle_dispatch_form.note} id="vehicle_dispatch_form_note" />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group  vehicle_dispatch_form_is_paid_cash">
              <div className="checkbox">
                <input value="0" autoComplete="off" type="hidden" name="vehicle_dispatch_form[is_paid_cash]" />
                <label className="" htmlFor="vehicle_dispatch_form_is_paid_cash">
                  <input className="" type="checkbox" value="1" name="vehicle_dispatch_form[is_paid_cash]" defaultChecked={vehicle_dispatch_form.is_paid_cash} id="vehicle_dispatch_form_is_paid_cash" />是否付現</label>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="form-group vehicle_dispatch_form_abnormal_description">
              <label className="control-label" htmlFor="vehicle_dispatch_form_abnormal_description">乘載異常說明</label>
              <input className="form-control" type="text" name="vehicle_dispatch_form[abnormal_description]" defaultValue={vehicle_dispatch_form.abnormal_description} id="vehicle_dispatch_form_abnormal_description" />
            </div>
          </div>
        </div>

        <hr />
        <div className='row'>
          <div className="col-md-8">
            <div className="form-group">
              <label>日期區間</label>
              <span className="text-danger"> *</span>
              <div className="row">
                <div className="col-md-4">
                  <select className="custom-select form-control" id="vehicle_dispatch_form_filter_type"
                    onChange={(e) => {
                      setDateCondition(e.target.value)
                    }}
                    >
                    <option value="created_at">依建立日期</option>
                    <option value="scheduled_shipping_date">依預約到貨日期</option>
                  </select>
                </div>
                <div className="col-md-8"><div className="input-group" id="datepicker-range">
                  <input autoComplete="off" className="form-control" 
                    id="vehicle_dispatch_form_start_date" placeholder="開始時間" 
                    onChange={(e) => {
                      setStartDate(e.target.value)
                    }}
                    value={startDate}
                    type="date" />
                  <span className="input-group-addon">至</span>
                  <input autoComplete="off" className="form-control" 
                    onChange={(e) => {
                      setEndDate(e.target.value)
                    }}
                    value={endDate}
                    id="vehicle_dispatch_form_end_date"  placeholder="結束時間" type="date" />
                </div>
                </div>
              </div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className="form-group">
              <label>&nbsp;</label>
              <div className="checkbox">
                <label className="form-control-static" htmlFor="vehicle_dispatch_form_include_dispatched_orders">
                <input type="checkbox" value="1" 
                  onChange={(e) => {
                    setIncludeDispatchedOrders(e.target.checked)
                  }}
                checked={includeDispatchedOrders} id="vehicle_dispatch_form_include_dispatched_orders" />包含已派車訂單</label>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-md-4">
            <div className="form-group vehicle_dispatch_form_shipping_type_id">
              <label className="control-label" htmlFor="vehicle_dispatch_form_shipping_type_id"><abbr title="required"></abbr> 物流類型</label>
              <Select
                name="vehicle_dispatch_form_shipping_type_id"
                isMulti={true}
                onChange={onShippingTypesSelect}
                options={shipping_types.map((shipping_type) => {
                  return {
                    value: shipping_type.id,
                    label: shipping_type.name
                  }
                })}
                value={selectedShippingTypes}
                placeholder="請選擇物流類型"
              />
            </div>
          </div>
          <div className="col-md-4">
            <div id="shop_channel_filter">
              <label>通路 </label>
              <Select
                isMulti={true}
                name="vehicle_dispatch_form_shop_channel_id"
                options={channels.map((channel) => {
                  return {
                    value: channel.id,
                    label: channel.code
                  }
                })}
                value={selectedChannels}
                onChange={onChannelsSelect}
                placeholder="請選擇通路"
              />
            </div>
          </div>
          <div className="col-md-4">
            <label>&nbsp;</label>
            <button className="btn btn-info form-control" id="vehicle_dispatch_form_filter_btn" type="button" onClick={getOrders}>篩選</button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
          <DataTable
            columns={
              [
                {
                  name: <span>
                    <input type="checkbox" onChange={
                      (e) => {
                        let checked = e.target.checked
                        setOrders(orders.map((order) => {
                          order.checked = checked
                          return order
                        }))
                      }
                    } 
                    checked={ orders.length > 0 && orders.filter((order) => order.checked).length == orders.length}
                    />
                  </span>,
                  sortable: false,
                  cell: (row) => {
                    return (
                      <input type="checkbox" name="vehicle_dispatch_form[related_order_ids][]" 
                      checked={row.checked}
                      onChange={
                        (e) => {
                          let checked = e.target.checked
                          setOrders(orders.map((order) => {
                            if (order.id == row.id) {
                              order.checked = checked
                            }
                            return order
                          }))
                        }
                      }
                      value={row.id} />
                    )
                  }
                },
                {
                  name: '單號',
                  sortable: true,
                  selector: (row, i) => row.name
                },
                {
                  name: '收件人',
                  sortable: true,
                  selector: (row, i) => row.customer_name,
                },
                {
                  name: '通路',
                  sortable: true,
                  selector: (row, i) => row.shop_channel_name,
                },
                {
                  name: '物流類型',
                  sortable: true,
                  selector: (row, i) => row.shipping_type_name,
                },
                {
                  name: '建立時間',
                  sortable: true,
                  selector: (row, i) => row.created_at,
                },
                {
                  name: '預約到貨時間',
                  sortable: true,
                  selector: (row, i) => row.scheduled_shipping_date,
                },
              ]
            }
            pagination={false}
            data={orders}
          ></DataTable>
          </div>
        </div>
      </div>
    </div>
  )
}