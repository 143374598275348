import React, { Component } from 'react'

import BarcodeInput from './barcode_input'
class ReceiptSerialNumbers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      serialNumbers: [],
      receipt: {}
    };
    this.handleDelete = this.handleDelete.bind(this);
    this.deleteReceiptSerialNumber = this.deleteReceiptSerialNumber.bind(this);
    this.onReceiptBarcodeEnter = this.onReceiptBarcodeEnter.bind(this);

  }

  componentDidMount() {
  }

  onReceiptBarcodeEnter(barcode) {
    fetch(`/operator/receipts/barcode.json`).then(res=>res.json()).then(data=>{
      this.setState({receipt: data.receipt, serialNumbers: data.serial_numbers})
    })
  }


  handleSubmit = (e) => {
    e.preventDefault();
  }

  deleteReceiptSerialNumber = (receiptId, serialNumber) => {
  }

  handleDelete = (e) => {
    const { receiptId } = this.props;
    const { serialNumbers } = this.state;
    const index = serialNumbers.indexOf(e.target.value);
    deleteReceiptSerialNumber(receiptId, serialNumbers[index]);
  }

  async onBarcodeEnter(barcode) {
    console.log(barcode)
    if(this.state.receipt.id){
    }else{
      this.onReceiptBarcodeEnter(barcode)
    }
  }

  render() {
    const { serialNumbers,receipt } = this.state;
    return (
      <div className="page-header">
      <div className="pull-left"><h1>登錄序號</h1></div>
      <div className="pull-right">
        <BarcodeInput onBarcodeEnter={this.onBarcodeEnter} />
      </div>
    </div>
    );
  }
}

class ReceiptSelect  extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      receipts: [],
      receipt: {}
    };
    this.handleReceiptChange = this.handleReceiptChange.bind(this);
    this.fetchReceipts = this.fetchReceipts.bind(this)
  }


  handleReceiptChange(e) {
    this.setState({receipt: {id: e.target.value}})
    this.props.onReceiptBarcodeEnter(e.target.value)

  }

  componentDidMount() {
    this.fetchReceipts();
  }

  fetchReceipts(){
    fetch('/operator/receipts.json?need_serial_number=true')
    .then((response) => response.json())
    .then((responseJson) => {
      this.setState({receipts: responseJson})
    })
    .catch((error) => {
      console.error(error);
    });
  }
  render(){
    const { receipts,receipt } = this.state;
    return (
      <div className="form-group">
        <label>選擇入倉單</label>
        <select className="form-control" value={receipt.id} onChange={this.handleReceiptChange}>
          <option value="">請選擇入倉單</option>
          {
            receipts.map((receipt) => {
              return <option key={receipt.id} value={receipt.id}>{receipt.title}</option>
            })
          }
        </select>
      </div>
    )
  }
}

export default ReceiptSerialNumbers