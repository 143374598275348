import React from "react"
import { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import LoadingOverlay from 'react-loading-overlay';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from "chart.js"
import { Line, Pie } from "react-chartjs-2"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
    intersect: false
  },
  stacked: false,
  scales: {
    y: {
      ticks: {
        stepSize: 10
      },
      type: "linear",
      display: true,
      position: "left",
      min: 0
    },
  }
}

const columns = [
  {
    name: '品號',
    selector: (row, i) => row.uid,
    sortable: true,
  },
  {
    name: '品名',
    selector: (row, i) => row.name,
    sortable: true,
  }, {
    id: 'quantity',
    name: '銷售量',
    selector: (row, i) => row.quantity,
    sortable: true,
  }
]


export default function OrderCharts(props) {
  const monthAgo = (n) => {
    const date = new Date();
    date.setMonth(date.getMonth() - n);
    return date;
  }

  const hashCode = (str) => { // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  const intToRGB = (string) => {
    let c = (hashCode(string) & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    let rgb = "00000".substring(0, 6 - c.length) + c;
    return `#${rgb}`
  }



  const { channels } = props;
  const [selectedChannels, setSelectedChannels] = useState(channels.map((channel) => channel.id));
  const [showChannels, setShowChannels] = useState(false);
  const [startDate, setStartDate] = useState(monthAgo(1).toLocaleDateString('en-CA'));
  const [endDate, setEndDate] = useState((new Date()).toLocaleDateString('en-CA'));
  const [orderChannels, setOrderChannels] = useState([])
  const [orderChannelData, setOrderChannelData] = useState([])
  const [orderChannelPcsData, setOrderChannelPcsData] = useState([])
  const [orderChannelPricesData, setOrderChannelPricesData] = useState([])

  const [ordersData, setOrdersData] = useState([])
  const [ordersPcsData, setOrdersPcsData] = useState([])
  const [ordersPricesData, setOrdersPricesData] = useState([])
  const [dates, setDates] = useState([])
  const [saleProducts, setSaleProducts] = useState([])
  const [filterText, setFilterText] = useState('');

  const [deboundState, setDeboundState] = useState(false)
  const [loading, setLoading] = useState(false)

  const filteredProducts = saleProducts.filter(
    (product) => {
      return product.uid.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 ||
        product.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    }
  );
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className='row'>
        <div className='col-md-12'>
          <input className='form-control' placeholder='搜尋商品' onChange={
            (e) => {
              setFilterText(e.target.value)
            }
          } />
        </div></div>
    );
  }, [filterText]);

  useEffect(() => {
    if (deboundState) {
      return;
    }
    setDeboundState(true)
    setTimeout(() => {
      setDeboundState(false)
    }, 2000)
  }, [selectedChannels, startDate, endDate])

  useEffect(() => {
    if (deboundState) {
      return;
    }
    fetchOrders()
  }, [deboundState])

  const checkRange = () => {
    if (startDate > endDate) {
      notyError('開始日期不可晚於結束日期')
      return false;
    }
    if (endDate > (new Date()).toLocaleDateString('en-CA')) {
      notyError('結束日期不可晚於今天')
      return false;
    }
    // 時間區間不可超過半年
    let start = new Date(startDate)
    let end = new Date(endDate)
    let diffTime = Math.abs(end - start);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 180) {
      notyError('時間區間不可超過半年')
      return false;
    }
    return true;
  }


  const fetchOrders = () => {
    if (!checkRange()) {
      return;
    }
    setLoading(true)
    const url = new URL('/admin/orders/list', window.location.origin);
    const params = {
      start_date: startDate,
      end_date: endDate,
      channels: selectedChannels,
      status: 'ready,processing,done,nostock'
    }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    fetch(url)
      .then((response) => response.json())
      .then((orders) => {
        let _dates = sortDates()
        // setDates不會馬上反應
        let _channels = [...new Set(orders.map(order => order.shop_channel_id))]
        setOrderChannels(_channels)
        sortOrdersData(orders, _dates, _channels)
        countOrdersByChannel(orders, _channels)
        countOrdersPcsByChannel(orders, _channels)
        countOrdersPricesByChannel(orders, _channels)
        setLoading(false)
      });
  }

  const countOrdersByChannel = (orders, orderChannels) => {
    let orderChannelDatasets = [
      {
        label: '訂單數',
        data: orderChannels.map((channelId) => {
          return orders.filter((order) => order.shop_channel_id == channelId).length
        }),
        borderColor: orderChannels.map(() => "#000000"),
        backgroundColor: orderChannels.map((channelId) => {
          let channel = channels.find((channel) => channel.id == channelId)
          return intToRGB(channel.code)
        }),
        borderWidth: 1
      }
    ]
    setOrderChannelData(orderChannelDatasets)
  }

  const countOrdersPcsByChannel = (orders, orderChannels) => {
    let orderChannelPcsDatasets = [
      {
        label: 'PCS數',
        data: orderChannels.map((channelId) => {
          return orders.filter((order) => order.shop_channel_id == channelId).reduce((sum, order) => sum + order.products.reduce((sum, product) => sum + product.quantity, 0), 0)
        }),
        borderColor: orderChannels.map(() => "#000000"),
        backgroundColor: orderChannels.map((channelId) => {
          let channel = channels.find((channel) => channel.id == channelId)
          return intToRGB(channel.code)
        }),
        borderWidth: 1
      }
    ]
    setOrderChannelPcsData(orderChannelPcsDatasets)
  }

  const countOrdersPricesByChannel = (orders, orderChannels) => {
    let orderChannelPricesDatasets = [
      {
        label: '訂單金額',
        data: orderChannels.map((channelId) => {
          return orders.filter((order) => order.shop_channel_id == channelId).reduce((sum, order) => sum + order.total_price, 0)
        }),
        borderColor: orderChannels.map(() => "#000000"),
        backgroundColor: orderChannels.map((channelId) => {
          let channel = channels.find((channel) => channel.id == channelId)
          return intToRGB(channel.code)
        }),
        borderWidth: 1
      }
    ]
    setOrderChannelPricesData(orderChannelPricesDatasets)
  }


  const sortDates = () => {
    let dates = []
    let date = new Date(startDate)
    while (date <= new Date(endDate)) {
      dates.push(date.toLocaleDateString('en-CA'))
      date.setDate(date.getDate() + 1)
    }
    setDates(dates)
    return dates
  }



  const sortOrdersData = (orders, orderDates, orderChannels) => {
    let orderDatasets = []
    let orderPcsDatasets = []
    let orderPricesDatasets = []
    let orderSaleProducts = {}
    orderChannels.forEach((channelId) => {
      let channel = channels.find((channel) => channel.id == channelId)
      orderDatasets.push({
        label: channel.code,
        data: orderDates.map((date) => {
          let orders_count = orders.filter((order) => {
            return order.shop_channel_id == channelId && new Date(order.created_at).toLocaleDateString('en-CA') == date
          }).length
          return orders_count
        }),
        borderColor: intToRGB(channel.code),
        backgroundColor: intToRGB(channel.code),
        yAxisID: "y"
      })
      orderPcsDatasets.push({
        label: channel.code,
        data: orderDates.map((date) => {
          let orders_pcs = orders.filter((order) => {
            return order.shop_channel_id == channelId && new Date(order.created_at).toLocaleDateString('en-CA') == date
          }).reduce((sum, order) => sum + order.products.reduce((sum, product) => {
            if (!orderSaleProducts[product.product_id]) {
              orderSaleProducts[product.product_id] = {
                product: product.product,
                quantity: product.quantity
              }
            } else {
              orderSaleProducts[product.product_id].quantity += product.quantity
            }
            return sum + product.quantity
          }, 0), 0)
          return orders_pcs
        }),
        borderColor: intToRGB(channel.code),
        backgroundColor: intToRGB(channel.code),
        yAxisID: "y"
      })
      orderPricesDatasets.push({
        label: channel.code,
        data: orderDates.map((date) => {
          let orders_prices = orders.filter((order) => {
            return order.shop_channel_id == channelId && new Date(order.created_at).toLocaleDateString('en-CA') == date
          }).reduce((sum, order) => sum + order.total_price, 0)
          return orders_prices
        }),
        borderColor: intToRGB(channel.code),
        backgroundColor: intToRGB(channel.code),
        yAxisID: "y"
      })
    })
    setOrdersData(orderDatasets)
    setOrdersPcsData(orderPcsDatasets)
    setOrdersPricesData(orderPricesDatasets)
    setSaleProducts(Object.keys(orderSaleProducts).map((key) => {
      let _product = orderSaleProducts[key].product
      _product.quantity = orderSaleProducts[key].quantity
      return _product
    }))
  }

  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        text='資料載入中，請稍候...'
      >
        <div className="page-header row">
          <div className="pull-left col-md-6">
            <h1>圖表分析</h1>
          </div>
          <div className="col-md-3 form-group">
            <div className='input-group'>
              <input type='date' name='start_date' className='form-control' max={
                endDate > (new Date()).toLocaleDateString('en-CA') ? (new Date()).toLocaleDateString('en-CA') : endDate
              }
                value={startDate} onChange={() => {
                  if (event.target.value > endDate) {
                    notyError('開始日期不可晚於結束日期')
                    return;
                  }
                  setStartDate(event.target.value);
                }} />
              <span className='input-group-addon'>~</span>
              <input type='date' name='end_date' min={startDate} max={
                (new Date()).toLocaleDateString('en-CA')
              } className='form-control' value={endDate} onChange={() => {
                if (event.target.value < startDate) {
                  notyError('結束日期不可早於開始日期')
                  return;
                }
                setEndDate(event.target.value);
              }} />
            </div>
          </div>
          <div className="col-md-3 form-group dropdown">
            <a className="btn btn-info btn-outline" role='button' onClick={() => {
              setShowChannels(!showChannels);
            }}>選擇通路
              <i className='fa fa-caret-down'></i>
            </a>
            <div style={{ display: showChannels ? '' : 'none' }} >
              <ul className="list-unstyled">
                {
                  channels.map((channel, index) => {
                    const checked = selectedChannels.includes(channel.id)
                    return (
                      <li key={index}>
                        <label className="checkbox-inline">
                          <input type="checkbox" value={channel.id} name='channels[]' checked={checked}
                            onChange={() => {
                              if (checked) {
                                setSelectedChannels(selectedChannels.filter((id) => id != channel.id))
                              } else {
                                setSelectedChannels([...selectedChannels, channel.id])
                              }
                            }}
                          /> {channel.code}
                        </label>
                      </li>
                    )
                  })
                }
                <li>
                  <a className="btn btn-outline btn-default" role='button' onClick={() => {
                    if (selectedChannels.length > 0) {
                      setSelectedChannels([])
                    } else {
                      setSelectedChannels(channels.map((channel) => channel.id))
                    }
                  }}>
                    {
                      selectedChannels.length > 0 ? '全部清除' : '全選選取'
                    }
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        {

          !loading && <><div className='page-header'>
            <div className='col-md-4'>
              <h4>訂單數量</h4>
              <Pie data={
                {
                  labels: orderChannels.map((channel) => channels.find((c) => c.id == channel).code),
                  datasets: orderChannelData
                }
              } />
            </div>
            <div className='col-md-4'>
              <h4>PCS數</h4>
              <Pie data={
                {
                  labels: orderChannels.map((channel) => channels.find((c) => c.id == channel).code),
                  datasets: orderChannelPcsData
                }
              } />
            </div>
            <div className='col-md-4'>
              <h4>訂單金額</h4>
              <Pie data={
                {
                  labels: orderChannels.map((channel) => channels.find((c) => c.id == channel).code),
                  datasets: orderChannelPricesData
                }
              } />
            </div>

          </div>
            <div style={{ height: '40vh', paddingBottom: '50px' }} className='page-header'>
              <h4>訂單數量</h4>
              <Line options={options} data={{
                labels: dates,
                datasets: ordersData
              }} />
            </div>
            <div style={{ height: '40vh', paddingBottom: '50px' }} className='page-header'>
              <h4>出貨PCS</h4>
              <Line options={options} data={{
                labels: dates,
                datasets: ordersPcsData
              }} />
            </div>
            <div style={{ height: '40vh', paddingBottom: '50px' }} className='page-header'>
              <h4>訂單金額</h4>
              <Line options={options} data={{
                labels: dates,
                datasets: ordersPricesData
              }} />
            </div>
            <div className='page-header'>
              <DataTable
                title="熱銷商品"
                columns={columns}
                pagination={true}
                data={filteredProducts}
                subHeader
                subHeaderComponent={subHeaderComponentMemo}
                persistTableHead
                defaultSortFieldId="quantity"
                defaultSortAsc={false}
              />
            </div></>
        }

      </LoadingOverlay>

    </>
  )
}

