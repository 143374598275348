import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootAdminReducer from '../reducers/admin'

const createStoreWithMiddleware = applyMiddleware(
  thunk
)(createStore)

export default function adminStore(initialState) {
  return createStoreWithMiddleware(rootAdminReducer, initialState)
}
