import React, { Component } from 'react'

export default class VehicleDispatchFormVehicle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      vehicles: this.props.vehicles.map(vehicle => {
        vehicle.key = vehicle.id;
        return vehicle
      })
    }
    this.handleVehicleAdd = this.handleVehicleAdd.bind(this)
    this.removeVehicle = this.removeVehicle.bind(this)
  }
  componentDidMount() {
    if(this.state.vehicles.length == 0) {
      this.handleVehicleAdd()
    }
  }
  handleVehicleAdd() {
    let vehicles = this.state.vehicles
    vehicles.push({ key: Math.random() })
    this.setState({ vehicles: vehicles })
  }
  removeVehicle(key) {
    let vehicles = this.state.vehicles.filter(e => e.key != key)
    this.setState({ vehicles: vehicles })
  }

  render() {
    const { vehicles } = this.state
    return (
      <>
        {
          vehicles.map((vehicle, index) => (
            <Vehicle
              key={vehicle.key}
              vehicle={vehicle}
              type={this.props.type}
              removeVehicle={this.removeVehicle}
            />
          ))
        }
        <div className='form-group'>
          <a className="btn btn-success btn-block" onClick={this.handleVehicleAdd}>+ 新增派車</a>
        </div>
        <hr className='hr'></hr>
      </>
    )
  }
}
class Vehicle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tonnage: this.props.vehicle.tonnage,
      number: this.props.vehicle.number ?? 1,
      shop_fee: this.props.vehicle.shop_fee,
      driver_fee: this.props.vehicle.driver_fee,
      driver_number: this.props.vehicle.driver_number,
      driver_name: this.props.vehicle.driver_name,
      driver_phone: this.props.vehicle.driver_phone,
      driver_vehicle_number: this.props.vehicle.driver_vehicle_number
    }
  }
  render() {
    const { vehicle, type, removeVehicle } = this.props
    const { tonnage, number, shop_fee, driver_fee, driver_number, driver_name, driver_phone, driver_vehicle_number } = this.state
    return (
      <div className='row'>
        <div className='col-md-1'>
          <a style={{marginTop: "20px"}} href='#!' onClick={() => removeVehicle(vehicle.key)} className='btn btn-link text-danger'>
            <i className='fa fa-trash' />
          </a>
        </div>
        <div className='col-md-11 row'>
          <div className='col-md-3'>
            <div className='form-group'>
              <label className='control-label'>車種 <span className="text-danger">*</span></label>
              <input
                className="form-control" type="text" required={true}
                name={`${type}_vehicles[][tonnage]`} value={tonnage}
                onChange={(event) => this.setState({ tonnage: event.target.value })}
              />
            </div>
          </div>
          <div className='col-md-3'>
            <div className='form-group'>
              <label className='control-label'>輛次 <span className="text-danger">*</span></label>
              <div className="input-group">
                <input
                  min="1" className="form-control" type="number" required={true}
                  value={number} name={`${type}_vehicles[][number]`}
                  onChange={(event) => this.setState({ number: event.target.value })}
                />
                <div className="input-group-addon">台</div>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='form-group'>
              <label className='control-label'>
                客戶運費（單價） <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  min="0" className="form-control" type="number" required={true}
                  value={shop_fee} name={`${type}_vehicles[][shop_fee]`}
                  onChange={(event) => this.setState({ shop_fee: event.target.value })}
                />
                <div className="input-group-addon">元</div>
              </div>
            </div>
          </div>
          <div className='col-md-3'>
            <div className='form-group'>
              <label className='control-label'>
                司機運費（單價） <span className="text-danger">*</span>
              </label>
              <div className="input-group">
                <input
                  min="0" className="form-control" type="number" required={true}
                  value={driver_fee} name={`${type}_vehicles[][driver_fee]`}
                  onChange={(event) => this.setState({ driver_fee: event.target.value })}
                />
                <div className="input-group-addon">元</div>
              </div>
            </div>
          </div>

          <div className='col-md-3'>
            <div className='form-group'>
              <label className='control-label'>
                司機編號 <span className="text-danger"></span>
              </label>
              <input
                className="form-control" type="text"
                value={driver_number} name={`${type}_vehicles[][driver_number]`}
                onChange={(event) => this.setState({ driver_number: event.target.value })}
              />
            </div>
          </div>
          <div className='col-md-3'>
            <div className='form-group'>
              <label className='control-label'>
                司機姓名 <span className="text-danger"></span>
              </label>
              <input
                className="form-control" type="text"
                value={driver_name} name={`${type}_vehicles[][driver_name]`}
                onChange={(event) => this.setState({ driver_name: event.target.value })}
              />
            </div>
          </div>
          <div className='col-md-3'>
            <div className='form-group'>
              <label className='control-label'>
                司機電話 <span className="text-danger"></span>
              </label>
              <input
                className="form-control" type="tel"
                value={driver_phone} name={`${type}_vehicles[][driver_phone]`}
                onChange={(event) => this.setState({ driver_phone: event.target.value })}
              />
            </div>
          </div>
          <div className='col-md-3'>
            <div className='form-group'>
              <label className='control-label'>
                司機車號 <span className="text-danger"></span>
              </label>
              <input
                className="form-control" type="text"
                value={driver_vehicle_number} name={`${type}_vehicles[][driver_vehicle_number]`}
                onChange={(event) => this.setState({ driver_vehicle_number: event.target.value })}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
