import React from 'react';
import Creatable, { useCreatable } from 'react-select/creatable';
import Select from 'react-select';
import { styles } from '../../styles';

export default function MultipleValue({inputName,onChange,defaultOptions=[],createable=false} ) {
  if(createable){
    return (
      <textarea
        name={`${inputName}`}
        placeholder="請輸入完整數值(可輸入多筆同時查詢)"
        className="form-control"
        rows={3}
      />
    );
  }else{
    return (
      <Select
        styles={styles.reactSelect}
        name={`${inputName}`}
        isClearable
        isMulti
        placeholder="請選擇(可選擇多筆同時查詢)"
        cacheOptions
        onChange={onChange}
        options={defaultOptions}
      />
    );
  }
}
