import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ProductItem from './product_item'
import * as AdminOrderActions from '../../../actions/admin/order/form'
import { useState } from 'react'

function Product(props){

  const { items , role} = props
  const [fetchedProducts, setFetchedProducts] = useState([])

  function handleItemAdd(){
    props.onProductItemAdd({
      index: +new Date(),
      quantity: 1
    })
  }
  function isColumnEnabled(code){
    for(let column of props.optional_columns ){
      if(code == column.code){
        return true
      }
    }
    return false
  }

  function fetchProducts(inputValue = "",shop_id) {
    return new Promise((resolve, reject) => {
      fetch(`/${role}/products.json?shop_id=${shop_id}&search=${inputValue}&limit=50`,
        {
          method: 'GET',
          credentials: 'same-origin'
        }).then(res => res.json()).then(data=>{
          for(let product of data.products){
            let found= false
            for(let fetched of fetchedProducts){
              if(fetched.id == product.id){
                found = true
                break
              }
            }
            if(!found){
              fetchedProducts.push(product)
            }
          }
          console.log('fetchedProducts',fetchedProducts)
          setFetchedProducts(fetchedProducts)
          resolve(data.products)
        })
      })
  }


  let product_items = items.map(function (item, index) {
    return <ProductItem key={index}
    fetchedProducts={fetchedProducts}
    onFetchProducts={fetchProducts}
    item={item} index={item.index}  shop_id={props.shop_id} />
  }
  )
  return (
    <div className="panel" style={{overflowX:'scroll'}} >
      <div className="panel-heading">
        <span className="panel-title">商品</span>
        <div className="panel-heading-controls">
          <a className="btn btn-success btn-xs" onClick={handleItemAdd}>{`新增`}</a>
        </div>
      </div>
      <table className="table table-condensed" >
        <thead>
          <tr>
            <th className="" ></th>
            <th className="text-center" style={{minWidth:'150px'}}>商品 <span style={{color: 'red'}}>*</span></th>
            <th className="text-center" style={{minWidth:'100px'}}>倉別 <span style={{color: 'red'}}>*</span></th>
            <th className="text-center" style={{minWidth:'100px'}}>數量 <span style={{color: 'red'}}>*</span></th>
            {
              isColumnEnabled('expiration_deadline') ?
              <th className="text-center" style={{minWidth:'120px'}}>效期</th> : null
            }
            {
              isColumnEnabled('batch') ?
              <th className="text-center" style={{minWidth:'100px'}}>批號</th> : null
            }
            {
                props.optional_columns.filter((column=>column.code!='expiration_deadline' && column.code!='batch' && column.code != 'identifier' && column.code != 'product.barcode')).map(column=>{
                  return <th key={column.code} className="text-center" style={{minWidth:'120px'}}>{column.title}</th>
                })
            }
          </tr>
        </thead>
        <tbody>
          {product_items}
        </tbody>
      </table>
    </div>
  )
}


// Redux connect
const mapStateToProps = (state) => {
  return {
    items: state.AdminOrderForm.product_items,
    optional_columns: state.AdminOrderForm.optional_columns
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(AdminOrderActions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Product)


