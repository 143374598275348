import React, { Component } from 'react'

export default class PackingList extends Component {
  constructor(props) {
    super(props)
    this.onClearAll = this.onClearAll.bind(this)
  }

  onClearAll(){
    if(confirm('確認要清除所有裝箱明細紀錄以及託運單?')){
      fetch(`/operator/orders/${this.props.order_id}/packings/clear`,
      {
        method: 'POST',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        alert(data.message)
        this.props.onClearAllDone()
      })
    }
  }

  render(){
    return <div className='panel'>
    <div className='panel-heading'>裝箱明細
    {
      this.props.items.length > 0 ?
      <a className='btn btn-sm btn-primary btn-outline pull-right' onClick={this.props.onPressPacking}>裝箱</a> :''
    }
      <p>
        <label>
          <input type="checkbox" onChange={(e) =>
            this.props.onPrintPackingChange(e.currentTarget.checked)}/>
          列印
        </label>
      </p>
      </div>
      <div className='panel-body'>
        <label style={{fontSize:'20px'}}>第{this.props.packing_index+1}箱</label>
        <table className='table'>
          <tbody>
          {
            this.props.items.map(item=>{
              return (
                <tr key={`${item.uid}-${item.batch}`}>
                  <td>
                    {item.uid}
                    <br/>
                    {item.name}
                  </td>
                  <td>
                    {item.quantity}
                  </td>
                </tr>
              )
            })
          }
          </tbody>
        </table>
        <hr/>
        <a href={`/operator/orders/${this.props.order_id}/packings.xlsx`} className='btn-block btn-sm btn btn-outline btn-primary'>下載</a>
        {/* {
          this.props.packings.map(p=>{
            return <a key={p.id} href={`/operator/packings/${p.id}.xlsx`} className='btn-block btn-sm btn btn-outline btn-primary'>下載 {p.id}</a>
          })
        } */}
        <a onClick={this.onClearAll} className='btn-block btn-sm btn btn-outline btn-danger'>清除記錄</a>

      </div>
    </div>
  }
}