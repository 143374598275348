import React, { Component } from 'react'
import Select from 'react-select'

export default class Shyangyih extends Component {
  render() {
    let { shipping_account_title, shipping_account_type, shipping_account } = this.props

    return (
      <table className="table table-striped">
        <tbody>
          <tr>
            <td>{shipping_account_title}</td>
            <td></td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">啟用</td>
            <td>
              <div className="form-group">
                <div className="col-md-3">
                  <label htmlFor={`${shipping_account_type}-${shipping_account.id}-enable-switcher`} className="switcher switcher-success">
                    <input type="checkbox" id={`${shipping_account_type}-${shipping_account.id}-enable-switcher`} name={`shipping_accounts[${shipping_account.id}][enabled]`} defaultChecked={shipping_account.enabled}/>
                    <div className="switcher-indicator">
                      <div className="switcher-yes">啟用</div>
                      <div className="switcher-no">停用</div>
                    </div>
                  </label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">客戶代號</td>
            <td>
              <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][customer_code]`} defaultValue={shipping_account.customer_code}/>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">帳號</td>
            <td>
              <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][cust_id]`} defaultValue={shipping_account.cust_id}/>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">密碼</td>
            <td>
              <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][check_word]`} defaultValue={shipping_account.check_word}/>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">聯絡電話</td>
            <td>
              <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][customer_phone]`} defaultValue={shipping_account.customer_phone} placeholder='聯絡電話或倉庫電話'/>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center"></td>
            <td className="col-md-6 text-center">
              <button className="btn btn-primary">更新</button>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}