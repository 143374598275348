import React, { useState } from "react";

function DateRange({inputName,startDate,endDate}) {

  return (
    <div className="date-range input-group">
      <input
        value={startDate}
        name={`${inputName}[start]`}
        type="date"
        className="form-control"
      />
      <div className="input-group-addon">到</div>
      <input
        value={endDate}
        name={`${inputName}[end]`}
        type="date"
        className="form-control"
      />
    </div>
  );
}

export default DateRange;
