import React from 'react'

import TableIndex,{handleSubmit} from '../../../components/common/table/TableIndex'

import {DateRange,BooleanCheckbox,MultipleValue} from '../../../components/common/table/Rules/component'


const columns = [
  {
    name: '追蹤編號',
    sortable: false,
    selector: ({ tracking_number }) => tracking_number,
  },
  {
    name: '上傳批次',
    sortable: false,
    cell: ({ shipping_batch }) => shipping_batch.id && <a href={`/operator/shipping_batches/${shipping_batch.id}`}>{shipping_batch.name}</a>,
  },
  {
    name: '物流',
    sortable: false,
    selector: ({shipping_type}) => shipping_type,
  },
  {
    name: '單據編號',
    sortable: false,
    cell: ({ order, return_order }) => {
      if (order) {
        return <a href={`/operator/orders/${order.id}`} target="_blank">{order.name}</a>
      } else if (return_order) {
        return <a href={`/operator/return_orders/${return_order.id}`} target="_blank">{return_order.token}</a>
      }
    }
  },
  {
    name: '狀態',
    sortable: false,
    selector: ({ status }) => status,
  },
  {
    name: '客戶',
    sortable: false,
    selector: ({ shop_name }) => shop_name,
  },
  {
    name: '建立時間',
    sortable: true,
    sortFieldName: 'shippings.created_at',
    selector: ({ created_at }) => created_at,
  },
]

export default function ShippingTableIndex({
    shippingTypes,
    shops
  }) {

  const shippingTypeOptions = shippingTypes.map(shippingType => ({
    label: shippingType.name,
    value: shippingType.code,
  }))
  const shopOptions = shops.map(shop => ({
    label: shop.name,
    value: shop.id,
  }))

  return (
    <TableIndex columns={columns}
      selectCheckboxEnabled
      getRecordsUrl='shippings/get_records.json'
      selectAllUrl='shippings/get_all.json'
      searchable={false}
      tableId={'OperatorShippingTable'}
      defaultQuery={{
        sorting_field: 'id',
        sorting_direction: 'desc',
      }}
      supportRules={[
        {
          label: '客戶',
          component: MultipleValue,
          inputName: 'shippings.shop_ids',
          defaultOptions: shopOptions,
          defaultPinned: true,
          createable: false,
        },
        {
          label: '托運單建立日',
          component: DateRange,
          inputName: 'shippings.created_at',
        },
        {
          label: '訂單建立日',
          component: DateRange,
          inputName: 'orders.created_at',
        },
        {
          label: '物流類型',
          component: MultipleValue,
          defaultOptions: shippingTypeOptions,
          defaultPinned: true,
          createable: false,
          inputName: 'shippings.shipping_types',
        },
        {
          label: '托運單號',
          component: MultipleValue,
          createable: true,
          inputName: 'shippings.tracking_numbers',
        },
        {
          label: '訂單編號',
          component: MultipleValue,
          createable: true,
          inputName: 'shippings.order_names',
        },
        {
          label: '包含已刪除',
          component: BooleanCheckbox,
          inputName: 'include_deleted',
        }
      ]}
      actions={[
        {
          name: '合併下載PDF',
          className: 'btn btn-primary btn-outline mb-0',
          do: (selected) => {
            handleSubmit('/operator/shippings/combine_pdf',selected,{})
          }
        },
        {
          name: '匯出',
          className: 'btn btn-info btn-outline mb-0',
          do: (selected) => {
            handleSubmit('/operator/shippings/export',selected,{})
          }
        }
      ]}
    />
  );
}