import React, { useState ,useEffect} from 'react';

import { MAX_SELECTED_RECORDS } from './Context'

export default function SubHeader({
  selected,
  recordsFiltered,
  onSelectAllRecords,
  columnsEditable,
  tableId,
  headerColumns,
  onColumnsChange,
  actions //custom actions
}) {
  const buttonStyle = {
    bgColor: selected.size > 0 ? 'btn-warning' : 'btn-success',
  };

  const [toggleColumns, setToggleColumns] = useState(false);
  const [columns, setColumns] = useState(headerColumns);
  const [ selectingAll, setSelectingAll] = useState(false)

  const tableColumnsKey = `tableColumns-${tableId}`


  const handleSaveColumns = () => {
    let visibleColumns = columns.filter(column => column.visible).map(column => column.name);
    localStorage.setItem(tableColumnsKey, JSON.stringify(visibleColumns));
    notyMessage('儲存成功');
    setToggleColumns(false);
  }

  const handleLoadColumns = () => {
    let visibleColumns = JSON.parse(localStorage.getItem(tableColumnsKey)) || columns.map(column => column.name);
    setColumns(headerColumns.map(column => {
      column.visible = visibleColumns.includes(column.name);
      return column;
    }));
  }

  useEffect(() => {
    handleLoadColumns();
  }, []);

  return (
    recordsFiltered > 0 && <div> <div className="d-flex align-items-center justify-content-between row">
      <div className="action-btn-group batch-control-area col-md-2">
        <span className="text-success m-r-sm">(已選取{selected.size}筆資料)</span>
      </div>
      <div className="action-btn-group batch-control-area col-md-4">
        <button
          disabled={selectingAll}
          className={`btn btn-outline-success btn-outline btn-sm mb-0 ${buttonStyle.bgColor}`}
          onClick={() => {
            setSelectingAll(true)
            onSelectAllRecords(selected.size > 0).then(()=>{
              setSelectingAll(false)
            }).catch(()=>{
              setSelectingAll(false)
            })
          }}
        >
          {selectingAll ? '讀取資料中....' : (selected.size > 0 ? `清除選取` : `選取條件範圍內所有 ${recordsFiltered > MAX_SELECTED_RECORDS ? `${MAX_SELECTED_RECORDS}` : recordsFiltered} 筆資料`)}
          {recordsFiltered > MAX_SELECTED_RECORDS && <span > (最多一次可選取{MAX_SELECTED_RECORDS}筆)</span>}
        </button>
      </div>
      <div className="col-md-5">
        {
          <div className="btn-group pull-right">
            {actions.filter(action => action.persist || selected.size > 0).map((action, index) => {
              return (
                <button
                  key={index}
                  className={action.className || `btn btn-outline-successmb-0`}
                  onClick={() => {
                    action.do(selected)
                  }}
                >
                  {action.name}
                </button>
              )
            })
            }
          </div>
        }
      </div>
      {columnsEditable && <div className=" col-md-1 ">
        <a className="btn btn-primary btn-outline pull-right" onClick={() => setToggleColumns(!toggleColumns)}>
          <i className="fa fa-ellipsis-h" />
        </a>
      </div>}
    </div>
      {
        toggleColumns && <div className="pull-right" style={{paddingRight: '20px'}}>
          {
            columns.filter(column => !column.persist).map((column, index) => {
              return (
                <div key={index}>
                  <label className='custom-control custom-checkbox'>
                    <input type="checkbox" checked={column.visible}
                      className='custom-control-input'
                        onChange={() => onColumnsChange(
                        columns.map(c => {
                          if (c.name === column.name) {
                            c.visible = !column.visible
                          }
                          return c
                        })
                      )}
                    />
                    <span className='custom-control-indicator'></span>
                    {column.name}
                  </label>
                </div>
              )
            })
          }
          <button className='btn btn-primary'
            onClick={handleSaveColumns}
          >儲存偏好</button>
        </div>
      }

    </div>
  );
}
