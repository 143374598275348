import React ,{useState} from 'react'

import TableIndex,{handleSubmit} from '../../../components/common/table/TableIndex'

import {DateRange,MultipleValue} from '../../../components/common/table/Rules/component'


export default function ReceiptTableIndex({
    statusOptions,
    receiptTypes,
    shops
  }) {

  const shopOptions = shops.map(shop => ({
    label: shop.name,
    value: shop.id,
  }))
  const [lastModified,setLastModified] = useState(0)

  const columns = [
    {
      name: '入倉單號',
      sortable: false,
      persist: true,
      cell: ({id,title}) => <a href={`/operator/receipts/${id}`} target="_blank" >{title}</a>
    },
    {
      name: '額外單號',
      sortable: false,
      cell: ({id,extra_name}) => <a href={`/operator/receipts/${id}`} target="_blank" >{extra_name}</a>
    },
    {
      name: '客戶',
      sortable: false,
      selector: ({ shop_name }) => shop_name,
    },
    {
      name: '類別',
      sortable: false,
      selector: ({ receipt_type }) => receipt_type,
    },
    {
      name: '狀態',
      sortable: false,
      cell: ({status})=>{
        let className = ''
        switch(status){
          case '待入庫':
            className = 'label label-success label-outline'
            break;
          case '已驗收':
            className = 'label label-info label-outline'
            break;
          case '處理中':
            className = 'label label-warning label-outline'
            break;
          case '已入庫':
            className = 'label label-success'
            break;
          case '已取消':
            className = 'text-muted'
            break;
        }
        return <span className={className}>{status}</span>
      },
    },

    {
      name: '預計入倉日',
      sortable: true,
      sortFieldName: 'receipts.est_date',
      selector: ({ est_date }) => est_date,
    },
    {
      name: '最後上架日',
      sortable: true,
      sortFieldName: 'receipts.max_item_received_at',
      selector: ({ max_item_received_at }) => max_item_received_at,
    },
    {
      name: '到倉日',
      sortable: true,
      sortFieldName: 'receipts.received_at',
      selector: ({ received_at }) => received_at,
    },
    {
      name: '完成上架日',
      sortable: true,
      sortFieldName: 'receipts.done_at',
      selector: ({ done_at }) => done_at,
    },
    {
      name: '建立時間',
      sortable: true,
      sortFieldName: 'receipts.created_at',
      selector: ({ created_at }) => created_at,
    },
    {
      name: '備註',
      sortable: false,
      sortFieldName: 'receipts.note',
      selector: ({ note }) => note,
    }
  ]


  const supportRules = [
    {
      label: '客戶',
      component: MultipleValue,
      inputName: 'receipts.shop_id',
      defaultOptions: shopOptions,
      defaultPinned: true,
      createable: false,
    },
    {
      label: '入倉單號',
      component: MultipleValue,
      inputName: 'receipts.title',
      defaultPinned: true,
      createable: true,
    },
    {
      label: '額外單號',
      component: MultipleValue,
      inputName: 'receipts.extra_name',
      createable: true,
    },
    {
      label: '入倉單建立日',
      component: DateRange,
      defaultPinned: true,
      inputName: 'receipts.created_at',
    },
    {
      label: '預計入倉日',
      component: DateRange,
      inputName: 'receipts.est_date',
    },
    {
      label: '到倉日',
      component: DateRange,
      inputName: 'receipts.received_at',
    },
    {
      label: '驗收日',
      component: DateRange,
      inputName: 'receipts.verified_at',
    },
    {
      label: '完成上架日',
      component: DateRange,
      inputName: 'receipts.done_at',
    },
    {
      label: '狀態',
      component: MultipleValue,
      defaultOptions: statusOptions,
      createable: false,
      inputName: 'receipts.status',
    },
    {
      label: '入倉類別',
      component: MultipleValue,
      defaultOptions: receiptTypes,
      createable: false,
      inputName: 'receipts.receipt_type',
    },
    {
      label: '退貨單號',
      component: MultipleValue,
      inputName: 'return_orders.token',
      createable: true,
    }
  ]


  return (
    <TableIndex columns={columns}
      selectCheckboxEnabled
      getRecordsUrl='receipts/get_records.json'
      selectAllUrl='receipts/get_all.json'
      searchable={true}
      columnsEditable={true}
      tableId='OperatorReceiptsTable' //for columnsEditable
      defaultQuery={{
        sorting_field: 'id',
        sorting_direction: 'desc',
      }}
      supportRules={supportRules}
      lastModified={lastModified}
      actions={[
        {
          name: '批次復原',
          className: 'btn btn-primary btn-outline mb-0',
          do: (selected) => {
            if(confirm(`確定要復原 ${selected.size} 筆訂單嗎?`)){
              handleSubmit('/operator/receipts/batch_resume.json',selected,{},()=>{
                setLastModified(Date.now())
              })
            }
          }
        },
        {
          name: '批次取消',
          className: 'btn btn-danger btn-outline mb-0',
          do: (selected) => {
            if(confirm(`確定要取消 ${selected.size} 筆入倉單嗎?`)){
              handleSubmit('/operator/receipts/batch_cancel.json',selected,{},()=>{
                setLastModified(Date.now())
              })
            }
          }
        },
        {
          name: '匯出',
          className: 'btn btn-primary btn-outline mb-0',
          do: (selected) => {
            handleSubmit('/operator/receipts/export.json',selected,{})
          }
        },
        {
          name: '下載儲位明細',
          className: 'btn btn-info btn-outline mb-0',
          do: (selected) => {
            handleSubmit('/operator/receipts/export_shelves.json',selected,{})
          }
        }
      ]}
    />
  );
}