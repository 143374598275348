import React, { Component } from 'react'

const Version = {
  LEGACY: "legacy",
  API: "api"
}

export default class Tcat extends Component {
  constructor(props) {
    super(props)
    this.state = {
      version: Version["API"]
    }
    this.updateVersion = this.updateVersion.bind(this)
  }
  componentDidMount() {
    if (this.props.shipping_account.ftp_username && !this.props.shipping_account.cust_id && !this.props.shipping_account.check_word)
      this.updateVersion(Version["LEGACY"])
  }
  updateVersion(version) {
    this.setState({version: version})
  }
  render() {
    let { shipping_account_type, shipping_account } = this.props
    return (
      <>
        <div className="form-group">
          <div className="col-md-offset-3 col-md-6">
            <label htmlFor={`${shipping_account_type}-${shipping_account.id}-version-switcher`} className="switcher switcher-success">
              <input type="checkbox" id={`${shipping_account_type}-${shipping_account.id}-version-switcher`} checked={this.state.version === Version["API"]} onChange={(e) => this.updateVersion(e.target.checked ? Version["API"] : Version["LEGACY"])}/>
              <div className="switcher-indicator">
                <div className="switcher-yes">API版</div>
                <div className="switcher-no">舊版</div>
              </div>
            </label>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>契客代號</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][customer_code]`} defaultValue={shipping_account.customer_code}/>
          </div>
        </div>
        {
          this.state.version == Version["API"] &&
            (<>
              <div className="form-group">
                <label className="col-md-3 control-label">
                  <label>契客授權碼</label>
                </label>
                <div className="col-md-6">
                  <input className="form-control" name={`shipping_accounts[${shipping_account.id}][check_word]`} defaultValue={shipping_account.check_word}/>
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-3 control-label">
                  <label>顯示固定品名</label>
                </label>
                <div className="col-md-6">
                  <input className="form-control" name={`shipping_accounts[${shipping_account.id}][default_product_name]`} defaultValue={shipping_account.default_product_name}/>
                </div>
              </div>
            </>)
        }
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>FTP 帳號</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][ftp_username]`} defaultValue={shipping_account.ftp_username}/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>FTP 密碼</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][ftp_password]`} defaultValue={shipping_account.ftp_password}/>
          </div>
        </div>
        <input type='hidden' name={`shipping_accounts[${shipping_account.id}][ftp_address]`} defaultValue="103.234.81.11"/>
      </>
    )
  }
}