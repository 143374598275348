import React, { useState } from 'react';



const ShippingsIndex = (props) => {

  const [shippingRules, setShippingRules] = useState(props.shop_channel_shipping_rule.map((shippingRule) => {
    shippingRule.key = shippingRule.id
    return shippingRule
  }));
  const { shippings } = props;

  const AddRuleBtn = () => {
    return <a href="#!" className='btn btn-primary btn-outline btn-block' onClick={() => {
      setShippingRules([...shippingRules, { cuft_threshold: '', direction: 'smaller_than', shipping_type_id: '', original_shipping_type_id: '', key: Math.random() }])
    }
    }>
      + 新增轉換規則
    </a>
  }

  const onRemoveShippingRule = (key) => {
    setShippingRules(shippingRules.filter((shippingRule) => {
      return shippingRule.key != key
    }))
  }
  console.log(shippingRules)
  return (
    <>
      <div className='form-group'>
        <label className='col-md-2 control-label'>
          依照材積自動變換物流方式
        </label>
          { shippingRules.length > 0 ?
            <div className='col-md-10'>
              {shippingRules.map((shippingRule) => {
                return (
                  <ShippingRule key={shippingRule.key} shippingRule={shippingRule} shippings={shippings} onRemoveShippingRule={onRemoveShippingRule} />
                )
              })}
            </div>
              : <div className='col-md-3'><AddRuleBtn/></div>
          }
      </div>
      { shippingRules.length > 0 &&
        <div className='form-group'>
        <div className='col-md-2'>
        </div>
        <div className='col-md-3'>
          <AddRuleBtn/>
        </div>
      </div>}
    </>
  )
}


const ShippingRule = (props) => {
  const { shippingRule, shippings } = props
  return <div className='form-group row'>
    <div className='col-md-1'>
      <a className='btn btn-link text-danger' href="#!"  onClick={() => {
        props.onRemoveShippingRule(shippingRule.key)
      }}>
        <i className='fa fa-trash'></i>
      </a>
    </div>

    <div className='col-md-3'>
      <select className='form-control' name='shop_channel_shipping_rules[][original_shipping_type_id]' defaultValue={shippingRule.original_shipping_type_id}>
        {
          shippings.map((shipping, index) => {
            return (
              <option key={index} value={shipping.id}>{shipping.name}</option>
            )
          })
        }
      </select>
    </div>
    <div className='input-group col-md-7'>
      <input type='number' name='shop_channel_shipping_rules[][cuft_threshold]' className='form-control' step='0.001' defaultValue={shippingRule.cuft_threshold} placeholder='若留白則不使用' />
      <div className='input-group-addon'>
        材(積)
      </div>
      <select className='form-control' name='shop_channel_shipping_rules[][direction]' defaultValue={shippingRule.direction}>
        <option value='smaller_than'>以下</option>
        <option value='bigger_than'>以上</option>
      </select>
      <div className='input-group-addon'>
        轉用
      </div>
      <select className='form-control' name='shop_channel_shipping_rules[][shipping_type_id]' defaultValue={shippingRule.shipping_type_id}>
        {
          shippings.map((shipping, index) => {
            return (
              <option key={index} value={shipping.id}>{shipping.name}</option>
            )
          })
        }
      </select>
    </div>
  </div>
}


export default ShippingsIndex;