import {
  RECEIPT_SET_PRODUCTS, RECEIPT_SET_STORAGE_TYPES, RECEIPT_SET_PRODUCT_ITEMS,
  RECEIPT_ADD_PRODUCT_ITEM, RECEIPT_UPDATE_PRODUCT_ITEM, RECEIPT_DELETE_PRODUCT_ITEM,
  RECEIPT_PRODUCT_ITEM_CHANGE,RECEIPT_PRODUCT_EXPIRATION_DATE_CHANGE,
} from '../../../actions/action_types'

// product
export const setProducts = (products) => {
  return {
    type: RECEIPT_SET_PRODUCTS,
    products: products
  }
}

export const setStorageTypes = (storage_types) => {
  return {
    type: RECEIPT_SET_STORAGE_TYPES,
    storage_types: storage_types
  }
}

export const setProductItems = (product_items) => {
  return {
    type: RECEIPT_SET_PRODUCT_ITEMS,
    product_items: product_items
  }
}

export const onProductItemAdd = (item) => {
  return {
    type: RECEIPT_ADD_PRODUCT_ITEM,
    item: item
  }
}

export const onProductItemUpdate = (item) => {
  return {
    type: RECEIPT_UPDATE_PRODUCT_ITEM,
    item: item
  }
}

export const onProductItemDelete = (item) => {
  return {
    type: RECEIPT_DELETE_PRODUCT_ITEM,
    item: item
  }
}

export const onProductItemChange = (item) => {
  return {
    type: RECEIPT_PRODUCT_ITEM_CHANGE,
    item: item
  }
}

export const onProductExpirationDateChange = (item) => {
  return {
    type: RECEIPT_PRODUCT_EXPIRATION_DATE_CHANGE,
    item: item
  }
}
