import React ,{useState,useEffect} from 'react'

import TableIndex, {handleSubmit} from '../../../components/common/table/TableIndex'


const columns = [
  {
    name: '健康度',
    sortable: false,
    persist: true,
    sortable: false,
    width: '100px',

    cell: ({stock_health_ratio}) => {

      let healthRatioLightColor = ''
      if(stock_health_ratio > 1){
        healthRatioLightColor = 'yellowgreen'
      }else if(stock_health_ratio > 0.7){
        healthRatioLightColor = 'orange'
      }else{
        healthRatioLightColor = 'red'
      }
      return <div style={{color: healthRatioLightColor}}
      >

        <span style={{fontWeight: 'bold',fontSize: stock_health_ratio >= 10 ? '0.9em' : '1.1em',backgroundColor: healthRatioLightColor,padding: '0',borderRadius: '50%',width: '40px',height: '40px',display: 'flex',alignItems: 'center',justifyContent: 'center',color: '#fff'}}>
          {
            (stock_health_ratio * 100).toFixed(0)
          }
          %
        </span>

      </div>
    },
  },
  {
    name: '追蹤商品',
    persist: true,
    sortable: false,
    cell: ({product}) => {
      return <a href={`/admin/products/${product.id}`} target="_blank" >{product.uid} {product.name}</a>
    },
  },
  {
    name: '倉別',
    sortable: false,
    selector: ({ product_storage_type }) => product_storage_type,
  },
  {
    name: '效期天數(以上)',
    sortable: false,
    selector: ({ expiry_days }) => expiry_days,
  },
  {
    name: '機制',
    sortable: false,
    cell: ({ mechanism }) => {
      let mechanismText = ''
      switch(mechanism){
        case 'turnover_days':
          mechanismText = '週轉天數'
          break;
        case 'stalled_days':
          mechanismText = '滯銷天數'
          break;
        case 'quantity':
          mechanismText = '庫存量'
          break;
      }
      return mechanismText
    },
  },
  {
    name: '天數',
    sortable: false,
    selector: ({days_interval}) => days_interval,
  },

  {
    name: '即時庫存/出貨量',
    sortable: false,
    cell: ({tracking_stocks}) => {
      return tracking_stocks
    },
  },
  {
    name: '警示量(小於)',
    sortable: false,
    cell: ({threshold_stocks})=>{
      return `${threshold_stocks} PCS`
    },
  },
  {
    name: '動作',
    sortable: false,
    cell: ({id})=>{
      return <div className='btn-group'>
        <a href={`/admin/stock_trackers/${id}/edit`} className='btn btn-warning btn-outline'>
          <i className='fa fa-pencil'></i>
        </a>
        <a href={`/admin/stock_trackers/${id}`} data-method="delete" data-confirm="確認要刪除?" className='btn btn-danger btn-outline'>
          <i className='fa fa-trash'></i>
        </a>
        </div>
    },
  },
]


export default function StockTrackerTableIndex({
    }) {

  const [lastModified,setLastModified] = useState(0)

  return (
    <TableIndex columns={columns}
      selectCheckboxEnabled
      getRecordsUrl='stock_trackers/get_records.json'
      selectAllUrl='stock_trackers/get_all.json'
      searchable={true}
      columnsEditable={true}
      tableId='AdminStockTrackersTable' //for columnsEditable
      defaultQuery={{
        sorting_field: 'id',
        sorting_direction: 'desc',
      }}

      lastModified={lastModified}
      actions={[
        {
          name: '批次刪除',
          className: 'btn btn-danger btn-outline mb-0',
          do: (selected) => {
            if(confirm(`確定要刪除 ${selected.size} 筆追蹤嗎?`)){
              handleSubmit('/admin/stock_trackers/batch_delete.json',selected,{},()=>{
                notyMessagePersist('已刪除')
                setLastModified(Date.now())
              })
            }
          }
        },
        {
          name: '匯出庫存不足商品',
          className: 'btn btn-primary btn-outline mb-0',
          do: (selected) => {
            fetch('/admin/stock_trackers/export_insufficient_stocks.json',{
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
              },
            })
            .then(res=>res.json())
            .then(data=>{
              if(data.status == 'success' && data.job_id){
                pushTaskItem(data.job_id,'export')
                notyMessagePersist('匯出中，請稍後至匯出紀錄查看')
              }else{
                notyMessagePersist('匯出失敗')
              }
            })
          },
          persist: true,
        }
      ]}
    />
  );
}