import React, { Component } from 'react'
import Video from '../../../components/common/video'

const WAITING = 'waiting'
const RECORDING = 'recording'

const DEFAULT_STATE = {
  video_recorder_title: null,
  status: WAITING
}

const MAX_VIDEO_LENGTH = 900000 // 30 minutes

export default class VideoRecording extends Component {
  constructor(props) {
    super(props)

    this.state = {
      video_recorder_title: null,
      status: WAITING,
      uploading_videos: 0
    }
    this.onBarcodeInput = this.onBarcodeInput.bind(this)
    this.onCancelClick = this.onCancelClick.bind(this)
    this.onRecordEnd = this.onRecordEnd.bind(this)
    this.videoRecordingClipTimer = null

  }
  componentDidMount() {
    document.body.onkeydown = (e) => {
      this.refs.title_input.focus()
    }
  }
  componentDidUpdate(){
    if(this.state.uploading_videos > 0){
      console.log('! > 0')
      window.onbeforeunload = ()=>{
        return `目前還有${this.state.uploading_videos}個錄影正在上傳中，確認要關閉視窗嗎?`;
       };
    }else{
      window.onbeforeunload = null
    }
  }

  async onRecordEnd(stream,title) {
    if(this.videoRecordingClipTimer){
      clearTimeout(this.videoRecordingClipTimer)
      this.videoRecordingClipTimer = null
    }

    return new Promise((resolve, reject) => {
      require('es6-promise').polyfill();
      let originalFetch = require('isomorphic-fetch');
      let fetch = require('fetch-retry')(originalFetch);
      if (stream) {
        let date = new Date()
        let fileName = `${title}-${date.getHours()}${date.getMinutes()}${date.getSeconds()}.webm`;
        let file = new File([stream], fileName, {
          type: 'video/webm'
        });
        let data = new FormData();
        data.append('title',title)
        data.append('file', file, fileName)
        // let current_status = this.state.status
        // this.setState({ status: UPLOADING })
        this.setState({ uploading_videos: this.state.uploading_videos + 1 })
        resolve()
        fetch(`/operator/video_records`,
          {
            retryDelay: 3000,
            retryOn: function (attempt, error, response) {
              if (error !== null || response.status >= 400) {
                if (attempt < 3) {
                  notyError(`上傳失敗，重新嘗試上傳中`)
                  return true
                } else {
                  notyError(`上傳錄影檔失敗`)
                  return false
                }
              }
            },
            method: 'POST',
            headers: {
              'X-CSRF-Token': this.props.authenticity_token,
            },
            body: data,
            credentials: 'same-origin'
          }
        ).then(res => {
          // this.setState({status:current_status})
          return res.json()
        }).then(data=>{
          this.setState({ uploading_videos: this.state.uploading_videos - 1 })
          notyMessage(`${data.title} 成功上傳`)
        }).catch(error => {
          notyError(`上傳失敗`)
          this.setState({ uploading_videos: this.state.uploading_videos - 1 })
          resolve()
        })
      } else {
        this.setState({ uploading_videos: this.state.uploading_videos - 1 })
        reject()
      }
    })
  }

  onRecordStart(barcode) {
    this.refs.video_recorder.start_recording()
    if(this.videoRecordingClipTimer){
      clearTimeout(this.videoRecordingClipTimer)
      this.videoRecordingClipTimer = null
    }
    this.videoRecordingClipTimer = setTimeout(()=>{
      this.refs.video_recorder.stop_recording().then((streaming) => {
        this.onRecordEnd(streaming, barcode).then(() => {
          this.onRecordStart(barcode)
        })
      })
    },MAX_VIDEO_LENGTH)
  }

  onRecordingResume(){
    if(this.videoRecordingClipTimer){
      clearTimeout(this.videoRecordingClipTimer)
      this.videoRecordingClipTimer = null
    }
    this.videoRecordingClipTimer = setTimeout(()=>{
      this.refs.video_recorder.stop_recording().then((streaming) => {
        this.onRecordEnd(streaming, this.state.video_recorder_title).then(() => {
          this.onRecordStart(this.state.video_recorder_title)
        })
      })
    },MAX_VIDEO_LENGTH)
  }

  onRecordingPause(){
    if(this.videoRecordingClipTimer){
      clearTimeout(this.videoRecordingClipTimer)
      this.videoRecordingClipTimer = null
    }
  }


  onBarcodeInput(e) {
    if (e.keyCode == 13 && this.refs.title_input.value) {
      let barcode_value = this.refs.title_input.value
      this.refs.title_input.value = ""
      switch (this.state.status) {
        case WAITING:
          this.setState({
            status: RECORDING,
            video_recorder_title: barcode_value
          })
          this.onRecordStart(barcode_value)
          break
        case RECORDING:
          if (barcode_value == this.state.video_recorder_title) {
            this.refs.video_recorder.stop_recording().then((streaming) => {
              this.onRecordEnd(streaming,barcode_value)
              this.setState({
                status: WAITING,
                video_recorder_title: null
              })
            })
          }else{
            this.refs.video_recorder.setCaption(barcode_value)
          }
          break
      }
    }
  }

  onCancelClick() {
    if (confirm('確認要取消此錄影?')) {
      this.refs.video_recorder.stop_recording().then(() => {
        this.setState(DEFAULT_STATE)
        if(this.videoRecordingClipTimer){
          clearTimeout(this.videoRecordingClipTimer)
          this.videoRecordingClipTimer = null
        }
      })
    }
  }

  render() {
    return <div>
      <div className="page-header">
        <div className="pull-left"><h1>錄影</h1>
        </div>
        <div className="pull-right col-md-3">

          <input type="text" ref="title_input"
            placeholder="請輸入錄影單號或者掃描條碼"
            autoFocus={true}
            onKeyDown={this.onBarcodeInput}
            className="form-control input-lg" />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-9'>
          <Video ref="video_recorder" onPause={this.onRecordingPause} onResume={this.onRecordingResume} />
        </div>
        <div className='col-md-3'>
          {
            this.state.video_recorder_title ? <span>
              {`錄影單號：${this.state.video_recorder_title}`}
              <a className='btn btn-outline btn-danger pull-right' onClick={this.onCancelClick}>
                取消
            </a>
            </span> : ''
          }
          {
            this.state.uploading_videos > 0 ?
              <div>
                <span className="lds-ellipsis"><div></div><div></div><div></div><div></div></span>
                {`${this.state.uploading_videos} 個錄影檔上傳中`}
              </div>
              : ''

          }
        </div>
      </div>
    </div>
  }
}