import React, { Component } from 'react'
import Select from 'react-select'

export default class SfCvs extends Component {
  render() {
    let { shipping_account_title, shipping_account_type, shipping_account } = this.props

    return (
      <table className="table table-striped">
        <tbody>
          <tr>
            <td>{shipping_account_title}</td>
            <td></td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">啟用</td>
            <td>
              <div className="form-group">
                <div className="col-md-3">
                  <label htmlFor={`${shipping_account_type}-${shipping_account.id}-enable-switcher`} className="switcher switcher-success">
                    <input type="checkbox" id={`${shipping_account_type}-${shipping_account.id}-enable-switcher`} name={`shipping_accounts[${shipping_account.id}][enabled]`} defaultChecked={shipping_account.enabled}/>
                    <div className="switcher-indicator">
                      <div className="switcher-yes">啟用</div>
                      <div className="switcher-no">停用</div>
                    </div>
                  </label>
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">公司名稱(順豐識別用)</td>
            <td>
              <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][customer_code]`} defaultValue={shipping_account.customer_code}/>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">廠商名稱</td>
            <td>
              <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][customer_name]`} defaultValue={shipping_account.customer_name}/>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">轉貼單打印帳號</td>
            <td>
              <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][client_code]`} defaultValue={shipping_account.client_code}/>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">API帳號</td>
            <td>
              <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][cust_id]`} defaultValue={shipping_account.cust_id}/>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center">API密碼</td>
            <td>
              <input className="form-control text-center" name={`shipping_accounts[${shipping_account.id}][check_word]`} defaultValue={shipping_account.check_word}/>
            </td>
          </tr>
          <tr>
            <td className="col-md-4 text-center"></td>
            <td className="col-md-6 text-center">
              <button className="btn btn-primary">更新</button>
            </td>
          </tr>
        </tbody>
      </table>
    )
  }
}