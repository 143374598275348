import React from 'react'
import {useDropzone} from 'react-dropzone';

export function Dropzone(props) {
  let { accept, fileMaxNum, fileMaxSizeMB, fileType ,onFileDrop} = props;
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: accept,
    maxFiles: fileMaxNum,
    maxSize: fileMaxSizeMB*1024*1024,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        console.log(fileRejections)
        switch (fileRejections[0].errors[0].code) {
          case "file-invalid-type":
            notyError(`${fileType}格式錯誤，請上傳指定格式～`)
            return
          case "too-many-files":
            notyError(`最多一次可上傳${fileMaxNum}張${fileType}～`)
            return
          case "file-too-large":
            notyError(`喔不～檔案太大了，請上傳小於${fileMaxSizeMB}MB的${fileType}`)
            return
          default:
            notyError(`${fileType}格式不符`)
            return
        }
      }
      onFileDrop(acceptedFiles)
    }
  });
  return (
    <div {...getRootProps({ className: `dropzone ${props.className ?? ""}` })}>
      <input {...getInputProps()} />
      <div className='placeholder'>
        {props.placeholder ?? '+'}
      </div>
    </div>
  );
}
