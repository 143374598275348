import React from 'react';
import { useRecordContext } from './Context';

export default function FilterComponent() {
  const { search, setSearch } = useRecordContext();

  return (
    <div className="form-group">
      <label className="control-label col-md-2" htmlFor="searchKeywords">
        搜尋
      </label>
      <div className="col-md-10">
        <input
          id='searchKeywords'
          type="text"
          placeholder="搜尋關鍵字..."
          className="form-control"
          value={search}
          onChange={({ target }) => setSearch(target.value)}
        />
      </div>
    </div>
  );
}
