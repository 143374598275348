import React,{useState} from 'react'

export default function (props) {
  const [shippings, setShippings] = useState(props.shippings)
  const { google_map_api_key } = props

  function onChangeCountry(value, index) {
    shippings[index].order.country= value;
    setShippings([...shippings]);
  }

  function onChangeZip(value, index) {
      shippings[index].order.zip_code= value;
      setShippings([...shippings]);
  }

  function onChangeCity(value, index) {
      shippings[index].order.city= value;
      setShippings([...shippings]);
  }

  function onChangeDistrict(value, index) {
      shippings[index].order.district= value;
      setShippings([...shippings]);
  }
  
  function onChangeAddress(value, index) {
      shippings[index].order.address= value;
      setShippings([...shippings]);
  }

  function queryGoogleMapApi(index) {
    let shipping = shippings[index];
    let address = [shipping.order.zip_code, shipping.order.city, shipping.order.district, shipping.order.address].join('')
    fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${address}&key=${google_map_api_key}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data)
        if(data.results[0]){
          let city, district, zip_code,country
          data.results[0].address_components.forEach((component) => {
            if(component.types.includes('country')){
              country = component.long_name
            }

            if(component.types.includes('administrative_area_level_1')){
              city = component.long_name
            }

            if(component.types.includes('administrative_area_level_2') || component.types.includes('administrative_area_level_1')){
              // level1直轄市, level2省轄市
              city = component.long_name
            }
            if(component.types.includes('administrative_area_level_3')){
              district = component.long_name
            
            }
            if(component.types.includes('postal_code')){
              zip_code = component.long_name
            }
          })
          // remove first zipcode, city and district string
          if(country == '台灣' || country == 'Taiwan'){
            country = ''
          }
          shipping.order.city = city
          shipping.order.district = district
          shipping.order.zip_code = zip_code
          shipping.order.address = shipping.order.address.replace(zip_code, '').replace(city, '').replace(district, '').replace(country, '')
          shipping.colibrated = true
        }
        setShippings([...shippings]);
      })
  }

  function CalibrateAll(){
    shippings.forEach((shipping, index) => {
      queryGoogleMapApi(index)
    })
  }
  
  
  return <table className="table">
    <thead>
      <tr>
        <th className="col-md-1">託運單號</th>
        <th className="col-md-3">地址</th>
        <th className="col-md-8">修正後地址 (郵遞區號/國家/縣市/行政區/地址)</th>
        <th className="col-md-1">
          <button className='btn btn-warning btn-outline btn-block' onClick={CalibrateAll} type='button'>校正全部</button>
        </th>
      </tr>
    </thead>
    <tbody>
      {
        shippings.map((shipping, index) => {
          return <tr key={index}>
            <td className='shipping-tracking-number'>
              <span className='tracking-number-text'>{shipping.tracking_number}</span>
            </td>
            <td>{shipping.order.full_address}</td>
            <td className='form-group row'>
              <input type='hidden' name='shippings[]order_id' value={shipping.order.id} />
              <div className='col-md-1'>
                <input className='form-control' name="shippings[]country" type='text' style={shipping.colibrated ? {backgroundColor: 'lightyellow'} : {}} onChange={(e)=>onChangeCountry(e.currentTarget.value,index)} value={shipping.order.country || ""}/>
              </div>
              <div className='col-md-1'>
                <input className='form-control' name="shippings[]zip_code" type='text' style={shipping.colibrated ? {backgroundColor: 'lightyellow'} : {}} onChange={(e)=>onChangeZip(e.currentTarget.value,index)} value={shipping.order.zip_code || ""}/>
              </div>
              <div className='col-md-2'>
                <input className='form-control' name="shippings[]city" type='text' style={shipping.colibrated ? {backgroundColor: 'lightyellow'} : {}} onChange={(e)=>onChangeCity(e.currentTarget.value,index)} value={shipping.order.city || ""} />
              </div>
              <div className='col-md-2'>
                <input className='form-control' name="shippings[]district" type='text' style={shipping.colibrated ? {backgroundColor: 'lightyellow'} : {}} onChange={(e)=>onChangeDistrict(e.currentTarget.value,index)} value={shipping.order.district || ""} />
              </div>
              <div className='col-md-6'>
                <input className='form-control' name="shippings[]address" type='text' style={shipping.colibrated ? {backgroundColor: 'lightyellow'} : {}} onChange={(e)=>onChangeAddress(e.currentTarget.value,index)} value={shipping.order.address || ""}/>
              </div>
            </td>
            <td>
              <button className='btn btn-primary btn-outline btn-block' type='button' onClick={(e)=>{queryGoogleMapApi(index)}}>校正單筆</button>
            </td>
          </tr>
        })
      }
    </tbody>
  </table>
}