import React, { createContext, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { usePrevious } from './usePrevious';

const RecordContext = createContext(null);

export const MAX_SELECTED_RECORDS = 50000

const PERSISTENT_SETTINGS = ['page_size','sorting_field','sorting_direction']

export function RecordProvider({ tableId,children,getRecordsUrl, selectAllUrl,queries,defaultQuery={},lastModified }) {
  const timeout = useRef();
  const [search, setSearch] = useState('');


  const [query, setQuery] = useState(
    // merge queries with following default values
    {
      page: 1,
      page_size: 10,
      sorting_field: 'id',
      sorting_direction: 'desc',
      ...defaultQuery,
      ...queries,
      ...getSettings(),
    }
  );
  const [submitForm, setSubmitForm] = useState();

  const [records, setRecords] = useState([]);
  const [recordsFiltered, setRecordsFiltered] = useState(0);

  const [selected, setSelected] = useState(new Set());
  const [isAllRecords, setIsAllRecords] = useState(false);

  const previousSearch = usePrevious(search);
  const previousForm = usePrevious(submitForm);
  const [selectAllData, setSelectAllData] = useState(new Set());
  const [submitStatus, setSubmitStatus] = useState(false);

  const getQueryJson = () => {
    let queryJson = {
      ...query,
      ...submitForm,
      search,

    }
    return queryJson
  }


  function compareJson(json1,json2){
    if(!json1 || !json2){
      return false
    }
    if(Object.keys(json1).length != Object.keys(json2).length){
      return false
    }
    for(let key of Object.keys(json1)){
      if(json1[key] != json2[key]){
        return false
      }
    }
    return true
  }

  useEffect(() => {
    if(search != previousSearch || !compareJson(submitForm,previousForm)){
      query.page = 1
      for(let key of Object.keys(query)){
        if(!['page','page_size','sorting_field','sorting_direction'].includes(key)){
          delete query[key]
        }
      }

    }
    setQuery({
      ...query,
      ...submitForm,
      search,
    })
  }, [submitForm])

  useEffect(() => {
    const settings = getSettings();
    PERSISTENT_SETTINGS.forEach(key=>{
      settings[key] = query[key]
    })

    window.localStorage.setItem(`${tableId}-settings`, JSON.stringify(settings));

    getRecords()
  }, [query,lastModified]);

  function getSettings(){
    const settings = window.localStorage.getItem(`${tableId}-settings`) || '{}';
    const settingsObj = JSON.parse(settings)
    for(let key of Object.keys(settingsObj)){
      if(!PERSISTENT_SETTINGS.includes(key)){
        delete settingsObj[key]
      }
    }
    return settingsObj
  }


  const getRecords = () => {
    return new Promise((resolve, reject) => {
      setSubmitStatus(true)
      if(!getRecordsUrl){
        resolve([])
        setSubmitStatus(false)
        return
      }

      const queryJson = getQueryJson()
      fetch(getRecordsUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        },
        body: JSON.stringify(queryJson)
      }).then(res=>res.json()).then(data=>{
        setRecords(data.data);
        setRecordsFiltered(data.recordsFiltered);
        resolve(data.data)
      }).catch(err=>{
        reject(err)
      }).finally(()=>{
        setSubmitStatus(false)
      })
    });
  };

  const selectAllRecords = () => {
    return new Promise((resolve, reject) => {
      if(!selectAllUrl){
        resolve([])
        return
      }
      const queryJson = getQueryJson()
      queryJson.limit = MAX_SELECTED_RECORDS

      fetch(selectAllUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        },
        body: JSON.stringify(queryJson)
      }).then(res=>res.json()).then(data=>{
        setSelectAllData(new Set(data));
        setSelected(new Set(data));
        setSubmitStatus(false)
        resolve(data)
      }).catch(err=>{
        setSubmitStatus(false)
        reject(err)
      })
    });
  }

  const onSelectAllRecords = (checked) => {
    // change select all records
    return new Promise((resolve, reject) => {
      if (checked) {
        setSelected(new Set())
        resolve([])
      }else{
        selectAllRecords({ ...query, search }).then(data=>{
          resolve(data)
        }).catch(err=>{
          reject(err)
        })
      }
    });
  };

  const onSelectRecord = (id) => {
    // select or undo current page data
    selected.has(id) ? selected.delete(id) : selected.add(id);
    setSelected(new Set(selected));
  };

  const checkedAll = () => {
    const all = records.length > 0 && records.every(({ id }) => selected.has(id));
    setIsAllRecords(all);
  };

  const toggleCurrentAllRecords = () => {
    const iDs = isAllRecords ? [] : records.map(({ id }) => id);
    setSelected(new Set(iDs));
  };

  const clearSelected = () => setSelected(new Set());

  const dependencies = {
    selected,
    clearSelected,

    submitForm,
    setSubmitForm,
    search,
    setSearch,
    getRecords,

    records,
    onSelectRecord,
    onSelectAllRecords,
    toggleCurrentAllRecords,

    query,
    setQuery,
    submitStatus,
    setSubmitStatus,
    isAllRecords,
    recordsFiltered,
  };

  const value = useMemo(() => dependencies, Object.values(dependencies));


  useEffect(() => {
    checkedAll();
  }, [selected]);


  return <RecordContext.Provider value={value}>{children}</RecordContext.Provider>;
}

export const useRecordContext = () => {
  return useContext(RecordContext);
};
