import React, { Component ,useState,useEffect} from 'react'
import Modal from 'react-responsive-modal';
import Video from '../../../components/common/video'
import Select from 'react-select'

const WAITING = "waiting"
const CHECKING = "checking"
const DONE = "done"
const DEFAULT_STATE = {
  products: [],
  token: null,
  status: WAITING,
  id: null,
  order_id: null,
  modal_open: false,
  serial_modal_open: false,
  current_product_id: null,
  uploading_videos: 0,
  shipping_type: null,
  shop_channel_id: null,
  box_count: 1
}


export default class Check extends Component {

  constructor(props) {
    super(props)
    this.state = Object.assign({}, DEFAULT_STATE, {
      channels:[],
      shop_shipping_types:[],
      storage_types:[],
     });
    this.onBarcodeInput = this.onBarcodeInput.bind(this)
    this.resetOrder = this.resetOrder.bind(this)
    this.checkCompleteness = this.checkCompleteness.bind(this)
    // this.confirm = this.confirm.bind(this)
    this.onCloseModal = this.onCloseModal.bind(this)
    this.addProduct = this.addProduct.bind(this)
    this.removeProduct = this.removeProduct.bind(this)
    this.submit = this.submit.bind(this)
    this.handleStorageTypeChange = this.handleStorageTypeChange.bind(this)
    this.serial_number_confirm = this.serial_number_confirm.bind(this)
    this.onCloseSerialModal = this.onCloseSerialModal.bind(this)
    this.getShopChannels = this.getShopChannels.bind(this)
    this.getProductStrageTypes = this.getProductStrageTypes.bind(this)
    this.getShopShippingTypes = this.getShopShippingTypes.bind(this)
    this.handleChannelChange = this.handleChannelChange.bind(this)
    this.handleShippingTypeChange = this.handleShippingTypeChange.bind(this)

  }

  componentDidUpdate(prevProps, prevState){
    if(prevState.shop_id != this.state.shop_id){
      this.getShopChannels(this.state.shop_id)
      this.getShopShippingTypes(this.state.shop_id)
      this.getProductStrageTypes(this.state.shop_id)
    }
  }

  handleChannelChange(selectedOption) {
    this.setState({ shop_channel_id: selectedOption.value })
  }

  handleShippingTypeChange(selectedOption) {
    this.setState({ shipping_type: selectedOption.value })
  }

  getShopChannels(shop_id) {
    fetch(`/operator/shop_channels.json?shop_id=${shop_id}`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        console.log(data)
        this.setState({ channels: data })
      })
  }

  getShopShippingTypes(shop_id) {
    fetch(`/operator/shop_shipping_types.json?shop_id=${shop_id}`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        console.log(data)
        this.setState({ shop_shipping_types: data })
      })
  }


  getProductStrageTypes(shop_id) {
    fetch(`/operator/product_storage_types.json?shop_id=${shop_id}`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json())
      .then(data => {
        this.setState({ storage_types: data.storage_types })
      }
    )
  }

  componentDidMount() {
    this.refs.order_input.focus()

  }

  resetOrder() {
    let { uploading_videos, ...default_state } = DEFAULT_STATE
    this.setState(default_state)
    this.refs.order_input.focus()
  }


  checkCompleteness() {
    for (let product of this.state.products) {
      if (product.quantity != product.scanned) {
        return false
      }
    }
    return true
  }

  onBarcodeInput(e) {
    if (e.keyCode == 13 && this.refs.order_input.value) {
      switch (this.state.status) {
        case DONE:
          this.resetOrder()
        case WAITING:
          fetch(`/operator/return_orders/${this.refs.order_input.value}.json`, {
            'credentials': 'same-origin'
          })
            .then(res => res.json())
            .then(data => {
              this.refs.order_input.value = ""
              if (data.success) {
                console.log(data)
                this.refs.video_recorder.start_recording()
                let products = data.return_order.return_order_products.map(return_order_product => {
                  return {
                    quantity: return_order_product.quantity,
                    barcode: return_order_product.product.barcode,
                    name: return_order_product.product.name,
                    expiration_date: return_order_product.expiration_date,
                    product_storage_type_id: return_order_product.product_storage_type_id,
                    product_id: return_order_product.product.id,
                    uid: return_order_product.product.uid,
                    id: return_order_product.id,
                    index: return_order_product.id,
                    batch: return_order_product.batch,
                    scanned: 0
                  }
                })
                this.setState({
                  shop_id: data.return_order.shop_id,
                  products: products,
                  status: CHECKING,
                  token: data.return_order.token,
                  id: data.return_order.id,
                  order_id: data.return_order.order_id,
                  shop_channel_id: data.return_order.shop_channel_id,
                  shipping_type: data.return_order.shipping_type,
                  tracking_number: data.return_order.tracking_number,
                })
              } else {
                if ('order_id' in data) {
                  if (window.confirm('訂單無退貨單，是否建立退貨單?')) {
                    window.location.replace(`/operator/return_orders/new?order_id=${data.order_id}`)
                  }
                } else {
                  window.notyError("查無訂單")
                }
              }
            });
          break
        case CHECKING:
          let products = this.state.products
          let found = false
          for (let product of products) {
            if (product.barcode == this.refs.order_input.value) {
              console.log('yo')
              product.scanned += 1
              found = true
              break
            }
          }
          if (!found) {
            window.notyError("沒有找到該商品，請點擊新增")
          }
          this.refs.order_input.value = ""
          this.setState({ products: products })
      }

    }
  }
  onCloseModal() {
    this.setState({ modal_open: false })
  }
  removeProduct() {

  }
  addProduct() {
    fetch(`/operator/products/${this.refs.uid_input.value}.json?shop_id=${this.state.shop_id}`, {
      'credentials': 'same-origin'
    })
      .then(res => {
        if (res.ok) return res.json()
        return Promise.reject(res)
      })
      .then(data => {
        this.refs.uid_input.value = ""
        let products = this.state.products
        products.push({
          quantity: null,
          barcode: data.barcode,
          name: data.name,
          expiration_date: null,
          product_storage_type_id: null,
          product_id: data.id,
          uid: data.uid,
          id: "",
          index: `new-${this.state.products.length}`,
          scanned: 0
        })
        this.setState({ modal_open: false, products: products })

        if (data.need_serial_number) {
          this.setState({ current_product_id: data.id, serial_modal_open: true })
        }
      }).catch(e => {
        alert("查無商品")
      })
  }
  serial_number_confirm(serial_number) {
    console.log(serial_number, this.state.current_product_id)
    let products = this.state.products
    let index = this.state.products.findIndex(product => product.product_id === this.state.current_product_id)
    products[index].serial_number = serial_number

    this.setState({ products: products }, () => {
      this.setState({ current_product_id: null, serial_modal_open: false })
    })
  }
  onCloseSerialModal() {
    this.setState({ serial_modal_open: false })
  }

  submit() {
    let formData = new FormData(this.return_order_form);
    fetch(`/operator/return_orders/${this.state.id}`, {
      method: 'PUT',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token,
      },
      body: formData
    }).then((res) => res.json()).then((data) => {
      let token = this.state.token
      // keep token before reset order
      this.resetOrder()
      notyMessage("驗收完成，上傳錄影中(可繼續作業)")
      this.refs.video_recorder.stop_recording().then((streaming) => {
        if (streaming) {
          let videoData = new FormData();
          require('es6-promise').polyfill();
          let originalFetch = require('isomorphic-fetch');
          let fetch = require('fetch-retry')(originalFetch);
          let date = new Date()
          let fileName = `退貨錄影-${token}-${date.getHours()}${date.getMinutes()}${date.getSeconds()}.webm`;
          let file = new File([streaming], fileName, {
            type: 'video/webm'
          });
          videoData.append('file', file, fileName)
          this.setState({ uploading_videos: this.state.uploading_videos + 1 })
          fetch(`/operator/return_orders/${data.return_order_id}/upload_video`, {
            retryDelay: 3000,
            retryOn: function (attempt, error, response) {
              if (error !== null || response.status >= 400) {
                if (attempt < 3) {
                  notyError(`上傳失敗，重新嘗試上傳中`)
                  return true
                } else {
                  notyError(`上傳錄影檔失敗`)
                  return false
                }
              }
            },
            method: 'POST',
            headers: {
              'X-CSRF-Token': this.props.authenticity_token,
            },
            body: videoData,
            credentials: 'same-origin'
          }).then(res => {
            console.log(res)
            this.setState({ uploading_videos: this.state.uploading_videos - 1 })
          }).catch(error => {
            notyError(`上傳失敗`)
            this.setState({ uploading_videos: this.state.uploading_videos - 1 })
          })
        }
      })
    })
  }

  handleSetBoxCount(e) {
    this.setState({ box_count: e.target.value })
  }

  handleStorageTypeChange(index, value) {
    let products = this.state.products
    for (let product of products) {
      if (product.index == index) {
        product.product_storage_type_id = value
        break
      }
    }
    this.setState({ products: products })
  }

  handleSetTrackingNumber(e) {
    this.setState({ tracking_number: e.target.value })
  }
  render() {
    return (
      <div>
        <Modal open={this.state.modal_open} onClose={this.onCloseModal}
          onEntered={() => { this.refs.uid_input.focus() }}
          center>
          <div className='form-group'>
            <label htmlFor="form-group-input-1">請輸入品號 / 條碼</label>
            <input type="text" className="form-control"
              id="form-group-input-1" ref="uid_input"
              onKeyDown={(e) => {
                if (e.keyCode == 13) {
                  this.addProduct()
                }
              }}
            />
            <hr />
            <a className='btn btn-primary' onClick={this.addProduct}>帶出資料</a>
          </div>
        </Modal>
        <Modal open={this.state.serial_modal_open} onClose={this.onCloseSerialModal}
          onEntered={() => { this.refs.serial_input.focus() }}
          center>
          <div className='form-group'>
            <label htmlFor="form-group-input-1">請掃描或輸入序號</label>
            <input type="text" className="form-control"
              id="serial-number-input-1" ref="serial_input"
              onKeyDown={e => {
                if (e.keyCode == 13) {
                  this.serial_number_confirm(e.target.value)
                }
              }}
            />
          </div>
        </Modal>
        <div className="page-header">
          <div className='col-md-10'>
            <div className="pull-left"><h1>退貨驗收</h1></div>
            <div className="pull-right">
              <input type="text" ref="order_input"
                placeholder="請輸入或者掃描條碼"
                autoFocus={true}
                onKeyDown={this.onBarcodeInput}
                className="form-control input-lg" />
            </div>
          </div>
          <div className='col-md-2'>
            <Video ref="video_recorder" />
            {
              this.state.uploading_videos > 0 ?
                <div>
                  <span className="lds-ellipsis"><div></div><div></div><div></div><div></div></span>
                  {`錄影上傳中(${this.state.uploading_videos})`}
                </div>
                : ''

            }
          </div>
        </div>
        <div className='row'>
          <div className='col-md-12'>
            {this.state.id ?
              <form action={`/operator/return_orders/${this.state.id}`} method="POST" ref={(el) => { this.return_order_form = el }}>
                <input type='hidden' value={this.props.authenticity_token} name='authenticity_token' />
                <input type="hidden" value="PUT" name="_method" />
                <input type="hidden" value={this.state.shop_id} name="shop_id" />
                <input type="hidden" value={this.state.order_id || ''} name="order_id" />

                <div className='panel'>
                  <div className='panel-heading'>
                    <div className='panel-title'>
                      {this.state.token}
                      <a className='btn btn-default pull-right' onClick={this.resetOrder}>
                        <i className='ion-md-close pull-right'></i>
                      </a>

                    </div>
                  </div>
                  <div className="panel-body">
                    <table className="table qc-table ">
                      <thead>
                        <tr>
                          <th>商品</th>
                          <th>倉別</th>
                          <th>效期</th>
                          <th>批號</th>
                          <th>已確認</th>
                          <th>總數</th>
                        </tr>

                      </thead>
                      <tbody>
                        {this.state.products.map(product => {
                          return <tr key={product.index}>
                            <td>
                              {product.uid}
                              <br />
                              {product.name}
                              <br />
                              {product.barcode}
                              <input type='hidden'
                                name={
                                  product.id ?
                                    `products[${product.id}][product_id]`
                                    : "new_products[][product_id]"
                                }
                                value={product.product_id}
                              />
                              <input type='hidden'
                                name={
                                  product.id ?
                                    `products[${product.id}][serial_number]`
                                    : "new_products[][serial_number]"
                                }
                                value={product.serial_number}
                              />
                            </td>
                            <td>
                              <select className='form-control' name={
                                product.id ?
                                  `products[${product.id}][product_storage_type_id]`
                                  : "new_products[][product_storage_type_id]"
                              } value={product.product_storage_type_id}
                                onChange={(e) => this.handleStorageTypeChange(product.index, e.currentTarget.value)}
                              >
                                {this.state.storage_types.map(s => {
                                  return <option key={s[0]} value={s[0]}>{s[2]}-{s[1]}</option>
                                })}
                              </select>
                            </td>
                            <td>
                              <input type="date" name={
                                product.id ?
                                  `products[${product.id}][expiration_date]`
                                  : "new_products[][expiration_date]"
                              } defaultValue={product.expiration_date}
                              max={'9999-01-01'}
                              className='form-control' />
                            </td>
                            <td>
                              <input type="text" name={
                                product.id ?
                                  `products[${product.id}][batch]`
                                  : "new_products[][batch]"
                              } defaultValue={product.batch} className='form-control' />
                            </td>

                            <td>
                              <input type="number" min="0" step="1" name={
                                product.id ?
                                  `products[${product.id}][received]`
                                  : "new_products[][received]"
                              } value={product.scanned}
                                onChange={(e) => {
                                  let products = this.state.products
                                  for (let p of products) {
                                    if (product.index == p.index) {
                                      p.scanned = parseInt(e.target.value)
                                      break
                                    }
                                  }
                                  this.setState({ products: products })
                                }}
                                className='form-control' />
                            </td>
                            <td>
                              {product.quantity}
                            </td>
                          </tr>
                        })
                        }
                      </tbody>
                    </table>
                    <a className='btn btn-info' onClick={() => { this.setState({ modal_open: true }) }}>
                      <i className='ion-md-add'></i>
                      新增品項
                    </a>

                  </div>
                  <div className='panel-footer'>
                    <input type='hidden' name="confirm" value='true' />
                    <div className='row '>
                      <div className='col-md-12 form-horizontal'>
                        <div className='form-group col-md-3'>
                          <label className='col-md-3 control-label'>托運單號</label>
                          <div className='col-md-9'>
                            <input type='text' name='tracking_number' className='form-control' value={this.state.tracking_number || ''} onChange={
                             this.handleSetTrackingNumber.bind(this)
                           } />
                          </div>
                        </div>
                        <div className='form-group col-md-3'>
                          <label className='col-md-3 control-label'>退貨物流</label>
                          <div className='col-md-9'>
                            {
                              this.state.shop_shipping_types.length > 0 ?
                                <Select
                                  name={`shipping_type`}
                                  options={this.state.shop_shipping_types.map(shipping_type => {
                                    return { value: shipping_type.code, label: shipping_type.name }
                                  })}
                                  onChange={this.handleShippingTypeChange}
                                  value={
                                    this.state.shipping_type ? this.state.shop_shipping_types.map(shipping_type => {
                                      return { value: shipping_type.code, label: shipping_type.name }
                                    }).find(shipping_type_option => shipping_type_option.value == this.state.shipping_type) : null
                                  }
                                  searchable={true}
                                  clearable={false}
                                  placeholder="請選擇物流類型" /> : "若需要指定物流，請先建立物流與客戶的對應"
                              }
                          </div>
                        </div>
                        <div className='form-group col-md-3'>
                          <label className='col-md-3 control-label'>件數</label>
                          <div className='col-md-9'>
                            <input type='text' name='box_count' className='form-control' value={this.state.box_count || ''} onChange={
                              this.handleSetBoxCount.bind(this)
                           } />
                          </div>
                        </div>
                        <div className='form-group col-md-3'>
                          <label className='col-md-3 control-label'>通路</label>
                          <div className='col-md-9'>
                            <Select
                              name={`shop_channel_id`}
                              options={this.state.channels.map(channel => {
                                return { value: channel.id, label: channel.code }
                              })}
                              searchable={true}
                              clearable={false}
                              onChange={this.handleChannelChange}
                              value={
                                this.state.shop_channel_id ? this.state.channels.map(channel => {
                                  return { value: channel.id, label: channel.code }
                                }).find(channel => channel.value == this.state.shop_channel_id) : null
                              }
                              placeholder="請選擇通路" />
                          </div>
                        </div>

                      </div>
                    </div>
                    <div className='row'>
                      <div className='col-md-12'>
                          {this.state.status == CHECKING ?
                            <button type='button' onClick={this.submit} className='btn btn-primary'>確認送出</button> : ''
                          }

                      </div>
                    </div>


                  </div>
                </div>
              </form> : ''}
          </div>
        </div>
      </div>
    )
  }
}
