import React, { useState } from 'react';
import Rules from './Rules';
import { RecordProvider } from './Context';
import Table from './Table';

export default function TableIndex({
  getRecordsUrl,
  selectAllUrl,
  supportRules =[],
  columns,
  queries,
  searchable,
  actions,
  defaultQuery,
  columnsEditable,
  tableId,
  selectCheckboxEnabled,
  lastModified,
  }) {
  return (
    <RecordProvider getRecordsUrl={getRecordsUrl} tableId={tableId} selectAllUrl={selectAllUrl} lastModified={lastModified} queries={queries} defaultQuery={defaultQuery} >
      <div className="">
        <div className="row">
          <div className="col-xs-12">
            <Rules supportRules={supportRules} tableId={tableId} searchable={searchable} lastModified={lastModified} />
            <hr />
            <Table
              selectCheckboxEnabled={selectCheckboxEnabled}
              columnsEditable={columnsEditable}
              tableId={tableId}
              columns={columns}
              getRecordsUrl={getRecordsUrl}
              selectAllUrl={selectAllUrl}
              queries={queries}
              actions={actions}
            />
          </div>
        </div>
      </div>
    </RecordProvider>
  );
}

export function handleSubmit(action,selected,params={},onTaskDone) {
  if(window.checkTask('batch_operation')){
    fetch(action, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
      },
      body: JSON.stringify({
        records: [...selected],
        ...params
        //set to array
      }),
    }).then(response => {
      if (response.ok) {
        return response.json()
      } else {
        throw new Error('Network response was not ok')
      }
    }).then(data => {
      if(data.done){
        if(onTaskDone){
          onTaskDone()
        }
      }else if(data.job_id){
        pushTaskItem(data.job_id,'batch_operation')
        if(onTaskDone){
          window[`onTaskDone-${data.job_id}`] = onTaskDone
        }
      }

    })
  }
}

