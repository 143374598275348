import React, { Component } from 'react'


import Select from 'react-select'


export default class Upload extends Component {
  constructor(props) {
    super(props)
    this.state = {
      shippings: [],
      shipping_account:null,
      shop: null,
      delivery_date: '',
      company: this.props.shipping_companies[0].code
    }
    this.onShopChange = this.onShopChange.bind(this)
    this.loadShipping = this.loadShipping.bind(this)
    this.onRemoveShipping = this.onRemoveShipping.bind(this)
    this.onQuantityChange = this.onQuantityChange.bind(this)
    this.onAccountChange = this.onAccountChange.bind(this)
    this.onDateChange = this.onDateChange.bind(this)
  }
  componentDidMount() {
  }

  onRemoveShipping(id) {
    this.setState({
      shippings: this.state.shippings.filter(shipping => shipping.id != id)
    })
  }

  loadShipping() {
    console.log(this.state)
    fetch(`/operator/shipping_batches/by_company.json?account=${this.state.shipping_account}&shop=${this.state.shop}`, {
      'credentials': 'same-origin'
    }).then(res => res.json())
      .then(data => {
        this.setState({ shippings: data.shippings })
      })
  }
  onQuantityChange(id, quantity) {
    let shippings = this.state.shippings
    for (let shipping of shippings) {
      if (shipping.id == id) {
        shipping.quantity = parseInt(quantity)
        break
      }
    }
    this.setState({ shippings: shippings })
  }

  onAccountChange(event){
    let account = this.props.shipping_accounts.find(e=>e.id == event.value)
    console.log(account)
    this.setState({shop:account.shop_id,shippings:[],shipping_account:event.value},()=>{
      if(account.shop_id){
        this.loadShipping()
      }
    })
  }

  onDateChange(event){
    this.setState({delivery_date: event.currentTarget.value})
  }

  onShopChange(shopOption){
    console.log(shopOption)
    this.setState({shop:shopOption.value},
      ()=>{
        if(shopOption.value){
          this.loadShipping()
        }
      })
  }

  render() {
    let shipping_accounts = this.props.shipping_accounts
    // let grouped_options = []
    // for(let shipping_account of shipping_accounts){
    //   let group = grouped_options.filter(g=>g.label == shipping_account.shop_name)[0]
    //   if(group){
    //     group.options.push({label: shipping_account.title,value: shipping_account.id})
    //   }else{
    //     grouped_options.push({
    //       label: shipping_account.shop_name,
    //       options: [{label: shipping_account.title,value: shipping_account.id}]
    //     })
    //   }
    // }
    let shipping_account_options = shipping_accounts.map(shipping_account=>{return {label: shipping_account.title,value: shipping_account.id}})

    let current_shipping_account = this.props.shipping_accounts.filter(s=>s.id == this.state.shipping_account)[0]
    let valid_shops = []

    if(current_shipping_account){
      if(!current_shipping_account.shop_id){
        valid_shops = [{label:'全部客戶',value:'all'}].concat(this.props.shops.map(shop =>{return{value:shop.id,label:shop.name}}))
      }else{
        valid_shops = this.props.shops.filter(shop=>shop.id == current_shipping_account.shop_id).map(shop => {return {value:shop.id,label:shop.name}})
      }
    }
    return (
      <div>
        <div className="page-header">
          <div className="pull-left"><h1>託運單彙整</h1></div>
        </div>
        <div className='col-md-12'>
          <div className='panel'>
            <div className='panel-heading form-horizontal'>
              <div className='form-group'>
                <label className="col-md-2 control-label">
                  客代
                </label>
              <div className="col-md-10">
                <Select placeholder='請選擇客代' 
                value={shipping_account_options.find(s=>s.id==this.state.shipping_account)} 
                options={shipping_account_options}  
                onChange={this.onAccountChange}/>
              </div>
            </div>

            <div className='form-group'>
              <label className="col-md-2 control-label">
                  客戶
              </label>
              <div className="col-md-10">
                <Select placeholder='請選擇客戶' options={valid_shops} value={valid_shops.find(s=>s.id == this.state.shop)} onChange={this.onShopChange}>
                </Select>
              </div>
            </div>
            <div className='form-group'>
              <label className="col-md-2 control-label">
                  篩選到貨日(以內)
              </label>
              <div className="col-md-10">
                <input type='date' className='form-control' value={this.state.delivery_date} onChange={this.onDateChange}></input>
              </div>
            </div>
            <div className='form-group'>
              <label className="col-md-2 control-label">
                {`共 ${this.state.shippings.reduce((a, b) => { return a + b.quantity }, 0)} 件`}
              </label>
            </div>
            </div>
            <form action="/operator/shipping_batches" method="POST">
              <input type='hidden' value={this.props.authenticity_token} name='authenticity_token' />
              <div className="panel-body">
                <table className="table">

                  <thead>
                    <tr>
                      <th>
                        託運單
                      </th>
                      <th>
                        訂單編號
                      </th>
                      <th>
                        地址
                      </th>
                      <th>
                        件數
                      </th>
                      <td>
                        預約到貨日
                      </td>
                      <td>
                        建立時間
                      </td>
                      <th></th>
                    </tr>

                  </thead>
                  <tbody>
                    {
                      this.state.shippings.map(shipping => {
                        console.log(this.state.delivery_date)
                        console.log(shipping.delivery_date)
                        if(this.state.delivery_date && shipping.delivery_date && new Date(this.state.delivery_date) < new Date(shipping.delivery_date) ){                          
                          return null
                        }
                        return <tr key={shipping.id}>
                          <td>
                            <input type="hidden" name="shipping_ids[]" value={shipping.id} />
                            {shipping.tracking_number}
                          </td>
                          <td>
                            {shipping.order_name}
                          </td>
                          <td>
                            {shipping.order_address}
                          </td>
                          <td>
                            <input className='form-control' type="number" name={`shipping[${shipping.id}][quantity]`} min={1} required="required" value={shipping.quantity} onChange={(e) => { this.onQuantityChange(shipping.id, e.currentTarget.value) }} />
                          </td>
                          <td>
                            {shipping.delivery_date}
                          </td>

                          <td>
                            {shipping.created_at}
                          </td>
                          <td>
                            <a className='btn btn-default' href="javascript:void(0)" onClick={() => this.onRemoveShipping(shipping.id)}>
                              <i className='ion-md-close'></i>
                            </a>
                          </td>
                        </tr>

                      })
                    }
                  </tbody>
                </table>
              </div>
              <div className='panel-footer'>
                {
                  this.state.shippings.length > 0 ?
                    <button className='btn btn-primary' type='submit'>
                      送出
                </button> : ""
                }
              </div>
            </form>
          </div>
        </div>


      </div>
    )
  }
}
