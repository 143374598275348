import React, { Component } from 'react'
import AsyncSelect from 'react-select/async'
import _ from "lodash";

import BaseSelect from "react-select";
import FixRequiredSelect from './fix_react_select'

const Select = props => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options || options}
  />
);

export default class ShopProductsSelect extends Component {
  constructor(props) {
    super(props)
    let selected_products = this.props.selected_products || []
    this.remove = this.remove.bind(this)
    this.handleItemAdd = this.handleItemAdd.bind(this)
    this.onProductSelect = this.onProductSelect.bind(this)
    this.onStorageSelect = this.onStorageSelect.bind(this)
    this.onQuantityChange = this.onQuantityChange.bind(this)
    this.addExisting = this.addExisting.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.debouncedLoadOptions = _.debounce(this.loadOptions, 500);
    this.state = {
      items: [],
      shop_product_options:[]
    }
  }
  componentDidMount(){
    for(let product of this.props.original_products){
      this.addExisting(product)
      console.log(product)
    }
    this.fetchDefaultProducts()
  }
  componentDidUpdate(prevProps) {
    if (this.props.shop_id !== prevProps.shop_id && !!this.props.shop_id) {
      this.fetchDefaultProducts()
    }
  }
  fetchDefaultProducts() {
    this.fetchProducts()
      .then(data => {
        this.setState({ shop_product_options: data.products.map(product=>this.toOption(product)) })
      })
  }
  async fetchProducts(inputValue = "") {
    return await fetch(`/operator/products.json?shop_id=${this.props.shop_id}&search=${inputValue}&product_only=true&limit=50`,
    {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token,
      },
      credentials: 'same-origin'
    }).then(res => res.json())
  }

  addExisting(product) {
    let items = this.state.items
    let product_id = product.product_id
    fetch(`/operator/products/${product_id}/product_storages.json`, {
      method: 'GET',
      credentials: 'same-origin'
    }).then(res => res.json())
      .then(res => {
        let original_product_storage = res.data.filter(s=>{
          return s.storage_type_id == product.product_storage_type_id && s.expiration_date == product.expiration_deadline && s.batch == product.batch
        })[0]
        items.push({ key: Math.random(),
          product: { id: product_id},
          storages: res.data.filter(s => s.available_pcs > 0),
          quantity: product.quantity,
          product_storage_id: original_product_storage ? original_product_storage.id : null,
          note: product.note
        })
        this.setState({ items: items })
      })
  }

  onProductSelect(id, label,key) {
    let items = this.state.items
    fetch(`/operator/products/${id}/product_storages.json`, {
      method: 'GET',
      credentials: 'same-origin'
    }).then(res => res.json())
      .then(res => {
        for (let item of items) {
          if (item.key == key) {
            item.id = id
            item.label = label
            item.product_storage_id = null
            item.product_storage_label = null
            item.storages = res.data.filter(s => s.available_pcs > 0)
            break
          }
        }
        this.setState({ items: items })
      })
  }

  onStorageSelect(product_storage_id,label, key) {
    let items = this.state.items
    let expiration_date
    for (let item of items) {
      if (item.key == key) {
        item.product_storage_id = product_storage_id
        item.product_storage_label = label
        for (let storage of item.storages) {
          if (product_storage_id == storage.id) {
            expiration_date = storage.expiration_date
            item.max_quantity = storage.available_pcs
          }
        }
        break
      }
    }
    this.setState({ items: items })
    // this.props.onItemExpirationDateChange(key,expiration_date)
  }

  onQuantityChange(quantity, key) {
    let items = this.state.items
    for (let item of items) {
      if (item.key == key) {
        item.quantity = quantity
      }
    }
    this.setState({ items: items })
  }

  handleItemAdd() {
    let items = this.state.items
    items.push({ key: Math.random() })
    this.setState({ items: items })
  }
  remove(key) {
    let items = this.state.items.filter(e => e.key != key)
    // this.props.onItemExpirationDateChange(key,null)
    this.setState({ items: items })
  }

  toOption(product){
    return { label: [product.barcode,product.uid, product.name].filter(Boolean).join("/"), value: product.id }
  }

  loadOptions(inputValue, callback){
    if(inputValue.length <2){
      callback([])
      return
    }

    this.fetchProducts(inputValue)
    .then(data => {
      let shop_product_options = this.state.shop_product_options
      let options = data.products.map(product=>this.toOption(product))
      for(let option of options){
        let found = false
        for(let shop_product of shop_product_options){
          if(shop_product.value == option.value){
            found = true
            break
          }
        }
        if(!found){
          shop_product_options.push(option)
        }
      }
      this.setState({shop_product_options: shop_product_options})
      callback(options)      
    })      
  };  

  render() {
    const { shop_id } = this.props
    return (
      <div>
        <table className="table">
          <thead>
            <tr>
              <th className="col-md-4">商品 <span style={{color: 'red'}}>*</span></th>
              <th className="col-md-3">倉別/效期/批號 <span style={{color: 'red'}}>*</span></th>
              <th className="col-md-2">數量 <span style={{color: 'red'}}>*</span></th>
              <th className="col-md-2">單品備註</th>
              <th className="col-md-1"></th>
            </tr>
          </thead>
          <tbody>
            {
              this.state.items.map(item => {
                return (
                  <tr key={item.key}>
                    <td>
                      <div className='form-group'>
                        <AsyncSelect name="products[][id]"
                          className='react-select-container'
                          classNamePrefix="react-select"
                          value={item.id ? {value: item.id, label: item.label} : null}
                          searchable={true}
                          clearable={false}
                          required={true}
                          noOptionsMessage={() => '找不到商品，請輸入兩碼以上關鍵字'}
                          onChange={e => this.onProductSelect(e.value,e.label, item.key)}
                          placeholder="請輸入品號或條碼"
                          loadOptions={(input,callback)=>this.debouncedLoadOptions(input,callback)}
                          defaultOptions={[...this.state.shop_product_options, {label: "輸入兩碼以上關鍵字查看更多..." ,value: undefined, isDisabled: true}]}
                        />

                      </div>
                    </td>
                    <td>
                      <div>
                        <Select name="products[][storage_id]"
                          className='react-select-container'
                          classNamePrefix="react-select"
                          value={item.product_storage_id ? {value: item.product_storage_id,label: item.product_storage_label} : null}
                          searchable={true}
                          clearable={false}
                          required={true}
                          placeholder="請選擇效期"
                          onChange={e => this.onStorageSelect(e.value,e.label, item.key)}
                          options={
                            item.storages ? item.storages.map(storage => { return { label: [storage.storage_type, storage.expiration_date, storage.batch].filter(Boolean).join("/"), value: storage.id } }).sort((a,b)=>{
                              return a.label.localeCompare(b.label)
                            })
                            : []
                          }
                        />
                      </div>
                    </td>
                    <td>
                      <div className="form-group">
                        <input type='number' name='products[][quantity]' className='form-control' min='0' required="required" defaultValue={item.quantity || ""} placeholder={item.max_quantity != null ? `庫存量:${item.max_quantity}` : '請先選擇倉別/效期/批號'} onChange={e => this.onQuantityChange(e.target.value, item.key)} />
                      </div>
                    </td>
                    <td>
                    <div className="form-group">
                      <input name='products[][note]' type='text' placeholder="單品備註" className='form-control' defaultValue={item.note || ''}/>
                      </div>
                    </td>
                    <td>
                      <a className="btn btn-danger" onClick={() => this.remove(item.key)}>X</a>
                    </td>
                  </tr>
                )
              })
            }
          </tbody>
        </table>
        <a className="btn btn-success btn-block" onClick={this.handleItemAdd}>+ 新增品項</a>
      </div>
    )
  }
}
