
import React ,{useState} from 'react'

function ShippingTypes(props) {
  const [ecShippingTypes, setEcShippingTypes] = useState(props.ecShippingTypes.map(e=> {e.key = e.id;return e}))


  const onClickAddShippingType = () => {
    let newShippingType = {
      shipping_type_id: null,
      key: Date.now(),
      ec_platform_shipping_code: null
    }
    setEcShippingTypes([...ecShippingTypes, newShippingType])
  }
  const removeShippingType = (key) => {
    setEcShippingTypes(ecShippingTypes.filter(s=> s.key != key))
  }

  return <div>
    {
      ecShippingTypes.length > 0 &&
      <div className='row'>
        <div className='col-md-6'>
          電商平台物流代碼
        </div>
        <div className='col-md-6'>
          倉庫端使用物流
        </div>
      </div>
    }
    {
      ecShippingTypes.map(ecShippingType => {
        console.log(ecShippingType)
        return <div className='row' key={ecShippingType.key} style={{marginTop:'5px',marginBottom:'5px'}}>
            <div className='col-md-10'>
              <div className="input-group">
                {
                  props.predefined_shipping_types && props.predefined_shipping_types.length > 0 ?
                  <select className="form-control" type="text" name="ec_platform_shipping_code[]" placeholder="電商平台物流代碼：例如 custom / post / ..." required='required'
                  defaultValue={ecShippingType.ec_platform_shipping_code}>
                    {
                      props.predefined_shipping_types.map(shipping_type=><option value={shipping_type} key={shipping_type}>{shipping_type}</option>)
                    }
                  </select> :
                  <input className="form-control" type="text" name="ec_platform_shipping_code[]" placeholder="電商平台物流代碼：例如 custom / post / ..." required='required' defaultValue={ecShippingType.ec_platform_shipping_code}/>
                }
                <div className="input-group-addon">
                  <i className='fa fa-arrow-right'></i>
                </div>
                <select className="form-control" type="number" name="shipping_type_id[]" defaultValue={ecShippingType.shipping_type_id}>
                  {
                    props.shipping_types.map(shipping_type=><option key={shipping_type.id} value={shipping_type.id}>{shipping_type.name}</option>)
                  }
                </select>
              </div>
            </div>
            <div className='col-md-2'>
              <a className='btn btn-outline btn-danger' onClick={()=>removeShippingType(ecShippingType.key)}>
                <i className='fa fa-trash-o'></i>
              </a>
            </div>
          </div>
      })
    }
    <div className="row">
      <div className="col-md-12">
        <a className='btn btn-success btn-block' onClick={onClickAddShippingType}>
          新增物流對應
        </a>
      </div>
    </div>
  </div>
}
export default ShippingTypes
