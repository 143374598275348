import React, { Component } from 'react'

import {useDropzone} from 'react-dropzone';

function Dropzone(props) {
  let { accept, fileMaxNum, fileMaxSizeMB, fileType ,onDrop} = props;
  const {
    acceptedFiles,
    fileRejections,
    getRootProps,
    getInputProps
  } = useDropzone({
    accept: accept,
    maxFiles: fileMaxNum,
    maxSize: fileMaxSizeMB*1024*1024,
    onDrop: (acceptedFiles, fileRejections) => {
      if (fileRejections.length > 0) {
        console.log(fileRejections)
        switch (fileRejections[0].errors[0].code) {
          case "file-invalid-type":
            notyError(`${fileType}格式錯誤，請上傳指定格式～`)
            return
          case "too-many-files":
            notyError(`最多一次可上傳${fileMaxNum}張${fileType}～`)
            return
          case "file-too-large":
            notyError(`喔不～檔案太大了，請上傳小於${fileMaxSizeMB}MB的${fileType}`)
            return
          default:
            notyError(`${fileType}格式不符`)
            return
        }
      }
      onDrop(acceptedFiles)
    }
  });

  return (
    <div {...getRootProps({ className: `dropzone ${props.className ?? ""}` })}>
      <input {...getInputProps()} />
      <div className='placeholder'>
        {props.placeholder ?? '+'}
      </div>
    </div>
  );
}

export default class UploadLabels extends Component {
  constructor(props) {
    super(props)
    this.onDrop = (files) => {
      this.setState({files: [...this.state.files, ...files]})
    };
    this.state = {
      files: []
    };
    this.formRef = React.createRef();
    this.appendFiles = this.appendFiles.bind(this)
  }

  async appendFiles(files) {
    let form = this.formRef.current
    let filePromises = files.map(file=>{
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64 = e.target.result;
          const input = document.createElement('input');
          input.type = 'hidden';
          input.name = `files[${file.name}]`;
          input.value = base64.split(',')[1];
          form.appendChild(input);
          resolve();
        };
        reader.readAsDataURL(file);
      })
    })
    const fileInfos = await Promise.all(filePromises);
    form.submit();
  }

  render(){
    const files = this.state.files.map(file => (
      <li key={file.name}>
        {file.name}
        <a href="#" onClick={(e) => {
          e.preventDefault()
          e.stopPropagation()
          this.setState({
            files: this.state.files.filter(f => f.name != file.name)
          })
        }}>
          <i className="fa fa-times text-danger"></i>
        </a>
      </li>
    ));

    return (
      <form ref={this.formRef} action={`/operator/product_labels`} method="post" encType="multipart/form-data">
        <input type="hidden" name="authenticity_token" value={this.props.authenticity_token} />
        <div className='form-horizontal'>
          <div className='form-group'>
            <label className='col-sm-2 control-label'>請選擇客戶</label>
            <div className='col-sm-10'>
              <select className='form-control' required={true} name="shop_id">
                {
                  this.props.shops.map((shop, index) => {
                    return <option key={index} value={shop.id}>{shop.name}</option>
                  })
                }
              </select>
            </div>
          </div>
          <div className='form-group'>
            <div className='col-sm-10 col-sm-offset-2'>
              <Dropzone
                onDrop={this.onDrop}
                placeholder={
                  <>
                  <p>請上傳與商品品號相同名稱的標籤PDF<br></br>
                      例如：商品品號為 XYZ123456789，則標籤檔名為 XYZ123456789.pdf
                      </p>
                      <ul>{files}</ul>
                      </>
                }
              >
              </Dropzone>
            </div>
          </div>
          <div className='form-group'>
            <div className='col-sm-10 col-sm-offset-2'>
              {
                this.state.files.length > 0 &&
                <button type='submit' className='btn btn-primary btn-block' onClick={(e)=>{
                  e.preventDefault()

                  this.appendFiles(this.state.files)
                }}>上傳</button>
              }
            </div>
          </div>
        </div>
      </form>
    );
  }
}
