import {
  RECEIPT_SET_PRODUCTS, RECEIPT_SET_STORAGE_TYPES, RECEIPT_SET_PRODUCT_ITEMS,
  RECEIPT_ADD_PRODUCT_ITEM, RECEIPT_UPDATE_PRODUCT_ITEM, RECEIPT_DELETE_PRODUCT_ITEM,
  RECEIPT_PRODUCT_ITEM_CHANGE,RECEIPT_PRODUCT_EXPIRATION_DATE_CHANGE,
} from '../../../actions/action_types'

const initialState = { products: [], product_items: [], validates: {} }

export default function ReceiptForm(state = initialState, action) {
  switch (action.type) {
  case RECEIPT_SET_PRODUCTS:
    return {
      ...state,
      products: action.products
    }
  case RECEIPT_SET_STORAGE_TYPES:
    return {
      ...state,
      storage_types: action.storage_types
    }
  case RECEIPT_SET_PRODUCT_ITEMS:
    return {
      ...state,
      product_items: action.product_items
    }
  case RECEIPT_ADD_PRODUCT_ITEM:
    const _item = {
      product_id: null,
      index: action.item.index,
      pcs_per_box: null,
      box_count: '',
      expiration_date: '',
      batch: ''
    }
    const product_item = Object.assign({}, state.products[0], _item)
    delete product_item.id

    return {
      ...state,
      product_items: [
        ...state.product_items,
        product_item,
      ]
    }
  case RECEIPT_UPDATE_PRODUCT_ITEM:
    const _product_items = state.product_items.map((item, index) => {
      if (item.index == action.item.index) {
        item = {
          ...item,
          ...action.item,
        }
      }
      return item
    })

    return {
      ...state,
      product_items: _product_items,
    }
  case RECEIPT_DELETE_PRODUCT_ITEM:
    const _product_item = state.product_items.filter(item => item.index === action.item.index)[0]
    if (_product_item.id) {
      const __product_items = state.product_items.map((item, index) => {
        if (item.index == action.item.index) {
          item = {
            ...item,
            _destroy: 1
          }
        }
        return item
      })

      return {
        ...state,
        product_items: __product_items,
      }
    } else {
      return {
        ...state,
        product_items: state.product_items.filter(item => item.index !== action.item.index),
      }
    }
  case RECEIPT_PRODUCT_ITEM_CHANGE:
    console.log(action)
    const product = Object.assign({}, state.products.filter(product => product.id == action.item.product_id)[0])
    console.log(state.products)
    const product_items = state.product_items.map((item, index) => {
      if (item.index == action.item.index) {
        const product_id = product.id
        delete product.id

        item = {
          ...item,
          ...product,
          product_id: action.item.product_id,
          product_label: action.item.product_label,
          pcs_per_box: product.default_pcs || '',
        }
      }
      return item
    })

    return {
      ...state,
      product_items: product_items,
    }
  case RECEIPT_PRODUCT_EXPIRATION_DATE_CHANGE:
    const _product = state.product_items.filter(item => item.index == action.item.index)[0]
    const expiration_date = new Date(action.item.expiration_date)

    let valid_expiration_date = false

    let min_date   = new Date()
    min_date.setDate(min_date.getDate())

    if ( expiration_date <= min_date ) {
      valid_expiration_date = true
    }

    let validates = Object.assign({}, state.validates[action.item.index])
    validates[action.item.index] = {
      valid_expiration_date: valid_expiration_date
    }

    return {
      ...state,
      validates: validates
    }
  default:
    return state
  }
}
