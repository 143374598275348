import React, { Component } from 'react'

class BarcodeInput extends React.Component {
  constructor(props) {
    super(props)
    this.onBarcodeInput = this.onBarcodeInput.bind(this);
  }
  onBarcodeInput(e) {
    if (e.keyCode == 13 && this.refs.barcodeText.value) {
      let barcode = this.refs.barcodeText.value;
      this.refs.barcodeText.value = ""
      this.props.onBarcodeEnter(barcode)
    }
  }

  componentDidMount() {
    document.body.onkeydown = (e) => {
      this.refs.barcodeText.focus()
    }
  }

  render(){
    return <input type="text" ref="barcodeText"
    placeholder="請掃描條碼"
    autoFocus={true}
    onKeyDown={this.onBarcodeInput}
    className="form-control input-lg" />

  }
}

export default BarcodeInput