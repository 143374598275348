import React, {useEffect, useState} from 'react';

import { PickingListPresetRule } from './rule';

export default function PickingListPresets(props){
  const { shops,picking_list_preset ,valid_operators,shipping_types} = props;
  const [shopId, setShopId] = useState(picking_list_preset.shop_id);
  const [ shopChannels, setShopChannels] = useState([]);
  const [ rules , setRules] = useState(picking_list_preset.picking_list_preset_rules);

  function getShopChannels(shopId){
    if (shopId) {
      fetch(`/operator/shop_channels.json?shop_id=${shopId}`).then(res=>res.json()).then(data => {
        console.log(data)
        setShopChannels(data);
      })
    }
  }
  useEffect(() => {
    if (shopId) {
      getShopChannels(shopId);
    }
  }, [shopId]);


  return <>
    <div className="form-group">
      <label className='col-md-2 control-label'>客戶</label>
      <div className='col-md-4'>
        <select value={shopId || ''} className='form-control' name='picking_list_preset[shop_id]' required onChange={(e) => setShopId(e.target.value)}>
          <option value="">選擇客戶</option>
          {shops.map(shop => <option key={shop.id} value={shop.id}>{shop.name}</option>)}
        </select>
      </div>
    </div>
    <div className="form-group">
      <label className='col-md-2 control-label'>篩選條件</label>
      <div className='col-md-10'>
        {
          rules.map((rule, index) => <PickingListPresetRule
          onRemove={()=>{
            setRules(rules.filter((r, i) => i !== index));
          }}
          shipping_types={shipping_types}
          shopChannels={shopChannels}
          key={index} rule={rule} valid_operators={valid_operators} />)
        }
        <div className='row'>
          <div className="col-md-6">
            <a className='btn btn-success btn-outline btn-block' onClick={()=>{
              setRules([...rules, {field: '', operator: '', picking_list_preset_rule_values: []}]);
            }}>新增條件</a>
          </div>
        </div>
      </div>
    </div>
    <button type="submit" className="btn btn-primary">儲存</button>
  </>
}
