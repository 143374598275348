import React, { useEffect, useState } from 'react';

import FixRequiredSelect from '../../../components/common/fix_react_select';
import CreatableSelect from "react-select/creatable";
import BaseSelect from "react-select";

function Select(props){
  const {creatable} = props;
  return <FixRequiredSelect
    {...props}
    SelectComponent={creatable ? CreatableSelect : BaseSelect}
    options={props.options}
  />
}

const PresetFiels = {
  'order_name': '訂單名稱',
  'order_address': '訂單地址',
  'order_city': '訂單城市',
  'order_receiver_name': '收件人名稱',
  'order_products_total_count': '訂單商品總數(PCS)',
  'order_product_sku_count': '訂單商品SKU數',
  'product_uid': '商品SKU',
  'product_name': '商品名稱',
  'order_shop_channel_id': '訂單通路',
  'order_shipping_type': '物流類型',
}

const OperatorLabels = {
  'equal': '完全等於',
  'greater_than_or_equal': '大於等於',
  'less_than_or_equal': '小於等於',
  'contains': '包含關鍵字',
  'not_contains': '不包含關鍵字',
  'in': '包括',
  'not_in': '排除',
}

export function PickingListPresetRule(props){
  const { rule, valid_operators } = props;
  const [field, setField] = useState(rule.field);
  const [operator, setOperator] = useState(rule.operator);
  const [values, setValues] = useState(rule.picking_list_preset_rule_values.map(v => v.value));

  let fixedOptions = [];
  if(field === 'order_shop_channel_id'){
    fixedOptions = props.shopChannels.map(channel => ({label: channel.code, value: channel.id}));
  }
  if(field === 'order_shipping_type'){
    fixedOptions = props.shipping_types.map(type => ({label: type.name, value: type.code}));
  }



  return <div className="row" style={{paddingBottom: '5px'}}>

    <div className='col-md-1'>
      <a href="#"  onClick={()=>{
          props.onRemove();
        }
        }>
          <i className="fa fa-trash text-danger" ></i>
      </a>
    </div>
    <div className='col-md-3'>
      <select value={field} name="rules[]field" className='form-control' onChange={(e) => {
        setField(e.target.value)
        setValues([]);
        setOperator('');
      }}>
        <option value="">選擇欄位</option>
        {Object.entries(PresetFiels).map(([key, value]) => <option key={key} value={key}>{value}</option>)}
      </select>
    </div>
    <div className="col-md-2">
      <select value={operator} name="rules[]operator" className='form-control' onChange={(e) => setOperator(e.target.value)}>
        <option value="">選擇條件</option>
        {
          valid_operators[field]?.map(operator => <option key={operator} value={operator}>{OperatorLabels[operator]}</option>)
        }
      </select>
    </div>
    <div className="col-md-6">
      {
        ['equal','greater_than_or_equal', 'less_than_or_equal'].includes(operator) ? <div>
          <input type="number" value={values[0]} name="rules[]values[]" required='required' className='form-control' onChange={(e) => setValues([e.target.value])} />
        </div> : null
      }
      {
        ['contains','not_contains','in','not_in'].includes(operator) ?
        <Select name="rules[]values[]"
        className='react-select-container'
        classNamePrefix="react-select"
        isMulti={true}
        creatable={fixedOptions.length === 0}
        placeholder="可輸入多個選項，按Enter鍵分隔"
        value={
          fixedOptions.length === 0 ? values.map(v => ({label: v, value: v})) : values.map(v => fixedOptions.find(o => `${o.value}` === `${v}`))
        }
        options={fixedOptions}
        required='required'
        createOption={inputValue => ({ label: inputValue, value: inputValue })}
        onChange={(value) => {
          setValues(value.map(v=>v.value));
        }}></Select> : null
      }
    </div>
  </div>
}