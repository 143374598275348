import React, { useState, useMemo, useContext, createContext } from 'react';

const RulesContext = createContext();

const toOption = (r) => ({ ...r, value: r.id, label: r.title });



export default function RulesProvider({ children }) {
  const [rulesInputNames, setRulesInputNames] = useState([]);


  const addRule = (newRule) => {
    if (rulesInputNames.includes(newRule)) {
      return
    }
    setRulesInputNames((r) => [...r, newRule]);
  };

  const updateRule = (oldRule, newRule) => {
    const nextRules = rulesInputNames.map(r => r === oldRule.inputName ? newRule.inputName : r)
    setRulesInputNames(nextRules);
  };

  const removeRule = (rule) => {
    const nextRules = rulesInputNames.filter(r => r !== rule.inputName)
    setRulesInputNames(nextRules);
  };

  const dependencies = {
    rulesInputNames,

    addRule,
    updateRule,
    removeRule,
  };


  const value = useMemo(() => dependencies, Object.values(dependencies));

  return <RulesContext.Provider value={value}>{children}</RulesContext.Provider>;
}

export const useRulesContext = () => {
  return useContext(RulesContext);
};
