import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import * as FinishedGoodsOrderActions from '../../../actions/admin/processing_order/form'
import _ from "lodash";

import AsyncSelect from 'react-select/async'

import BaseSelect from "react-select";
import FixRequiredSelect from "../../common/fix_react_select";

const Select = props => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options || options}
  />
);

class Product extends Component {
  constructor(props) {
    super(props)
    this.remove = this.remove.bind(this)
    this.handleItemAdd = this.handleItemAdd.bind(this)
    this.onProductSelect = this.onProductSelect.bind(this)
    this.onStorageSelect = this.onStorageSelect.bind(this)
    this.copyToTarget = this.copyToTarget.bind(this)
    this.onQuantityChange = this.onQuantityChange.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.debouncedLoadOptions = _.debounce(this.loadOptions, 500);
    this.state = {
      items: this.props.items,
    }
  }
  componentDidMount() {
    if (!!this.props.shop) {
      this.fetchDefaultProducts()
    }
  }
  fetchDefaultProducts() {
    this.fetchProducts()
      .then(data => {
        let shop_products = data.products.map(product=>this.toOption(product))
        this.props.onSyncProducts(shop_products)
      })
  }
  async fetchProducts(inputValue = "") {
    return await fetch(`/admin/products.json?search=${inputValue}&product_only=true&limit=50`,
    {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token,
      },
      credentials: 'same-origin'
    }).then(res => res.json())
  }
  toOption(product) {
    return { label: [product.barcode,product.uid, product.name].filter(Boolean).join("/"), value: product.id }
  }

  loadOptions(inputValue, callback){
    if(inputValue.length <2){
      callback([])
      return
    }
    this.fetchProducts(inputValue)
    .then(data => {
      let shop_products = this.props.shop_products
      let options = data.products.map(product=>this.toOption(product))
      for(let option of options){
        let found = false
        for(let shop_product of shop_products){
          if(shop_product.value == option.value){
            found = true
            break
          }
        }
        if(!found){
          shop_products.push(option)
        }
      }
      this.props.onSyncProducts(shop_products)
      callback(options)
    })
  };

  onProductSelect(id, label,key) {
    let items = this.state.items
    fetch(`/admin/products/${id}/product_storages.json`, {
      method: 'GET',
      credentials: 'same-origin'
    }).then(res => res.json())
      .then(res => {
        for (let item of items) {
          if (item.key == key) {
            item.product_id = id
            item.product_label = label
            item.product_storage_id = null
            item.product_storage_label = null
            item.storages = res.data.filter(s => s.available_pcs > 0)
            item.quantity = null
            break
          }
        }
        this.setState({ items: items })
      })
  }

  onStorageSelect(product_storage_id,label, key) {
    let items = this.state.items
    let expiration_date
    for (let item of items) {
      if (item.key == key) {
        item.product_storage_id = product_storage_id
        item.product_storage_label = label
        for(let storage of item.storages){
          if(product_storage_id == storage.id){
            expiration_date = storage.expiration_date
            item.product_storage_id = storage.id
            item.product_storage_type_id = storage.product_storage_type_id
            item.expiration_deadline = storage.expiration_date
            item.batch = storage.batch
            item.max_quantity = storage.available_pcs
          }
        }
        break
      }
    }
    this.setState({ items: items })
    // this.props.onItemExpirationDateChange(key,expiration_date)
  }

  copyToTarget(){
    let items_to_copy = this.state.items.map(item=>{
      let storage = item.storages.filter(e=>e.id == item.product_storage_id)[0] || {}
      return {
        key: Math.random(),
        product_id: item.product_id,
        product_label: item.product_label,
        product_storage_type_id: storage.storage_type_id || item.product_storage_type_id,
        quantity: item.quantity,
        batch: storage.batch || item.batch || "",
        expiration_date: storage.expiration_date || item.expiration_deadline || "",
      }
    })
    this.props.onCopyItem(items_to_copy)
  }

  onQuantityChange(quantity,key){
    let items = this.state.items
    for (let item of items) {
      if (item.key == key) {
        item.quantity = quantity
      }
    }
    this.setState({ items: items })
  }

  handleItemAdd() {
    let items = this.state.items
    items.push({ key: Math.random() })
    this.setState({ items: items })
  }
  remove(key) {
    let items = this.state.items.filter(e => e.key != key)
    // this.props.onItemExpirationDateChange(key,null)
    this.setState({ items: items })
  }

  render() {
    const { items, shop } = this.props
    if (!shop) {
      return (
        <div className="col-md-12"></div>
      )
    }
    return (
      <div className="col-md-12">
        <div className="panel">
          <div className="panel-heading">
            <span className="panel-title">來源品項</span>
            <div className="panel-heading-controls">
              <a className='btn btn-info btn-sm' onClick={this.copyToTarget}>複製至目標品項</a>
            </div>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th className="col-md-4 ">商品 <span style={{color: 'red'}}>*</span></th>
                <th className="col-md-4 ">倉別/效期/批號 <span style={{color: 'red'}}>*</span></th>
                <th className="col-md-3 ">數量 <span style={{color: 'red'}}>*</span></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {
                this.state.items.map(item => {
                  let select_value = null
                  if (!!item.product_storage_label) {
                    select_value = {value: item.product_storage_id, label: item.product_storage_label}
                  }
                  else if (!!item.product_storage_type_id) { // EDIT
                    let _storage = item.storages.find(storage => (
                      (storage.product_storage_type_id == item.product_storage_type_id)
                        && (storage.expiration_date == item.expiration_deadline)
                        && ((storage.batch == null && item.batch == "指定無批號") || (storage.batch == item.batch))
                    ))
                    if (!!_storage)
                      select_value = {value: _storage.id, label: [_storage.storage_type, _storage.expiration_date, _storage.batch].filter(Boolean).join("/")}
                  }
                  return (
                    <tr key={item.key}>
                      <td>
                        <div>
                          <AsyncSelect name="finished_goods_order[products][][id]"
                            className='react-select-container'
                            classNamePrefix="react-select"
                            value={item.product_id ? {value: item.product_id ,label: item.product_label} : null}
                            searchable={true}
                            clearable={false}
                            required={true}
                            onChange={e => this.onProductSelect(e.value,e.label, item.key)}
                            placeholder="請選擇商品"
                            noOptionsMessage={() => '找不到商品，請輸入兩碼以上關鍵字'}
                            loadOptions={(inputValue,callback) => this.debouncedLoadOptions(inputValue,callback)}
                            defaultOptions={
                              [...this.props.shop_products, {label: "輸入兩碼以上關鍵字查看更多..." ,value: undefined, isDisabled: true}]
                            }
                          />

                        </div>
                      </td>
                      <td>
                        <div>
                          <Select name="finished_goods_order[products][][storage_id]"
                            className='react-select-container'
                            classNamePrefix="react-select"
                            value={select_value}
                            searchable={true}
                            clearable={false}
                            required={true}
                            placeholder="請選擇效期"
                            onChange={e => this.onStorageSelect(e.value,e.label, item.key)}
                            options={
                              item.storages ? item.storages.map(storage => { return { label: [storage.storage_type, storage.expiration_date, storage.batch].filter(Boolean).join("/"), value: storage.id } }).sort((a,b)=>{
                                return a.label.localeCompare(b.label)
                              })
                                : []
                            }
                          />
                        </div>
                      </td>
                      <td>
                        <div>
                          <input type='number' name='finished_goods_order[products][][quantity]' className='form-control' min='0' max={item.max_quantity} placeholder={item.max_quantity != null ? `最多可選取${item.max_quantity}` : '請先選擇倉別/效期/批號'} required="required" value={item.quantity || ""} onChange={e => this.onQuantityChange(e.target.value, item.key)} />
                        </div>
                      </td>
                      <td>
                        <a className="btn btn-danger" onClick={() => this.remove(item.key)}>X</a>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          <a className="btn btn-success btn-block" onClick={this.handleItemAdd}>+ 新增品項</a>
        </div>
      </div>
    )
  }
}

Product.propTypes = {
  items: PropTypes.array.isRequired,
}

// Redux connect
const mapStateToProps = (state) => {
  return {
    items: state.AdminFinishedGoodsOrderForm.product_items,
    shop: state.AdminFinishedGoodsOrderForm.shop,
    shop_products: state.AdminFinishedGoodsOrderForm.shop_products
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(FinishedGoodsOrderActions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Product)
