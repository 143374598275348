import { combineReducers } from 'redux'
import AdminBundleForm from './admin/bundle/form'
import AdminOrderForm from './admin/order/form'
import AdminOrderImportForm from './admin/order_import/form'
import ReceiptForm from './common/receipt/form'
import AdminFinishedGoodsOrderForm from './admin/processing_order/form'

const rootReducer = combineReducers({
  AdminBundleForm,
  AdminOrderForm,
  AdminOrderImportForm,
  ReceiptForm,
  AdminFinishedGoodsOrderForm
})

export default rootReducer
