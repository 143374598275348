import React, {useEffect,useState,useMemo} from 'react';
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import * as FinishedGoodsOrderActions from '../../../actions/operator/finished_goods_order/form'

import BaseSelect from "react-select";
import FixRequiredSelect from '../../common/fix_react_select'


const Select = props => (
  <FixRequiredSelect
    {...props}
    SelectComponent={BaseSelect}
    options={props.options || options}
  />
);


function ShopChannelSelect({shop,defaultChannel}) {

  const [shopChannels, setShopChannels] = useState([])
  const [shopChannel, setShopChannel] = useState(defaultChannel || "")

  useEffect(()=>{
    let authenticity_token = document.querySelector('meta[name="csrf-token"]').content
    if(shop){
      fetch(`/operator/shop_channels.json?shop_id=${shop}`,
      {
        method: 'GET',
        headers: {
          'X-CSRF-Token': authenticity_token,
        },
        credentials: 'same-origin'
      }).then(res => res.json()).then(data=>{
        setShopChannels(data)
      })
    }
  }, [shop])

  useEffect(()=>{
    setShopChannel(shopChannel || (shopChannels[0]?.code || ""))
  }, [shopChannels])

  return <div>
    <Select name='shop_channel_id' options={shopChannels.map(channel=>{
      return {value: channel.code, label: channel.code}
    })}
    name='finished_goods_order[shop_channel]'
    onChange={(e)=>setShopChannel(e.value)}
    value={{value: shopChannel,label: shopChannel}} />
  </div>

}

// Redux connect
const mapStateToProps = (state) => {
  return {
    shop: state.OperatorFinishedGoodsOrderForm.shop,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(FinishedGoodsOrderActions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ShopChannelSelect)
