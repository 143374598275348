import React, { Component } from 'react'
import OnShelf from './on_shelf'
import Switch from "react-switch";


export default class Form extends Component {
  constructor(props){
    super(props)
    let pcs_per_pallet = this.props.product.pcs_per_pallet
    let to_received_pcs = this.props.receipt_item.verified_pcs  ?
      this.props.receipt_item.verified_pcs - this.props.receipt_item.received_pcs :
      this.props.receipt_item.scheduled_pcs
    if(pcs_per_pallet === 0){
      pcs_per_pallet = to_received_pcs
    }
    this.state = {
      partial_switch: false,
      partial_verify: false,
      receipt_item: this.props.receipt_item,
      expiration_date: this.props.receipt_item.expiration_date || '',
      batch: this.props.receipt_item.batch || '',
      verified_pcs: this.props.receipt_item.verified_pcs
        || this.props.receipt_item.scheduled_pcs,
      to_received_pcs: to_received_pcs,
      pcs_per_pallet: pcs_per_pallet,
      pallet_count: Math.ceil(to_received_pcs/pcs_per_pallet) > 100 ? 100 : Math.ceil(to_received_pcs/pcs_per_pallet),
    }
    this.onChangePalletCount = this.onChangePalletCount.bind(this)

  }
  handleExpirationDateChange = (event) => {
    let receipt_item = this.state.receipt_item;
    receipt_item.expiration_date = event.target.value;
    this.setState({ expiration_date: event.target.value,
      receipt_item: receipt_item });
  }
  handleBatchChange = (event) => {
    let receipt_item = this.state.receipt_item;
    receipt_item.batch = event.target.value;
    this.setState({ batch: event.target.value,
      receipt_item: receipt_item });
  }
  handleVerifiedPcsChange = (event) => {
    if(event.target.value < this.props.receipt_item.scheduled_pcs){
      this.setState({partial_switch: true})
    }else{
      this.setState({partial_switch: false,partial_verify: false})
    }

    this.setState({
      verified_pcs: event.target.value,
      to_received_pcs: event.target.value-this.props.receipt_item.received_pcs
    });
  }

  onChangePalletCount(pallet_count){
    this.setState({pallet_count})
  }


  render() {
    const { product, product_storage_type, receipt,
      receipt_item, authenticity_token } = this.props
    if (receipt_item.verifier_id !== null &&
      receipt_item.verified_pcs === receipt_item.received_pcs) {
      return (<div className="row">本商品已完成驗收上架</div>)
    }
    else {
      return (
        <div>
          <div className="row">
            <div className="col-md-12">
              <div className="panel">
                <table className="table">
                  <thead>
                    <tr>
                      <th className='text-center'>品名</th>
                      <th className='text-center'>品號</th>
                      <th className='text-center'>倉別</th>
                      <th className='text-center'>效期</th>
                      <th className='text-center'>批號</th>
                      <th className='text-center'>預計入庫(PCS)</th>
                      <th className='text-center'>
                        驗收數量(PCS)
                        <span style={{color: 'red'}}>*</span>
                      </th>
                      {
                        this.state.partial_switch && <th>分批驗收</th>
                      }
                      <th className='text-center'>已上架(PCS)</th>
                      <th className='text-center'>待上架(PCS)</th>
                      <th className='text-center'>板數</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='text-center'>
                        <a href={"/operator/products/"+product.id}>
                          {product.name}
                        </a>
                      </td>
                      <td className='text-center'>
                        {product.uid}
                      </td>
                      <td className='text-center'>
                        {product_storage_type.full_name}
                      </td>
                      <td className='text-center' width="10%">
                        { product.valid_period
                            ? <input className="form-control" type="date"
                                value={this.state.expiration_date}
                                onChange={this.handleExpirationDateChange.bind(this)}/>
                            : null
                        }
                      </td>
                      <td className='text-center' width="10%">
                        <input className="form-control" type="string"
                          value={this.state.batch}
                          onChange={this.handleBatchChange.bind(this)}/>
                      </td>
                      <td className='text-center'>
                        {receipt_item.scheduled_pcs}
                        <br/>
                        <label className="label label-primary label-outline">
                          {receipt_item.box_count+"箱"}
                        </label>
                      </td>
                      <td className='text-center' width="10%">
                        <input className="form-control" type="numeric" min="0"
                          value={this.state.verified_pcs}
                          onChange={this.handleVerifiedPcsChange.bind(this)}/>
                      </td>
                      {
                        this.state.partial_switch && <td>
                          <Switch onChange={(checked)=>{
                            this.setState({partial_verify: checked})
                          }} checked={this.state.partial_verify}/>
                        </td>
                      }
                      <td className='text-center'>
                        {this.props.receipt_item.received_pcs}
                      </td>
                      <td className='text-center' width="10%">
                        {this.state.to_received_pcs}
                      </td>
                      <td className='text-center'>
                        <input className="form-control" type="numeric" min="1" value={this.state.pallet_count} onChange={(e)=>{
                          if(parseInt(e.target.value) > 500){
                            e.target.value = 500
                            notyError("板數不可超過500")
                          }
                          this.setState({pallet_count: e.target.value})
                        }}>
                        </input>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <OnShelf
            product={product}
            product_storage_type={product_storage_type}
            receipt={receipt}
            receipt_item={this.state.receipt_item}
            authenticity_token={authenticity_token}
            pcs_per_pallet={this.state.pcs_per_pallet || 1}
            palletCount={this.state.pallet_count}
            onChangePalletCount={this.onChangePalletCount}
            verified_pcs={this.state.verified_pcs}
            to_received_pcs={this.state.to_received_pcs}
            partial_verify={this.state.partial_verify}
          />
        </div>
      )
    }
  }
}
