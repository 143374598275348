import React, { Component } from 'react'
import ShopProductsSelect from '../../../components/common/shop_products_select'

export default class OrderForm extends Component {
  constructor(props) {
    super(props)
    this.onShopSelect = this.onShopSelect.bind(this)
    this.state = {
      shop_products: [],
    }
  }

  componentDidMount(){
    let shop_select =document.getElementsByName("order[shop_id]")[0]
    let shop_id = shop_select[0].value
    if(shop_id){
      this.onShopSelect(shop_id)
    }
    window.shop_picker = window.shop_picker || {}
    shop_picker.onShopSelect = this.onShopSelect
  }

  onShopSelect(shop_id) {
    this.setState({ shop_id: shop_id})
  }

  render() {
    return (
      <div>
        {
          this.state.shop_id ?
            <ShopProductsSelect 
              original_products={[]}
              shop_id={this.state.shop_id}
            /> : ''
        }

      </div>
    )
  }
}
