import React, { Component } from 'react'
import Select from 'react-select'

export default class Shyangyih extends Component {
  render() {
    let { shipping_account_type, shipping_account } = this.props

    return (
      <>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>客代</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][customer_code]`} defaultValue={shipping_account.customer_code}/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>名稱</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][customer_name]`} defaultValue={shipping_account.customer_name}/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>帳號</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][cust_id]`} defaultValue={shipping_account.cust_id}/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>密碼</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][check_word]`} defaultValue={shipping_account.check_word}/>
          </div>
        </div>
      </>
    )
  }
}