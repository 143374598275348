import React , { useState,useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { useRecordContext } from './Context';
import SelectAll from './SelectAll';
import SubHeader from './SubHeader';
import Loader from './Loader';

export default function Table({ selectCheckboxEnabled,columns,columnsEditable,tableId,actions}) {
  const { records, recordsFiltered, query, selected, setQuery ,submitStatus} = useRecordContext();
  const { onSelectRecord, onSelectAllRecords } = useRecordContext();



  const [headerColumns, setHeaderColumns] = useState(
    columns.map(column => ({
      ...column,
      visible: true
    }))
  );


  const selectAllHeader = {
    name: <SelectAll />,
    width: '25px',
    sortable: false,
    compact: true,
    persist: true,
    cell: ({ id }) => (
      <label className='custom-control custom-checkbox' htmlFor={`SelectItem-${id}`}>
        <input
          id={`SelectItem-${id}`}
          className='custom-control-input'
          key={id}
          type="checkbox"
          checked={selected.has(id)}
          onChange={() => onSelectRecord(id)}
        />
        <span className='custom-control-indicator'></span>
        &nbsp;
      </label>
    ),
    visible: true
  }


  const handleChangePageSize = (page_size) => {
    setQuery((query) => ({ ...query, page: 1, page_size }));
  };

  const handleChangePage = (page) => {
    setQuery((query) => ({ ...query, page }));
  };

  const handleSort = (selectColumn, direction, rows) => {
    setQuery((query) => ({ ...query, sorting_direction: direction,sorting_field:selectColumn.sortFieldName }));
  };

  const onColumnsChange = (columns) => {
    setHeaderColumns(columns)
  };


  return (
    <>
      <SubHeader
        selected={selected}
        onSelectAllRecords={onSelectAllRecords}
        recordsFiltered={recordsFiltered}
        actions={actions}
        headerColumns={headerColumns}
        onColumnsChange={onColumnsChange}
        columnsEditable={columnsEditable}
        tableId={tableId}
      />
      <div className="ibox-content dataTable-container">
        <DataTable
          style={{color:'red'}}
          fixedHeader
          noHeader
          columns={
            selectCheckboxEnabled ? [selectAllHeader, ...headerColumns.filter(column => column.persist ||column.visible)] : headerColumns.filter(column => column.visible)
          }
          data={records}
          pagination
          paginationServer
          progressPending={submitStatus}
          progressComponent={<Loader />}
          paginationDefaultPage={query.page || 1}
          paginationTotalRows={recordsFiltered}
          subHeader={false}
          persistTableHead
          selectableRowsVisibleOnly // 全選時，調整為只選取當前頁面上的產品
          paginationPerPage={query.page_size || 10}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangePageSize}
          onSort={handleSort}
          paginationRowsPerPageOptions={[10, 20, 30, 50,100, 200]}
          paginationComponentOptions={{
            rowsPerPageText: '每頁筆數',
            rangeSeparatorText: '共',
          }}

        />
      </div>
    </>
  );
}
