import React from 'react'


const DaChain = ({shipping_account_title, shipping_account_type, shipping_account}) => {
  return (
    <>
      <div className="form-group">
        <label className="col-md-3 control-label">
          <label>帳號</label>
        </label>
        <div className="col-md-6">
          <input className="form-control" name={`shipping_accounts[${shipping_account.id}][cust_id]`} defaultValue={shipping_account.cust_id}/>
        </div>
      </div>
      <div className="form-group">
        <label className="col-md-3 control-label">
          <label>密碼</label>
        </label>
        <div className="col-md-6">
          <input className="form-control" name={`shipping_accounts[${shipping_account.id}][check_word]`} defaultValue={shipping_account.check_word}/>
        </div>
      </div>
      <div className="form-group">
        <label className="col-md-3 control-label">
          <label>AES KEY</label>
        </label>
        <div className="col-md-6">
          <input className="form-control" name={`shipping_accounts[${shipping_account.id}][aes_key]`} defaultValue={shipping_account.aes_key}/>
        </div>
      </div>
      <div className="form-group">
        <label className="col-md-3 control-label">
          <label>AES IV</label>
        </label>
        <div className="col-md-6">
          <input className="form-control" name={`shipping_accounts[${shipping_account.id}][aes_iv]`} defaultValue={shipping_account.aes_iv}/>
        </div>
      </div>
    </>
  )
}


export default DaChain