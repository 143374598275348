import React, { Component } from 'react'
import ShopProducts from '../../../components/common/shop_products'

export default class OrderEdit extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <ShopProducts {...this.props}/> 
    )
  }
}
