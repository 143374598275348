import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import DataTable from 'react-data-table-component';


export default function BatchRetryEcLabels(props) {

  const { shops } = props
  const [shopId, setShopId] = useState(null)
  const [shopChannels, setShopChannels] = useState([])
  const [shopChannelId, setShopChannelId] = useState(null)
  const [orders, setOrders] = useState([])
  const [filterText, setFilterText] = useState('');
  const [sentOrders, setSentOrders] = useState({})

  const filteredOrders = orders.filter(
    item => !sentOrders[item.id] && item.name && item.name.toLowerCase().includes(filterText.toLowerCase()),
  );


  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className='row'>
        <div className='col-md-12'>
          <input className='form-control' placeholder='搜尋訂單' onChange={
            (e) => {
              setFilterText(e.target.value)
            }
          } />
        </div></div>
    );
  }, [filterText]);

  useEffect(() => {
    if (shopId) {
      fetchShopChannels()
    }
  }, [shopId])

  useEffect(() => {
    if (shopChannelId) {
      fetchEcOrders()
    }
  }, [shopChannelId])


  function fetchEcOrders() {
    fetch(`/operator/orders/ec_orders.json?shop_channel_id=${shopChannelId}`).then((response) => {
      return response.json()
    }).then((json) => {
      setOrders(json.map(([order_id, order_name]) => {
        return {
          checked: true,
          id: order_id,
          name: order_name
        }
      }))
    })
  }
  function fetchShopChannels() {
    fetch(`/operator/shop_channels.json?shop_id=${shopId}&ec_only=true`).then((response) => {
      return response.json()
    }).then((json) => {
      setShopChannels(json)
    })
  }
  function handleShopChange(event) {
    setShopId(event.value)
  }
  function handleShopChannelChange(event) {
    setShopChannelId(event.value)
  }

  return <div>
    <div className='row'>
      <div className='col-md-6'>
        <div className='form-group'>
          <label className='col-sm-3 control-label'>客戶</label>
          <div className='col-sm-9'>
            <Select
              name='shop_id'
              options={shops.map((shop) => { return { value: shop.id, label: shop.name } })}
              onChange={handleShopChange}
              clearable={false}
            />
          </div>
        </div>
      </div>
      <div className='col-md-6'>
        <div className='form-group'>
          <label className='col-sm-3 control-label'>通路</label>
          {shopId ? <div className='col-sm-9'>
            <Select
              name='shop_channel_id'
              options={shopChannels.map((shopChannel) => { return { value: shopChannel.id, label: shopChannel.code } })}
              onChange={handleShopChannelChange}
              clearable={false}
            />
          </div> : <div className='col-sm-9'>請先選擇客戶</div>}
        </div>
      </div>
    </div>
    <br></br>

    <div className='row '>
      <div className='form-group col-md-12'>
        <label className='col-sm-3 control-label'>共選取 {filteredOrders.filter(order => order.checked).length} 筆訂單</label>
        <div className='col-md-3'>
          {
            filteredOrders.filter(order => order.checked).length > 0 &&
            <button className='btn btn-primary' onClick={
              () => {
                if (confirm('確定要重新取號嗎?')) {
                  let orderIds =  filteredOrders.filter(order => order.checked).map(order => order.id)
                  fetch(`/operator/orders/do_batch_retry_ec_labels.json`, {
                    method: 'POST',
                    headers: {
                      'Content-Type': 'application/json',
                      'X-CSRF-Token': document.querySelector("meta[name=csrf-token]").content
                    },
                    body: JSON.stringify({
                      orders: orderIds
                    })
                  }).then((response) => {
                    return response.json()
                  }).then((json) => {
                    if(json.success){
                      let _sentOrders = Object.assign({}, sentOrders)

                      orderIds.forEach((orderId) => {
                        _sentOrders[orderId] = true                        
                      })
                      setSentOrders(_sentOrders)
                      notyMessagePersist(json.message)
                    }else{
                      notyError(json.message)
                    }
                  })
                }
              }
            }>重新取號</button>
          }
        </div>

      </div>

    </div>
    <div className='row'>
      <div className='col-md-12'>
        <DataTable
          columns={
            [
              {
                name: <span>
                  <input type="checkbox" onChange={
                    (e) => {
                      let checked = e.target.checked
                      setOrders(orders.map((order) => {
                        order.checked = checked
                        return order
                      }))
                    }
                  }
                    checked={orders.length > 0 && orders.filter((order) => order.checked).length == orders.length}
                  />
                </span>,
                sortable: false,
                cell: (row) => {
                  return (
                    <input type="checkbox"
                      checked={row.checked}
                      onChange={
                        (e) => {
                          let checked = e.target.checked
                          setOrders(orders.map((order) => {
                            if (order.id == row.id) {
                              order.checked = checked
                            }
                            return order
                          }))
                        }
                      }
                    />
                  )
                }
              },
              {
                name: '單號',
                sortable: true,
                selector: (row, i) => row.name,
              }
            ]}
          data={filteredOrders}
          pagination={false}
          subHeader
          subHeaderComponent={subHeaderComponentMemo}
          persistTableHead
        />
      </div>
    </div>
  </div>

}