
import React from 'react'
const CATEGORY = {
  invoice: '#發票',
  order: '#訂單',
  system: '#系統公告',
  shipment: '#出貨',
  receipt: '#進倉',
  return_order: '#退貨',
  payment: '#金流',
  stock: '#庫存',
}

class NotifyMessageIndex extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      messages: [],
      category_filter: null,
      searchKeyword: ''
    }
    this.fetchMessages = this.fetchMessages.bind(this)
    this.setRead = this.setRead.bind(this)
    this.setAllRead = this.setAllRead.bind(this)
    this.setReadApi = this.setReadApi.bind(this)
    this.categories = this.categories.bind(this)
  }

  fetchMessages() {
    fetch('/admin/notify_messages.json').then(res => res.json()).then(data => {
      this.setState({ messages: data })
    })
  }
  componentDidMount() {
    this.fetchMessages()
  }
  async setReadApi(ids) {
    return new Promise((resolve, reject) => {
      fetch('/admin/notify_messages/set_read', {
        method: 'POST',
        body: JSON.stringify({ ids: ids }),
        headers: {
          'X-CSRF-Token': this.props.authenticity_token,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        credentials: 'same-origin'
      }).then(()=>{
        resolve()
      })
    })
  }

  setRead(id) {
    let { messages } = this.state
    for (let message of messages) {
      if (message.id == id) {
        message.is_read = true
      }
    }
    this.setReadApi([id]).then(()=>{
      this.fetchMessages()
    })
  }

  setAllRead() {
    let { messages } = this.state
    for (let message of messages) {
      message.is_read = true
    }
    messages = messages.filter(message => this.state.category_filter ? message.category == this.state.category_filter : true)
    .filter(message => this.state.searchKeyword ? message.title.includes(this.state.searchKeyword) || message.content.includes(this.state.searchKeyword) : true)
    this.setState({ messages: messages })
    this.setReadApi(messages.map(message => message.id)).then(()=>{
      this.fetchMessages()
    })
  }

  categories() {
    let categories = new Set(this.state.messages.map(message => message.category))
    return <div className='btn-group'>
      {[...categories].map(category => <a key={category} className='btn btn-outline btn-default' onClick={() => {
        this.setState({ category_filter: this.state.category_filter == category ? null : category })
      }}>{CATEGORY[category]}</a>)}
    </div>
  }

  render() {
    return (
      <div className="panel panel-success">
        <div className="panel-heading">
          <h5><i className="fa fa-envelope-o"></i> 您有{this.state.messages.length}則新訊息</h5>
          {this.state.messages.length > 0 ?
            <div className='pull-right'>
              <a className='btn btn-primary' onClick={this.setAllRead}>一鍵已讀</a>
            </div> : ''
          }
          <br />
          <div className='row'>
            <div className='col-md-3'>
              <input className='form-control' value={this.state.searchKeyword} placeholder='搜尋關鍵字' onChange={(e)=>{
                this.setState({searchKeyword: e.target.value})
              }}>
              </input>
            </div>
            <div className='col-md-9'>
              {this.categories()}
            </div>
          </div>
        </div>
        <div className="ps-block ps-container ps-theme-default ps-active-y">
          {
            this.state.messages.filter(message => this.state.category_filter ? message.category == this.state.category_filter : true)
              .filter(message => this.state.searchKeyword ? message.title.includes(this.state.searchKeyword) || message.content.includes(this.state.searchKeyword) : true)
              .map(message => {
              return <div className="widget-support-tickets-item" key={message.id}>
                <div className='col-md-11 col-lg-10'>
                  <small className="pull-right text-navy">{(new Date(message.created_at)).toLocaleString()}</small>
                  <strong dangerouslySetInnerHTML={{ __html: message.title }}></strong>
                  {message.is_raw_html ?
                      <div dangerouslySetInnerHTML={{
                        __html: message.content?.replaceAll("\n", "<br />") || ""
                      }}></div> :
                      <div>
                        {
                          message.content?.split("\n").map((line, index) => {
                            return <p key={index}>{line}</p>
                          })
                        }
                      </div>
                    }
                </div>
                <div className='col-md-1 col-lg-2'>
                  {
                    message.is_read ? '' :
                      <a className='btn btn-primary btn-block btn-outline' onClick={() => this.setRead(message.id)}>
                        設為已讀
                      </a>
                  }
                </div>
              </div>
            })
          }
        </div>
      </div>
    )
  }
}
export default NotifyMessageIndex