import React ,{useState,useEffect} from 'react'

import TableIndex,{handleSubmit} from '../../../components/common/table/TableIndex'

import {DateRange,MultipleValue,BooleanCheckbox,NumberRange} from '../../../components/common/table/Rules/component'


const columns = [
  {
    name: '單號',
    sortable: false,
    persist: true,
    sortable: true,
    sortFieldName: 'orders.name',
    width: '200px',

    cell: ({id,name,urgent}) => {
      let label = ''
      if(urgent){
        label = <span className='label label-danger'>急單</span>
      }
      return <a href={`/operator/orders/${id}`} target="_blank" >{name}{label}</a>
    },
  },
  {
    name: '額外單號',
    sortable: false,
    selector: ({ internal_name }) => internal_name,
  },
  {
    name: '客戶',
    sortable: false,
    selector: ({ shop_name }) => shop_name,
  },
  {
    name: '應出',
    sortable: false,
    selector: ({ total_pcs }) => total_pcs,
  },
  {
    name: '實出',
    sortable: false,
    selector: ({ shipped_pcs }) => shipped_pcs,
  },
  {
    name: '通路',
    sortable: false,
    selector: ({ channel_code }) => channel_code,
  },
  {
    name: '狀態',
    sortable: false,
    cell: ({status})=>{
      let className = ''
      switch(status){
        case '待處理':
          className = 'label label-success label-outline'
          break;
        case '作業中':
          className = 'label label-warning label-outline'
          break;
        case '已完成':
          className = 'label label-success'
          break;
        case '已取消':
          className = 'text-muted'
          break;

      }
      return <span className={className}>{status}</span>
    },
  },

  {
    name: '收件人',
    sortable: false,
    selector: ({ receiver_name }) => receiver_name,
  },
  {
    name: '物流',
    selector: ({shipping_type}) => shipping_type,
    sortable: true,
    sortFieldName: 'orders.shipping_type',

  },
  {
    name: '預約出貨日',
    sortable: true,
    sortFieldName: 'orders.scheduled_shipping_date',
    selector: ({ scheduled_shipping_date }) => scheduled_shipping_date,
  },
  {
    name: '預約到貨日',
    sortable: true,
    sortFieldName: 'orders.delivery_date',
    selector: ({ delivery_date }) => delivery_date,
  },
  {
    name: '備註',
    sortable: false,
    selector: ({ note }) => note,
  },
  {
    name: '建立時間',
    sortable: true,
    sortFieldName: 'orders.created_at',
    selector: ({ created_at }) => created_at,
  },
  {
    name: '結單時間',
    sortable: true,
    sortFieldName: 'orders.shipped_at',
    selector: ({ shipped_at }) => shipped_at,
  }
]


export default function OrderTableIndex({
    statusOptions,
    shippingTypes,
    shops
  }) {

  const shippingTypeOptions = shippingTypes.map(shippingType => ({
    label: shippingType.name,
    value: shippingType.code,
  }))
  const shopOptions = shops.map(shop => ({
    label: shop.name,
    value: shop.id,
  }))
  const [lastModified,setLastModified] = useState(0)

  const [shopIds,setShopIds] = useState([])



  const filterShopChannelOptions = (shopIds)=>{
    return shops.filter(shop=>{
      if(!shopIds || shopIds.length === 0 || shopIds.includes(shop.id)) return true
    }).map(shop=>{
      return shop.channels.map(channel=>{
        return {
          label: `${shop.name} | ${channel.code}`,
          value: channel.id,
        }
      })
    }).flat()
  }



  const supportRules = [
    {
      label: '客戶',
      component: MultipleValue,
      inputName: 'orders.shop_id',
      defaultOptions: shopOptions,
      defaultPinned: true,
      onChange: (value)=>{
        setShopIds(value.map(v=>v.value))
      },
      createable: false,
    },
    {
      label: '訂單單號',
      component: MultipleValue,
      inputName: 'orders.name',
      defaultPinned: true,
      createable: true,
    },
    {
      label: '額外單號',
      component: MultipleValue,
      inputName: 'orders.internal_name',
      createable: true,
    },
    {
      label: '訂單建立日',
      component: DateRange,
      defaultPinned: true,
      inputName: 'orders.created_at',
    },
    {
      label: '訂單完成日',
      component: DateRange,
      inputName: 'orders.shipped_at',
    },
    {
      label: '出倉日',
      component: DateRange,
      inputName: 'picking_lists.close_date',
    },
    {
      label: '預約到貨日',
      component: DateRange,
      inputName: 'orders.delivery_date',
    },
    {
      label: '預約出貨日',
      component: DateRange,
      inputName: 'orders.scheduled_shipping_date',
    },
    {
      label: '物流類型',
      component: MultipleValue,
      defaultOptions: shippingTypeOptions,
      createable: false,
      inputName: 'orders.shipping_type',
    },
    {
      label: '包含品項(SKU)',
      component: MultipleValue,
      createable: true,
      inputName: 'products.uid',
    },
    {
      label: '托運單號',
      component: MultipleValue,
      createable: true,
      inputName: 'shippings.tracking_number',
    },
    {
      label: '狀態',
      component: MultipleValue,
      defaultOptions: statusOptions,
      createable: false,
      inputName: 'orders.status',
    },
    {
      label: '收件人',
      component: MultipleValue,
      createable: true,
      inputName: 'orders.customer_name',
    },
    {
      label: '通路',
      component: MultipleValue,
      createable: false,
      inputName: 'orders.shop_channel_id',
      defaultOptions: filterShopChannelOptions(shopIds),
    },
    {
      label: '有備註',
      component: BooleanCheckbox,
      inputName: 'orders.note_exist',
    }
  ]


  return (
    <TableIndex columns={columns}
      selectCheckboxEnabled
      getRecordsUrl='orders/get_records.json'
      selectAllUrl='orders/get_all.json'
      searchable={true}
      columnsEditable={true}
      tableId='OperatorOrdersTable' //for columnsEditable
      defaultQuery={{
        sorting_field: 'id',
        sorting_direction: 'desc',
      }}
      supportRules={supportRules}
      lastModified={lastModified}
      actions={[
        {
          name: '批次復原',
          className: 'btn btn-primary btn-outline mb-0',
          do: (selected) => {
            if(confirm(`確定要復原 ${selected.size} 筆訂單嗎?`)){
              handleSubmit('/operator/orders/batch_resume.json',selected,{},()=>{
                setLastModified(Date.now())
              })
            }
          }
        },
        {
          name: '批次取消',
          className: 'btn btn-danger btn-outline mb-0',
          do: (selected) => {
            if(confirm(`確定要取消 ${selected.size} 筆訂單嗎?`)){
              handleSubmit('/operator/orders/batch_cancel.json',selected,{},()=>{
                setLastModified(Date.now())
              })
            }
          }
        },
        {
          name: '檢查庫存',
          className: 'btn btn-primary btn-outline mb-0',
          do: (selected) => {
            handleSubmit('/operator/orders/batch_check.json',selected,{},()=>{
              setLastModified(Date.now())
            })
          }
        },
        {
          name: '匯出',
          className: 'btn btn-primary btn-outline mb-0',
          do: (selected) => {
            handleSubmit('/operator/orders/export.json',selected,{})
          }
        },
        {
          name: '匯出(分配效期)',
          className: 'btn btn-info btn-outline mb-0',
          do: (selected) => {
            handleSubmit('/operator/orders/export.json',selected,{export_order_product_storages: true})
          }
        }
      ]}
    />
  );
}