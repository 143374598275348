
import {ERROR_SOUND_1} from './sound_1';

import {ERROR_SOUND_2} from './sound_2'
import {ERROR_SOUND_3} from './sound_3'
import {ERROR_SOUND_4} from './sound_4'
import {ERROR_SOUND_5} from './sound_5'
import {ERROR_SOUND_6} from './sound_6'
import {ERROR_SOUND_7} from './sound_7'

export function playErrorSound(error_sound){
  let sound = ERROR_SOUND_1
  switch(error_sound){
    case 'ERROR_SOUND_2':
      sound = ERROR_SOUND_2 
      break
    case 'ERROR_SOUND_3':
      sound = ERROR_SOUND_3 
      break
    case 'ERROR_SOUND_4':
        sound = ERROR_SOUND_4
        break
    case 'ERROR_SOUND_5':
      sound = ERROR_SOUND_5
      break
    case 'ERROR_SOUND_6':
      sound = ERROR_SOUND_6
      break
    case 'ERROR_SOUND_7':
      sound = ERROR_SOUND_7
      break
  }
  var snd = new Audio("data:audio/wav;base64," + sound );
  snd.play();
}
