import {
  ORDER_IMPORT_SET_ATTACHMENT_ITEMS,
  ORDER_IMPORT_ADD_ATTACHMENT_ITEM, ORDER_IMPORT_DELETE_ATTACHMENT_ITEM,
  ORDER_IMPORT_SET_SUBMIT,
} from '../../../actions/action_types'

const initialState = { attachment_items: [], is_submit: false }

export default function AdminOrderImportForm(state = initialState, action) {
  switch (action.type) {
  case ORDER_IMPORT_SET_ATTACHMENT_ITEMS:
    return {
      ...state,
      attachment_items: action.attachment_items
    }
  case ORDER_IMPORT_ADD_ATTACHMENT_ITEM:
    const __item = {
      index: action.item.index,
    }
    const attachment_items = Object.assign({}, __item)

    return {
      ...state,
      attachment_items: [
        ...state.attachment_items,
        attachment_items,
      ]
    }
  case ORDER_IMPORT_DELETE_ATTACHMENT_ITEM:
    const _attachment_item = state.attachment_items.filter(item => item.index === action.item.index)[0]
    if (_attachment_item.id) {
      const __attachment_items = state.attachment_items.map((item, index) => {
        if (item.index == action.item.index) {
          item = {
            ...item,
            _destroy: 1
          }
        }
        return item
      })

      return {
        ...state,
        attachment_items: __attachment_items,
      }
    } else {
      return {
        ...state,
        attachment_items: state.attachment_items.filter(item => item.index !== action.item.index),
      }
    }
  case ORDER_IMPORT_SET_SUBMIT:
    return {
      ...state,
      is_submit: action.is_submit
    }
  default:
    return state
  }
}
