import React from 'react';

function BooleanCheckbox({inputName}) {
  return (
    <div className="input-group">
      <input type="hidden" value="1" name={inputName} />
    </div>
  );
}

export default BooleanCheckbox;
