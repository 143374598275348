import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import ProductItem from './product_item'
import * as ReceiptActions from '../../../actions/common/receipt/form'

class Product extends Component {
  handleItemAdd = () => {
    this.props.onProductItemAdd({
      index: +new Date(),
    })
  }

  render() {
    const { items } = this.props

    let product_items = items.map(function (item, index) {
      return <ProductItem key={index} item={item} index={item.index} operator_interface={this.props.operator_interface} shop_id={this.props.shop_id}/>
    }.bind(this))

    return (
      <div className="panel">
        <div className="panel-heading">
          <span className="panel-title">
            商品
          </span>
        </div>
        <div className='panel-body'>
          <table className="table">
            <thead>
              <tr>
                <th className="col-md-1"></th>
                <th className="col-md-3 text-center">
                  {'商品 '}
                  <span style={{color: 'red'}}>*</span>
                </th>
                <th className="col-md-1 text-center">
                  {'倉別 '}
                  <span style={{color: 'red'}}>*</span>
                </th>
                <th className="col-md-1 text-center">
                  {'箱入數 '}
                  <span style={{color: 'red'}}>*</span>
                </th>
                <th className="col-md-1 text-center">
                  {'箱數 '}
                  <span style={{color: 'red'}}>*</span>
                </th>
                <th className="col-md-2 text-center">
                  效期</th>
                <th className="col-md-2 text-center">
                  批號
                </th>
              </tr>
            </thead>
            <tbody>
              {product_items}
            </tbody>
          </table>
        </div>
        <div className='panel-footer text-right'>
          <a className="btn btn-success" onClick={this.handleItemAdd}>增加品項</a>
        </div>
      </div>
    )
  }
}

Product.propTypes = {
  products: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
}

// Redux connect
const mapStateToProps = (state) => {
  return {
    products: state.ReceiptForm.products,
    items: state.ReceiptForm.product_items,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(ReceiptActions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Product)
