import React, { useEffect } from 'react'


const AttachmentItem = ({attachment, deleteAttachmentItem}) => {

  const handleItemDelete = (attachment_id) => {
    deleteAttachmentItem(attachment_id)
  }

  useEffect(() => {
    $('.custom-file').pxFile()
  }, [])

  return (
    <tr className={attachment._destroy == 1 ? 'hidden' : ''}>
      <td className="text-center">
        <input name="finished_goods_order[attachments_attributes][][id]" type="hidden" value={attachment.id} />
        {
          attachment._destroy && <input name="finished_goods_order[attachments_attributes][][_destroy]" type="hidden" value={attachment._destroy} />
        }
        <a className="btn btn-danger btn-xs" onClick={() => handleItemDelete(attachment.id || attachment.index)}>刪除</a>
      </td>
      <td className="text-center">
        <div className="form-group m-a-0">
          {
            attachment.file ? <a className="" href={attachment.file.url} target="_blank">
              下載
            </a> :
            <label id="grid-input-6-file" className="custom-file px-file">
              <input name="finished_goods_order[attachments_attributes][][file]" className="custom-file-input" required="required" type="file"/>
              <span className="custom-file-control form-control">請選擇檔案</span>
              <div className="px-file-buttons">
                <button type="button" className="btn btn-xs px-file-clear">清除</button>
                <button type="button" className="btn btn-primary btn-xs px-file-browse">瀏覽</button>
              </div>
            </label>
          }
        </div>
      </td>
    </tr>
  )
}

AttachmentItem.defaultProps = {
  attachment: {},
  deleteAttachmentItem: () => {}
}


export default AttachmentItem;