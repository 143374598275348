import React, { Component } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import _ from "lodash";

export default class ReturnOrderProducts extends Component {
  constructor(props) {
    super(props)
    let { return_order_products } = this.props
    this.state = {
      return_order_products: return_order_products.map(return_order_product => {return_order_product.key = Math.random(); return return_order_product}),
      product_select_options: [],
      remove_product_ids: []
    }
    this.loadOptions = this.loadOptions.bind(this)
    this.debouncedLoadOptions = _.debounce(this.loadOptions, 500);
  }
  componentDidMount() {
    if (!!this.props.shop_id) {
      this.fetchDefaultProducts()
    }
  }
  fetchDefaultProducts() {
    this.fetchProducts()
      .then(data => {
        let shop_products = data.products.map(product=>this.toOption(product))
        this.setState({ product_select_options: shop_products })
      })
  }
  async fetchProducts(inputValue = "") {
    return await fetch(`/operator/products.json?shop_id=${this.props.shop_id}&search=${inputValue}&product_only=true&limit=50`,
    {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token,
      },
      credentials: 'same-origin'
    }).then(res => res.json())
  }

  loadOptions(inputValue, callback){
    if(inputValue.length <2){
      callback([])
      return
    }
    this.fetchProducts(inputValue)
    .then(data => {
      let product_select_options = this.state.product_select_options
      let options = data.products.map(product=>this.toOption(product))
      for(let option of options){
        let found = false
        for(let product_select_option of product_select_options){
          if(product_select_option.value == option.value){
            found = true
            break
          }
        }
        if(!found){
          product_select_options.push(option)
        }
      }
      this.setState({ product_select_options })
      callback(options)
    })
  };
  toOption(product) {
    return { label: (product.label || [product.barcode, product.uid, product.name].filter(Boolean).join("/")), value: (product.value || product.id) }
  }
  onProductSelect(option, key) {
    let { return_order_products } = this.state
    let selectProductIndex = return_order_products.findIndex(return_order_product => return_order_product.key == key)
    return_order_products[selectProductIndex].product = option
    this.setState({ return_order_products })
  }

  handleRemoveProduct(return_order_product) {
    let { return_order_products } = this.state
    let { remove_product_ids } = this.state
    remove_product_ids.push(return_order_product.id)
    this.setState({
      return_order_products: return_order_products.filter(p => p.key != return_order_product.key),
      remove_product_ids: remove_product_ids
    })
  }

  handleAddProduct() {
    let { return_order_products } = this.state
    return_order_products.push({
      quantity: 0,
      key: Math.random()
    })
    this.setState({ return_order_products })
  }

  render() {
    const { return_order_products, product_select_options } = this.state
    const { product_storage_types } = this.props

    let product_storage_types_options = product_storage_types.map((product_storage_type) => {
      return { value: product_storage_type.id, label: `${product_storage_type.name}` }
    })

    console.log(product_select_options)
    console.log(return_order_products)

    return (
      <table className='table'>
        <thead>
          <tr>
            <th></th>
            <th>商品</th>
            <th>倉別</th>
            <th>效期</th>
            <th>批號</th>
            <th className='col-md-2'>應收數量</th>
            <th className='col-md-2'>實收數量</th>
          </tr>
        </thead>
        <tbody>
          {return_order_products.map((return_order_product) => {
            return (
              <tr key={return_order_product.key}>
                <td>
                  <input type='hidden' name='return_order[return_order_products][][id]' value={return_order_product.id} />
                  <a href="#" onClick={this.handleRemoveProduct.bind(this, return_order_product)}>
                    <span className='fa fa-trash-o text-danger'></span>
                  </a>
                </td>
                <td>
                  <AsyncSelect
                    className='react-select-container'
                    classNamePrefix="react-select"
                    placeholder="請選擇商品"
                    name="return_order[return_order_products][][product_id]"
                    value={!!return_order_product.product ? this.toOption(return_order_product.product) : null}
                    onChange={e => this.onProductSelect(e, return_order_product.key)}
                    noOptionsMessage={() => '找不到商品，請輸入兩碼以上關鍵字'}
                    loadOptions={(inputValue,callback) => this.debouncedLoadOptions(inputValue,callback)}
                    defaultOptions={
                      [...product_select_options, {label: "輸入兩碼以上關鍵字查看更多..." ,value: undefined, isDisabled: true}]
                    }
                    searchable={true}
                    clearable={false}
                    required={true}
                  />
                </td>
                <td>
                  <Select
                    className='react-select-container'
                    classNamePrefix="react-select"
                    placeholder="請選擇倉別"
                    name="return_order[return_order_products][][product_storage_type_id]"
                    defaultValue={product_storage_types_options.find(p => p.value == return_order_product.product_storage_type_id)}
                    searchable={true}
                    clearable={false}
                    required={true}
                    options={product_storage_types_options}
                  />
                </td>
                <td>
                  <input type='date' className='form-control' name='return_order[return_order_products][][expiration_date]' max={'9999-01-01'} defaultValue={return_order_product.expiration_date} />
                </td>
                <td>
                  <input type='text' className='form-control' name='return_order[return_order_products][][batch]' defaultValue={return_order_product.batch} />
                </td>
                <td>
                  {return_order_product.quantity}
                </td>
                <td>
                  <input type='number' className='form-control' name='return_order[return_order_products][][received]' required='required' defaultValue={return_order_product.received} />
                </td>
              </tr>
            )
          })}
          <tr>
            <td colSpan='7'>
              <button type='button' className='btn btn-primary btn-block btn-outline' onClick={this.handleAddProduct.bind(this)}>
                <span className='fa fa-plus'></span>
              </button>
              {this.state.remove_product_ids.map((id) => {
                return <input type='hidden' name='return_order[remove_return_order_product_ids][]' value={id} key={id} />
              })}

            </td>
          </tr>
        </tbody>
      </table>

    )
  }
}
