import { pick } from 'lodash'
import React, { Component } from 'react'
import ReactToPrint from 'react-to-print';
import DataTable from 'react-data-table-component';

export default class HighLayer extends Component {
  constructor(props) {
    super(props)
    this.onShopSelected = this.onShopSelected.bind(this)
    this.state = {
      picking_lists: [],
      checked_lists: [],
      high_layer: [],
      checkedAll: true
    }
    this.reloadHighLayer = this.reloadHighLayer.bind(this)
    this.onDownloadClick = this.onDownloadClick.bind(this)
    this.onTgoggleCheckAll = this.onTgoggleCheckAll.bind(this)
  }

  onTgoggleCheckAll() {
    console.log('onToggleCheck')
    if (this.state.checkedAll) {
      this.setState({ checked_lists: [] })
    } else {
      this.setState({ checked_lists: this.state.picking_lists.map(p => p.id) })
    }
    this.setState({ checkedAll: !this.state.checkedAll })

  }

  onShopSelected(e) {
    let shop_id = e.target.value
    fetch(`/operator/picking_lists/by_shop.json?shop_id=${shop_id}`, {
      'credentials': 'same-origin',
      method: "GET",
    }).then(res => res.json()).then(data => {
      this.setState({ picking_lists: data.picking_lists, checked_lists: data.picking_lists.map(p => p.id) })
      this.reloadHighLayer(data.picking_lists.map(p => p.id))
    })
  }

  onPickingListChecked(checked, picking_list_id) {
    let checked_lists = this.state.checked_lists
    if (checked) {
      checked_lists.push(picking_list_id)
      this.setState({ checked_lists: checked_lists })
    } else {
      checked_lists = checked_lists.filter(e => e != picking_list_id)
      this.setState({ checked_lists: checked_lists })
    }
    this.reloadHighLayer(checked_lists)
  }

  reloadHighLayer(checked_lists) {
    console.log(checked_lists)
    fetch(`/operator/picking_lists/high_layer.json?picking_lists=${checked_lists.join(',')}`, {
      'credentials': 'same-origin',
      method: "GET",
    }).then(res => res.json()).then(data => {
      this.setState({ high_layer: data.data })
    })
  }

  onDownloadClick() {
    console.log('here!')
    fetch(`/operator/picking_lists/high_layer.xlsx?picking_lists=${this.state.checked_lists.join(',')}`, {
      'credentials': 'same-origin',
      method: "GET",
    }).then(res => res.blob()).then(blob => {
      let a = document.createElement("a")
      let blobURL = window.URL.createObjectURL(blob);
      a.download = '高空表.xlsx'
      a.href = blobURL
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
    });
  }

  render() {
    return <div className='col-md-12 form-horizontal'>
      <div className="form-group"><label className="col-md-2 control-label">客戶選擇</label><div className="col-md-4">
        <select name="" onChange={this.onShopSelected} defaultValue="please_select" className="form-control">
          <option value='please_select' disabled='disabled'>請選擇客戶</option>
          <option value="">全部</option>
          {
            this.props.shops.map(shop => {
              return <option value={shop.id} key={shop.id}>{shop.name}</option>
            })
          }
        </select>
      </div>
      </div>
      <div className="form-group">
        <label className="col-md-2 control-label">相關揀貨單</label>
        <div className="col-md-10">
          {
            this.state.picking_lists.map(picking_list => {
              return <label className="custom-control custom-checkbox checkbox-inline" key={picking_list.id}>
                <input type="checkbox" className="custom-control-input" onChange={(e) => this.onPickingListChecked(e.target.checked, picking_list.id)} checked={this.state.checked_lists.includes(picking_list.id)} />
                <span className="custom-control-indicator"></span>
                {picking_list.id}/
                {picking_list.channel}
              </label>
            })
          }
        </div>
      </div>
      <hr className='hr'></hr>
      {
        this.state.picking_lists.length > 0 ?
          <div className="form-group">
            <label className="col-md-2 control-label"></label>
            <div className="col-md-10">
              <label className="custom-control custom-checkbox checkbox-inline">
                <input type="checkbox" className="custom-control-input" onChange={(e) => this.onTgoggleCheckAll()} checked={this.state.checkedAll} />
                <span className="custom-control-indicator"></span>
                全選/全不選
              </label>
            </div>
          </div> : ''
      }


      <div className="row">

        <div className="col-md-12">
          <div className='pull-right'>
            <div className='btn-group'>
              <ReactToPrint
                trigger={() => {
                  // NOTE: could just as easily return <SomeComponent />. Do NOT pass an `onClick` prop
                  // to the root node of the returned component as it will be overwritten.
                  return <a href="#" className='btn btn-primary'>列印</a>;
                }}
                content={() => this.componentRef}
              />
              <a href='#' className='btn btn-info' onClick={this.onDownloadClick}>下載</a>
            </div>
          </div>
          <div ref={el => (this.componentRef = el)}>
            <DataTable
              columns={
                [
                  {
                    name: '儲位',
                    sortable: true,
                    selector: ({shelf_token}) => shelf_token,
                  },
                  {
                    name: '客戶',
                    sortable: true,
                    selector: ({shop}) => shop,
                  },
                  {
                    name: '品號',
                    sortable: true,
                    selector: ({product_uid}) => product_uid,
                  },
                  {
                    name: '商品',
                    sortable: true,
                    selector: ({product}) => product,
                  },
                  {
                    name: '條碼',
                    sortable: true,
                    selector: ({product_barcode}) => product_barcode,
                  },
                  {
                    name: '效期',
                    sortable: true,
                    selector: ({expiration_date}) => expiration_date,
                  },
                  {
                    name: '數量',
                    sortable: true,
                    selector: ({shelf_quantity}) => shelf_quantity,
                  },
                  {
                    name: '低位儲',
                    sortable: true,
                    selector: ({low_layer_shelf}) => low_layer_shelf,
                  }]
              }
              pagination={false}
              data={this.state.high_layer}
            ></DataTable></div>
        </div>
      </div>
    </div>
  }
}