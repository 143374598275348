import React, { Component } from 'react'

export default class ShopChannelRuleIndex extends Component {
  constructor(props) {
    super(props)
    this.state = {
      rules: this.props.rules.map(rule=>{
        rule.key = rule.id;
        return rule
      })
    }
    this.handleItemAdd = this.handleItemAdd.bind(this)
    this.removeItem = this.removeItem.bind(this)
    this.updateThreshold = this.updateThreshold.bind(this)
  }
  componentDidMount() {
  }

  handleItemAdd() {
    let rules = this.state.rules
    rules.push({ key: Math.random() })
    this.setState({ rules: rules })
  }
  removeItem(key) {
    let rules = this.state.rules.filter(e => e.key != key)
    this.setState({ rules: rules })
  }

  updateThreshold(key,value){
    let rules = this.state.rules
    let rule = rules.find(rule=>rule.key==key)
    rule.original_expiration_date_threshold = value
    this.setState({ rules: rules })

  }

  render() {
    const { rules } = this.state
    return (
      <div>
        {
          rules.map((rule,index) => {
            return <div key={rule.key}>
              <hr className='hr'></hr>
              <div className="form-group">
                <label className="col-md-2 control-label">
                  <a href='#!' onClick={()=>this.removeItem(rule.key)} className='btn btn-link text-danger'>
                  <i className='fa fa-trash'>
                  </i>
                    </a>
                  原始效期</label>
                <div className="col-md-2">
                  <div className="input-group">
                    <input className="form-control" type="number" required='required' onChange={(e)=>this.updateThreshold(rule.key,e.target.value)} value={rule.original_expiration_date_threshold || ''} name="rules[][original_expiration_date_threshold]"/>
                    <div className="input-group-addon">
                      {
                        rules[index+1] && rules[index+1].original_expiration_date_threshold ? `天 ~ ${rules[index+1].original_expiration_date_threshold - 1}天` : "天以上"
                      }
                    </div>
                  </div>
                </div>
                <label className="col-md-2 control-label">允收效期百分比</label>
                <div className="col-md-2">
                  <div className="input-group">
                    <input max="100" min="0" className="form-control" type="number" step='any' defaultValue={rule.expiration_ratio || 0} name="rules[][expiration_ratio]" />
                    <div className="input-group-addon">%</div>
                  </div>
                </div>
                <label className="col-md-2 control-label">允收效期天數</label>
                <div className="col-md-2">
                  <input className="form-control" type="number" defaultValue={rule.days_before_expiration || 0} name="rules[][days_before_expiration]"  />
                </div>
              </div>
              <div className="form-group">
                <div className='col-md-4'/>
                <label className="col-md-2 control-label">海外效期百分比</label>
                <div className="col-md-2">
                  <div className="input-group">
                    <input max="100" min="0" className="form-control" type="number" defaultValue={rule.oversea_expiration_ratio || 0} name="rules[][oversea_expiration_ratio]" />
                    <div className="input-group-addon">%</div>
                  </div>
                </div>
                <label className="col-md-2 control-label">海外效期天數</label>
                <div className="col-md-2">
                  <input className="form-control" type="number" defaultValue={rule.oversea_days_before_expiration || 0} name="rules[][oversea_days_before_expiration]"  />
                </div>
              </div>
            </div>
          })
        }

        <div className='form-group'>
          <div className='col-md-2'>
          </div>
          <div className='col-md-3'>
            <a className="btn btn-success btn-block btn-outline" onClick={this.handleItemAdd}>+ 新增額外規則</a>
          </div>
        </div>
        <hr className='hr'></hr>
      </div>
    )
  }
}
