import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AttachmentItem from './attachment_item'
import * as AdminOrderImportActions from '../../../actions/admin/order_import/form'

class Attachment extends Component {
  handleItemAdd = () => {
    this.props.onAttachmentItemAdd({
      index: +new Date(),
    })
  }

  render() {
    const { items, is_submit } = this.props

    let attachment_items = items.map(function (item, index) {
      return <AttachmentItem key={index} item={item} index={item.index}/>
    }.bind(this))

    let submit_html = ''
    if (is_submit) {
      submit_html = (
        <input type="submit" name="commit"
          value={`更新`}
          className="btn btn-default btn btn-primary btn-xs submit"
          data-disable-with={`更新`}
        />
      )
    }

    return (
      <div className="panel">
        <div className="panel-heading">
          <span className="panel-title">
            附件
          </span>
          <div className="panel-heading-controls">
            <a className="btn btn-success btn-xs"
              onClick={this.handleItemAdd}>
              {`新增`}
            </a>
            {submit_html}
          </div>
        </div>
        <table className="table">
          <tbody>
            {attachment_items}
          </tbody>
        </table>
      </div>
    )
  }
}

Attachment.propTypes = {
  items: PropTypes.array.isRequired,
}

// Redux connect
const mapStateToProps = (state) => {
  return {
    items: state.AdminOrderImportForm.attachment_items,
    is_submit: state.AdminOrderImportForm.is_submit,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(AdminOrderImportActions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Attachment)
