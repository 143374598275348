import React, { Component } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AttachmentItem from './attachment_item'
import * as AdminOrderActions from '../../../actions/admin/order/form'

class Attachment extends Component {
  handleItemAdd = () => {
    this.props.onAttachmentItemAdd({
      index: +new Date(),
    })
  }

  render() {
    const { items } = this.props

    let attachment_items = items.map(function (item, index) {
      return <AttachmentItem key={index} item={item} index={item.index}/>
    }.bind(this))

    return (
      <div className="panel">
        <div className="panel-heading">
          <span className="panel-title">附件</span>
          <div className="panel-heading-controls">
            <a className="btn btn-success btn-xs" onClick={this.handleItemAdd}>新增</a>
          </div>
        </div>
        <table className="table">
          <thead>
            <tr>
              <th className="col-md-1"></th>
              <th className="col-md-11 text-center">附件</th>
            </tr>
          </thead>
          <tbody>
            {attachment_items}
          </tbody>
        </table>
      </div>
    )
  }
}

Attachment.propTypes = {
  items: PropTypes.array.isRequired,
}

// Redux connect
const mapStateToProps = (state) => {
  return {
    items: state.AdminOrderForm.attachment_items,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(AdminOrderActions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(Attachment)
