import React, { Component } from 'react'
import Select from 'react-select'

const Version = {
  LEGACY: "legacy",
  API: "api"
}

export default class Hct extends Component {
  constructor(props) {
    super(props)
    this.state = {
      version: Version["API"]
    }
    this.updateVersion = this.updateVersion.bind(this)
  }
  componentDidMount() {
    if (this.props.shipping_account.ftp_username && !this.props.shipping_account.cust_id && !this.props.shipping_account.check_word)
      this.updateVersion(Version["LEGACY"])
  }
  updateVersion(version) {
    this.setState({version: version})
  }
  render() {
    let { shipping_account_type, shipping_account } = this.props
    let billing_rule_options = [
          { value: "by_weight", label: "論重量" },
          { value: "by_cuft", label: "論才數" },
          { value: "by_length", label: "總長" }          
        ]
    let billing_rule_default_value = billing_rule_options.find(b => b.value == shipping_account.billing_rule)
    billing_rule_default_value ||= { value: "by_weight", label: "論重量" }

    return (
      <>
        <div className="form-group">
          <div className="col-md-offset-3 col-md-6">
            <label htmlFor={`${shipping_account_type}-${shipping_account.id}-version-switcher`} className="switcher switcher-success">
              <input type="checkbox" id={`${shipping_account_type}-${shipping_account.id}-version-switcher`} checked={this.state.version === Version["API"]} onChange={(e) => this.updateVersion(e.target.checked ? Version["API"] : Version["LEGACY"])}/>
              <div className="switcher-indicator">
                <div className="switcher-yes">API版</div>
                <div className="switcher-no">舊版</div>
              </div>
            </label>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>客代</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][customer_code]`} defaultValue={shipping_account.customer_code}/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>名稱</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][customer_name]`} defaultValue={shipping_account.customer_name}/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>聯絡電話</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][customer_phone]`} defaultValue={shipping_account.customer_phone}/>
          </div>
        </div>        
        {
          this.state.version == Version["LEGACY"] &&
            (<>
              
              <div className="form-group">
                <label className="col-md-3 control-label">
                  <label>物流貨號</label>
                </label>
                <div className="col-md-6">
                  <div className="input-daterange input-group">
                    <input className="form-control" name={`shipping_accounts[${shipping_account.id}][number_start]`} defaultValue={shipping_account.number_start} placeholder="起始貨號"/>
                    <span className="input-group-addon"> ~ </span>
                    <input className="form-control" name={`shipping_accounts[${shipping_account.id}][number_end]`} defaultValue={shipping_account.number_end} placeholder="結束貨號"/>
                  </div>
                </div>
              </div>
            </>)
        }
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>FTP 帳號</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][ftp_username]`} defaultValue={shipping_account.ftp_username}/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>FTP 密碼</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][ftp_password]`} defaultValue={shipping_account.ftp_password}/>
          </div>
        </div>
        <input type='hidden' name={`shipping_accounts[${shipping_account.id}][ftp_address]`} defaultValue="hctrt.hct.com.tw"/>
        {
          this.state.version == Version["API"] &&
            (<>
              <div className="form-group">
                <label className="col-md-3 control-label">
                  <label>公司名稱(帳號)</label>
                </label>
                <div className="col-md-6">
                  <input className="form-control" name={`shipping_accounts[${shipping_account.id}][cust_id]`} defaultValue={shipping_account.cust_id}/>
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-3 control-label">
                  <label>密碼</label>
                </label>
                <div className="col-md-6">
                  <input className="form-control" name={`shipping_accounts[${shipping_account.id}][check_word]`} defaultValue={shipping_account.check_word}/>
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-3 control-label">
                  <label>收費標準</label>
                </label>
                <div className="col-md-6">
                  <Select
                    name={`shipping_accounts[${shipping_account.id}][billing_rule]`}
                    options={billing_rule_options}
                    defaultValue={billing_rule_default_value}
                    clearable={false}
                  />
                </div>
              </div>
            </>)
        }
      </>
    )
  }
}