import React, { Component } from 'react'
import { Provider } from 'react-redux'
import operatorStore from '../../../store/admin_store'
import { playErrorSound } from '../../../components/common/sounds/sounds'
import { requestFullscreen } from '../../../components/common/utils'
import  DistributeGrid from './distribute_grid'
import {writePermanentCookie,readCookie} from '../../../components/common/utils'
const ActionCable = require('actioncable')


const store = operatorStore()

const WAITING_STATUS = "waiting"
const DISTRIBUTING_STATUS = "distributing"
const SELECTING_STATUS = "selecting"
const DONE_STATUS = "done"

const STATE_KEY = 'distribution_state'

const SINGLE_MODE = "single_mode"
const MUTLIPLE_MODE = "mutlple_mode"

const ALERT_UNKNOWN = "alert_unknown"
const ACCEPT_UNKNOWN = "accept_unknown"

const DISPLAY_MODE_NUMBER = 'display_mode_number'
const DISPLAY_MODE_GRID = 'display_mode_grid'

const COOKIE_KEY_GRID_COLUMNS = 'distribution_grid_columns'
const COOKIE_KEY_GRID_LAYERS = 'distribution_grid_layers'
const COOKIE_KEY_DISPLAY_MODE = 'distribution_display_mode'
const COOKIE_KEY_DISPATCH_ALL_ORDERS = 'distribution_dispatch_all_orders'

const DEFAULT_STATE = {
  picking_list_id: null,
  orders: [],
  status: WAITING_STATUS,
  products: [],
  candidates: [],
  current_order_index: [],
  current_picked: 0,
  current_product: null,
  total_quantity: 0,
  work_mode: SINGLE_MODE,
  show_progress_index: null,
  handle_unknown: ALERT_UNKNOWN,
  error_handle_buckets: [],
  display_mode: readCookie(COOKIE_KEY_DISPLAY_MODE) || DISPLAY_MODE_NUMBER,
  grid_columns: readCookie(COOKIE_KEY_GRID_COLUMNS) || 5,
  grid_layers: readCookie(COOKIE_KEY_GRID_LAYERS) || 5,
  dispatch_all_orders: readCookie(COOKIE_KEY_DISPATCH_ALL_ORDERS) || false,
  conn_ip: null,
  picking_product_barcode: null
}


export default class Distribute extends Component {
  constructor(props) {
    super(props)
    let stored_state = JSON.parse(localStorage.getItem(STATE_KEY))

    this.onBarcodeInput = this.onBarcodeInput.bind(this)
    this.reset = this.reset.bind(this)
    this.onProductPick = this.onProductPick.bind(this)
    this.checkDone = this.checkDone.bind(this)
    this.checkOrderDone = this.checkOrderDone.bind(this)
    this.batchCheck = this.batchCheck.bind(this)
    this.onMutlipleModeOn = this.onMutlipleModeOn.bind(this)
    this.onMutlipleModeOff = this.onMutlipleModeOff.bind(this)
    this.syncPickingList = this.syncPickingList.bind(this)
    this.orderProgress = this.orderProgress.bind(this)
    this.broadcast = this.broadcast.bind(this)
    this.state = Object.assign({}, DEFAULT_STATE, stored_state)
    this.cable = ActionCable.createConsumer()
    this.syncData = this.syncData.bind(this)
    this.onProductError = this.onProductError.bind(this)
    if (this.state.work_mode == MUTLIPLE_MODE && this.state.picking_list_id) {
      this.syncPickingList(this.state.picking_list_id)
    }
    this.displayArea = this.displayArea.bind(this)
    this.displayNumber = this.displayNumber.bind(this)
    this.displayGrid = this.displayGrid.bind(this)
    this.requestGetReadings = this.requestGetReadings.bind(this)
    this.setConnIp = this.setConnIp.bind(this)
  }

  componentDidMount() {

    document.body.onkeydown = (e) => {
      this.refs.barcode_input.focus()
    }
    if (this.state.work_mode == MUTLIPLE_MODE) {
      this.onMutlipleModeOn()
    } else {
      this.onMutlipleModeOff()
    }

    this.setConnIp()
  }

  componentDidUpdate() {
    localStorage.setItem(STATE_KEY, JSON.stringify(this.state));

  }
  onMutlipleModeOn() {
    this.setState({ work_mode: MUTLIPLE_MODE })
    if (this.state.picking_list_id) {
      this.syncPickingList(this.state.picking_list_id)
    }
  }

  onMutlipleModeOff() {
    this.setState({ work_mode: SINGLE_MODE })
    if (this.channel) {
      this.channel.unsubscribe()
    }
  }
  broadcast(data) {
    if (this.channel) {
      this.channel.send(data)
    }
  }
  syncPickingList(picking_list_id) {
    let self = this
    if (this.channel) {
      this.channel.unsubscribe()
    }
    this.channel = this.cable.subscriptions.create({ channel: 'DistributeChannel', picking_list_id: picking_list_id }, {
      connected() {
        console.log("CONNECTED!")
        this.sync()
      },
      sync() {
        this.perform("sync")
      },
      disconnected() {
        console.log('disconnected')
      },
      appear() {
        console.log('appear')
      },
      away() {
        console.log('away')
      },
      received(data) {
        let new_state = JSON.parse(data)
        console.log(new_state)
        self.setState(new_state)
      }
    });

  }
  onProductError(message) {
    notyError(message)
    playErrorSound(this.props.operator_error_sound)
  }
  syncData(data_object) {
    if (this.state.work_mode == SINGLE_MODE) {
      this.setState(data_object)
    } else {
      this.broadcast(data_object)
    }
  }

  handleProductError(barcode, message) {
    switch (this.state.handle_unknown) {
      case ALERT_UNKNOWN:
        this.setState({
          current_order_index: [],
          current_picked: 0,
          total_quantity: 0
        })
        this.onProductError(message)
        break
      case ACCEPT_UNKNOWN:
        if (this.state.work_mode == SINGLE_MODE) {
          let index = null
          let error_handle_buckets = this.state.error_handle_buckets
          for (let bucket of error_handle_buckets) {
            if (bucket.barcode == barcode) {
              bucket.quantity += 1
              index = bucket.index
              break
            }
          }
          if (!index) {
            index = this.state.orders.length + error_handle_buckets.length + 1
            error_handle_buckets.push({ barcode: barcode, quantity: 1, index: index })
          }
          try {
            var msg = new SpeechSynthesisUtterance(`${index}號`);
            window.speechSynthesis.speak(msg)
          } catch (e) {

          }
          this.setState({
            current_order_index: [index],
            current_product: { title: barcode }
          })

          this.setState({ error_handle_buckets: error_handle_buckets })

        } else {
          fetch(`/operator/picking_lists/${this.state.picking_list_id}/scan_product.json`, {
            'credentials': 'same-origin',
            method: "POST",
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json',
              'X-CSRF-Token': this.props.authenticity_token,
            },
            body: JSON.stringify({
              barcode: barcode,
            })
          }).then(res => res.json())
            .then(res => {
              console.log(`accept unknown: `)
              console.log(res)
              if (res.success) {
                try {
                  var msg = new SpeechSynthesisUtterance(`${index}號`);
                  window.speechSynthesis.speak(msg)
                } catch (e) {

                }
                this.setState({
                  current_order_index: [res.current_order_index],
                  current_picked: res.current_picked,
                  total_quantity: res.total_quantity,
                  current_product: { title: barcode },
                  products: res.products,
                  error_handle_buckets: res.error_handle_buckets

                })
              }
            })
        }
        break
    }
  }
  checkDone() {
    let done = true
    for (let product of this.state.products) {
      for (let order of product.orders) {
        done &= order.total == order.picked
        if (done == false) return false
      }
    }
    this.syncData({ status: DONE_STATUS })
    return true
  }

  orderProgress(order_index) {
    if (!order_index) {
      return ''
    }
    if (order_index > (this.state.orders.length)) {
      for (let bucket of this.state.error_handle_buckets) {
        if (bucket.index == order_index) {
          return <table className='table'>
            <thead>
              <tr>
                <th>
                  條碼
                </th>
                <th className='text-center'>
                  總量
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  {bucket.barcode}
                </td>
                <td className='text-center'>
                  {bucket.quantity}
                </td>
              </tr>
            </tbody>
          </table>
        }
      }
    }
    let orders = this.state.orders
    let order_products = []
    for (let order of orders) {
      if (order.picking_index == order_index) {
        for (let product of this.state.products) {
          for (let product_order of product.orders) {
            if (product_order.index == order_index) {
              order_products.push(
                {
                  total: product_order.total,
                  picked: product_order.picked,
                  product_name: product.title,
                  product_uid: product.uid
                }
              )
            }
          }
        }
      }
    }
    return <table className='table'>
      <thead>
        <tr>
          <th>
            品號
          </th>
          <th>
            品名
          </th>
          <th className='text-center'>
            已分
          </th>
          <th className='text-center'>
            總量
          </th>
        </tr>
      </thead>
      <tbody>
        {
          order_products.map(op => {
            return <tr key={op.product_uid}>
              <td>
                {op.product_uid}
              </td>
              <td>
                {op.product_name}
              </td>
              <td className='text-center'>
                {op.picked}
              </td>
              <td className='text-center'>
                {op.total}
              </td>
            </tr>
          })
        }
      </tbody>
    </table>


  }
  checkOrderDone(order_index, products) {
    let done = true
    let orders = this.state.orders
    for (let product of products) {
      for (let order of product.orders) {
        if (order.index == order_index) {
          done &= (order.total == order.picked)
        }
        if (!done) {
          return false
        }
      }
    }
    for (let order of orders) {
      if (order.picking_index == order_index) {
        order.done = true
        break
      }
    }
    this.syncData({ orders: orders })
    return true
  }

  onProductPick(candidate, quantity, isBatch) {
    let {products, dispatch_all_orders,display_mode} = this.state
    if (this.state.work_mode == MUTLIPLE_MODE) {
      fetch(`/operator/picking_lists/${this.state.picking_list_id}/scan_product.json`, {
        'credentials': 'same-origin',
        method: "POST",
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'X-CSRF-Token': this.props.authenticity_token,
        },
        body: JSON.stringify({
          uid: candidate.uid,
          barcode: candidate.barcode,
          product_storage_type: candidate.product_storage_type,
          quantity: quantity,
          is_batch: isBatch
        })
      }).then(res => res.json())
        .then(res => {
          if (res.success) {
            this.setState({
              current_order_index: [res.current_order_index],
              current_picked: res.current_picked,
              total_quantity: res.total_quantity,
              current_product: candidate,
              products: res.products,
              error_handle_buckets: res.error_handle_buckets
            })
            let done = this.checkOrderDone(res.current_order_index, res.products)
            try {
              var msg = new SpeechSynthesisUtterance(`${res.current_order_index}號${quantity > 1 ? '批次，共' + res.total_quantity + '件' : ''}${done ? '，完成' : ''}`);
              window.speechSynthesis.speak(msg)
            } catch (e) {
            }
          } else {
            this.setState({
              current_order_index: [],
              current_picked: 0,
              total_quantity: 0
            })
            this.handleProductError(candidate.barcode, '商品錯誤或已經超過')
          }
        })
      return
    } else {
      let original_quantity = quantity
      for (let product of products) {
        let _order_indexes = []
        if (product.uid == candidate.uid && product.barcode == candidate.barcode && product.product_storage_type == candidate.product_storage_type) {
          for (let target of product.orders) {
            if (target.picked < target.total) {
              let _quantity = Math.min(quantity,target.total - target.picked)
              target.picked += _quantity
              quantity -= _quantity
              this.setState({
                current_picked: target.picked,
                total_quantity: target.total,
                current_product: product,
              })
              let done = this.checkOrderDone(target.index, products)
              _order_indexes.push(target.index)
              if(dispatch_all_orders && quantity > 0){
                continue
              }else{
                this.setState({ current_order_index: _order_indexes })
                if(display_mode == DISPLAY_MODE_NUMBER){
                  try {
                    var msg = new SpeechSynthesisUtterance(`${target.index}號${quantity > 1 ? '批次，共' + target.total + '件' : ''}${done ? '，完成' : ''}`);
                    window.speechSynthesis.speak(msg)
                  } catch (e) {
    
                  }
                }else{
                  try {
                    var msg = new SpeechSynthesisUtterance(`共${original_quantity}件`);
                    window.speechSynthesis.speak(msg)
                  } catch (e) {
    
                  }
                }
                if (this.checkDone()) {
                  this.syncData({ status: DONE_STATUS })
                }
                return  
              }
            }
          }
        }
      }
    }
    this.handleProductError(candidate.barcode, '商品錯誤或已經超過')
  }

  setConnIp() {
    const conn_ip = "127.0.0.1:54088"
    return fetch(`http://${conn_ip}/ping`, {
    }).then(res => {
      console.log(res.status)
      console.log(res)
      if (res.status == 200) {
        this.setState({ conn_ip }, () => {
          console.log("set CONN_IP!!")
        })
        return true
      }
      else
        return false
    }).catch((e) => {
      console.log(e)
      return false
    })
  }

  setReadings(barcode, to_pick_arr) {
    this.setState({picking_product_barcode: barcode})

    if (!!this.state.conn_ip) {
      fetch(`http://${this.state.conn_ip}/nodes`, {
        method: 'POST',
        body: JSON.stringify(Array.from(to_pick_arr, elem => elem || 0)),
        headers: {
          "Origin": `http://${this.state.conn_ip}`,
          "content-type": "application/json"
        }
      }).then(res => {
        console.log(res.status)
        console.log(res)
        if (res.status == 200)
          this.requestGetReadings()
        else
          notyErrorBlock("串接燈異常!")
      })
    }
  }
  requestGetReadings() {
    var timer = setInterval(() => {
      if (!!this.state.conn_ip && !!this.state.picking_product_barcode) {
        this.getReadings()
      }
      else {
        clearInterval(timer)
      }
    }, 100)
  }
  getReadings() {
    if (!!this.state.conn_ip) {
      fetch(`http://${this.state.conn_ip}/nodes`, {
        method: 'GET'
      }).then(res => res.json())
        .then(res => {
          console.log(res)
          if(!!this.state.picking_product_barcode && Array.isArray(res) && res.every(item => item === 0)) {
            this.setState({picking_product_barcode: null})
          }
        })
    }
  }

  batchCheck() {
    this.onProductPick(this.state.current_product, this.state.total_quantity - this.state.current_picked, true)
  }

  onBarcodeInput(e) {
    let {display_mode} = this.state
    if (e.keyCode == 13 && this.refs.barcode_input.value) {
      let barcode = this.refs.barcode_input.value
      switch (this.state.status) {
        case WAITING_STATUS:
          fetch(`/operator/picking_lists/${barcode}/distribute_orders.json`, {
            'credentials': 'same-origin'
          }).then(res => res.json())
            .then(res => {
              let products = []
              for (let order of res.data.orders) {
                order.done = false
                for (let order_product of order.products) {
                  let product = products.filter(p => p.barcode == order_product.barcode && p.uid == order_product.uid && p.product_storage_type == order_product.product_storage_type)[0]
                  if (!product) {
                    product = {
                      barcode: order_product.barcode,
                      uid: order_product.uid,
                      product_storage_type: order_product.product_storage_type,
                      title: order_product.title,
                      orders: []
                    }
                    products.push(product)
                  }
                  let existing_order = product.orders.find(o => o.index == order.picking_index)
                  if(existing_order){
                    existing_order.total += order_product.quantity
                  }else{
                    product.orders.push({ index: order.picking_index, total: order_product.quantity, picked: 0 })
                  }
                }
              }
              this.setState(Object.assign(res.data, { status: DISTRIBUTING_STATUS, products: products }))
              if (this.state.work_mode == MUTLIPLE_MODE) {
                this.syncPickingList(barcode)
              }

              this.refs.barcode_input.value = ""
            }).catch(e => {
              console.log(e)
              notyError(`查無資料，請檢查揀貨單條碼或者手動輸入`)
              this.refs.barcode_input.value = ""
            })
          break
        case DISTRIBUTING_STATUS:
          let candidate_products = []
          if (!barcode) {
            break
          }
          if (!!this.state.conn_ip && !!this.state.picking_product_barcode) {
            notyErrorBlock("請等候目前商品揀完後再輸入條碼!")
            this.refs.barcode_input.value = ""
            break
          }
          this.refs.barcode_input.value = ""
          for (let product of this.state.products) {
            if (product.barcode == barcode) {
              candidate_products.push({
                barcode: product.barcode,
                uid: product.uid,
                product_storage_type: product.product_storage_type,
                orders: product.orders
              })
            }
          }
          if (candidate_products.length == 1) {
            let to_pick_arr = Array((parseInt(this.state.grid_layers)||0) * (parseInt(this.state.grid_columns)||0)).fill(0)
            if(display_mode == DISPLAY_MODE_NUMBER){
              this.onProductPick(candidate_products[0], 1, false)
            }else{
              let _quantity = 0 
              for(let order of candidate_products[0].orders){
                to_pick_arr[order.index-1] = (order.total - order.picked)
                _quantity += order.total - order.picked
                if(!this.state.dispatch_all_orders && _quantity > 0){
                  break
                }
              }
              if (!!this.state.conn_ip)
                this.setReadings(barcode, to_pick_arr)

              if(_quantity > 0){
                this.onProductPick(candidate_products[0],_quantity , false)
              }else{
                this.handleProductError(candidate_products[0].uid, '商品錯誤')
              }
            }
          } else if (candidate_products.length > 1) {
            this.setState({ status: SELECTING_STATUS, candidates: candidate_products })

          } else {
            this.handleProductError(barcode, '商品錯誤')
          }
          break
        case SELECTING_STATUS:
          break
        case DONE_STATUS:
          if (barcode == this.state.picking_list_id) {
            this.setState(DEFAULT_STATE, () => this.setConnIp())
          } else {
            this.handleProductError(barcode, '商品錯誤')
          }
          this.refs.barcode_input.value = ""
          break
      }
    }
  }

  displayArea(){
    if(this.state.display_mode == DISPLAY_MODE_NUMBER){
      return this.displayNumber()
    }else{
      return this.displayGrid()
    }
  }

  displayNumber(){
    return this.state.current_order_index.length > 0 && (this.state.status == DISTRIBUTING_STATUS || this.state.status ==DONE_STATUS) ?
      <div className='row'>
        <div className='col-md-2'>
          <span>{[this.state.current_product.title, this.state.current_product.product_storage_type].filter(e => e).join(" / ")}</span>
        </div>
        <div className='col-md-10'>
          <h1 className='animated pulse' style={{ lineHeight: "280px", fontSize: "400px", textAlign: 'center', height: '300px', overflow: 'hidden', margin: 0 }}>
            {this.state.current_order_index[0]}
          </h1>
        </div>
      </div>
      : ''
  }

  displayGrid(){
    let grids = []
    let {grid_columns, grid_layers ,current_order_index,current_product ,orders} = this.state
    let index = 1
    let height = `${80 / grid_layers}cqh`
    let width = `${100 / grid_columns}cqw`
    for(let i = 0 ; i < grid_layers ; i++){
      let row = []
      for(let j = 0 ; j < grid_columns ; j++){
        let pcs = 0
        let active = current_order_index.includes(index)
        if(current_product && current_product.orders && active){
          pcs = current_product.orders.find(o => o.index == index).total
        }
        row.push(<DistributeGrid key={index} index={index} active={active} width={width}  pcs={pcs} />)
        index += 1
      }
      grids.push(<div className='row' style={{display: 'flex',height: height}} key={i}>{row}</div>)
    }
    return grids
  }
  


  reset() {
    if (confirm("確定要清除當前進度並且重新掃描揀貨單?")) {
      console.log('reset',DEFAULT_STATE)
      this.setState(Object.assign({}, DEFAULT_STATE, { work_mode: this.state.work_mode }), () => this.setConnIp())
      if (this.channel) {
        this.channel.unsubscribe()
      }
    }
  }

  render() {
    let no_barcodes = []
    let duplicate_barcodes = []
    let {display_mode} = this.state
    if (this.state.status == SELECTING_STATUS) {
      duplicate_barcodes = this.state.candidates.map(candidate => {
        return <div className='row' key={candidate.uid + candidate.product_storage_type}>
          <div className='col-md-12 form-group'>
            <a className='btn btn-xl btn-warning btn-block' onClick={() => {
              this.setState({ status: DISTRIBUTING_STATUS })
              if(display_mode == DISPLAY_MODE_NUMBER){
                this.onProductPick(candidate, 1, false)
              }else{
                let _quantity = 0 
                for(let order of candidate.orders){
                  _quantity += order.total - order.picked
                  if(!this.state.dispatch_all_orders && _quantity > 0){
                    break
                  }
                }
                if(_quantity > 0){
                  this.onProductPick(candidate,_quantity , false)
                }else{
                  this.handleProductError(candidate.uid, '商品錯誤')
                }
              }
            }}>
              {candidate.uid}
              {candidate.product_storage_type}
            </a>
          </div>
        </div>
      })
    }

    no_barcodes = this.state.products.filter(p => !p.barcode).map(product => {
      return <div className='row' key={product.uid}>
        <div className='col-md-12 form-group'>
          <a className='btn btn-xl btn-info btn-outline btn-block' onClick={() => {
              if(display_mode == DISPLAY_MODE_NUMBER){
                this.onProductPick(product, 1, false)
              }else{
                let _quantity = 0 
                for(let order of product.orders){
                  _quantity += order.total - order.picked
                  if(!this.state.dispatch_all_orders && _quantity > 0){
                    break
                  }
                }
                if(_quantity > 0){
                  this.onProductPick(product,_quantity , false)
                }else{
                  this.handleProductError(product.uid, '商品錯誤')
                }
              }
            
            }}>
            {product.uid}
          </a>
        </div>
      </div>
    })
    let progress_area = <div>
      {
        this.state.orders.sort((a, b) => a.picking_index - b.picking_index).map(order => {
          return <a key={order.picking_index} onClick={() => {
            this.setState({ show_progress_index: order.picking_index == this.state.show_progress_index ? null : order.picking_index })
          }
          } style={{ marginRight: '5px', minWidth: '45px' }} className={'label ' + (order.done ? 'label-success' : '')}>{order.picking_index}</a>
        })
      }
      {
        this.state.error_handle_buckets.sort((a, b) => a.index - b.index).map(bucket => {
          return <a key={bucket.index} onClick={() => {
            this.setState({ show_progress_index: bucket.index == this.state.show_progress_index ? null : bucket.index })
          }
          } style={{ marginRight: '5px', minWidth: '45px' }} className={'label label-danger label-outline'}>{bucket.index}</a>
        })
      }
    </div>
    return (
      <Provider store={store}>
        <div>
          <ol className="breadcrumb page-breadcrumb">
            <li><a href="/operator"><i className="px-nav-icon ion-md-home"></i></a></li>
            <li><a href="/operator/picking_lists/distribute">分配訂單</a></li>
          </ol>
          <div className="page-header">
            <div className="pull-left">
              <h1>分配訂單</h1>
            </div>
            <div className='pull-right'>
              <div style={{display: 'flex', alignItems: 'center'}}>
                <button
                  type="button" className="btn btn-default" style={{marginRight: '4px'}}
                  onClick={() => {
                    this.setConnIp().then((res) => {
                      if (res)
                        notyMessage("串接燈成功!")
                      else
                        notyError("串接燈失敗!")
                    })
                  }}
                >串接燈檢查</button>
                <div className='btn-group'>
                  <a className={`btn btn-outline btn-primary ${this.state.work_mode == SINGLE_MODE ? 'active' : ''}`} onClick={() => this.onMutlipleModeOff()}>
                    單機模式
                  </a>
                  {
                    this.state.display_mode == DISPLAY_MODE_NUMBER && <a className={`btn btn-outline btn-primary ${this.state.work_mode == MUTLIPLE_MODE ? 'active' : ''}`} onClick={() => this.onMutlipleModeOn()}>
                      聯機模式
                    </a>
                  }
                </div>
                <a className='btn btn-warning' onClick={() => {
                  requestFullscreen(document.getElementById('distributionArea'))
                }}>
                  全屏
                </a>
              </div>
            </div>
          </div>
          <div className='row' id='distributionArea'>
            <div className='col-md-10'>
              <div className='row'>
                <div className='panel' style={{ height: '100vh' }}>
                  <div className='panel-heading'>
                    {this.state.picking_list_id ?
                      <div className='col-md-9'>
                        <span>
                          揀貨單號 #
                          {[this.state.picking_list_id, this.state.shop_name, this.state.orders.length + '訂單'].join(' / ')}
                          <a className='btn btn-sm btn-danger' onClick={this.reset}>
                            <i className='ion-md-close'></i>
                          </a>
                        </span>

                        {
                          this.state.is_over ? '' : <span className='label label-warning'><i className='ion-md-warning'></i>撿貨單尚未完成</span>
                        }
                        <br />
                        <a data-toggle="collapse" href="#collapseOne">顯示進度</a>
                        <div id="collapseOne" className='collapse in'>
                          {progress_area}
                          {
                            this.orderProgress(this.state.show_progress_index)
                          }

                        </div>
                      </div> : <div className='col-md-9'>請掃描揀貨單</div>}
                    <div className='col-md-3'>
                      <input type="text" ref="barcode_input"
                        placeholder={this.state.status == WAITING_STATUS ? "請輸入或者掃描撿貨單條碼" : '請掃描商品條碼'}
                        autoFocus={true}
                        onKeyDown={this.onBarcodeInput}
                        className="form-control" />

                    </div>
                  </div>
                  <div className="panel-body" style={{ minHeight: '48',maxHeight: '80vh' }}>
                    {
                      this.state.status == SELECTING_STATUS ?
                        <div style={{ fontSize: "75px", textAlign: 'center' }}>
                          條碼重複，請依照品號選擇商品
                          <i className='ion-md-arrow-round-forward'></i>
                        </div> : ''

                    }
                    {
                      this.displayArea()
                    }
                    {
                      this.state.status == DONE_STATUS &&
                      (
                        <div className='row' style={{ width:"100%",margin: 0,  textAlign: 'center',position:'absolute',top: '20vh' }}>
                          <h1 className='animated text-success' style={{fontSize: "150px"}}>
                            批次完成
                          </h1>
                          <h2>
                            請掃描揀貨單或者點擊上方X重新開始
                          </h2>
                        </div>
                      )
                    }
                  </div>
                  {this.state.display_mode == DISPLAY_MODE_NUMBER && <div className='panel-footer text-right' style={{ fontSize: '30px' }}>
                    {
                      this.state.current_picked < this.state.total_quantity && this.state.total_quantity > 1 ?
                        <a className='btn btn-sm btn-primary' onClick={this.batchCheck} >整批確認</a> : ''
                    }

                    {this.state.current_picked}
                    /
                    {this.state.total_quantity}
                  </div>}
                </div>

              </div>
            </div>
            <div className='col-md-2 form-horizontal'>
              {
                duplicate_barcodes.length > 0 ?
                  <div className='panel panel-transparent'>
                    <div className='panel-heading p-x-1 text-info'>
                      重複條碼商品
                    </div>
                    <div className='panel-body'>
                      {duplicate_barcodes}
                    </div>
                  </div> : ''
              }
              {
                no_barcodes.length > 0 ?
                  <div className='panel panel-transparent'>
                    <div className='panel-heading p-x-1 text-info'>
                      無條碼商品
                    </div>
                    <div className='panel-body'>
                      {no_barcodes}
                    </div>
                  </div> : ''
              }
            </div>

          </div>
          <div className='row'>
            <div className='col-md-6 form-horizontal'>
              <div className="form-group">
                <label className="col-sm-2 control-label">顯示模式</label>
                <div className="col-sm-10">
                  <div className='btn-group'>
                    <a className={`btn btn-info btn-outline ${this.state.display_mode == DISPLAY_MODE_NUMBER ? 'active' : ''}`} onClick={() => {
                      this.setState({ display_mode: DISPLAY_MODE_NUMBER })
                      writePermanentCookie(COOKIE_KEY_DISPLAY_MODE, DISPLAY_MODE_NUMBER)
                    }}> 唱號 </a>
                    <a className={`btn btn-info btn-outline ${this.state.display_mode == DISPLAY_MODE_GRID ? 'active' : ''}`} onClick={() => {
                      this.setState({ display_mode: DISPLAY_MODE_GRID,work_mode: SINGLE_MODE })
                      writePermanentCookie(COOKIE_KEY_DISPLAY_MODE, DISPLAY_MODE_GRID)
                    }}> 格狀 </a>
                  </div>
                </div>
              </div>
              {
                this.state.display_mode == DISPLAY_MODE_GRID ?
                  <div>
                    <div className="form-group">
                      <label className="col-sm-2 control-label">格狀層數</label>
                      <div className="col-sm-4">
                        <input type="number" className="form-control" value={this.state.grid_layers} onChange={(e) => {
                          this.setState({ grid_layers: e.target.value })
                          writePermanentCookie(COOKIE_KEY_GRID_LAYERS, e.target.value)
                        }} />
                      </div>
                    </div>
                    <div className="form-group">
                      <label className="col-sm-2 control-label">格狀列數</label>
                      <div className="col-sm-4">
                        <input type="number" className="form-control" value={this.state.grid_columns} onChange={(e) => {
                          this.setState({ grid_columns: e.target.value })
                          writePermanentCookie(COOKIE_KEY_GRID_COLUMNS, e.target.value)
                        }} />
                      </div>
                    </div>
                    {this.state.work_mode == SINGLE_MODE && <div className="form-group">
                      <label className="col-sm-2 control-label">每次顯示</label>
                      <div className="col-sm-4">
                        <div className='btn-group'>
                          <a className={`btn btn-info btn-outline ${!this.state.dispatch_all_orders ? 'active' : ''}`} onClick={() => {
                            this.setState({ dispatch_all_orders: false })
                            writePermanentCookie(COOKIE_KEY_DISPATCH_ALL_ORDERS, false)
                          }}> 單格 </a>
                          <a className={`btn btn-info btn-outline ${this.state.dispatch_all_orders ? 'active' : ''}`} onClick={() => {
                            this.setState({ dispatch_all_orders: true })
                            writePermanentCookie(COOKIE_KEY_DISPATCH_ALL_ORDERS, true)
                          }}> 全部 </a>
                        </div>
                      </div>
                    </div>}
                  </div> : ''
              }
            </div>
            <div className='col-md-6 form-horizontal'>
                <div className="form-group">
                  <label className="col-sm-2 control-label">條碼錯誤時</label>
                  <div className="col-sm-10">
                    <div className='btn-group'>
                      <a className={`btn btn-info btn-outline ${this.state.handle_unknown == ALERT_UNKNOWN ? 'active' : ''}`} onClick={() => {
                        this.syncData({ handle_unknown: ALERT_UNKNOWN })
                      }
                      }>
                        警示
                      </a>
                      <a className={`btn btn-info btn-outline ${this.state.handle_unknown == ACCEPT_UNKNOWN ? 'active' : ''}`} onClick={() => {
                        this.syncData({ handle_unknown: ACCEPT_UNKNOWN })
                      }}>
                        歸納
                      </a>
                    </div>
                  </div>

                </div>
              </div>            
          </div>
        </div>
      </Provider>
    )
  }
}
