import React, { Component } from 'react'
import ShopProducts from '../../../components/common/shop_products'
import Select from 'react-select'
import Switch from "react-switch";
export default class ReturnOrderForm extends Component {
  constructor(props) {
    super(props)
    this.state = {
      reverse_logistic: false,
      original_products: (this.props.order?.products || []).map((order_product,index) => {
        return {
          id: order_product.product_id ,
          expiration_date: order_product.expiration_date,
          storage_type: order_product.product_storage_type_id,
          uid: order_product.product.uid,
          name: order_product.product.name,
          batch: order_product.batch,
          index: index,
          quantity: order_product.quantity,
          batches: []
        }
      })
    }
  }

  render() {
    let { order } = this.props
    return (
      <div className="form-horizontal">
        <div className="form-group">
          <label className="col-md-2 control-label">
            退貨單號
          </label>
          <div className="col-md-9">
            <input required="required" className="form-control" type="text" name="return_order[token]" id="return_order_token" defaultValue={order.name} />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">
            原始訂單單號
          </label>
          <div className="col-md-9">
            <input className="form-control" type="text" name="return_order[order_name]" id="return_order_token" defaultValue={order.name}/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">
            退貨人名稱
          </label>
          <div className="col-md-9">
            <input required="required" className="form-control" type="text" name="return_order[sender_name]" defaultValue={order.customer_name} />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">
            退貨人電話
          </label>
          <div className="col-md-9">
            <input className="form-control" type="text" name="return_order[sender_phone]" defaultValue={order.phone} />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">
            退貨人地址
          </label>
          <div className="col-md-9">
            <input className="form-control" type="text" name="return_order[sender_address]" defaultValue={order.full_address} />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">
            退貨通路
          </label>
          <div className="col-md-9">
            <Select
              name={`return_order[shop_channel_id]`}
              options={this.props.channels.map(channel => {
                return { value: channel.id, label: channel.code }
              })}
              defaultValue={
                order.shop_channel_id ? {
                  value: this.props.channels.find(channel => channel.id == order.shop_channel_id).id,
                  label: this.props.channels.find(channel => channel.id == order.shop_channel_id).code
                } : null
              }
              searchable={true}
              clearable={false}
              placeholder="請選擇通路" />
          </div>
        </div>

        <div className="form-group">
          <label className="col-md-2 control-label">
            備註
          </label>
          <div className="col-md-9">
            <input className="form-control" type="text" name="return_order[note]" />
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-2 control-label">
            主動派收(逆物流)
          </label>
          <div className="col-md-9">
            <Switch onChange={
              (checked) => {
                this.setState({reverse_logistic: checked})
              }
            } name="return_order[reverse_logistic]" checked={this.state.reverse_logistic} />
          </div>
        </div>
        {
          this.state.reverse_logistic &&
          <div className="form-group">
            <label className="col-md-2 control-label">
              指定派收日期
            </label>
            <div className="col-md-9">
              <input className="form-control" type="date" name="return_order[reverse_logistic_date]" />
            </div>
          </div>
        }


        <ShopProducts
        original_products= {this.state.original_products}
        storage_types={this.props.storage_types}
        shop_id={this.props.shop_id} batch_input={true}/>

      </div>
    )
  }
}
