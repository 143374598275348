import React, { useState } from 'react'
import Modal from 'react-responsive-modal'
import Select from 'react-select'

import TableIndex, { handleSubmit } from '../../../components/common/table/TableIndex'
import { DateRange, MultipleValue } from '../../../components/common/table/Rules/component'


export default function LogisticDistributionTableIndex({
    statusOptions,
    shops
}) {
  const shopOptions = shops.map(shop => ({
    label: shop.name,
    value: shop.id,
  }))
  const [lastModified, setLastModified] = useState(0)
  const [changeShippingStatusModalOpen, setChangeShippingStatusModalOpen] = useState(false)

  const [selectedShippings, setSelectedShippings] = useState(new Set())
  const [selectedShippingStatus, setSelectedShippingStatus] = useState(null)

  const useableShippingStatus = statusOptions.filter(option => new Set(["received", "transit", "returned", "answered", "no_answered", "temp_no_answered", "undecided", "non_delivery_area", "need_help"]).has(option.value))

  const closeChangeShippingStatusModal = () => {
    setChangeShippingStatusModalOpen(false)
  }

  const displayChangeShippingStatusModal = () => {
    setChangeShippingStatusModalOpen(true)
  }

  const batchChangeShippingStatus = () => {
    if(confirm(`確定要將 ${selectedShippings.size} 筆托運單更新狀態為 ${selectedShippingStatus['label']} 嗎?`)) {
      console.log(selectedShippings)
      fetch('/operator/logistic_distributions/batch_change_status', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': $('meta[name="csrf-token"]').attr('content')
        },
        body: JSON.stringify({
          records: [...selectedShippings],
          status: selectedShippingStatus['value']
        })
      }).then(res => {
        window.location.reload()
      }).catch(err => {
        console.log(err)
        alert('更新托運單狀態失敗: ' + err)
        closeChangeShippingStatusModal()
      })
    }
  }

  const columns = [
    {
      name: '訂單編號',
      sortable: false,
      persist: true,
      width: '200px',
      cell: ({ id, name }) => {
        return <a href={`/operator/logistic_distributions/${id}`} target="_blank">{name}</a>
      }
    },
    {
      name: '托運單號',
      sortable: false,
      persist: true,
      width: '160px',
      cell: ({shipping}) => shipping.tracking_number
    },
    {
      name: '托運單批次',
      sortable: false,
      persist: true,
      width: '150px',
      cell: ({shipping}) => shipping.shipping_batch ? <a href={`/operator/logistic_distributions/shipping_batch/${shipping.shipping_batch.name}`} target="_blank" >{shipping.shipping_batch.name}</a> : ''
    },
    {
      name: '客戶',
      sortable: false,
      selector: ({ shop_name }) => shop_name,
    },
    {
      name: '狀態',
      sortable: false,
      width: '110px',
      cell: ({shipping})=>{
        let className = ''
        switch(shipping.status){
          case '準備出貨':
            className = 'label label-success label-outline'
            break;
          case '已出貨':
            className = 'label label-info label-outline'
            break;
          case '已到達':
            className = 'label label-warning label-outline'
            break;
          case '配送完成':
            className = 'label label-success'
            break;
          case '未取貨':
            className = 'text-muted'
            break;
          case '被退貨':
            className = 'label label-danger'
            break;
          case '異常狀況':
            className = 'label label-warning'
            break;
          case '配送中':
            className = 'label label-info label-outline'
            break;
          case '暫時電聯不上':
            className = 'label label-warning label-outline'
            break;
          case '多次電聯不上':
            className = 'label label-warning label-outline'
            break;
          case '需配送加價協調中':
            className = 'label label-warning label-outline'
            break;
          case '已電聯上但尚未決定配送日期':
            className = 'label label-warning label-outline'
            break;
          case '不配送':
            className = 'label label-danger'
            break;
          case '非配送地區':
            className = 'label label-danger'
            break;
          case '需通知電商協助':
            className = 'label label-secondary'
            break;
          case '已電聯上可配送':
            className = 'label label-success'
            break;
        }
        return <span className={className}>{shipping.status}</span>
      },
    },
    {
      name: '收件人',
      sortable: false,
      cell: ({ customer_name }) => <span>{ customer_name }</span>,
    },
    {
      name: '聯絡電話',
      sortable: false,
      cell: ({ phone }) => <span>{ phone }</span>,
    },
    {
      name: '收件地址',
      sortable: false,
      cell: ({ address }) => <span>{ address }</span>,
    },
    {
      name: '預計出貨日',
      sortable: true,
      sortFieldName: 'shippings.scheduled_date',
      width: '130px',
      cell: ({ scheduled_date }) => scheduled_date,
    },
    {
      name: '備註',
      sortable: false,
      cell: ({ note }) => note,
    }
  ]


  const supportRules = [
    {
      label: '客戶',
      component: MultipleValue,
      inputName: 'orders.shop_id',
      defaultOptions: shopOptions,
      defaultPinned: true,
      createable: false,
    },
    {
      label: '預計出貨日',
      component: DateRange,
      inputName: 'orders.scheduled_shipping_date',
    },
    {
      label: '托運單建立日',
      component: DateRange,
      inputName: 'shippings.created_at',
    },
    {
      label: '訂單建立日',
      component: DateRange,
      inputName: 'orders.created_at',
    },
    {
      label: '狀態',
      component: MultipleValue,
      defaultOptions: statusOptions,
      createable: false,
      inputName: 'shippings.status',
    },
    {
      label: '托運單號',
      component: MultipleValue,
      createable: true,
      inputName: 'shippings.tracking_number',
    },
    {
      label: '托運單批次',
      component: MultipleValue,
      inputName: 'shippings.shipping_batch_name',
      defaultPinned: true,
      createable: true,
    },
    {
      label: '訂單編號',
      component: MultipleValue,
      createable: true,
      inputName: 'orders.name',
    }
  ]

  return (
    <>
      <TableIndex columns={columns}
        selectCheckboxEnabled
        getRecordsUrl='logistic_distributions/get_records.json'
        selectAllUrl='logistic_distributions/get_all.json'
        searchable={true}
        columnsEditable={true}
        tableId='OperatorLogisticDistributionsTable' //for columnsEditable
        defaultQuery={{
          sorting_column: 'id',
          sorting_direction: 'desc',
        }}
        supportRules={supportRules}
        lastModified={lastModified}
        actions={[
          {
            name: '建立配送批次',
            className: 'btn btn-primary btn-outline mb-0',
            do: (selected) => {
              if(confirm(`確定要將 ${selected.size} 筆托運單綁定批次嗎?`)) {
                handleSubmit('/operator/logistic_distributions/create_shipping_batch.json', selected, {}, () => {
                  setLastModified(Date.now())
                })
              }
            }
          },
          {
            name: '批次修改狀態',
            className: 'btn btn-warning btn-outline mb-0',
            do: (selected) => {
              setSelectedShippings(selected)
              displayChangeShippingStatusModal(selected)
            }
          }
        ]}
      />
      <Modal
        open={changeShippingStatusModalOpen}
        onClose={closeChangeShippingStatusModal}
        center
      >
        <div className="row" style={{marginTop: "30px"}}>
          <div className="col-md-12 control-label">
            選擇要切換的狀態
          </div>
          <div className="col-md-12">
            <Select
              className='react-select-container'
              placeholder="請選擇狀態"
              options={useableShippingStatus}
              value={selectedShippingStatus}
              onChange={(option) => {
                setSelectedShippingStatus(option)
              }}
            ></Select>
            <button className="btn btn-primary" onClick={batchChangeShippingStatus}>確認</button>
          </div>
        </div>
      </Modal>
    </>
  );
}