import React from 'react'
import { useEffect, useState } from 'react'

const SHELF_STATUS_NORMAL = 'NORMAL'
const SHELF_STATUS_WARNING = 'WARNING'
const SHELF_STATUS_ERROR = 'ERROR'
const SHELF_STATUS_PASSED = 'PASSED'

const statusHashColor = {
  'NORMAL': 'black',
  'WARNING': 'orange',
  'ERROR': 'red',
  'PASSED': 'green'
}

export default function OnShelfRow(props) {
  const { shelf, onUpdateToken,onUpdatePcs, destroyShelf, checkDuplicateToken,pcs ,onUpdateValidity} = props
  const [token, setToken] = useState(shelf.token)
  const [shelfStatus, setShelfStatus] = useState(SHELF_STATUS_NORMAL)

  function handleTokenChange(event) {
    let token = event.target.value.trim()
    setToken(token)
    onUpdateToken(shelf.randomKey,token);
  }
  function handlePcsChange(event) {
    onUpdatePcs(shelf.randomKey,event.target.value);
  }

  function checkStatus() {
    if(token.length === 0){
      return
    }
    if (checkDuplicateToken(token)) {
      setShelfStatus(SHELF_STATUS_ERROR)
      onUpdateValidity(shelf.randomKey, false)
      return
    }
    fetch(`${window.location.pathname}/check_shelf_status.json?token=${token}`,
      {
        method: 'GET',
        credentials: 'same-origin'
      })
      .then(res => res.json())
      .then(data => {
        console.log(data)
        switch (data.status) {
          case 'NOT_EXIST':
            setShelfStatus(SHELF_STATUS_ERROR)
            onUpdateValidity(shelf.randomKey, false)
            notyError(token + " 儲位不存在");
            break;
          case 'LOCKED':
            setShelfStatus(SHELF_STATUS_ERROR)
            onUpdateValidity(shelf.randomKey, false)
            notyError(token + " 儲位已鎖定，不可入儲");
            break;
          case 'OCUPPIED':
            setShelfStatus(SHELF_STATUS_WARNING)
            onUpdateValidity(shelf.randomKey, true)
            notyWarning(token + " 儲位上已有其他商品");
            break;
          case 'EMPTY':
            onUpdateValidity(shelf.randomKey, true)
            setShelfStatus(SHELF_STATUS_PASSED)
            break
        }
      })
  }

  return (
    <tr>
      <td>
        <input className="form-control" required="required"
          style={{ color: statusHashColor[shelfStatus] }}
          value={token} name="token"
          onChange={handleTokenChange}
          onKeyDown={(e) =>{
            if(e.key === 'Enter' ){
              e.target.blur()
            }
          }}
          onBlur={checkStatus} />
      </td>
      <td>
        <input className="form-control" required="required"
          value={pcs} name="pcs" type="number" min="1"
          onChange={handlePcsChange} />
        <div >
          {
            (pcs.length === 0 || pcs < 1) && <div style={{ color: 'red' }}>必須填寫</div>
          }
        </div>
      </td>
      <td style={{ width: "2%" }}>
        <a
          className="btn btn-danger"
          onClick={() => destroyShelf(shelf.randomKey)}
        >X</a>
      </td>
    </tr>
  )
}

