import React, { Component } from 'react'
import Select from 'react-select'

const Version = {
  LEGACY: "legacy",
  API: "api"
}

export default class Ecan extends Component {
  constructor(props) {
    super(props)
    this.state = {
      version: Version["API"]
    }
    this.updateVersion = this.updateVersion.bind(this)
  }
  componentDidMount() {
    if (this.props.shipping_account.ftp_username)
      this.updateVersion(Version["LEGACY"])
  }
  updateVersion(version) {
    this.setState({version: version})
  }
  render() {
    let { shipping_account_type, shipping_account } = this.props

    return (
      <>
        <div className="form-group">
          <div className="col-md-offset-3 col-md-6">
            <label htmlFor={`${shipping_account_type}-${shipping_account.id}-version-switcher`} className="switcher switcher-success">
              <input type="checkbox" id={`${shipping_account_type}-${shipping_account.id}-version-switcher`} checked={this.state.version === Version["API"]} onChange={(e) => this.updateVersion(e.target.checked ? Version["API"] : Version["LEGACY"])}/>
              <div className="switcher-indicator">
                <div className="switcher-yes">API版</div>
                <div className="switcher-no">舊版</div>
              </div>
            </label>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>客代</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" required='required' name={`shipping_accounts[${shipping_account.id}][customer_code]`} defaultValue={shipping_account.customer_code}/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>名稱</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][customer_name]`} defaultValue={shipping_account.customer_name}/>
          </div>
        </div>
        <div className="form-group">
          <label className="col-md-3 control-label">
            <label>聯絡電話</label>
          </label>
          <div className="col-md-6">
            <input className="form-control" name={`shipping_accounts[${shipping_account.id}][customer_phone]`} defaultValue={shipping_account.customer_phone}/>
          </div>
        </div>
        {
          this.state.version == Version["API"] &&
          <>
            <input type="hidden" name={`shipping_accounts[${shipping_account.id}][ftp_username]`} ></input>
            <input type="hidden" name={`shipping_accounts[${shipping_account.id}][ftp_password]`} ></input>

          </>
        }
        {
          this.state.version == Version["LEGACY"] &&
            (<>
              <div className="form-group">
                <label className="col-md-3 control-label">
                  <label>物流貨號</label>
                </label>
                <div className="col-md-6">
                  <div className="input-daterange input-group">
                    <input className="form-control" name={`shipping_accounts[${shipping_account.id}][number_start]`} defaultValue={shipping_account.number_start} placeholder="起始貨號"/>
                    <span className="input-group-addon"> ~ </span>
                    <input className="form-control" name={`shipping_accounts[${shipping_account.id}][number_end]`} defaultValue={shipping_account.number_end} placeholder="結束貨號"/>
                  </div>
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-3 control-label">
                  <label>FTP 帳號</label>
                </label>
                <div className="col-md-6">
                  <input className="form-control" name={`shipping_accounts[${shipping_account.id}][ftp_username]`} defaultValue={shipping_account.ftp_username}/>
                </div>
              </div>
              <div className="form-group">
                <label className="col-md-3 control-label">
                  <label>FTP 密碼</label>
                </label>
                <div className="col-md-6">
                  <input className="form-control" name={`shipping_accounts[${shipping_account.id}][ftp_password]`} defaultValue={shipping_account.ftp_password}/>
                </div>
              </div>
              <input type='hidden' value="p2ap3.e-can.com.tw" name={`shipping_accounts[${shipping_account.id}][ftp_address]`}/>
            </>)
        }
      </>
    )
  }
}