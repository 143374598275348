import React, { Component } from 'react'

export default class TransferItem extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <div className='panel-group'>
    <div className="panel panel-default">
      <div className="panel-heading">
        <a aria-expanded="false" className="accordion-toggle" data-toggle="collapse" href={`#collapse-${this.props.id}`}>
          {
            [
              this.props.source_product_storage.product.name,
              this.props.source_product_storage.product_storage_type.code,
              this.props.source_product_storage.product_storage_type.name,
              this.props.source_product_storage.expiration_date,
              this.props.source_product_storage.batch,
            ].filter(e=>e).join(' / ')
          }
          <span style={{marginLeft: '10px',marginRight:'10px'}}>
            <i className='ion-md-arrow-round-forward'></i>
          </span>
          {
            [
              this.props.destination_product_storage.product_storage_type.code,
              this.props.destination_product_storage.product_storage_type.name,
              this.props.destination_product_storage.expiration_date,
              this.props.destination_product_storage.batch,
            ].filter(e=>e).join(' / ')
          }
          <span className='pull-right'>總數: {this.props.quantity}</span>
        </a>
      </div>
      <div className="panel-collapse collapse in" id={`collapse-${this.props.id}`}>
        <div className="panel-body">
          {
            this.props.source_product_storage.shelf_storages.map(shelf_storage=>{
              return <a 
              onClick={()=>this.props.onShelfClick(this.props.id,shelf_storage.id)}
              style={{
                marginBottom: '10px',marginRight: '10px'
              }} className={`btn ${shelf_storage.quantity_to_transfer ? 'btn-warning' : 'btn-outline btn-info'}`} key={shelf_storage.shelf.token}>
                {`${shelf_storage.shelf.token}   (${shelf_storage.pcs})`}
                {
                  shelf_storage.quantity_to_transfer ? <div>
                    <input type='hidden' name='shelf_storages[][id]' value={shelf_storage.id}></input>
                    <input type='hidden' name='shelf_storages[][destination_product_storage_id]' value={this.props.destination_product_storage.id}></input>
                    <input type='hidden' name='shelf_storages[][quantity]' value={shelf_storage.quantity_to_transfer}></input>
                    <hr style={{margin:0}}></hr>
                    調撥 : {shelf_storage.quantity_to_transfer}
                  </div> : ''
                }
                </a>
            })
          }
        </div>
        {
          this.props.valid ? "" :
          <div className="panel-footer">
            <span className='text-danger'>數量不足</span>
          </div>
        }
      </div>
    </div>
    </div>
  }
}