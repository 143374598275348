import React from 'react';
import { useRecordContext } from './Context';

function SelectAll() {
  const { isAllRecords, toggleCurrentAllRecords } = useRecordContext();

  return (<label className='custom-control custom-checkbox' htmlFor={`SelectAllItems`}>
        <input
          id='SelectAllItems'
          type={'checkbox'}
          className='custom-control-input'
          checked={isAllRecords}
          onChange={toggleCurrentAllRecords}
        />
        <span className='custom-control-indicator'></span>
        &nbsp;

      </label>
  );
}

export default SelectAll;
