import React, { Component } from 'react'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import _ from "lodash";

export default class ShopProducts extends Component {
  constructor(props) {
    super(props)
    let original_products = this.props.original_products || []
    this.state = {
      products: original_products,
      current_index: original_products.length,
      shop_products: JSON.parse(JSON.stringify(original_products))
    }
    this.remove = this.remove.bind(this)
    this.addProduct = this.addProduct.bind(this)
    this.onProductSelect = this.onProductSelect.bind(this)
    this.onBatchSelect = this.onBatchSelect.bind(this)
    this.loadOptions = this.loadOptions.bind(this)
    this.debouncedLoadOptions = _.debounce(this.loadOptions, 500)
    this.toOption = this.toOption.bind(this)
  }

  componentDidMount() {
    if (this.state.products.length == 0) {
      this.addProduct()
    }
    if (!!this.props.shop_id) {
      this.fetchDefaultProducts()
    }
  }
  componentDidUpdate(prevProps) {
    if (this.props.shop_id !== prevProps.shop_id && !!this.props.shop_id) {
      this.fetchDefaultProducts()
    }
  }
  fetchDefaultProducts() {
    this.fetchProducts()
      .then(data => {
        this.setState({shop_products: data.products})
      })
  }
  async fetchProducts(inputValue = "") {
    let url_prefix = this.props.operator_interface ? 'operator' : 'admin'
    return await fetch(`/${url_prefix}/products.json?shop_id=${this.props.shop_id}&search=${inputValue}&product_only=true&limit=50`,
    {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token,
      },
      credentials: 'same-origin'
    }).then(res => res.json())
  }

  toOption(product){
    return { label: (product.label || [product.barcode,product.uid, product.name].filter(Boolean).join("/")), value: product.uid }
  }

  loadOptions(inputValue, callback){
    if(inputValue.length <2){
      callback([])
      return
    }

    this.fetchProducts(inputValue)
    .then(data => {
      let shop_products = this.state.shop_products
      let options = data.products.map(product=> this.toOption(product))
      for(let _product of data.products){
        let found = false
        for(let product of shop_products){
          if(_product.uid == product.uid){
            found = true
            break
          }
        }
        if(!found){
          shop_products.push(_product)
        }
      }
      this.setState({shop_products: shop_products})
      callback(options)
    })
  };

  remove(index) {
    let products = this.state.products
    products = products.filter(p => p.index != index)
    console.log('remove')
    this.setState({ products: products })
  }

  addProduct() {
    let products = this.state.products
    products.push({ index: this.state.current_index, uid: '',batches:[] })
    $('.submit-button').show()
    console.log('Addproduct')
    this.setState({ products: products, current_index: this.state.current_index + 1 })
  }
  onProductSelect(index, uid,label) {
    let products = this.state.products
    console.log('on Product Select')
    for (let product of products) {
      if (index == product.index) {
        product.uid = uid
        product.label = label
        for (let shop_product of this.state.shop_products) {
          if (uid == shop_product.uid) {
            product.is_bundle = shop_product.is_bundle
            product.batches = shop_product.batches || []
          }
        }
        break
      }
    }
    this.setState({ products: products })
  }

  onBatchSelect(index,batch){
    let products = this.state.products
    for (let product of products) {
      if (index == product.index) {
        product.batch = batch
        break
      }
    }
    console.log('on Batch Select')
    this.setState({ products: products })
  }


  render() {
    let products = this.state.products.map((product) => {
      let batches = []
      if(product.batches && product.batches.length > 0){
        batches = product.batches.map(b => { return { label: b, value: b} })
        batches.unshift({label: '不指定',value:''},{label: '指定無批號',value:'指定無批號'})
      }
      let options = this.state.shop_products.map(sp=>this.toOption(sp))
      let block = <div className='row' style={{ "padding": "3px" }} key={product.index}>
        <div className='col-md-3'>
          <AsyncSelect name={`products[${product.index}][uid]`}
            className='react-select-container'
            classNamePrefix="react-select"
            onChange={(e) => {
              this.onProductSelect(product.index, e.value,e.label)
            }}
            value={this.toOption(product)}
            searchable={true}
            clearable={false}
            required={true}
            placeholder="請選擇商品"
            noOptionsMessage={() => '找不到商品，請輸入兩碼以上關鍵字'}
            loadOptions={(inputValue,callback) => this.debouncedLoadOptions(inputValue,callback)}
            defaultOptions={[...options, {label: "輸入兩碼以上關鍵字查看更多..." ,value: undefined, isDisabled: true}]}
          />
        </div>
        {
          this.props.storage_types ?
            <div className='col-md-1' >
              {
                product.is_bundle ? '' :
                  <select className='form-control' name={`products[${product.index}][product_storage_type_id]`} defaultValue={product.storage_type} required="required"  >
                    {
                      this.props.storage_types.map(type => {
                        return <option key={type[0]} value={type[0]}>{type[2]}-{type[1]}</option>
                      })
                    }
                  </select>
              }
            </div>
            : ''
        }
        <div className='col-md-1' >
          <input name={`products[${product.index}][quantity]`} defaultValue={product.quantity || 1} required='required' type='number' min='1' className='form-control' />
        </div>
        <div className='col-md-2' >
          {
            this.props.batch_input ?
              product.is_bundle ? '' :
                <input name={`products[${product.index}][batch]`} type='text' placeholder="批號" className='form-control' defaultValue={product.batch} />
              :
              batches.length > 0 ?
                <Select
                name={`products[${product.index}][batch]`}
                options={batches}
                value={product.batch ? {label: product.batch, value: product.batch} : null}
                searchable={true}
                clearable={false}
                onChange={(e) => this.onBatchSelect(product.index, e.value)}
                placeholder="批號" /> :''
          }
        </div>

        <div className='col-md-2'>
          <input name={`products[${product.index}][expiration_date]`} type='date' max={'9999-01-01'} placeholder="效期" className='form-control' defaultValue={product.expiration_date} />
        </div>
        <div className='col-md-2'>
          <input name={`products[${product.index}][note]`} type='text' placeholder="備註" className='form-control' defaultValue={product.note} />
        </div>

        <div className='col-md-1'>
          <a className='btn btn-danger' onClick={() => this.remove(product.index)}>X</a>
        </div>
      </div>
      return block
    })
    return (
      <div>
        <div className='form-group'>
          <label>商品</label>
          <div>
            {products}
          </div>
        </div>

        <div className='form-group'>
          <a className='btn btn-success btn-block' onClick={this.addProduct}>+</a>
        </div>
      </div>
    )
  }
}
