import {
  FINISHED_GOODS_ORDER_SET_CSRF_TOKEN,
  FINISHED_GOODS_ORDER_SET_SHOPS,
  FINISHED_GOODS_ORDER_SET_PRODUCTS,
  FINISHED_GOODS_ORDER_SET_STORAGE_TYPES,
  FINISHED_GOODS_ORDER_SET_PRODUCT_ITEMS,
  FINISHED_GOODS_ORDER_SET_EXPIRATION_DATES,

  FINISHED_GOODS_ORDER_CHANGE_SHOP,
  FINISHED_GOODS_ORDER_SET_TARGET_ITEMS,

  FINISHED_GOODS_ORDER_ADD_PRODUCT_ITEM, FINISHED_GOODS_ORDER_UPDATE_PRODUCT_ITEM,
  FINISHED_GOODS_ORDER_DELETE_PRODUCT_ITEM, FINISHED_GOODS_ORDER_PRODUCT_ITEM_CHANGE,
  FINISHED_GOODS_ORDER_ITEM_EXPIRATION_DATE_CHANGE,FINISHED_GOODS_ORDER_COPY_ITEM,
  FINISHED_GOODS_ORDER_SYNC_PRODUCTS,

} from '../../../actions/action_types'

export const setCSRFToken = (csrf_token) => {
  return {
    type: FINISHED_GOODS_ORDER_SET_CSRF_TOKEN,
    csrf_token: csrf_token
  }
}

// shop
export const setShops = (shops) => {
  return {
    type: FINISHED_GOODS_ORDER_SET_SHOPS,
    shops: shops
  }
}

// product
export const setProducts = (products) => {
  return {
    type: FINISHED_GOODS_ORDER_SET_PRODUCTS,
    products: products
  }
}

export const setStorageTypes = (storage_types) => {
  return {
    type: FINISHED_GOODS_ORDER_SET_STORAGE_TYPES,
    storage_types: storage_types
  }
}



export const setExpirationDates = (uid, expiration_dates) => {
  return {
    type: FINISHED_GOODS_ORDER_SET_EXPIRATION_DATES,
    uid: uid,
    expiration_dates: expiration_dates
  }
}

export const setProductItems = (product_items) => {
  return {
    type: FINISHED_GOODS_ORDER_SET_PRODUCT_ITEMS,
    product_items: product_items
  }
}


export const onShopChange = (shop) => {
  return {
    type: FINISHED_GOODS_ORDER_CHANGE_SHOP,
    shop: shop
  }
}

export const onTargetItemsSet = (target_items) => {
  return {
    type: FINISHED_GOODS_ORDER_SET_TARGET_ITEMS,
    target_items: target_items
  }
}


// product
export const onProductItemAdd = (item) => {
  return {
    type: FINISHED_GOODS_ORDER_ADD_PRODUCT_ITEM,
    item: item
  }
}

export const onProductItemUpdate = (item) => {
  return {
    type: FINISHED_GOODS_ORDER_UPDATE_PRODUCT_ITEM,
    item: item
  }
}

export const onProductItemDelete = (item) => {
  return {
    type: FINISHED_GOODS_ORDER_DELETE_PRODUCT_ITEM,
    item: item
  }
}

export const onSyncProducts = (shop_products) => {
  return {
    type: FINISHED_GOODS_ORDER_SYNC_PRODUCTS,
    shop_products: shop_products
  }
}

export const onProductItemChange = (item) => {
  return {
    type: FINISHED_GOODS_ORDER_PRODUCT_ITEM_CHANGE,
    item: item
  }
}

export const onCopyItem = (items) => {
  return {
    type: FINISHED_GOODS_ORDER_COPY_ITEM,
    target_items: items
  }
}

export const onItemExpirationDateChange = (key,date) => {
  return {
    type: FINISHED_GOODS_ORDER_ITEM_EXPIRATION_DATE_CHANGE,
    item: {key: key,expiration_date: date}
  }
}



