


export function requestFullscreen(_el) {
  var rfs = _el.requestFullscreen
    || _el.webkitRequestFullScreen
    || _el.mozRequestFullScreen
    || _el.msRequestFullscreen
  rfs.call(_el);
}

export function writePermanentCookie(key,value){
  var date = new Date();
  date.setTime(date.getTime() + (365*24*60*60*1000));
  var expires = "; expires=" + date.toUTCString();
  document.cookie = key + "=" + (value || "")  + expires + "; path=/";
}

export function readCookie(key) {
  var nameEQ = key + "=";
  var ca = document.cookie.split(';');
  for(var i=0;i < ca.length;i++) {
    var c = ca[i].trim();
    if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
  }
  return null;
}