import React, { Component } from 'react'
import { Provider } from 'react-redux'
import Product from '../../../components/common/receipt/product'
import operatorStore from '../../../store/admin_store'
import { setStorageTypes, setProductItems, setProducts } from '../../../actions/common/receipt/form'

const store = operatorStore()

export default class Form extends Component {
  componentWillMount() {
    if (this.props.storage_types) store.dispatch(setStorageTypes(this.props.storage_types))
    if (this.props.product_items) store.dispatch(setProductItems(this.props.product_items))
  }

  componentWillUnmount() {
    store.dispatch(setProductItems([]))
    store.dispatch(setStorageTypes([]))
    store.dispatch(setProducts([]))

  }

  render() {
    return (
      <Provider store={store}>
        <div>
          <Product operator_interface={true} shop_id={this.props.shop_id}/>
        </div>
      </Provider>
    )
  }
}
