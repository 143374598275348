import React, { Component } from 'react'

const DEFAULT_STATE = {
  shippings: []
}


export default class Cancel extends Component {
  constructor(props) {
    super(props)
    this.state = DEFAULT_STATE
    this.onBarcodeInput = this.onBarcodeInput.bind(this)
    this.confirm = this.confirm.bind(this)
    this.onRemoveShipping = this.onRemoveShipping.bind(this)
  }
  confirm() {
  }


  componentDidMount() {
    document.body.onkeydown = (e) => {
      this.refs.barcode_input.focus()
    }
  }



  static onShippingError() {
    notyError("託運單條碼錯誤!")
  }


  onBarcodeInput(e) {
    if (e.keyCode == 13 && this.refs.barcode_input.value) {
      let barcode = this.refs.barcode_input.value
      this.refs.barcode_input.value = ""
      fetch(`/operator/shippings/${barcode}.json`, {
        'credentials': 'same-origin'
      }).then(res => res.json())
      .then(data => {
        let shippings = this.state.shippings
        for(let shipping of shippings){
          if(shipping.id == data.id){
            return
          }
        }
        shippings.unshift(data)
        this.setState({shippings: shippings})
      }).catch(e=>{
        Cancel.onShippingError()
      } )
    }
  }

  onRemoveShipping(id){
    this.setState({
      shippings: this.state.shippings.filter(shipping=> shipping.id!= id)
    })

  }

  render() {
    return (
      <div>
        <div className="page-header">
          <div className="pull-left"><h1>託運單作廢</h1></div>
          <div className="pull-right">
            <input type="text" ref="barcode_input"
              placeholder="請輸入或者掃描託運單條碼"
              autoFocus={true}
              onKeyDown={this.onBarcodeInput}
              className="form-control input-lg" />
          </div>
        </div>
        <div className='col-md-9'>
          <div className='panel'>
            <div className='panel-heading'>
              託運單作廢列表
            </div>
            <form action="/operator/shippings/batch_destroy" method="POST">
              <input type="hidden" value="delete" name="_method"/>

              <input type='hidden' value={this.props.authenticity_token} name='authenticity_token'/>
              <div className="panel-body">
                <table className="table">

                  <thead>
                    <tr>
                      <th>
                        託運單
                      </th>
                      <th>
                        物流
                      </th>
                      <th>
                        訂單編號
                      </th>
                      <th></th>
                    </tr>

                  </thead>
                  <tbody>
                    {
                      this.state.shippings.map(shipping=>{
                        return <tr key={shipping.id}>
                        <td>
                          <input type="hidden" name="shippings[]" value={shipping.id}/>
                          {shipping.barcode}
                        </td>
                        <td>
                          {shipping.shipping_type}
                        </td>
                        <td>
                          {shipping.order_name}
                        </td>
                        <td>
                          <a className='btn btn-default' href="javascript:void(0)" onClick={()=>this.onRemoveShipping(shipping.id)}>
                            <i className='ion-md-close'></i>
                          </a>
                        </td>
                        </tr>

                      })
                    }
                  </tbody>
                </table>
              </div>
              <div className='panel-footer'>
              {
                this.state.shippings.length > 0 ?
                <button className='btn btn-primary' type='submit'>
                  送出
                </button> : ""
              }
              </div>
            </form>
          </div>
        </div>


      </div>
    )
  }
}
