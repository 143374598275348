import React from 'react';
import Content from './Content';
import RulesProvider from './Context';


function Rules({
  supportRules,
  searchable ,
  tableId,
  lastModified
}) {
  return (
    <RulesProvider>
      <Content supportRules={supportRules} tableId={tableId} searchable={searchable} lastModified={lastModified} />
    </RulesProvider>
  );
}

export default Rules;
