import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import _ from "lodash";

import * as AdminReceiptActions from '../../../actions/common/receipt/form'

class ProductItem extends Component {
  constructor(props) {
    super(props)
    this.loadOptions = this.loadOptions.bind(this)
    this.debouncedLoadOptions = _.debounce(this.loadOptions, 500);
  }

  componentDidMount() {
    this.fetchDefaultProducts()
  }
  componentDidUpdate(prevProps) {
    if (this.props.shop_id !== prevProps.shop_id && !!this.props.shop_id) {
      this.fetchDefaultProducts()
    }
  }
  fetchDefaultProducts() {
    this.fetchProducts()
      .then(data => {
        this.props.setProducts(data.products)
      })
  }
  async fetchProducts(inputValue = "") {
    let url_prefix = this.props.operator_interface ? 'operator' : 'admin'
    return await fetch(`/${url_prefix}/products.json?shop_id=${this.props.shop_id}&search=${inputValue}&product_only=true&limit=50`,
    {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token,
      },
      credentials: 'same-origin'
    }).then(res => res.json())
  }

  toOption(product){
    return { label: [product.uid, product.name].filter(Boolean).join("/"), value: product.id }
  }

  loadOptions(inputValue, callback){
    if(inputValue.length <2){
      callback([])
      return
    }

    this.fetchProducts(inputValue)
    .then(data => {
      let products = this.props.products
      console.log(products)
      let options = data.products.map(product=>this.toOption(product))
      for(let shop_product of data.products){
        let found = false
        for(let product of products){
          if(shop_product.uid == product.uid){
            found = true
            break
          }
        }
        if(!found){
          products.push(shop_product)
        }
      }
      this.props.setProducts(products)
      callback(options)
    })
  };


  handleItemDelete = (event) => {
    this.props.onProductItemDelete({index: this.props.index})
  }

  handleProductChange = (product_id,product_label) => {
    console.log('on Change')
    console.log(product_id)
    this.props.onProductItemChange({
      index: this.props.index,
      product_id: product_id,
      product_label: product_label
    })
  }

  handleIdChange = (event) => {
    this.handleItemUpdate('id', event.target.value)
  }

  handleProductTypeChange = (event) => {
    this.handleItemUpdate('product_storage_type_id', event.target.value)
  }

  handlePcsChange = (event) => {
    this.handleItemUpdate('pcs_per_box', event.target.value)
  }

  handleBoxCountChange = (event) => {
    this.handleItemUpdate('box_count', event.target.value)
  }

  handleExpirationDateChange = (event) => {
    this.handleItemUpdate('expiration_date', event.target.value)

    this.props.onProductExpirationDateChange({
      index: this.props.index,
      expiration_date: event.target.value,
    })
  }

  handleBatchChange = (event) => {
    this.handleItemUpdate('batch', event.target.value)
  }

  handleItemUpdate = (key, value) => {
    this.props.onProductItemUpdate({
      index: this.props.index,
      [key]: value,
    })
  }

  handleDestroyChange = (event) => {
  }


  render() {
    const { products, storage_types, validates, item } = this.props
    const { id, product_id,product_label, product_storage_type_id, pcs_per_box, box_count, expiration_date, batch, _destroy } = item

    const storage_type_select_options = storage_types.map(function (storage_type, index) {
      let name = `${storage_type.code} - ${storage_type.name}`
      let id = storage_type.id
      return <option key={index} value={id}>{name}</option>
    })

    let expiration_date_html = ''
    if (item.valid_period) {
      expiration_date_html = (
        <div className={"form-group m-a-0 " + expiration_date_warning}>
          <input name="receipt[items_attributes][][expiration_date]" value={expiration_date || ''} onChange={this.handleExpirationDateChange} className="form-control datepicker-input" type="date" />
        </div>
      )
      let expiration_date_warning = ''
      if (validates[this.props.index] && validates[this.props.index].valid_expiration_date) {
        expiration_date_warning = 'has-warning'
        notyWarning('效期錯誤，請大於品項的最短效期')
      }
    }

    let tr_class = ''
    let destroy_html = ''
    if (_destroy) {
      destroy_html = (
        <input name="receipt[items_attributes][][_destroy]" type="hidden" value={_destroy || ''} onChange={this.handleDestroyChange} />
      )
      tr_class = 'hide'
    }

    return (
      <tr className={tr_class}>
        <td className="text-center">
          <input name="receipt[items_attributes][][id]" type="hidden" value={id || ''} onChange={this.handleIdChange} />
          {destroy_html}
          <a className="btn btn-danger btn-xs" onClick={this.handleItemDelete}>{`刪除`}</a>
        </td>
        <td>
          <div className="form-group m-a-0">
            <AsyncSelect
              name="receipt[items_attributes][][product_id]"
              className='react-select-container'
              classNamePrefix="react-select"
              value={product_id ? { label: [item.uid, item.name].filter(Boolean).join("/"), value: product_id } : null}
              searchable={true}
              clearable={false}
              placeholder="請選擇商品"
              noOptionsMessage={() => '找不到商品，請輸入兩碼以上關鍵字'}
              onChange={(e)=>this.handleProductChange(e.value,e.label)}
              loadOptions={(inputValue,callback) => this.debouncedLoadOptions(inputValue,callback)}
              defaultOptions={[...products.map(product=>this.toOption(product)), {label: "輸入兩碼以上關鍵字查看更多..." ,value: undefined, isDisabled: true}]}
            />
          </div>
        </td>
        <td>
          <div className="form-group m-a-0">
            <select name="receipt[items_attributes][][product_storage_type_id]"
              className="custom-select form-control"
              value={product_storage_type_id}
              onChange={this.handleProductTypeChange}
            >
              {storage_type_select_options}
            </select>
          </div>
        </td>
        <td>
          <div className="form-group m-a-0">
            <input name="receipt[items_attributes][][pcs_per_box]"
              type="number" min="1" className="form-control" required='required'
              value={pcs_per_box || ''} onChange={this.handlePcsChange}/>
          </div>
        </td>
        <td>
          <div className="form-group m-a-0">
            <input name="receipt[items_attributes][][box_count]"
              type="number" min="1" className="form-control" required='required'
              value={box_count || ''} onChange={this.handleBoxCountChange}/>
          </div>
        </td>
        <td>
          {expiration_date_html}
        </td>
        <td>
          <div className="form-group m-a-0">
            <input name="receipt[items_attributes][][batch]"
              className="form-control ignore" type="text"
              value={batch || ''} onChange={this.handleBatchChange}/>
          </div>
        </td>
      </tr>
    )
  }
}

ProductItem.propTypes = {
  storage_types: PropTypes.array.isRequired,
  validates: PropTypes.object.isRequired,
}

// Redux connect
const mapStateToProps = (state, ownProps) => {
  return {
    products: state.ReceiptForm.products,
    product_items: state.ReceiptForm.product_items,
    storage_types: state.ReceiptForm.storage_types,
    validates: state.ReceiptForm.validates,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(AdminReceiptActions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductItem)
