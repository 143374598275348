import React from "react"
import { useState, useEffect, useMemo } from 'react';
import DataTable from 'react-data-table-component';
import LoadingOverlay from 'react-loading-overlay';
import ProductSelect from '../../../components/admin/product_select'
import Select from 'react-select'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement
} from "chart.js"
import { Line, Pie } from "react-chartjs-2"

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend
)

const options = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: "index",
    intersect: false
  },
  stacked: false,
  scales: {
    y: {
      ticks: {
        stepSize: 10
      },
      type: "linear",
      display: true,
      position: "left",
      min: 0
    },
  }
}

const styles = {
  container: {
    width: '100%',
    height: '100%',
  },

  reactSelect: {
    control: (provided, state) => ({
      ...provided,
      minHeight: '30px',
      boxShadow: state.isFocused ? null : null,
    }),
    valueContainer: (provided, state) => ({
      ...provided,
      padding: '0 6px',
    }),

    input: (provided, state) => ({
      ...provided,
      margin: '0px',
    }),
    indicatorSeparator: state => ({
      display: 'none',
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
    }),
    menu: (provided, state) => ({
      ...provided,
      zIndex: 1000
    })
  }
};





export default function ProductCharts(props) {
  const monthAgo = (n) => {
    const date = new Date();
    date.setMonth(date.getMonth() - n);
    return date;
  }

  const hashCode = (str) => { // java String#hashCode
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return hash;
  }

  const intToRGB = (string) => {
    let c = (hashCode(string) & 0x00FFFFFF)
      .toString(16)
      .toUpperCase();

    let rgb = "00000".substring(0, 6 - c.length) + c;
    return `#${rgb}`
  }



  const { channels, preloadProducts ,categories} = props;
  const [selectedChannels, setSelectedChannels] = useState(channels.map((channel) => channel.id));
  const [showChannels, setShowChannels] = useState(false);
  const [startDate, setStartDate] = useState(monthAgo(1).toLocaleDateString('en-CA'));
  const [endDate, setEndDate] = useState((new Date()).toLocaleDateString('en-CA'));

  const [dates, setDates] = useState([])
  const [saleProducts, setSaleProducts] = useState([])
  const [filterText, setFilterText] = useState('');

  const [debounceState, setDebounceState] = useState(false)
  const [loading, setLoading] = useState(false)

  const [selectedProducts, setSelectedProducts] = useState([])

  const [orderProducts, setOrderProducts] = useState([])
  const [groupedProducts, setGroupedProducts] = useState([])
  const [debounceTimer, setDebounceTimer] = useState(null)
  const [limit, setLimit] = useState(30)
  const [isInitialized, setIsInitialized] = useState(false);
  const [excludeCategories, setExcludeCategories] = useState([])

  // const [dailyOrdersData, setDailyOrdersData] = useState([])

  const filteredProducts = saleProducts.filter(
    (product) => {
      return product.uid.toLowerCase().indexOf(filterText.toLowerCase()) !== -1 ||
        product.name.toLowerCase().indexOf(filterText.toLowerCase()) !== -1
    }
  );
  const subHeaderComponentMemo = React.useMemo(() => {
    return (
      <div className='row'>
        <div className='col-md-12'>
          <input className='form-control' placeholder='搜尋商品' onChange={
            (e) => {
              setFilterText(e.target.value)
            }
          } />
        </div></div>
    );
  }, [filterText]);

  const categoryOptions = categories.map((category) => {
    return {
      value: category.id,
      label: category.name
    }
  })


  useEffect(() => {
    if(!isInitialized) {
      setIsInitialized(true)
      return;
    }
    if (debounceState) {
      return;
    }
    setDebounceState(true)
    if (debounceTimer) {
      clearTimeout(debounceTimer)
    }
    setDebounceTimer(setTimeout(() => {
      setDebounceState(false)
      setDebounceTimer(null)
    }, 2000))
  }, [selectedChannels, startDate, endDate, limit])

  useEffect(() => {
    if(!debounceState) {
      fetchOrders()
    }
  }, [debounceState])

  const checkRange = () => {
    if (startDate > endDate) {
      notyError('開始日期不可晚於結束日期')
      return false;
    }
    if (endDate > (new Date()).toLocaleDateString('en-CA')) {
      notyError('結束日期不可晚於今天')
      return false;
    }
    // 時間區間不可超過半年
    let start = new Date(startDate)
    let end = new Date(endDate)
    let diffTime = Math.abs(end - start);
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > 180) {
      notyError('時間區間不可超過半年')
      return false;
    }
    return true;
  }

  const orderProductOrdersPieMemo = useMemo(() => {
    let sortedOrderProducts = groupedProducts.sort((a, b) => {
      return a.orders.length - b.orders.length
    })

    return [
      {
        label: '訂單數',
        data: sortedOrderProducts.map((orderProduct) => {
          return orderProduct.orders.length
        }),
        borderColor: sortedOrderProducts.map(() => "#000000"),
        backgroundColor: sortedOrderProducts.map((orderProduct) => {
          return intToRGB(orderProduct.name)
        }),
        borderWidth: 1
      }
    ]
  }, [groupedProducts])

  const orderProductPcsPieMemo = useMemo(() => {
    let sortedOrderProducts = groupedProducts.sort((a, b) => {
      return a.quantity - b.quantity
    })

    return [
      {
        label: '總銷售量',
        data: sortedOrderProducts.map((orderProduct) => {
          return orderProduct.quantity
        }),
        borderColor: sortedOrderProducts.map(() => "#000000"),
        backgroundColor: sortedOrderProducts.map((orderProduct) => {
          return intToRGB(orderProduct.name)
        }),
        borderWidth: 1
      }
    ]
  }, [groupedProducts])

  const orderProductsOrdersLineMemo = useMemo(() => {
    let orderDatasets = []

    for(let groupedProduct of groupedProducts) {
      orderDatasets.push({
        label: groupedProduct.name,
        data: dates.map((date) => {
          let orders = orderProducts.filter((orderProduct) => {
            return orderProduct.date == date && orderProduct.product_id == groupedProduct.id
          }).map((orderProduct) => {
            return orderProduct.order_id
          })
          return new Set(orders).size
        }),
        borderColor: intToRGB(groupedProduct.name),
        backgroundColor: intToRGB(groupedProduct.name),
        yAxisID: "y"
      })
    }
    return orderDatasets
  }, [ groupedProducts])

  const orderProductsPcsLineMemo = useMemo(() => {
    let orderDatasets = []

    for(let groupedProduct of groupedProducts) {
      orderDatasets.push({
        label: groupedProduct.name,
        data: dates.map((date) => {
          let sum = orderProducts.filter((orderProduct) => {
            return orderProduct.date == date && orderProduct.product_id == groupedProduct.id
          }).reduce((sum, orderProduct) => sum + orderProduct.quantity, 0)
          return sum
        }),
        borderColor: intToRGB(groupedProduct.name),
        backgroundColor: intToRGB(groupedProduct.name),
        yAxisID: "y"
      })
    }
    return orderDatasets
  }, [groupedProducts])




  const fetchOrders = () => {
    if (!checkRange()) {
      return;
    }
    setLoading(true)
    const url = new URL('/admin/products/graph_data', window.location.origin);
    const params = {
      start_date: startDate,
      end_date: endDate,
      channels: selectedChannels,
      products: selectedProducts.map((product) => product.value),
      exclude_categories: excludeCategories.map((category) => category.value),
      limit: limit,
      status: 'ready,processing,done,nostock'
    }
    Object.keys(params).forEach(key => url.searchParams.append(key, params[key]))
    fetch(url).then((res) => {
      return res.json()
    }).then((data) => {
      let _dates = sortDates()

      let productsHash = {}

      data.forEach((orderProduct) => {
        if (!productsHash[orderProduct.product_id]) {
          productsHash[orderProduct.product_id] = {
            id: orderProduct.product_id,
            name: orderProduct.product.name,
            uid: orderProduct.product.uid,
            quantity: 0,
            orders: []
          }
        }
        productsHash[orderProduct.product_id].quantity += orderProduct.quantity
        productsHash[orderProduct.product_id].orders.push(orderProduct.order_id)
      })
      // setDates不會馬上反應
      setOrderProducts(data)
      setGroupedProducts(Object.values(productsHash))
      setLoading(false)
    });
  }



  const sortDates = () => {
    let dates = []
    let date = new Date(startDate)
    while (date <= new Date(endDate)) {
      dates.push(date.toLocaleDateString('en-CA'))
      date.setDate(date.getDate() + 1)
    }
    setDates(dates)
    return dates
  }




  return (
    <>
      <LoadingOverlay
        active={loading}
        spinner
        text='資料載入中，請稍候...'
      >
        <div className="page-header">
          <div className="row">
            <div className="pull-left col-md-3">
              <h1>圖表分析</h1>
            </div>
            <div className="col-md-6 form-group">
              <div className='input-group'>
                <input type='date' name='start_date' className='form-control' max={
                  endDate > (new Date()).toLocaleDateString('en-CA') ? (new Date()).toLocaleDateString('en-CA') : endDate
                }
                  value={startDate} onChange={() => {
                    if (event.target.value > endDate) {
                      notyError('開始日期不可晚於結束日期')
                      return;
                    }
                    setStartDate(event.target.value);
                  }} />
                <span className='input-group-addon'>~</span>
                <input type='date' name='end_date' min={startDate} max={
                  (new Date()).toLocaleDateString('en-CA')
                } className='form-control' value={endDate} onChange={() => {
                  if (event.target.value < startDate) {
                    notyError('結束日期不可早於開始日期')
                    return;
                  }
                  setEndDate(event.target.value);
                }} />
              </div>
            </div>
            <div className="col-md-3 form-group dropdown">
              <a className="btn btn-info btn-outline" role='button' onClick={() => {
                setShowChannels(!showChannels);
              }}>選擇通路
                <i className='fa fa-caret-down'></i>
              </a>
              <div style={{ display: showChannels ? '' : 'none' }} >
                <ul className="list-unstyled">
                  {
                    channels.map((channel, index) => {
                      const checked = selectedChannels.includes(channel.id)
                      return (
                        <li key={index}>
                          <label className="checkbox-inline">
                            <input type="checkbox" value={channel.id} name='channels[]' checked={checked}
                              onChange={() => {
                                if (checked) {
                                  setSelectedChannels(selectedChannels.filter((id) => id != channel.id))
                                } else {
                                  setSelectedChannels([...selectedChannels, channel.id])
                                }
                              }}
                            /> {channel.code}
                          </label>
                        </li>
                      )
                    })
                  }
                  <li>
                    <a className="btn btn-outline btn-default" role='button' onClick={() => {
                      if (selectedChannels.length > 0) {
                        setSelectedChannels([])
                      } else {
                        setSelectedChannels(channels.map((channel) => channel.id))
                      }
                    }}>
                      {
                        selectedChannels.length > 0 ? '全部清除' : '全選選取'
                      }
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-1">
              <div className="form-group">
                <label className="">最多顯示</label>
              </div>
            </div>
            <div className="col-md-4">
              <div className="input-group">

                <input type="number" className="form-control" value={limit} onChange={(e) => {
                  if(e.target.value < 1) {
                    notyError('最少顯示1個商品')
                    return;
                  }
                  if(e.target.value > 200) {
                    notyError('最多顯示200個商品')
                    return;
                  }
                  setLimit(e.target.value)
                }} />
                <label className="input-group-addon">個商品</label>
              </div>
            </div>
            <div className="col-md-4">
              <ProductSelect multiple={true}
                placeholder="篩選商品"
                preloadProducts={preloadProducts}
                name="product"
                onChange={(option) => {
                  setSelectedProducts(option)
                }}
                onBlur={() => {
                  fetchOrders()
                }}
                selectedProducts={selectedProducts}

              />
            </div>
            <div className="col-md-3">
              <Select
                styles={styles.reactSelect}
                name={`exclude_categories`}
                isClearable
                isMulti
                placeholder="排除類別"
                value={excludeCategories}
                onChange={(option) => {
                  setExcludeCategories(option)
                }}
                onBlur={() => {
                  fetchOrders()
                }}
                options={categoryOptions}
              />

            </div>
            <div className="col-md-6">

            </div>
          </div>
        </div>
        {

          !loading && <>
            <div className='page-header'>
              <div className='col-md-5 col-md-offset-1'>
                <h4>訂單數量</h4>
                <Pie data={
                  {
                    labels: groupedProducts.map((product) => product.name),
                    datasets: orderProductOrdersPieMemo
                  }
                } />
              </div>
              <div className='col-md-5'>
                <h4>銷售量</h4>
                <Pie data={
                  {
                    labels: groupedProducts.map((product) => product.name),
                    datasets: orderProductPcsPieMemo
                  }
                } />
              </div>
            </div>
            <div style={{ height: '40vh', paddingBottom: '50px' }} className='page-header'>
              <h4>訂單數量</h4>
              <Line options={options} data={{
                labels: dates,
                datasets: orderProductsOrdersLineMemo
              }} />
            </div>
            <div style={{ height: '40vh', paddingBottom: '50px' }} className='page-header'>
              <h4>銷售量</h4>
              <Line options={options} data={{
                labels: dates,
                datasets: orderProductsPcsLineMemo
              }} />
            </div>
          </>
        }

      </LoadingOverlay>

    </>
  )
}

