import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import AsyncSelect from 'react-select/async'
import _ from "lodash";

import * as AdminBundleActions from '../../../actions/admin/bundle/form'

class ProductItem extends Component {
  constructor(props) {
    super(props)
    this.debouncedLoadOptions = _.debounce(this.loadOptions, 500);
    this.toOption = this.toOption.bind(this)
    this.state = {
      product_id: props.item?.product?.id,
    }
  }

  componentDidMount() {
    $(ReactDOM.findDOMNode(this)).find('.product-select').select2({
      allowClear: false,
    })
    this.fetchDefaultProducts()
  }
  fetchDefaultProducts() {
    this.fetchProducts()
      .then(data => {
        this.props.setProducts(data.products)
      })
  }
  async fetchProducts(inputValue = "") {
    return await fetch(`/admin/products.json?search=${inputValue}&product_only=true&limit=50`,
    {
      method: 'GET',
      headers: {
        'X-CSRF-Token': this.props.authenticity_token,
      },
      credentials: 'same-origin'
    }).then(res => res.json())
  }

  handleItemDelete = () => {
    this.props.onProductItemDelete(this.props.item.id)
  }
  loadOptions(inputValue, callback){
    if(inputValue.length <2){
      callback([])
      return
    }

    this.fetchProducts(inputValue)
    .then(data => {
      let products = this.props.products
      let options = data.products.map(product=>{
        return this.toOption(product)
      })
      for(let _product of data.products){
        let found = false
        for(let product of products){
          if(_product.uid == product.uid){
            found = true
            break
          }
        }
        if(!found){
          products.push(_product)
        }
      }
      this.props.setProducts(products)
      callback(options)
    })
  };
  toOption(product){
    return { value: product.id, label: `${product.uid} ${product.name}` }
  }

  render() {
    const { product_storage_types, item } = this.props
    let select_options = this.props.products.map((product, index)=>{
      return this.toOption(product)
    })

    const storage_type_select_options = product_storage_types.map(function (storage_type, index) {
      let name = `${storage_type.code} - ${storage_type.name}`
      let id = storage_type.id
      return <option key={index} value={id} selected={id==item?.product_storage_type?.id}>{name}</option>
    })

    return (
      <tr>
        <td className="text-center">
          <a className="btn btn-danger btn-xs" onClick={this.handleItemDelete}>刪除</a>
        </td>
        <td>
          <AsyncSelect
            name="bundle[products_attributes][][product_id]"
            className='react-select-container'
            classNamePrefix="react-select"
            placeholder="請選擇商品"
            value={!!this.state.product_id ? ((this.state.product_id == item?.product?.id) ? this.toOption(item.product) : select_options.find(p=>p.value == this.state.product_id)) : ''}
            onChange={(option)=>{this.setState({product_id: option.value})}}
            searchable={true}
            clearable={false}
            loadOptions={(input,callback)=>this.debouncedLoadOptions(input,callback)}
            defaultOptions={ [...select_options, {label: "輸入兩碼以上關鍵字查看更多..." ,value: undefined, isDisabled: true}] }
          />
        </td>
        <td>
          <select name="bundle[products_attributes][][product_storage_type_id]" className="custom-select form-control product-select" required>
            {storage_type_select_options}
          </select>
        </td>
        <td>
          <div className="form-group m-a-0">
            <input name="bundle[products_attributes][][quantity]" className="form-control" type="number" min={1} defaultValue={item.quantity || 1} required/>
          </div>
        </td>
      </tr>
    )
  }
}

ProductItem.propTypes = {
  product_storage_types: PropTypes.array.isRequired,
  item: PropTypes.object.isRequired,
  products: PropTypes.array.isRequired,
}

// Redux connect
const mapStateToProps = (state) => {
  return {
    product_storage_types: state.AdminBundleForm.product_storage_types,
    products: state.AdminBundleForm.products,
  }
}
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(AdminBundleActions, dispatch)
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductItem)
