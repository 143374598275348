import React, { Component } from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import Target from '../../../components/admin/processing_order/target'
import Product from '../../../components/admin/processing_order/product'
import ShopChannelSelect from '../../../components/admin/processing_order/shop_channels_select'
import adminStore from '../../../store/admin_store'
import Attachment from '../../../components/admin/processing_order/attachment'
import { setCSRFToken, onShopChange, setStorageTypes, onTargetItemsSet, setProductItems, onSyncProducts} from '../../../actions/admin/processing_order/form'
import AsyncSelect from 'react-select/async'

const store = adminStore()

export default class Form extends Component {
  componentWillMount() {
    if (this.props.csrf_token) store.dispatch(setCSRFToken(this.props.csrf_token))
    if (this.props.storage_types) store.dispatch(setStorageTypes(this.props.storage_types.map(ps => [`${ps.code} - ${ps.name}`,ps.id])))
    if (this.props.target_items) store.dispatch(onTargetItemsSet(this.props.target_items.map(item => {
      item.key ||= item.index;
      item.quantity = item.box_count * item.pcs_per_box;
      return item
    })))
    if (this.props.product_items) store.dispatch(setProductItems(this.props.product_items.map(item => {
      item.key ||= item.index;
      return item
    })))
    store.dispatch(onShopChange(this.props.finished_goods_order.shop_id))
  }
  constructor(props) {
    super(props)
    store.dispatch(onSyncProducts([]))
    this.submit=this.submit.bind(this)
    this.formRef = React.createRef();
  }

  submit(e) {
    let button = e.currentTarget
    button.disabled = true
    let form = this.formRef.current
    let formData = new FormData(form)
    for (const pair of formData.entries()) {
      let key = pair[0]
      let value = pair[1]
      switch(key) {
        case 'finished_goods_order[name]':
          if(!value) {
            notyError('請輸入加工單號')
            button.disabled = false
            return
          }
          break
        case 'finished_goods_order[shop_channel]':
          if(!value) {
            notyError('請選擇通路')
            button.disabled = false
            return
          }
          break
        }
    }
    if (form.checkValidity()) {
      let url = (this.props.action === "new" ? '/admin/processing_orders' : `/admin/processing_orders/${this.props.finished_goods_order.id}`)
      let method = (this.props.action === "new" ? 'POST' : 'PUT')
      fetch(url, {
        method: method,
        credentials: 'same-origin',
        headers: {
          'X-CSRF-Token': this.props.csrf_token
        },
        body: formData
      })
      .then(response => response.json())
      .then(data => {
        if(data.success) {
          window.location.href = (this.props.action === "new" ? '/admin/processing_orders' : `/admin/processing_orders/${this.props.finished_goods_order.id}`)
        }else{
          notyError(data.message)
        }
        button.disabled = false
      }
      )
    }
    else {
      form.reportValidity();
      button.disabled = false
    }
  }


  render() {
    let { finished_goods_order } = this.props
    return (
        <Provider store={store}>
          <div className='panel'>
            <div className='panel-body'>
              <form ref={this.formRef}>
                <div className="col-md-12">
                  <div className="form-group">
                    <label className='required' >加工單號</label>
                    <input className="form-control required" required="required" name="finished_goods_order[name]" defaultValue={finished_goods_order.name}/>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label >預定完工交期</label>
                    <input className="form-control required" type="date" name="finished_goods_order[delivery_date]" defaultValue={finished_goods_order.delivery_date}/>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label >指定通路</label>
                    <ShopChannelSelect defaultChannel={finished_goods_order.shop_channel?.code}></ShopChannelSelect>
                  </div>
                </div>
                <Product /> {/* 來源品項 */}
                <Target /> {/* 加工後品項 */}
                <div className="col-md-12">
                  <div className="form-group string optional finished_goods_order_note">
                    <label className="control-label string optional" htmlFor="finished_goods_order_note">備註</label>
                    <input className="form-control string optional" type="text" name="finished_goods_order[note]" id="finished_goods_order_note" defaultValue={finished_goods_order.note} />
                  </div>
                </div>
                <div className="col-md-12">
                  <Attachment attachments={this.props.attachments}/>
                </div>
              </form>
            </div>
          </div>
          <div className='panel-footer'>
            <button type='button' onClick={this.submit} className='btn btn-primary'>送出</button>
          </div>
        </Provider>
    )
  }
}
