import React, { Component } from 'react'
import TicketMessageForm from '../../../components/common/ticket_message/form'

export default class TicketMessage extends Component {
  render() {
    return (
      <TicketMessageForm
        sender="operator"
        {...this.props}
      />
    )
  }
}
