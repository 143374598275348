import React from 'react';

export default function (props) {
  let {index , active , width  ,pcs} = props

  return (
    <div className={`distribute-grid ${active ? 'active' : ''}`} style={{width: width}}>
      <div className="distribute-grid-index">{index}</div>
      <div className="distribute-grid-pcs">
        {
          pcs > 0 && <span>{pcs}</span>
        }
      </div>
    </div>
  )
}